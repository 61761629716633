// Libs
import classNames from 'classnames/bind';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { FormInput } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { IFormClient } from '~/utils/interface/client';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldContactClient.module.scss';

type Props = {
  fieldIndex: number;
  removeField: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldContactClient = (props: Props) => {
  //#region Destructuring Props
  const { fieldIndex, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IFormClient>();
  //#endregion Declare Hook

  //#region Selector

  //#endregion Selector

  //#region Declare Form Watch
  //#endregion Form Watch

  //#region Declare Memo
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldContactClientComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <Controller
          name={`contacts.${fieldIndex}.name`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('template_form_client_field_name')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              required
              errorMessage={errors.contacts?.[fieldIndex]?.name?.message || EMPTY_STRING}
            />
          )}
        />

        <Controller
          name={`contacts.${fieldIndex}.title`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('template_form_client_field_title')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <Controller
          name={`contacts.${fieldIndex}.email`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('template_form_client_field_email')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              required
              errorMessage={errors.contacts?.[fieldIndex]?.email?.message || EMPTY_STRING}
            />
          )}
        />

        <Controller
          name={`contacts.${fieldIndex}.phoneNumber`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('template_form_client_field_phone')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              type='number'
              errorMessage={errors.contacts?.[fieldIndex]?.phoneNumber?.message}
            />
          )}
        />
      </div>

      <div className={cx('btnRemoveContainer')}>
        <button
          className={cx('btnRemoveField')}
          type='button'
          onClick={() => removeField(fieldIndex)}
        >
          <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
        </button>
      </div>
    </div>
  );
};

export default FieldContactClient;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, CircleAvatar, InputSearch, ReactPortal } from '~/components';
// Others
import { convertToTitleCase } from '~/utils/helper';
import { IAddAssignee } from '~/utils/interface/common';
import {
  ASTERISK_SYMBOL,
  CURRENT_VALUE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  LIMIT_TAG_ASSIGNEE,
  PIXEL,
  PLUS_CHARACTER,
} from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  CircleAvatarEnum,
  HTMLEventEnum,
  InputSearchTypeStyleEnum,
} from '~/utils/enum';
import { IManageTechnicians } from '~/utils/interface/technician';
// Styles, images, icons
import styles from './AdminAddAssigneeModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  technicianList: IManageTechnicians[];
  isRequired?: boolean;
  onAdd: (assignee: IAddAssignee) => void;
  onCloseAssignee: (assignee: IAddAssignee) => void;
  errorMessage?: string;
};

const cx = classNames.bind(styles);

const AdminAddAssigneeModal = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  //#region Destructuring Props
  const { isOpen, technicianList, isRequired, onAdd, onCloseAssignee, errorMessage } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [position, setPosition] = useState({ top: DEFAULT_NUMBER_ZERO, left: DEFAULT_NUMBER_ZERO });
  const [filteredAssignees, setFilteredAssignees] = useState<IAddAssignee[]>([]);
  const [listSelectedAssign, setListSelectedAssign] = useState<IAddAssignee[]>([]);
  const [listAssign, setListAssign] = useState<IAddAssignee[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updatePosition = useCallback(() => {
    if (modalRef && CURRENT_VALUE in modalRef && modalRef.current) {
      const rect = modalRef.current.getBoundingClientRect();
      setPosition({ top: rect.bottom, left: rect.left });
    }
  }, [modalRef]);

  useEffect(() => {
    if (!technicianList) return;

    const newListDataAssignee: IAddAssignee[] = technicianList.map((technician) => ({
      id: parseInt(technician.id),
      firstName: technician.firstName,
      lastName: technician.lastName,
      avatarUrl: technician.avatarUrl ?? EMPTY_STRING,
      status: technician.status,
    }));

    setFilteredAssignees(newListDataAssignee);
    setListAssign(newListDataAssignee);
  }, [technicianList]);

  useEffect(() => {
    if (isOpen) {
      updatePosition();
      window.addEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.addEventListener(HTMLEventEnum.SCROLL, updatePosition);
    } else {
      window.removeEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.removeEventListener(HTMLEventEnum.SCROLL, updatePosition);
    }

    return () => {
      window.removeEventListener(HTMLEventEnum.RESIZE, updatePosition);
      window.removeEventListener(HTMLEventEnum.SCROLL, updatePosition);
    };
  }, [isOpen, updatePosition]);

  //#endregion Implement Hook

  //#region Handle Function
  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node) &&
      modalRef.current &&
      !modalRef.current.contains(event.target as Node)
    ) {
      setIsShow(false);
    }
  };

  const handleShowAssign = () => {
    setIsShow(!isShow);
  };

  const handleAddAssignee = (assignee: IAddAssignee) => {
    onAdd(assignee);
    setListSelectedAssign((prevAssignees) => [...prevAssignees, assignee]);
    setFilteredAssignees((prev) => prev.filter((item) => item.id !== assignee.id));
  };

  const handleCloseAssignee = (assignee: IAddAssignee) => {
    onCloseAssignee(assignee);
    setFilteredAssignees((prevAssignees) => [...prevAssignees, assignee]);
    setListSelectedAssign((prev) => prev.filter((item) => item.id !== assignee.id));
  };

  const handleSearchAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filtered = listAssign
      .filter((assignee) =>
        `${assignee.firstName?.toLowerCase()} ${assignee.lastName?.toLowerCase()}`.includes(
          value.toLowerCase()
        )
      )
      .filter((assignee) => !listSelectedAssign.some((selected) => selected.id === assignee.id));

    setFilteredAssignees(filtered);
  };
  //#endregion Handle Function

  return (
    <div ref={modalRef} id='addAssigneeModalComponent'>
      <h3 className={cx('titleSelect')}>
        {t('admin_manage_schedule_select_assignee_label')}
        {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
      </h3>
      <div className={cx('selectAssign')} onClick={handleShowAssign}>
        <span className={cx('titleAssignee')}>{t('common_placeholder_select')}</span>
        <img
          src={icons.commonIconArrowBottom}
          className={cx('iconDown')}
          alt={t('common_img_text_alt')}
        />
      </div>
      {listSelectedAssign.length > DEFAULT_NUMBER_ZERO && (
        <div className={cx('assigneeSelected')}>
          {listSelectedAssign
            .slice(DEFAULT_NUMBER_ZERO, LIMIT_TAG_ASSIGNEE)
            .map((assigneeItem, index) => {
              return (
                <div className={cx('tagAssigneeSelected')}>
                  <CircleAvatar
                    width={16}
                    height={16}
                    fontSize={8}
                    imageUrl={assigneeItem.avatarUrl}
                    firstName={assigneeItem.firstName}
                    lastName={assigneeItem.lastName}
                    type={assigneeItem.avatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
                  />
                  <span className={cx('tagFirstName')}>{assigneeItem.firstName}</span>
                  <img
                    src={icons.commonIconCloseModal}
                    className={cx('iconCloseAssignee')}
                    onClick={() => handleCloseAssignee(assigneeItem)}
                    alt={t('common_img_text_alt')}
                  />
                </div>
              );
            })}
          {listSelectedAssign.length > LIMIT_TAG_ASSIGNEE && (
            <span className={cx('tagMore')}>{`${PLUS_CHARACTER}${
              listSelectedAssign.length - LIMIT_TAG_ASSIGNEE
            }`}</span>
          )}
        </div>
      )}

      {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}

      {isShow && (
        <ReactPortal wrapperId='reactPortalModalAddAssignee'>
          <div
            style={{ top: `${position.top}${PIXEL}`, left: `${position.left}${PIXEL}` }}
            className={cx('addAssigneeContainer')}
            ref={selectRef}
          >
            <div className={cx('container')}>
              <div className={cx('search')}>
                <InputSearch
                  placeholder={t('common_search_placeholder')}
                  typeStyle={InputSearchTypeStyleEnum.WITH_FULL}
                  onChange={handleSearchAssignee}
                />
              </div>
              <div className={cx('assignee')}>
                {filteredAssignees.length > DEFAULT_NUMBER_ZERO ? (
                  filteredAssignees.map((assigneeItem, index) => {
                    return (
                      <div key={index} className={cx('assigneeItem')}>
                        <div className={cx('avatarFirstLastName')}>
                          <CircleAvatar
                            fontSize={14}
                            firstName={assigneeItem.firstName}
                            lastName={assigneeItem.lastName}
                            imageUrl={assigneeItem.avatarUrl}
                            type={
                              assigneeItem.avatarUrl
                                ? CircleAvatarEnum.IMAGE
                                : CircleAvatarEnum.TEXT
                            }
                          />

                          <div className={cx('firstLastName')}>
                            {`${assigneeItem.firstName} ${assigneeItem.lastName}`}
                          </div>
                        </div>

                        <BaseButton
                          label={t('common_btn_add')}
                          typeStyle={ButtonTypeEnum.SMALL}
                          width={45}
                          height={24}
                          minWidth={45}
                          onClick={() => handleAddAssignee(assigneeItem)}
                        />
                      </div>
                    );
                  })
                ) : (
                  <p className={cx('textNoData')}>{t('common_empty_data')}</p>
                )}
              </div>
            </div>
          </div>
        </ReactPortal>
      )}
    </div>
  );
});

export default AdminAddAssigneeModal;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AvatarStack,
  BasePagination,
  BaseTable,
  ConfirmModal,
  FormTaskModal,
  ModalUnderDevelopment,
  Status,
  ThreeDotOptions,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { deleteTask, getTaskList } from '~/thunks/task/taskThunk';
import { taskActions } from '~/thunks/task/taskSlice';
import { ITask } from '~/utils/interface/task';
import { ColumnType, IPagination, ITableParams } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { AccountRoleCodesEnum, BaseTableEnum, StorageEnum } from '~/utils/enum';
import { formatAddress, formattedTime } from '~/utils/helper';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import styles from './Task.module.scss';

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<'translation'>,
  handleViewTaskDetails: (id: string) => void,
  handleShowEditTaskModal: (id: string) => void,
  handleShowConfirmDeleteModal: (id: string) => void
): ColumnType<ITask>[] => {
  return [
    {
      key: 'name',
      title: t('admin_manage_task_table_label_task_name'),
      dataIndex: 'name',
      render: (_, record) => (
        <div>
          <span>{record.name || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'assignees',
      title: t('admin_manage_task_table_label_assignees'),
      dataIndex: 'assignees',
      render: (_, record) => (
        <AvatarStack
          avatars={record.assignees.map((item) => ({
            ...item,
            avatarUrl: item.avatar,
            thumbnailUrl: item.thumbnail,
          }))}
          remaining
        />
      ),
    },
    {
      key: 'location',
      title: t('admin_manage_task_table_label_location'),
      render: (_, record) => (
        <div>
          <span>
            {formatAddress({
              ...record,
              address: record.streetAddress,
            })}
          </span>
        </div>
      ),
    },
    {
      key: 'dueDate',
      title: t('admin_manage_task_table_label_due_date'),
      dataIndex: 'dueDate',
      render: (_, record) => (
        <div className={cx('textBase')}>
          {record?.dueDate ? formattedTime(record?.dueDate) : EMPTY_STRING}
        </div>
      ),
    },
    {
      key: 'job',
      title: t('admin_manage_task_table_label_job'),
      dataIndex: 'job',
      render: (_, record) => (
        <div>
          <span>{record.job.name || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'status',
      title: t('admin_manage_task_table_label_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record.status} />,
    },
    {
      key: 'action',
      title: t('admin_manage_task_table_label_action'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          onView={() => handleViewTaskDetails(record.id)}
          onEdit={() => handleShowEditTaskModal(record.id)}
          onDelete={() => handleShowConfirmDeleteModal(record.id)}
        />
      ),
    },
  ];
};

type NavigateAction = 'create' | 'edit' | 'detail';

const ManageTask = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshTaskList } = useAppSelector((state: RootState) => state.task);
  //#endregion Selector

  //#region Declare State
  const [isDevelopment, setIsDevelopment] = useState<boolean>();
  const [taskList, setTaskList] = useState<ITask[]>([]);
  const [taskSelected, setTaskSelected] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetTaskList(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!isRefreshTaskList) return;

    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
    };

    handleGetTaskList(newParams);
    dispatch(taskActions.setRefreshTaskList(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTaskList]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTaskList = (payload: ITableParams) => {
    loadingData?.show();

    dispatch(getTaskList(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;

        const { pagination, responses } = res?.data;

        setTaskList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAddTask = () => {
    handleNavigate('create');
  };

  const handleViewTaskDetails = (id: string) => {
    if (!id) return;
    handleNavigate('detail', id);
  };

  const handleEditTask = (id: string) => {
    if (!id) return;
    handleNavigate('edit', id);
  };

  const handleNavigate = (action: NavigateAction, id?: string) => {
    const roleRoutes = {
      [AccountRoleCodesEnum.ADMIN]: adminRouteAbsolute,
      [AccountRoleCodesEnum.STAFF]: staffRouteAbsolute,
      [AccountRoleCodesEnum.PROJECT_MANAGER]: projectManageRouteAbsolute,
    };

    if (!currentRole || !(currentRole in roleRoutes)) return;

    const roleRoute = roleRoutes[currentRole as keyof typeof roleRoutes];

    switch (action) {
      case 'create':
        navigate(roleRoute.createTask);
        break;

      case 'edit':
        id && navigate(`${roleRoute.editTask}/${id}`);
        break;

      case 'detail':
        id && navigate(`${roleRoute.task}/${id}`);
        break;

      default:
        break;
    }
  };

  const handleShowConfirmDeleteModal = (id: string) => {
    setTaskSelected(id);
    setIsShowConfirmDelete(true);
  };

  const handleChangePagination = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsDevelopment(false);
  };

  // const handleCloseFormTask = () => {
  //   setTaskSelected('');
  // };

  // const handleFormTaskSuccess = () => {
  //   dispatch(taskActions.setRefreshTaskList(true));
  //   handleCloseFormTask();
  // };

  const handleCancelDeleteTask = () => {
    setIsShowConfirmDelete(false);
    setTaskSelected('');
  };

  const handleDeleteTask = () => {
    loadingData?.show();

    dispatch(deleteTask(taskSelected))
      .unwrap()
      .then((res) => {
        dispatch(taskActions.setRefreshTaskList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        handleCancelDeleteTask();
      });
  };

  const handleCLickRow = (record: ITask) => {
    if (!record.id) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.task}/${record.id}`);

      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.task}/${record.id}`);

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.task}/${record.id}`);
    }
  };

  const handleSearchTask = (value: string) => {
    setSearchKey(value);
  };
  //#endregion Handle Function

  return (
    <div id='manageTaskPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_task_title')}
        primaryBtn={{
          action: handleAddTask,
          label: t('admin_manage_task_btn_add_task'),
        }}
        onSearch={handleSearchTask}
        valueSearch={searchKey || EMPTY_STRING}
      />

      <section className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewTaskDetails,
              handleEditTask,
              handleShowConfirmDeleteModal
            )}
            dataSource={taskList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handleChangePagination}
          />
        </div>
      </section>

      {isShowConfirmDelete && taskSelected && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleCancelDeleteTask}
          onAction={handleDeleteTask}
        />
      )}

      {/* <FormTaskModal
        taskId={taskSelected}
        isOpen={isShowFormTask}
        onClose={handleCloseFormTask}
        onSuccess={handleFormTaskSuccess}
      /> */}

      {isDevelopment && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />}
    </div>
  );
};

export default ManageTask;

// Libs
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { BaseRichEditor, LabelValueField, Status } from '~/components';
// Others
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IPosition } from '~/utils/interface/common';
import { useSelector } from 'react-redux';
import { selectJobDetails } from '~/thunks/job/jobSlice';
import { ModeTypeEnum, ThemeTextEditorEnum } from '~/utils/enum';
import { convertProjectTypeOfJob, formatAddress, formattedTime, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './ServiceJobSummaryTab.module.scss';
import './ServiceJobSummaryTab.scss';

type Props = {};

const cx = classNames.bind(styles);

const ServiceJobSummaryTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useSelector(selectJobDetails);
  //#endregion Selector

  //#region Declare State
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    getUserLocation();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  //#endregion Handle Function

  return (
    <div id='serviceJobSummaryTab' className={cx('container')}>
      <div className={cx('jobDetailsSection')}>
        <div className={cx('jobDetailsContainer')}>
          <div className={cx('jobDetailsWrap')}>
            <div className={cx('jobDetails')}>
              <div className={cx('jobDetailsTitle')}>
                {t('manage_service_jobs_tabs_summary_job_details')}
              </div>

              <LabelValueField
                label={t('manage_service_jobs_tabs_summary_status')}
                value={jobDetails?.status && <Status status={jobDetails?.status} />}
              />

              <LabelValueField
                label={t('manage_service_jobs_tabs_summary_type')}
                value={t(convertProjectTypeOfJob(jobDetails?.type)) || EMPTY_STRING}
              />

              <LabelValueField
                label={t('manage_service_jobs_tabs_summary_resources')}
                value={getFullName({
                  firstName: jobDetails?.creator?.firstName,
                  lastName: jobDetails?.creator?.lastName,
                })}
              />

              <LabelValueField
                label={t('manage_service_jobs_tabs_summary_service_date')}
                value={
                  jobDetails?.serviceDate ? formattedTime(jobDetails.serviceDate) : EMPTY_STRING
                }
              />

              <LabelValueField
                label={t('manage_service_jobs_tabs_summary_service_request')}
                value={
                  <div className={cx('editorServiceRequest')}>
                    <BaseRichEditor
                      mode={ModeTypeEnum.VIEW}
                      theme={ThemeTextEditorEnum.BUBBLE}
                      value={jobDetails?.serviceRequest}
                    />
                  </div>
                }
              />
            </div>
          </div>

          <div className={cx('mapSection')}>
            <div className={cx('jobDetailsItem')}>
              <span className={cx('key')}>{`${t('manage_jobs_tabs_summary_address')}`}</span>
              <span className={cx('value')}>
                {formatAddress({
                  ...jobDetails,
                  address: jobDetails?.streetAddress,
                })}
              </span>
            </div>

            {isLoaded && (
              <div className={cx('map')}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%', borderRadius: 8 }}
                  center={{
                    lat: jobDetails?.latitude || positionSelected.lat,
                    lng: jobDetails?.longitude || positionSelected.lng,
                  }}
                  zoom={DEFAULT_MAP_ZOOM}
                >
                  <Marker
                    position={{
                      lat: jobDetails?.latitude || positionSelected.lat,
                      lng: jobDetails?.longitude || positionSelected.lng,
                    }}
                  />
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceJobSummaryTab;

// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseTextarea,
  FormInput,
  JobAddressFields,
  MultipleSelection,
  SearchDropdown,
  TaskTemplateNameModal,
} from '~/components';
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, StatusEnum, StorageEnum } from '~/utils/enum';
import { IBaseOption } from '~/utils/interface/common';
import { IBodyFormTask, IUpdateTask } from '~/utils/interface/task';
import { DEFAULT_DATA_FROM_TASK, DEFAULT_INIT_EDIT_VALUES, taskSchema } from './helper';
import { useAppDispatch } from '~/redux/hooks';
import {
  createTask,
  getTaskDetails,
  getTaskListTemplate,
  updateTask,
} from '~/thunks/task/taskThunk';
import { CYAN600, WHITE } from '~/utils/constants/color';
import { IAccount, IQueryAccount } from '~/utils/interface/account';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { getFullName } from '~/utils/helper';
import { LoadingData } from '~/context';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
// Styles, images, icons
import styles from './FormTask.module.scss';
import { CommonIconPlus } from '~/assets/svgComponents';

type Props = {
  isEditMode?: boolean;
  onClose: () => void;
};

export type InitEditModeValues = Pick<IBodyFormTask, 'dueDate' | 'completionDate'>;

const cx = classNames.bind(styles);

const FormTask = (props: Props) => {
  //#region Destructuring Props
  const { isEditMode = false, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const initEditModeValues = useRef<InitEditModeValues>(DEFAULT_INIT_EDIT_VALUES);
  const { taskId } = useParams();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State'
  const [isShowAddNameTemplate, setIsShowAddNameTemplate] = useState<boolean>(false);
  const [initValueFormTask, setInitialValueFormTask] = useState<IBodyFormTask>();
  const [listAssignee, setListAssignee] = useState<IAccount[]>([]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    watch,
    formState: { errors, isDirty },
  } = useForm<IBodyFormTask>({
    resolver: yupResolver(
      taskSchema({
        t,
        isEditMode,
        initEditValue: initEditModeValues.current,
        isShowAddNameTemplate,
      })
    ),
    defaultValues: DEFAULT_DATA_FROM_TASK,
  });

  const templateSelected = watch('template');
  //#endregion Declare State

  //#region Declare useMemo
  const assigneeOptions: IBaseOption[] = useMemo(() => {
    if (listAssignee?.length === DEFAULT_NUMBER_ZERO) return [];
    return listAssignee?.map((account: IAccount) => ({
      label: getFullName({ firstName: account.firstName, lastName: account.lastName }),
      value: account.id,
    }));
  }, [listAssignee]);
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    if (templateSelected?.id) {
      setValue('name', templateSelected.name || EMPTY_STRING);
      trigger('name');
      setValue('city', templateSelected.city || EMPTY_STRING);
      setValue('completionDate', templateSelected.completionDate || EMPTY_STRING);
      setValue('country', templateSelected.country || EMPTY_STRING);
      setValue('dueDate', templateSelected.dueDate || EMPTY_STRING);
      trigger('dueDate');
      setValue('templateName', templateSelected.templateName || EMPTY_STRING);
      setValue('note', templateSelected.note || EMPTY_STRING);
      setValue('streetAddress', templateSelected.streetAddress || EMPTY_STRING);
      trigger('streetAddress');
      setValue('state', templateSelected.state || EMPTY_STRING);
      setValue('zipCode', templateSelected.zipCode || EMPTY_STRING);
      setValue('latitude', templateSelected.latitude ?? DEFAULT_NUMBER_ZERO);
      setValue('longitude', templateSelected.longitude ?? DEFAULT_NUMBER_ZERO);
      setValue(
        'assigneeIds',
        templateSelected.assignees?.map((assignee) => Number(assignee.id)) || []
      );
      trigger('assigneeIds');
      setValue('jobId', Number(templateSelected.job?.id) ?? null);
      trigger('jobId');
      setValue('job', { id: templateSelected.job?.id || EMPTY_STRING });
      trigger('job');
      setValue('phaseId', Number(templateSelected.phase?.id) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSelected?.id]);

  useEffect(() => {
    handleGetAssigneeList({
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [
        AccountRoleCodesEnum.STAFF,
        AccountRoleCodesEnum.PROJECT_MANAGER,
        AccountRoleCodesEnum.TECHNICIAN,
      ],
      status: StatusEnum.ACTIVE,
    });

    if (taskId) {
      handleGetDetailTask(taskId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitDataTask = (data: IBodyFormTask) => {
    if (data.isTemplate) {
      setIsShowAddNameTemplate(true);
      return;
    }

    handleSaveFormTask(data);
  };

  const handleSaveFormTask = (data: IBodyFormTask) => {
    const { template, job, ...restData } = data;

    if (!isEditMode) {
      handleCreateTask(restData);
      return;
    }

    if (isEditMode && taskId) {
      handleEditTask(restData);
      return;
    }
  };

  const handleCancelTask = () => {
    onClose && onClose();
  };

  const handlePreparedData = (data: IBodyFormTask) => {
    if (!data) return {} as IBodyFormTask;

    const formattedData: IBodyFormTask = { ...data };
    const bodyData: Partial<IBodyFormTask> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IBodyFormTask] = value;
      }
    });

    return bodyData as IBodyFormTask;
  };

  const handleCreateTask = (data: IBodyFormTask) => {
    const newData = handlePreparedData(data);
    if (!newData || Object.keys(newData).length === DEFAULT_NUMBER_ZERO) return;

    loading?.show();
    dispatch(createTask(newData))
      .unwrap()
      .then(() => {
        loading?.hide();
        handleCancelTask();
      })
      .catch(() => {})
      .finally(() => {
        loading?.hide();
      });
  };

  const handleEditTask = (data: IBodyFormTask) => {
    if (!taskId) return;
    loading?.show();

    const bodyTask: IUpdateTask = {
      taskId: taskId,
      body: data,
    };

    dispatch(updateTask(bodyTask))
      .unwrap()
      .then((_res) => {
        switch (role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.task}`);
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.task}`);
            break;

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            navigate(`${projectManageRouteAbsolute.task}`);
            break;

          default:
            break;
        }
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const handleCloseModalAddTemplateName = () => {
    setIsShowAddNameTemplate(false);
  };

  const handleGetAssigneeList = (payload: IQueryAccount) => {
    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res.data;

        setListAssignee(responses);
      })
      .catch((error) => {});
  };

  const handleGetDetailTask = (taskId: string) => {
    loading?.show();
    dispatch(getTaskDetails(taskId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const formatData: IBodyFormTask = {
          name: res.data.name || EMPTY_STRING,
          assigneeIds:
            res.data?.assignees.map((assignee) => {
              return assignee.id;
            }) || [],
          jobId: Number(res.data?.job?.id),
          job: { id: res.data?.job?.id || EMPTY_STRING },
          phaseId: Number(res.data?.phase?.id) || null,
          streetAddress: res.data?.streetAddress || EMPTY_STRING,
          city: res.data?.city || EMPTY_STRING,
          state: res.data?.state || EMPTY_STRING,
          zipCode: res.data?.zipCode || EMPTY_STRING,
          dueDate: res.data?.dueDate || EMPTY_STRING,
          completionDate: res.data?.completionDate || EMPTY_STRING,
          note: res.data?.note || EMPTY_STRING,
        };

        reset(formatData);
        setInitialValueFormTask(formatData);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const fetchOptionsTaskTemplate = useCallback(async (search: string, page: number) => {
    try {
      const listTaskTemplate = await dispatch(
        getTaskListTemplate({
          page: page,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listTaskTemplate?.data?.responses,
        hasMore:
          listTaskTemplate?.data?.pagination.page <
            listTaskTemplate?.data?.pagination?.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);
  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        setValue,
        getValues,
        reset,
        handleSubmit,
        formState: { errors },
      } as UseFormReturn<IBodyFormTask>)}
    >
      <form
        id='formTaskComponent'
        className={cx('form')}
        onSubmit={handleSubmit(handleSubmitDataTask)}
      >
        <div className={cx('body')}>
          <div className={cx('twoColumns')}>
            <Controller
              name={'template'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SearchDropdown
                  fetchOptions={fetchOptionsTaskTemplate}
                  label={t('common_form_task_field_template')}
                  placeholder={t('common_placeholder_select')}
                  value={value?.id}
                  onChange={(item) => onChange(item)}
                  renderLabel={(item) => {
                    return <>{item.templateName}</>;
                  }}
                  renderOption={(item, selectedValue) => {
                    return (
                      <div className={cx('optionSelect')}>
                        <div className={cx('templateName')}>{item.templateName}</div>

                        <CommonIconPlus
                          width={16}
                          height={16}
                          strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                        />
                      </div>
                    );
                  }}
                  getOptionKey={(item) => {
                    return item.id;
                  }}
                />
              )}
            />
          </div>

          <div className={cx('twoColumns')}>
            <Controller
              name={'name'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormInput
                  name={name}
                  label={t('common_form_task_field_name')}
                  value={value}
                  onChange={onChange}
                  required
                  errorMessage={errors?.name?.message}
                />
              )}
            />

            <Controller
              name={'assigneeIds'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <MultipleSelection
                  name={name}
                  height={35}
                  label={t('common_form_task_field_assignees')}
                  placeholder={t('common_placeholder_select')}
                  options={assigneeOptions}
                  value={value?.map(String) ?? []}
                  onChange={(optionsSelected: IBaseOption[], _) => {
                    const assigneeValues = optionsSelected.map((option) => Number(option.value));
                    onChange(assigneeValues);
                  }}
                  isRequired
                  errorMessage={errors.assigneeIds?.message}
                />
              )}
            />
          </div>

          <JobAddressFields />

          <div className={cx('twoColumns')}>
            <Controller
              name={'dueDate'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <BaseDatePicker
                  name={name}
                  label={t('common_form_task_field_due_date')}
                  placeholderText={t('common_placeholder_select_date')}
                  value={value}
                  onDateSelected={onChange}
                  isRequired
                  errorMessage={errors?.dueDate?.message}
                />
              )}
            />

            <Controller
              name={'completionDate'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <BaseDatePicker
                  name={name}
                  label={t('common_form_task_field_completion_date')}
                  placeholderText={t('common_placeholder_select_date')}
                  value={value ?? EMPTY_STRING}
                  onDateSelected={onChange}
                  errorMessage={errors?.completionDate?.message}
                />
              )}
            />
          </div>

          <Controller
            name={'note'}
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseTextarea
                name={name}
                label={t('common_form_task_field_note')}
                height={120}
                maxLength={100}
                value={value}
                onTextareaChange={onChange}
                errorMessage={errors?.note?.message}
              />
            )}
          />

          <Controller
            name={'isTemplate'}
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <BaseCheckbox
                name={'isTemplate'}
                value={value || false}
                onChange={onChange}
                label={t('common_form_task_field_save_as_template')}
              />
            )}
          />
        </div>

        <div className={cx('actions')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={onClose}
            />
          </div>

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            type='submit'
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            isDisable={isEditMode && !isDirty}
          />
        </div>
      </form>

      <TaskTemplateNameModal
        isOpen={isShowAddNameTemplate}
        onClose={handleCloseModalAddTemplateName}
        onSave={handleSaveFormTask}
        initValue={initValueFormTask}
      />
    </FormProvider>
  );
};

export default FormTask;

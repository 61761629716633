// Libs
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_TOTAL_ITEM,
  DEFAULT_TOTAL_PAGE,
  MAX_VISIBLE_PAGE,
  SYMBOL_THREE_DOTS,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './BasePagination.module.scss';
import { icons } from '~/assets';

type Props = {
  defaultCurrentPage?: number;
  totalItems?: number;
  totalPages?: number;
  onChange: (currentPage: number) => void;
};

const cx = classNames.bind(styles);

const BasePagination = (props: Props) => {
  //#region Destructuring Props
  const {
    defaultCurrentPage,
    totalItems = DEFAULT_TOTAL_ITEM,
    totalPages = DEFAULT_TOTAL_PAGE,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!defaultCurrentPage) return;

    setCurrentPage(defaultCurrentPage);
  }, [defaultCurrentPage]);
  //#endregion Implement Hook

  //#region Handle Function
  const handlePrevPage = () => {
    if (currentPage <= DEFAULT_CURRENT_PAGE) return;

    setCurrentPage((prev) => prev - 1);
    onChange && onChange(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage >= totalPages) return;

    setCurrentPage((prev) => prev + 1);
    onChange && onChange(currentPage + 1);
  };

  const onClickPageButton = (pageNumber: number) => {
    if (!pageNumber || pageNumber === currentPage) return;

    setCurrentPage(pageNumber);
    onChange && onChange(pageNumber);
  };

  const createPageButton = (pageNumbers: number[]) => {
    if (pageNumbers.length <= 0) return;

    return pageNumbers.map((pageNumber, index) => (
      <button
        type='button'
        key={index}
        onClick={() => onClickPageButton(pageNumber)}
        className={cx(
          'pageButton',
          isNaN(pageNumber) && 'threeDotStyle',
          pageNumber === currentPage && 'active'
        )}
      >
        {isNaN(pageNumber) ? SYMBOL_THREE_DOTS : pageNumber}
      </button>
    ));
  };

  const renderPageNumbers = () => {
    const pageNumbers: number[] = [];

    if (totalPages <= MAX_VISIBLE_PAGE) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      return createPageButton(pageNumbers);
    }

    if (currentPage <= DEFAULT_CURRENT_PAGE + 1 || currentPage >= totalPages - 1) {
      for (let i = 1; i <= DEFAULT_CURRENT_PAGE + 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(NaN);
      for (let i = totalPages - 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      return createPageButton(pageNumbers);
    }

    pageNumbers.push(DEFAULT_CURRENT_PAGE);
    pageNumbers.push(NaN);
    pageNumbers.push(currentPage);
    pageNumbers.push(NaN);
    pageNumbers.push(totalPages);
    return createPageButton(pageNumbers);
  };

  const validatePaginationData = () => {
    return totalItems > 0 && totalPages > 0;
  };
  //#endregion Handle Function

  return (
    <>
      {validatePaginationData() && (
        <div id='basePaginationComponent' className={cx('basePaginationContainer')}>
          <button
            type='button'
            className={cx('pageButton', currentPage === DEFAULT_CURRENT_PAGE && 'disabled')}
            onClick={handlePrevPage}
          >
            <img
              src={icons.commonPaginationIconChevron}
              alt={t('common_img_text_alt')}
              className={cx('leftChevronIcon')}
            />
          </button>

          <>{renderPageNumbers()}</>

          <button
            type='button'
            className={cx('pageButton', currentPage === totalPages && 'disabled')}
            onClick={handleNextPage}
          >
            <img src={icons.commonPaginationIconChevron} alt={t('common_img_text_alt')} />
          </button>
        </div>
      )}
    </>
  );
};

export default BasePagination;

// Libs
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import classNames from 'classnames/bind';
import { Fragment, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { ConfirmModal } from '~/components';
// Others
import { LoadingContext } from '~/context';
import { RootState } from '~/redux/store';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteSchedule } from '~/thunks/schedule/scheduleThunk';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IScheduleEvent } from '~/utils/interface/schedule';
import { AccountRoleCodesEnum, ScheduleJobTypeEnum, StorageEnum } from '~/utils/enum';
import { formattedTime, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './EventContainer.module.scss';

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  data?: IScheduleEvent;
  height?: string | number;
};

const cx = classNames.bind(styles);

const EventContainer = (props: Props) => {
  //#region Destructuring Props
  const { onClick, children, data, height } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  const { refreshSchedule } = useAppSelector((state: RootState) => state.scheduleState);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Selector

  //#region Declare State
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClick = () => {
    onClick && onClick();
  };

  const handleDetails = () => {
    if (!data) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        data.jobType === ScheduleJobTypeEnum.CONTRACT
          ? navigate(`${adminRouteAbsolute.contractJobs}/${data.jobId}`)
          : navigate(`${adminRouteAbsolute.serviceJobs}/${data.jobId}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        data.jobType === ScheduleJobTypeEnum.CONTRACT
          ? navigate(`${staffRouteAbsolute.contractJobs}/${data.jobId}`)
          : navigate(`${staffRouteAbsolute.serviceJobs}/${data.jobId}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        data.jobType === ScheduleJobTypeEnum.CONTRACT
          ? navigate(`${projectManageRouteAbsolute.contractJobs}/${data.jobId}`)
          : navigate(`${projectManageRouteAbsolute.serviceJobs}/${data.jobId}`);
        break;
    }
  };

  const handleUnschedule = () => {
    handleShowConfirmDeleteModal();
  };

  const handleShowConfirmDeleteModal = () => {
    setIsShowConfirmDelete(!isShowConfirmDelete);
  };

  const handleDeleteSchedule = () => {
    if (!data) return;

    loadingContext?.show();
    dispatch(deleteSchedule(data?.id.toString()))
      .unwrap()
      .then(() => {
        handleDeleteScheduleSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleDeleteScheduleSuccess = () => {
    handleShowConfirmDeleteModal();
    dispatch(scheduleActions.setRefreshScheduleList(!refreshSchedule));
  };
  //#endregion Handle Function

  return (
    <Popover as={Fragment}>
      <PopoverButton className={cx('container')} onClick={handleClick} style={{ height }}>
        {children}
      </PopoverButton>

      {isShowConfirmDelete ? (
        <PopoverPanel anchor={{ to: 'top' }}>
          <ConfirmModal
            title={t('admin_manage_schedule_confirm_delete_title')}
            titleAction={t('admin_manage_schedule_popup_button_unschedule')}
            onCancel={handleShowConfirmDeleteModal}
            onAction={handleDeleteSchedule}
          />
        </PopoverPanel>
      ) : (
        <Transition>
          <PopoverPanel anchor={{ to: 'top', gap: '4px' }} className={cx('popupContainer')}>
            <div className={cx('body')}>
              <div className={cx('content')}>
                <span className={cx('title')}>{t('admin_manage_schedule_popup_title_client')}</span>
                <span
                  className={cx('value')}
                  data-tooltip-id={`tooltipName`}
                  data-tooltip-place='top'
                  data-tooltip-variant='light'
                >
                  {getFullName({
                    firstName: data?.clientFirstName,
                    lastName: data?.clientLastName,
                  })}
                </span>

                <Tooltip id={`tooltipName`} className={cx('tooltipWrap')}>
                  <span className={cx('tooltipText')}>
                    {getFullName({
                      firstName: data?.clientFirstName,
                      lastName: data?.clientLastName,
                    })}
                  </span>
                </Tooltip>
              </div>

              <div className={cx('content')}>
                <span className={cx('title')}>{t('admin_manage_schedule_popup_title_job')}</span>
                <span
                  className={cx('value')}
                  data-tooltip-id={`tooltipTitleJob`}
                  data-tooltip-place='top'
                  data-tooltip-variant='light'
                >
                  {data?.jobTitle || EMPTY_STRING}
                </span>

                <Tooltip id={`tooltipTitleJob`} className={cx('tooltipWrap')}>
                  <span className={cx('tooltipText')}> {data?.jobTitle || EMPTY_STRING}</span>
                </Tooltip>
              </div>

              <div className={cx('content')}>
                <span className={cx('title')}>
                  {t('admin_manage_schedule_popup_title_job_phase')}
                </span>
                <span
                  className={cx('value')}
                  data-tooltip-id={`tooltipJobPhase`}
                  data-tooltip-place='top'
                  data-tooltip-variant='light'
                >
                  {data?.phaseName || EMPTY_STRING}
                </span>

                <Tooltip id={`tooltipJobPhase`} className={cx('tooltipWrap')}>
                  <span className={cx('tooltipText')}> {data?.phaseName || EMPTY_STRING}</span>
                </Tooltip>
              </div>

              <div className={cx('content')}>
                <span className={cx('title')}>
                  {t('admin_manage_schedule_popup_title_start_date')}
                </span>

                <span className={cx('value')}>
                  {data?.startDate ? formattedTime(data?.startDate) : EMPTY_STRING}
                </span>
              </div>

              <div className={cx('content')}>
                <span className={cx('title')}>
                  {t('admin_manage_schedule_popup_title_end_date')}
                </span>
                <span className={cx('value')}>
                  {data?.endDate ? formattedTime(data?.endDate) : EMPTY_STRING}
                </span>
              </div>
            </div>

            <div className={cx('buttonGroup')}>
              {currentRole === AccountRoleCodesEnum.ADMIN && (
                <button className={cx('btnUnschedule')} onClick={handleUnschedule}>
                  {t('admin_manage_schedule_popup_button_unschedule')}
                </button>
              )}

              <button
                className={cx('btnDetails', {
                  isExpanded: currentRole !== AccountRoleCodesEnum.ADMIN,
                })}
                onClick={handleDetails}
              >
                {t('admin_manage_schedule_popup_button_details')}
              </button>
            </div>
          </PopoverPanel>
        </Transition>
      )}
    </Popover>
  );
};

export default EventContainer;

// Libs
import classNames from 'classnames/bind';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useParams } from 'react-router-dom';

// Components, Layouts, Pages
import { FormInput, BaseButton, Editor, BaseSelect, ModalUnderDevelopment } from '~/components';

// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { TFormModalMode } from '~/utils/type/common';
import {
  EMPTY_STRING,
  FORM_MAIN_TERM_MODE_CREATE,
  FORM_MAIN_TERM_MODE_EDIT,
  termTypeOptions,
} from '~/utils/constants/common';
import { ButtonTypeEnum, CreateMainTermEnum } from '~/utils/enum';
import { IFormMainContent, IUpdateMainContent } from '~/utils/interface/termsAndConditions';
import {
  createMainContent,
  getMainContentDetail,
  updateMainContent,
} from '~/thunks/termConditions/termConditionsThunk';
import { IBaseOption } from '~/utils/interface/common';
// Styles, images, icons
import styles from './FormMainTerm.module.scss';

type Props<T> = {
  onCancel?: () => void;
  mode?: T;
};

const mainContentSchema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('form_main_terms_title_validate_required_name')),
      upper: yup.string().optional(),
      lower: yup.string().optional(),
      isTemplate: yup.boolean().required(t('form_main_terms_title_validate_required_template')),
      type: yup.string().required(t('form_main_terms_title_validate_required_type_term')),
    })
    .required();
};

const cx = classNames.bind(styles);

const FormMainTerm = <T extends TFormModalMode>(props: Props<T>) => {
  //#region Destructuring Props
  const { onCancel, mode = FORM_MAIN_TERM_MODE_CREATE } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const { mainContentId } = useParams();
  const dispatch = useAppDispatch();

  const defaultData: IFormMainContent = useMemo(() => {
    return {
      name: EMPTY_STRING,
      isTemplate: true,
      type: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormMainContent>({
    resolver: yupResolver(mainContentSchema(t)),
    defaultValues: defaultData,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initDataForm, setInitDataForm] = useState<IFormMainContent>(defaultData);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (mode === FORM_MAIN_TERM_MODE_CREATE) {
      reset(defaultData);
      return;
    }

    if (mode === FORM_MAIN_TERM_MODE_EDIT && mainContentId) {
      handleGetDetailMainContent(mainContentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, mainContentId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSaveFormMainTerm = (data: IFormMainContent) => {
    //handle logic save
    if (mode === FORM_MAIN_TERM_MODE_CREATE) {
      handleAdd(data);
      return;
    }

    if (mode === FORM_MAIN_TERM_MODE_EDIT && mainContentId) {
      handleUpdateMainContent(data);
      return;
    }
  };

  const getChangedValues = (originalValues: IFormMainContent, newValues: IFormMainContent) => {
    const changes: Partial<IFormMainContent> = {};

    Object.entries(newValues).forEach(([key, newValue]) => {
      const typedKey = key as keyof IFormMainContent;
      const originalValue = originalValues[typedKey];

      if (newValue !== originalValue) {
        changes[typedKey] = newValue;
      }
    });

    return changes as IFormMainContent;
  };

  const handleGetDetailMainContent = (id: string) => {
    loadingData?.show();
    dispatch(getMainContentDetail(id))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        if (!res?.data) return;
        const mainContentPrepareData: IFormMainContent = {
          name: res?.data.name || EMPTY_STRING,
          lowerContent: res?.data.lowerContent || EMPTY_STRING,
          upperContent: res?.data.upperContent || EMPTY_STRING,
          isTemplate: res?.data.isTemplate || false,
          type: res?.data.type || EMPTY_STRING,
        };
        setInitDataForm(mainContentPrepareData);
        reset(mainContentPrepareData);
      })
      .catch((error) => {
        loadingData?.hide();
      });
  };

  const handleAdd = (payload: IFormMainContent) => {
    const newData = Object.fromEntries(
      Object.entries(payload).filter(([_, value]) => value !== EMPTY_STRING)
    ) as IFormMainContent;

    if (!newData) return;

    loadingData?.show();
    dispatch(createMainContent(newData))
      .unwrap()
      .then(() => {
        handleCancelFormMainTerm();
      })
      .catch(() => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdateMainContent = (data: IFormMainContent) => {
    if (!mainContentId) return;
    const dataChanges = getChangedValues(initDataForm, data);
    const payload: IUpdateMainContent = {
      mainContentId: mainContentId,
      body: dataChanges,
    };
    loadingData?.show();
    dispatch(updateMainContent(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        handleCancelFormMainTerm();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleCancelFormMainTerm = () => {
    onCancel && onCancel();
  };
  //#endregion Handle Function

  return (
    <>
      <form
        id='formMainTermComponent'
        className={cx('container')}
        onSubmit={handleSubmit(handleSaveFormMainTerm)}
      >
        <div className={cx('content')}>
          <div className={cx('input')}>
            <Controller
              name={CreateMainTermEnum.TYPE}
              control={control}
              render={({ field }) => (
                <BaseSelect
                  label={t('form_main_terms_title_type_term')}
                  placeholder={t('common_placeholder_select')}
                  value={field.value}
                  options={termTypeOptions || []}
                  onChange={({ value }: IBaseOption) => {
                    field.onChange(value);
                  }}
                  isRequired
                  errorMessage={errors.type?.message}
                  disabled={mode === FORM_MAIN_TERM_MODE_EDIT}
                />
              )}
            />

            <Controller
              name={CreateMainTermEnum.NAME}
              control={control}
              render={({ field }) => (
                <FormInput
                  label={t('form_main_terms_title_name')}
                  value={field.value}
                  onChange={field.onChange}
                  errorMessage={errors.name?.message}
                  required
                />
              )}
            />
          </div>
          <div className={cx('upperContent')}>
            <Controller
              name={CreateMainTermEnum.UPPER_CONTENT}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  mode={mode}
                  label={t('form_main_terms_title_upper')}
                  errorMessage={errors.upperContent?.message}
                  height={200}
                  onChange={(value: string) => onChange(value)}
                  value={value}
                />
              )}
            />
          </div>
          <div className={cx('textFormTerm')}>
            <p className={cx('label')}>{t('INCLUSION ZONE')}</p>
            <p className={cx('label')}>{t('EXCLUSION ZONE')}</p>
          </div>
          <div className={cx('upperContent')}>
            <Controller
              name={CreateMainTermEnum.LOWER_CONTENT}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  mode={mode}
                  label={t('form_main_terms_title_lower')}
                  errorMessage={errors.lowerContent?.message}
                  height={200}
                  onChange={(value: string) => onChange(value)}
                  value={value}
                />
              )}
            />
          </div>
        </div>

        <div className={cx('btnGroup')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            type='button'
            onClick={handleCancelFormMainTerm}
          />

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
            isDisable={mode === FORM_MAIN_TERM_MODE_EDIT && !isDirty}
          />
        </div>
      </form>
    </>
  );
};

export default FormMainTerm;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
// Components, Layouts, Pages
import { ProductSpreadsheet, Toolbar } from '~/components';
// Others
import { IBaseOption } from '~/utils/interface/common';
import { TProductGridColumn } from '~/utils/type/common';
import {
  DEFAULT_BUDGET_CATEGORY_EXCEL,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { IBudgetProduct, IPhaseContent, IProductOption } from '~/utils/interface/estimate';
import { CurrencyEnum, TypeProductEnum } from '~/utils/enum';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import { IProductOptionForJob, IQueryListProduct } from '~/utils/interface/product';
import {
  getDetailEstimateByJob,
  getOptionFixtureLocations,
  getOptionProductByBudget,
} from '~/thunks/estimate/estimateThunk';
import { LoadingData } from '~/context';
import { mockJobId } from '~/mockData';
import { convertKeyToCamelCase, formatProductIdSelected } from '~/utils/helper';
import { formatCurrency } from '~/utils/helper';
// Styles, images, icons
import styles from './ViewExcel.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  productMaterials?: IProductOptionForJob[],
  fixtureLocation?: IBaseOption[]
): TProductGridColumn<IBudgetProduct>[] => {
  return [
    {
      columnId: 'productIdSelected',
      dataIndex: 'productIdSelected',
      name: t('view_excel_table_title_item'),
      render: (value) => {
        return {
          type: 'dropdown',
          selectedValue: value?.toString(),
          values: productMaterials || [],
        };
      },
      width: 200,
    },
    {
      columnId: 'fixtureLocationId',
      dataIndex: 'fixtureLocationId',
      name: t('view_excel_table_title_fixture_location'),
      render: (value) => {
        return {
          type: 'dropdown',
          selectedValue: value?.toString(),
          values: fixtureLocation || [],
        };
      },
      width: 200,
    },
    {
      columnId: 'quantity',
      dataIndex: 'quantity',
      name: t('view_excel_table_title_quantity'),
      render: (value) => {
        return { type: 'number', value: Number(value) };
      },
      width: 200,
    },
    {
      columnId: 'unitCost',
      dataIndex: 'unitCost',
      name: t('view_excel_table_title_unit_cost'),
      render: (value) => {
        return {
          type: 'text',
          text: formatCurrency(CurrencyEnum.USD, Number(value) ?? DEFAULT_NUMBER_ZERO).toString(),
        };
      },
      width: 200,
      disabled: true,
    },
    {
      columnId: 'subTotal',
      dataIndex: 'subTotal',
      name: t('view_excel_table_title_subtotal'),
      render: (value) => {
        return {
          type: 'text',
          text: formatCurrency(CurrencyEnum.USD, Number(value) ?? DEFAULT_NUMBER_ZERO).toString(),
        };
      },
      width: 200,
      disabled: true,
    },
  ];
};

const ViewExcel = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { productMaterials, fixtureLocations } = useAppSelector(
    (state: RootState) => state.estimate
  );
  //#endregion Selector

  //#region Declare State
  const [data, setData] = useState<IBudgetProduct[]>([DEFAULT_BUDGET_CATEGORY_EXCEL]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newArr = data.map((item) => ({
      ...item,
      subTotal: Number(item.quantity) * Number(item.unitCost),
    }));
    setData(newArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   //Todo: HuyPahmGRF-ABD - Mock get details job
  //   fetchDetailEstimate(mockJobId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const materialParams: IQueryListProduct = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      type: TypeProductEnum.MATERIAL_SUPPLIER,
    };
    fetchProductByBudget(materialParams);

    const params: IQueryListFixtureLocations = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };
    fetchFixtureLocation(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchProductByBudget = (params: IQueryListProduct) => {
    if (!params) return;
    dispatch(getOptionProductByBudget(params));
  };

  const fetchFixtureLocation = (params: IQueryListFixtureLocations) => {
    if (!params) return;
    dispatch(getOptionFixtureLocations(params));
  };

  // const fetchDetailEstimate = (jobId: string) => {
  //   loadingData?.show();

  //   dispatch(getDetailEstimateByJob(jobId))
  //     .unwrap()
  //     .then((resp) => {
  //       const phases = resp?.data?.phases || [];
  //       const materials: IBudgetProduct[] = extractMaterials(phases);
  //       setData(materials);
  //     })
  //     .catch((error) => {})
  //     .finally(() => {
  //       loadingData?.hide();
  //     });
  // };

  const hasProductSpreadsheetChanged = (
    currentData: IBudgetProduct[],
    newData: IBudgetProduct[]
  ): boolean => {
    if (currentData.length !== newData.length) return true;

    return currentData?.some((item, index) => {
      const other = newData[index];

      return (
        item?.productIdSelected !== other?.productIdSelected ||
        item?.fixtureLocationName !== other?.fixtureLocationName ||
        item?.quantity !== other?.quantity ||
        item?.unitCost !== other?.unitCost ||
        item?.subTotal !== other?.subTotal
      );
    });
  };

  const handleDataChange = (updatedData: IBudgetProduct[]) => {
    const recalculatedData = updatedData.map((item) => {
      const selectedMaterials = productMaterials?.find(
        (product) => product.value === item.productIdSelected
      );
      const selectedFixtureLocation = fixtureLocations?.find(
        (location) => Number(location.value) === Number(item.fixtureLocationId)
      );
      const unitCost = selectedMaterials
        ? Number(selectedMaterials?.unitCost)
        : Number(item.unitCost);
      const validUnitCost = isNaN(unitCost) ? DEFAULT_NUMBER_ZERO : unitCost;
      const subTotal = Number(item.quantity) * validUnitCost;

      return {
        ...item,
        unitCost: validUnitCost,
        subTotal,
        productName: selectedMaterials?.productName || item.productName,
        fixtureLocationName: selectedFixtureLocation?.label || item.fixtureLocationName,
        productIdSelected: item.productIdSelected,
        productId: selectedMaterials?.productId || item.productId,
      };
    });
    if (hasProductSpreadsheetChanged(recalculatedData, data)) {
      setData(recalculatedData);
    }
  };

  const formatExcelDataForMaterials = (excelData: unknown[]): IBudgetProduct[] => {
    const dataExcelCamel = convertKeyToCamelCase(excelData) as IBudgetProduct[];
    // return dataExcelCamel.map((dataItem: IBudgetProduct) => {
    //   const productMatch = productMaterials?.find((item) => item.label === dataItem.productName);
    //   const fixtureMatch = fixtureLocations?.find(
    //     (item) => item.label === dataItem.fixtureLocationName
    //   );

    //   const unitCost = Number(productMatch?.unitCost) || DEFAULT_NUMBER_ZERO;
    //   const productName = productMatch?.label ?? EMPTY_STRING;
    //   const fixtureLocationName = fixtureMatch?.label ?? EMPTY_STRING;
    //   const productId = productMatch?.value ?? null;
    //   const fixtureLocationId = fixtureMatch?.value ?? null;

    //   return {
    //     ...dataItem,
    //     productName,
    //     fixtureLocationName,
    //     unitCost,
    //     subTotal: unitCost * (dataItem.quantity ?? DEFAULT_NUMBER_ZERO),
    //     ...(productMatch?.value ? { productId } : {}),
    //     ...(fixtureMatch?.value ? { fixtureLocationId } : {}),
    //   };
    // });

    return [] as IBudgetProduct[];
  };

  const handleProcessExcelFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const fileData = event.target?.result;
      if (fileData) {
        const workbook = read(fileData); // Parse the array buffer
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]; // Get the first worksheet
        const jsonData = utils.sheet_to_json(firstWorksheet); // Convert to JSON

        // Format data for MATERIALS
        const formattedData = formatExcelDataForMaterials(jsonData);
        console.log('formattedData (MATERIALS)', JSON.stringify(formattedData, null, 2));

        setData((prevData) => [...prevData, ...formattedData]);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleUploadExFile = (file: File | null) => {
    if (!file) return;
    handleProcessExcelFile(file);
  };

  const extractMaterials = (phases: IPhaseContent[]): IBudgetProduct[] => {
    return phases.flatMap((phase: IPhaseContent) =>
      phase.materials.map((material) => ({
        fixtureLocationId: material.fixtureLocationId || DEFAULT_NUMBER_ZERO,
        fixtureLocationName: material.fixtureLocationName || EMPTY_STRING,
        productId: material.productId || DEFAULT_NUMBER_ZERO,
        productName: material.productName || EMPTY_STRING,
        quantity: material.quantity ?? DEFAULT_NUMBER_ZERO,
        subTotal: material.subTotal ?? DEFAULT_NUMBER_ZERO,
        unitCost: material.unitCost ?? DEFAULT_NUMBER_ZERO,
        productIdSelected: formatProductIdSelected(Number(material?.productId), material.vendorId),
      }))
    );
  };
  //#endregion Handle Function

  return (
    <div id='viewExcelPage' className={cx('container')}>
      <Toolbar title={t('contract_job_beta_title')} />

      <div className={cx('body')}>
        <div className={cx('bodyScroll')}>
          <div className={cx('section')}>
            <ProductSpreadsheet
              title={t('template_add_estimate_label_category_material')}
              columns={columns(t, productMaterials, fixtureLocations)}
              dataSource={data}
              defaultDataSource={DEFAULT_BUDGET_CATEGORY_EXCEL}
              onChange={handleDataChange}
              onFileUpload={handleUploadExFile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewExcel;

// Libs
import classNames from 'classnames/bind';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseSelect, FormInput } from '~/components';
// Others
import { IFormCreatePurchaseOrder } from '~/utils/interface/purchaseOrder';
import { IBaseOption, IQueryBase } from '~/utils/interface/common';
import { IProduct } from '~/utils/interface/product';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  REGEX_INTEGER,
  VALID_NUMBER_ZERO,
} from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { getListProductsByVendor } from '~/thunks/product/productThunk';
import { useAppDispatch } from '~/redux/hooks';
// Styles, images, icons
import styles from './ProductPurchaseOrder.module.scss';
import { icons } from '~/assets';

type Props = {
  index: number;
  onRemoveItem: (index: number) => void;
};

const cx = classNames.bind(styles);

const ProductPurchaseOrder = (props: Props) => {
  //#region Destructuring Props
  const { index, onRemoveItem } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IFormCreatePurchaseOrder>();

  const watchQuantity = watch(`products.${index}.quantity`);
  const watchUnitCost = watch(`products.${index}.unitCost`);
  const watchSubtotal = watch(`products.${index}.subTotal`);
  const vendorId = useWatch({ control: control, name: 'vendorId' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [products, setProducts] = useState<IProduct[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!watchUnitCost || !watchQuantity) {
      setValue(`products.${index}.subTotal`, 0);
      return;
    }

    setValue(`products.${index}.subTotal`, watchQuantity * watchUnitCost);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchQuantity, watchUnitCost]);

  useEffect(() => {
    if (!vendorId) return;
    const payload: IQueryBase = {
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      page: DEFAULT_CURRENT_PAGE,
    };

    handleGetListProduct(vendorId, payload);
    setValue(`products.${index}.quantity`, DEFAULT_NUMBER_ONE);
    setValue(`products.${index}.unitCost`, DEFAULT_NUMBER_ZERO);
    setValue(`products.${index}.subTotal`, DEFAULT_NUMBER_ZERO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListProduct = (vendorId: string, payload: IQueryBase) => {
    dispatch(getListProductsByVendor({ vendorId: vendorId, payload: payload }))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res?.data;
        setProducts(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };
  //#endregion Handle Function

  return (
    <div id='productPurchaseOrderComponent' className={cx('container')}>
      <div className={cx('twoCol')}>
        <Controller
          name={`products.${index}.productId`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <BaseSelect
              label={t('create_purchase_label_product')}
              placeholder={t('common_placeholder_select')}
              value={value || EMPTY_STRING}
              options={
                products?.map((option) => ({
                  label: option?.name || EMPTY_STRING,
                  value: option?.id,
                })) || []
              }
              onChange={(optionSelected: IBaseOption) => {
                onChange(optionSelected?.value);
                setValue(`products.${index}.productName`, optionSelected?.label);

                const findProduct = products?.find((item) => item.id === optionSelected?.value);
                setValue(`products.${index}.unitCost`, Number(findProduct?.unitCost) || 0);
              }}
            />
          )}
        />

        <div className={cx('fiveCol')}>
          <Controller
            name={`products.${index}.quantity`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('create_purchase_label_quantity')}
                value={value || EMPTY_STRING}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  if (!value || value === VALID_NUMBER_ZERO) {
                    onChange(DEFAULT_NUMBER_ZERO);
                    return;
                  }

                  const isValidInput = REGEX_INTEGER.test(value);
                  if (isValidInput) {
                    onChange(value);
                  }
                }}
                errorMessage={errors.products?.[index]?.quantity?.message || EMPTY_STRING}
              />
            )}
          />

          <Controller
            name={`products.${index}.unitCost`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('create_purchase_label_unit_cost')}
                value={formatCurrency(CurrencyEnum.USD, value ?? 0)}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  if (!isNaN(Number(value))) {
                    onChange(value);
                  }
                }}
                disabled
              />
            )}
          />

          <div className={cx('itemReadOnly')}>
            <p className={cx('itemLabel')}>{t('create_purchase_label_total')}</p>

            <div className={cx('itemValue')}>
              {formatCurrency(CurrencyEnum.USD, watchSubtotal ?? 0)}
            </div>
          </div>

          <div className={cx('removeItemWrap')}>
            <div className={cx('removeItem')}>
              <img
                src={icons.commonIconRemoveField}
                alt={t('common_img_text_alt')}
                className={cx('removeItemImg')}
                onClick={() => onRemoveItem(index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPurchaseOrder;

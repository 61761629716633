// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
// Components, Layouts, Pages
import {
  AccountantExpensesDefaultTab,
  AccountantIncomeDefaultTab,
  NavTab,
  Toolbar,
} from '~/components';
// Others
import { AccountantTabsEnum, SearchParamsEnum } from '~/utils/enum';
import { accountantTabs } from '~/utils/constants/tab';
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_RECORD_TO_FETCH } from '~/utils/constants/common';
// Styles, images, icons
import styles from './Accountant.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Accountant = (props: Props) => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!params?.tab) {
      setSearchParams({
        tab: AccountantTabsEnum.EXPENSES_DEFAULTS,
      });
    }
  }, [params?.tab]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const currentTab = params?.tab || AccountantTabsEnum.EXPENSES_DEFAULTS;

    switch (currentTab) {
      case AccountantTabsEnum.EXPENSES_DEFAULTS:
        return <AccountantExpensesDefaultTab />;

      case AccountantTabsEnum.INCOME_DEFAULTS:
        return <AccountantIncomeDefaultTab />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [params?.tab, t]);

  const handleSearchParams = (tab: string) => {
    setSearchParams({
      tab,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
    });
  };
  //#endregion Handle Function

  return (
    <div id='accountantPage' className={cx('container')}>
      <Toolbar title={t('template_accountant_toolbar_title')} />

      <div className={cx('body')}>
        <NavTab
          items={accountantTabs}
          searchParams={SearchParamsEnum.TAB}
          onClick={handleSearchParams}
        />

        <div className={cx('tabContent')}>{renderTabContent}</div>
      </div>
    </div>
  );
};

export default Accountant;

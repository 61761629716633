// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BasePagination, BaseTable, Toolbar } from '~/components';
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, BaseTableEnum, CurrencyEnum, StorageEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { ColumnType, IPagination } from '~/utils/interface/common';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import useDebounce from '~/utils/hooks/useDebounce';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { IQueryListVendorByProduct, IVendorProduct } from '~/utils/interface/vendors';
import { getListVendorByProduct } from '~/thunks/vendors/vendorsThunk';
// Styles, images, icons
import styles from './VendorProductTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleShowProductDetail: (productId: string) => void
): ColumnType<IVendorProduct>[] => {
  return [
    {
      key: 'name',
      title: t('admin_manage_vendors_table_name'),
      dataIndex: 'name',
      render: (_, record) => (
        <span
          onClick={() => {
            handleShowProductDetail(String(record?.id));
          }}
          className={cx('tableLink')}
        >
          {record?.name || EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'unitCost',
      title: t('admin_manage_vendors_table_unit_cost'),
      dataIndex: 'unitCost',
      render: (_, record: IVendorProduct) => (
        <div>
          {record.unitCost ? (
            <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitCost))}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'unitPrice',
      title: t('admin_manage_vendors_table_unit_price'),
      dataIndex: 'unitPrice',
      render: (_, record: IVendorProduct) => (
        <div>
          {record.unitPrice ? (
            <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitPrice))}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'sku',
      title: t('admin_manage_vendors_table_sku'),
      dataIndex: 'sku',
      render: (_, record) => <span>{record?.sku || EMPTY_STRING}</span>,
    },
  ];
};

const VendorProductTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [vendorsListByProduct, setVendorsListByProduct] = useState<IVendorProduct[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { tab, ...rest } = params;
    const payload = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListVendorByProduct(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCLickRow = (record: IVendorProduct) => {
    if (!role || !record) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.manageVendors}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.manageVendors}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.manageVendors}/${record.id}`);
        break;
    }
  };

  const handleShowVendorDetail = (vendorId: string) => {
    if (!role || !vendorId) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.manageVendors}/${vendorId}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.manageVendors}/${vendorId}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.manageVendors}/${vendorId}`);
        break;
    }
  };

  const handleSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleGetListVendorByProduct = (payload: IQueryListVendorByProduct) => {
    if (!payload || !productId) return;

    loadingData?.show();
    dispatch(getListVendorByProduct({ productId, payload }))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setVendorsListByProduct(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='vendorProductTab' className={cx('container')}>
      <Toolbar onSearch={handleSearch} rounded={false} valueSearch={searchKey || EMPTY_STRING} />

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(t, handleShowVendorDetail)}
            dataSource={vendorsListByProduct || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorProductTab;

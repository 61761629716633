// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
// Components, Layouts, Pages
import { BaseButton, FormInput, BaseModal } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { IBodyFormTask } from '~/utils/interface/task';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './TaskTemplateNameModal.module.scss';
import { icons } from '~/assets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (payload: IBodyFormTask) => void;
  initValue?: Partial<IBodyFormTask>;
};

const cx = classNames.bind(styles);

const TaskTemplateNameModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onSave, initValue } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext<IBodyFormTask>();

  const isTemplate = useWatch({ control: control, name: 'isTemplate' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isOpen && !isTemplate) {
      const { templateName, ...restValues } = getValues();
      reset({
        ...restValues,
        ...(restValues.isTemplate ? { templateName: templateName } : {}),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isTemplate]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseModal = () => {
    const { templateName, ...restValues } = getValues();
    onClose();
    reset({
      ...restValues,
      ...(initValue?.templateName ? { templateName: initValue.templateName } : {}),
    });
  };

  const handleSave = (value: IBodyFormTask) => {
    onSave(value);
    onClose();
  };
  //#endregion Handle Function

  return (
    <BaseModal id='addTaskTemplateModalComponent' isOpen={isOpen} onClose={handleCloseModal}>
      <div className={cx('iconCloseModal')} onClick={handleCloseModal}>
        <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
      </div>

      <div className={cx('header')}>{t('common_form_task_field_template_name')}</div>

      <div className={cx('line')}></div>

      <form className={cx('form')} onSubmit={handleSubmit(handleSave)}>
        <div className={cx('body')}>
          <Controller
            name='templateName'
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('common_form_task_field_name')}
                required
                value={value || EMPTY_STRING}
                onChange={onChange}
                errorMessage={errors.templateName?.message}
              />
            )}
          />
        </div>

        <div className={cx('actions')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            onClick={handleCloseModal}
          />

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default TaskTemplateNameModal;

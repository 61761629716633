import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ServiceJobTypeEnum } from '~/utils/enum';
import { IFormServiceJob, IServiceJobMaterial } from '~/utils/interface/job';

export const formSchema = (payload: { t: TFunction }) => {
  const { t } = payload;

  return yup.object({
    title: yup.string().required(t('common_form_service_job_validate_field_job_name')),
    clientId: yup
      .number()
      .nullable()
      .notOneOf([null], t('common_form_service_job_validate_field_client')),
    streetAddress: yup.string().optional(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    country: yup.string().optional(),
    zipCode: yup.string().optional(),
    latitude: yup.number().optional().nullable(),
    longitude: yup.number().optional().nullable(),
    serviceRequest: yup.string().optional(),
    serviceDate: yup.string().optional(),
    type: yup.string().optional(),
    materials: yup.array(materialsSchema(t)).optional(),
  });
};

const materialsSchema = (t: TFunction) => {
  return yup.object({
    productId: yup.number().optional().nullable(),
    productName: yup.string().optional().nullable(),
    fixtureLocationName: yup.string().optional().nullable(),
    fixtureLocationId: yup.number().optional().nullable(),
    quantity: yup.number().optional(),
    subTotal: yup.number().optional(),
    unitCost: yup.number().optional(),
  });
};

export const DEFAULT_DATA_FROM_JOB_SERVICE: IFormServiceJob = {
  clientId: null,
  title: '',
  type: ServiceJobTypeEnum.TIME_AND_MATERIALS,
  materials: [],
};

export const DEFAULT_MATERIAL_ITEM_JOB_SERVICE: IServiceJobMaterial = {
  productIdSelected: '',
  fixtureLocationId: null,
  quantity: 1,
};

// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseImageCircle, LabelValueField, Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getDetailEmployee } from '~/thunks/employee/employeeThunk';
import { EMPTY_STRING } from '~/utils/constants/common';
import { convertCodeToRole, formattedTime, getFullName, isAdminRole } from '~/utils/helper';
import { IDetailEmployee } from '~/utils/interface/employee';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './AdminEmployeeDetailsModal.module.scss';

type Props = {
  employeeId: string;
  handleEdit: (id: string) => void;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const AdminEmployeeDetailsModal = (props: Props) => {
  //#region Destructuring Props
  const { employeeId, onClose, handleEdit } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAdmin = useMemo(() => isAdminRole(), []);
  //#endregion Declare Hook

  //#region Declare State
  const [employeeDetails, setEmployeeDetails] = useState<IDetailEmployee>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!employeeId) return;
    handleGetDetailEmployee();
  }, [employeeId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailEmployee = async () => {
    setIsLoading(true);

    dispatch(getDetailEmployee(Number(employeeId)))
      .unwrap()
      .then((resp) => {
        const employeeDetail: IDetailEmployee = resp?.data;
        employeeDetail && setEmployeeDetails(employeeDetail);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseModal = () => {
    onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='adminEmployeeDetailsModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={handleCloseModal}
        />

        <div className={cx('title')}>{t('admin_employee_details_title')}</div>

        <div className={cx('body')}>
          <div className={cx('viewInfo')}>
            <LabelValueField
              label={t('admin_employee_details_title_employee_name')}
              value={
                employeeDetails?.firstName || employeeDetails?.lastName ? (
                  <div className={cx('avatarGroup')}>
                    <span>
                      {getFullName({
                        firstName: employeeDetails?.firstName,
                        lastName: employeeDetails?.lastName,
                      })}
                    </span>
                  </div>
                ) : (
                  EMPTY_STRING
                )
              }
            />

            <LabelValueField
              label={t('admin_employee_details_title_phone')}
              value={employeeDetails?.phoneNumber || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_employee_details_title_email')}
              value={employeeDetails?.email || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_employee_details_title_position')}
              value={
                (employeeDetails?.role && t(convertCodeToRole(employeeDetails.role))) ||
                EMPTY_STRING
              }
            />

            <LabelValueField
              label={t('admin_employee_details_title_join_date')}
              value={
                employeeDetails?.joinedDate
                  ? formattedTime(employeeDetails?.joinedDate)
                  : EMPTY_STRING
              }
              isLastItem
            />
          </div>

          <div className={cx('viewAvatar')}>
            {employeeDetails && (
              <BaseImageCircle
                url={employeeDetails?.avatarUrl}
                firstText={employeeDetails?.firstName}
                secondText={employeeDetails?.lastName}
                width={175}
                height={175}
                fontSize={84}
              />
            )}
          </div>
        </div>

        {/* <div className={cx('actions')}>
          {isAdmin && (
            <BaseButton
              label={t('admin_employee_details_button_deactive')}
              width={120}
              onClick={handleDeactivateEmployee}
              typeStyle={ButtonTypeEnum.DEACTIVATE}
            />
          )}

          <BaseButton
            label={t('common_action_edit')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            onClick={handleEditEmployee}
          />
        </div> */}
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminEmployeeDetailsModal;

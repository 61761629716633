// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
// Components, Layouts, Pages
import { BaseCheckbox } from '~/components';
// Others
import { ColumnType, IRowSelection } from '~/utils/interface/common';
import { DEFAULT_NUMBER_RECORD_TO_FETCH, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { BaseTableEnum, KeyTableEnum } from '~/utils/enum';
import { useRowSelection } from '~/utils/hooks/useRowSelection';
// Styles, images, icons
import styles from './TableSelected.module.scss';

type Props<T> = {
  columns: ColumnType<T>[];
  dataSource: T[];
  typeStyle?: BaseTableEnum;
  onClickRow?: (record: T) => void;
  rowSelection?: IRowSelection<T>;
};

const cx = classNames.bind(styles);

const TableSelected = <T extends { id: React.Key }>(props: Props<T>) => {
  //#region Destructuring Props
  const { dataSource = [], columns, typeStyle, onClickRow, rowSelection } = props;
  const visibleData = useMemo(
    () => dataSource.slice(DEFAULT_NUMBER_ZERO, DEFAULT_NUMBER_RECORD_TO_FETCH),
    [dataSource]
  );
  const { selectedRowKeys, handleSelectRow, handleSelectAllRows, isAllRowsSelected } =
    useRowSelection(dataSource, rowSelection, visibleData);

  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickRow = (record: T) => {
    onClickRow && onClickRow(record);
  };

  const renderHeaderSelected = () =>
    rowSelection && dataSource.length > DEFAULT_NUMBER_ZERO ? (
      <th className={cx('colTable', 'checkbox', typeStyle)}>
        <BaseCheckbox
          name=''
          value={isAllRowsSelected}
          onChange={(checked) => handleSelectAllRows(checked)}
        />
      </th>
    ) : null;

  const renderSelected = (key: React.Key) =>
    rowSelection ? (
      <td className={cx('colTableBody', 'checkbox', typeStyle)}>
        <BaseCheckbox
          name=''
          value={selectedRowKeys.includes(key)}
          onChange={(checked) => handleSelectRow(key, checked)}
        />
      </td>
    ) : null;
  //#endregion Handle Function
  return (
    <div id='tableSelectedComponent' className={cx('tableSelectedComponent', typeStyle)}>
      <table className={cx('tableContainer', typeStyle)}>
        <thead className={cx('thead', typeStyle)}>
          <tr>
            {renderHeaderSelected()}
            {columns.map((column) => (
              <th className={cx('colTable', typeStyle)} key={column.key}>
                {column.title && column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={cx('tbody', typeStyle)}>
          {dataSource.length > DEFAULT_NUMBER_ZERO ? (
            dataSource.map((data, rowIndex: number) => (
              <tr key={rowIndex} className={cx('rowTableBody', { rowClickable: onClickRow })}>
                {renderSelected(data.id)}
                {columns.map((column) => (
                  <td
                    key={column.key}
                    style={{ maxWidth: column.width, width: column.width }}
                    className={cx('colTableBody', typeStyle)}
                    onClick={() => column.key !== KeyTableEnum.ACTION && handleClickRow(data)}
                  >
                    <div className={cx('cellContainer')}>
                      {column.render
                        ? column.render(data[column.dataIndex!], data, rowIndex)
                        : (data[column.dataIndex!] as React.ReactNode)}
                    </div>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className={cx('noDataAvailable')}>{t('common_empty_data')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default TableSelected;

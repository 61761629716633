// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseImageCircle, LabelValueField, Loading, Toolbar } from '~/components';
// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { getFullName } from '~/utils/helper';
import { IClientDetail } from '~/utils/interface/client';
import { useAppDispatch } from '~/redux/hooks';
import { getClientDetails } from '~/thunks/client/clientThunk';
// Styles, images, icons
import styles from './DetailClient.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailClient = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { clientId } = useParams();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [clientDetail, setClientDetail] = useState<IClientDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!clientId) return;
    handleGetDetailClient(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailClient = (id: string) => {
    setIsLoading(true);
    dispatch(getClientDetails(id))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setClientDetail(res?.data);
      })
      .catch((error) => {})
      .then(() => setIsLoading(false));
  };
  //#endregion Handle Function

  return (
    <div id='detailClientPage' className={cx('container')}>
      <Toolbar title={t('template_client_detail_toolbar_title')} />

      <div className={cx('detailClientWrap')}>
        <div className={cx('detailClientBody')}>
          <div className={cx('viewInfoWrap')}>
            <div className={cx('viewContentUp')}>
              <div className={cx('viewInfoLeft')}>
                <LabelValueField
                  label={t('template_client_detail_client_name_label')}
                  value={
                    clientDetail?.firstName || clientDetail?.lastName ? (
                      <span>
                        {getFullName({
                          firstName: clientDetail?.firstName,
                          lastName: clientDetail?.lastName,
                        })}
                      </span>
                    ) : (
                      EMPTY_STRING
                    )
                  }
                />

                <LabelValueField
                  label={t('template_client_detail_main_contact_label')}
                  value={clientDetail?.contactName ? clientDetail.contactName : EMPTY_STRING}
                />

                <LabelValueField
                  label={t('template_client_detail_phone_label')}
                  value={clientDetail?.phoneNumber ? clientDetail.phoneNumber : EMPTY_STRING}
                />

                <LabelValueField
                  label={t('template_client_detail_email_label')}
                  value={clientDetail?.email ? clientDetail.email : EMPTY_STRING}
                />

                <LabelValueField
                  label={t('template_client_detail_other_label')}
                  value={clientDetail?.other ? clientDetail.other : EMPTY_STRING}
                />
              </div>

              <div className={cx('viewAvatarRight')}>
                <BaseImageCircle
                  url={clientDetail?.avatar}
                  firstText={clientDetail?.firstName}
                  secondText={clientDetail?.lastName}
                  width={175}
                  height={175}
                  fontSize={84}
                />
              </div>
            </div>

            <div className={cx('viewInfoContent')}>
              <LabelValueField
                label={t('template_client_detail_note_label')}
                value={clientDetail?.note ? clientDetail.note : EMPTY_STRING}
                isLongValue
              />

              <LabelValueField
                label={t('template_client_detail_status_quick_book_label')}
                value={
                  clientDetail?.quickBookCustomerId ? (
                    <div className={cx('greenCircle')} />
                  ) : (
                    <div className={cx('grayCircle')} />
                  )
                }
              />
            </div>
          </div>

          <div className={cx('additionalContactWrap')}>
            <div className={cx('additionalContactTitle')}>
              {t('template_client_detail_additional_contact_title')}
            </div>

            {clientDetail?.contacts && clientDetail.contacts.length > DEFAULT_NUMBER_ZERO ? (
              <div className={cx('contentAdditionalContact')}>
                <div className={cx('contactAdditionalHeader')}>
                  <span className={cx('titleContactHeader')}>
                    {t('template_client_detail_additional_contact_name_label')}
                  </span>
                  <span className={cx('titleContactHeader')}>
                    {t('template_client_detail_additional_title_label')}
                  </span>
                  <span className={cx('titleContactHeader')}>
                    {t('template_client_detail_additional_contact_email_label')}
                  </span>
                  <span className={cx('titleContactHeader')}>
                    {t('template_client_detail_additional_contact_phone_label')}
                  </span>
                </div>

                {clientDetail.contacts.map((item) => {
                  return (
                    <div key={item.id} className={cx('additionalContactDetailItem')}>
                      <span className={cx('titleContact')}>
                        {item.name ? item.name : EMPTY_STRING}
                      </span>

                      <span className={cx('titleContact')}>
                        {item.title ? item.title : EMPTY_STRING}
                      </span>

                      <span className={cx('titleContact')}>
                        {item.email ? item.email : EMPTY_STRING}
                      </span>

                      <span className={cx('titleContact')}>
                        {item.phoneNumber ? item.phoneNumber : EMPTY_STRING}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className={cx('textNoData')}>{t('common_empty_data')}</p>
            )}
          </div>
        </div>
      </div>

      {isLoading && <Loading />}
    </div>
  );
};

export default DetailClient;

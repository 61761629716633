import { createAsyncThunk } from '@reduxjs/toolkit';
import billApi from '~/apis/bill';
import {
  GET_BILL_DETAIL,
  GET_LIST_BILL,
  CREATE_BILL,
  RECORD_PAYMENT_BILL,
} from '~/utils/constants/actionType';
import { IBodyRecordBillsPayments, IFormBill } from '~/utils/interface/bill';
import { IQueryBase } from '~/utils/interface/common';

export const createBill = createAsyncThunk(
  CREATE_BILL,
  async (payload: IFormBill, { rejectWithValue }) => {
    try {
      const res = await billApi.createBill(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListBill = createAsyncThunk(
  GET_LIST_BILL,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await billApi.getListBill(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBillDetail = createAsyncThunk(
  GET_BILL_DETAIL,
  async (billId: string, { rejectWithValue }) => {
    try {
      const res = await billApi.getBillDetail(billId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const recordPaymentBill = createAsyncThunk(
  RECORD_PAYMENT_BILL,
  async (payload: IBodyRecordBillsPayments, { rejectWithValue }) => {
    try {
      const res = await billApi.recordPaymentBill(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Others
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import {
  IFormTask,
  IParamsListTaskTemplate,
  ITask,
  ITaskDetails,
  ITaskTemplate,
  IUpdateTask,
} from '~/utils/interface/task';
import { urlApiTask } from '~/utils/constants/common';

const taskApi = {
  getTaskList(params: ITableParams) {
    const url = `${urlApiTask.getTaskList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITask[]>>>(url, {
      params,
    });
  },

  getListTaskTemplate(params: IParamsListTaskTemplate) {
    const url = `${urlApiTask.getTaskListTemplate}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITaskTemplate[]>>>(url, {
      params,
    });
  },

  createTask(body: IFormTask) {
    const url = `${urlApiTask.createTask}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  deleteTask(id: string) {
    const url = `${urlApiTask.deleteTask(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getTaskDetails(id: string) {
    const url = `${urlApiTask.getTaskDetails(id)}`;
    return axiosClient.get<BaseResponse<ITaskDetails>>(url);
  },

  updateTask({ taskId, body }: IUpdateTask) {
    const url = `${urlApiTask.updateTask(taskId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default taskApi;

import { CONTENT_TYPE_FORM_DATA, urlApiDocument } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import {
  ICommonDocument,
  IDocumentDetail,
  IDocumentJobDetails,
  IDocumentResp,
  IGetCommonDocumentReq,
  IFormDocumentUpdate,
  IGetDocumentJobReq,
  IUpdateDocumentJobReq,
} from '~/utils/interface/document';
import { IJobDocument } from '~/utils/interface/job';

const documentApi = {
  getListDocument(params: ITableParams) {
    const url = `${urlApiDocument.document}`;
    return axiosClient.get<BaseResponse<IDocumentResp>>(url, {
      params: params,
    });
  },

  getListDocumentJob(params: IGetDocumentJobReq) {
    const url = `${urlApiDocument.getListDocumentJob}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IJobDocument[]>>>(url, {
      params: params,
    });
  },

  addDocumentJob(formData: FormData) {
    const url = `${urlApiDocument.addDocumentJob}`;
    return axiosClient.post(url, formData, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  downloadDocumentJob(documentId: string) {
    const url = `${urlApiDocument.downloadDocumentJob}/${documentId}`;
    return axiosClient.get<BaseResponse<ArrayBuffer>>(url);
  },

  getDocumentJobDetails(id: string) {
    const url = `${urlApiDocument.getDocumentJobDetails}/${id}`;
    return axiosClient.get<BaseResponse<IDocumentJobDetails>>(url);
  },

  updateDocumentJob(request: IUpdateDocumentJobReq) {
    const url = `${urlApiDocument.updateDocument(request.documentId)}`;
    return axiosClient.put<BaseResponse>(url, request.formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  deleteDocumentJob(id: string) {
    const url = `${urlApiDocument.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  addDocument(formData: FormData) {
    const url = `${urlApiDocument.addDocument}`;
    return axiosClient.post<BaseResponse>(url, formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  getListCommonDocument(params: IGetCommonDocumentReq) {
    const url = `${urlApiDocument.getListCommonDocument}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICommonDocument[]>>>(url, {
      params: params,
    });
  },

  getDocumentDetail(documentId: string) {
    const url = `${urlApiDocument.getDocumentDetail}/${documentId}`;
    return axiosClient.get<BaseResponse<IDocumentDetail>>(url);
  },

  updateDocument({ documentId, body }: IFormDocumentUpdate) {
    const url = `${urlApiDocument.updateCommonDocument(documentId)}`;
    return axiosClient.put<BaseResponse>(url, body, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  downloadDocument(documentId: string) {
    const url = `${urlApiDocument.downloadDocument(documentId)}`;
    return axiosClient.get<BaseResponse<ArrayBuffer>>(url);
  },

  deleteCommonDocument(documentId: string) {
    const url = `${urlApiDocument.deleteCommon(documentId)}`;
    return axiosClient.delete<BaseResponse>(url);
  },
};

export default documentApi;

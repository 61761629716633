// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { InputSearchTypeStyleEnum, InputTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './InputSearch.module.scss';
import { icons } from '~/assets';

type Props = {
  width?: string | number;
  placeholder?: string;
  typeStyle?: InputSearchTypeStyleEnum;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
};

const cx = classNames.bind(styles);

const InputSearch = (props: Props) => {
  //#region Destructuring Props
  const { width = '100%', placeholder, typeStyle, onChange, value } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };
  //#endregion Handle Function

  return (
    <div id='inputSearchComponent'>
      <div className={cx('inputSearchWrapper', width, typeStyle)}>
        <input
          type={InputTypeEnum.TEXT}
          placeholder={placeholder}
          onChange={handleChange}
          className={cx('inputSearch', width, typeStyle)}
          value={value}
        />
        <div className={cx('iconInputSearch')}>
          {typeStyle === InputSearchTypeStyleEnum.SEARCH_ANYTHING ? (
            <img
              className={cx('iconSearch')}
              src={icons.commonIconSearchAnything}
              alt={t('common_img_text_alt')}
            />
          ) : (
            <img
              className={cx('iconSearch')}
              src={icons.commonIconSearch}
              alt={t('common_img_text_alt')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InputSearch;

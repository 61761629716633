// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import { BaseButton, BaseImageCircle, BasePhoneNumber, FormInput, Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { profileActions, selectUserProfile } from '~/thunks/profile/profileSlice';
import { updateProfile } from '~/thunks/profile/profileThunk';
import { IFormProfile, IUserProfile } from '~/utils/interface/profile';
import {
  ACCEPT_IMAGE_AVATAR,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FILE_TYPE,
  REGEX,
} from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Profile.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      avatar: yup
        .mixed<File>()
        .nullable()
        .optional()
        .test(FILE_TYPE, t('profile_form_avatar_invalid'), (value) => {
          if (!value) return true;
          return ACCEPT_IMAGE_AVATAR.split(',').some((type) => value.type.includes(type));
        }),
      thumbnail: yup.mixed<File>().nullable().optional(),
      firstName: yup.string().trim().required(t('profile_form_first_name_required')),
      lastName: yup.string().trim().required(t('profile_form_last_name_required')),
      countryCode: yup.string().optional(),
      phoneNumber: yup.string().trim().optional(),
      email: yup
        .string()
        .trim()
        .required(t('profile_form_email_required'))
        .matches(REGEX.EMAIL, t('profile_form_email_invalid')),
      isDeleteFile: yup.boolean().optional(),
    })
    .required();
};

const Profile = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const defaultValue: IFormProfile = useMemo(() => {
    return {
      avatar: null,
      thumbnail: null,
      firstName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      email: EMPTY_STRING,
      isDeleteFile: false,
    };
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IFormProfile>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValue,
  });

  //#endregion Declare Hook

  //#region Selector
  const userProfile: IUserProfile = useSelector(selectUserProfile);
  //#endregion Selector

  //#region Declare State
  const [avatar, setAvatar] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!userProfile) return;

    handleResetFormProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    const picture = userProfile?.thumbnail || userProfile?.avatar;
    setAvatar(picture);
  }, [userProfile?.avatar, userProfile?.thumbnail]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleResetFormProfile = () => {
    const picture = userProfile?.thumbnail || userProfile?.avatar;
    setAvatar(picture);

    reset({
      ...defaultValue,
      countryCode: userProfile.countryCode || EMPTY_STRING,
      email: userProfile.email || EMPTY_STRING,
      firstName: userProfile.firstName || EMPTY_STRING,
      lastName: userProfile.lastName || EMPTY_STRING,
      phoneNumber: userProfile.phoneNumber || EMPTY_STRING,
    });
  };

  const handleDeletePicture = () => {
    setValue('avatar', null);
    setValue('thumbnail', null);
    setAvatar(EMPTY_STRING);
    setValue('isDeleteFile', true);
  };

  const handleProfileChange = (data: IFormProfile) => {
    const formData = new FormData();

    (Object.entries(data) as [keyof IFormProfile, IFormProfile[keyof IFormProfile]][]).forEach(
      ([key, value]) => {
        if (value && key !== 'phoneNumber') {
          formData.append(key, value instanceof File ? value : String(value));
        }

        if (key === 'phoneNumber') {
          formData.append(key, String(value));
        }
      }
    );

    loadingData?.show();

    dispatch(updateProfile(formData))
      .unwrap()
      .then((res) => {
        return dispatch(profileActions.setIsRefresh(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[DEFAULT_NUMBER_ZERO];

    if (selectedFile) {
      setValue('avatar', selectedFile);
      setValue('thumbnail', selectedFile);
      setAvatar(URL.createObjectURL(selectedFile));
      trigger('avatar');
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = EMPTY_STRING;
    }
  };
  //#endregion Handle Function

  return (
    <div id='profilePage' className={cx('container')}>
      <Toolbar title={t('template_sidebar_label_profile')} />

      <div className={cx('bodyContent')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleProfileChange)}>
          <div className={cx('formBody')}>
            <div className={cx('titleGroup')}>
              <img
                src={icons.adminIconProfilePicture}
                alt={t('common_img_text_alt')}
                className={cx('titleIcon')}
              />

              <p className={cx('title')}>{t('profile_form_profile_picture_title')}</p>
            </div>

            <div className={cx('profileAvatarContainer')}>
              <div className={cx('profileAvatar')}>
                <BaseImageCircle
                  url={avatar}
                  firstText={userProfile?.firstName}
                  secondText={userProfile?.lastName}
                  width={120}
                  height={120}
                  fontSize={48}
                />

                <div className={cx('avatarActions')}>
                  <label id='changeAvatar' className={cx('btn', 'changeAvatar')}>
                    <input
                      ref={fileInputRef}
                      type='file'
                      name='changeAvatar'
                      id='changeAvatar'
                      accept={ACCEPT_IMAGE_AVATAR}
                      className={cx('inputChangeAvatar')}
                      onChange={handleAvatarChange}
                    />

                    <span> {t('profile_form_profile_change_picture')}</span>
                  </label>

                  <BaseButton
                    height={31}
                    label={t('profile_form_profile_delete_picture')}
                    onClick={handleDeletePicture}
                    type='button'
                    typeStyle={ButtonTypeEnum.RED_SYSTEM}
                  />
                </div>
              </div>

              {errors.avatar?.message && (
                <p className={cx('errMessage')}>{errors.avatar?.message}</p>
              )}
            </div>

            <section className={cx('information')}>
              <div className={cx('titleGroup')}>
                <img
                  src={icons.adminIconProfilePersonalInformation}
                  alt={t('common_img_text_alt')}
                  className={cx('titleIcon')}
                />

                <p className={cx('title')}>{t('profile_form_personal_information_title')}</p>
              </div>

              <div className={cx('informationBody')}>
                <Controller
                  name='firstName'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('profile_form_first_name_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      required
                      errorMessage={errors.firstName?.message}
                    />
                  )}
                />

                <Controller
                  name='lastName'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('profile_form_last_name_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      required
                      errorMessage={errors.lastName?.message}
                    />
                  )}
                />

                <Controller
                  name='phoneNumber'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BasePhoneNumber
                      label={t('profile_form_phone_label')}
                      phoneNumber={value || EMPTY_STRING}
                      onChange={({ phoneNumber }) => {
                        onChange(phoneNumber);
                      }}
                      errorMessage={errors.phoneNumber?.message}
                    />
                  )}
                />

                <Controller
                  name='email'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('profile_form_email_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      required
                      errorMessage={errors.email?.message}
                    />
                  )}
                />
              </div>
            </section>
          </div>

          <div className={cx('formFooter')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleResetFormProfile}
            />

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { FormEstimateContainer, Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { IDetailEstimate } from '~/utils/interface/estimate';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailEstimate } from '~/thunks/estimate/estimateThunk';
import { formatProductIdSelected } from '~/utils/helper';
// Styles, images, icons
import styles from './DetailEstimate.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailEstimate = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { estimateId } = useParams();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [estimateDetail, setEstimateDetail] = useState<IDetailEstimate>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!estimateId) return;

    handleGetDetailEstimate();
  }, [estimateId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailEstimate = () => {
    loadingData?.show();

    dispatch(getDetailEstimate(Number(estimateId)))
      .unwrap()
      .then((resp) => {
        const estimateData: IDetailEstimate = resp?.data;
        const formattedEstDetailData = formatDataDetailEst(estimateData);
        formattedEstDetailData && setEstimateDetail(formattedEstDetailData);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const formatDataDetailEst = (data: IDetailEstimate): IDetailEstimate => {
    if (!data.phases || !Array.isArray(data.phases)) return data;

    const formattedPhases = data.phases.map((phase) => ({
      ...phase,
      materials:
        phase.materials?.map((item) => ({
          ...item,
          productIdSelected: formatProductIdSelected(Number(item?.productId), item.vendorId),
        })) || [],
      equipments:
        phase.equipments?.map((item) => ({
          ...item,
          productIdSelected: formatProductIdSelected(Number(item?.productId), item.vendorId),
        })) || [],
      miscellanea:
        phase.miscellanea?.map((item) => ({
          ...item,
          productIdSelected: formatProductIdSelected(Number(item?.productId), item.vendorId),
        })) || [],
    }));

    return {
      ...data,
      phases: formattedPhases,
    };
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  //#endregion Handle Function

  return (
    <div id='detailEstimatePage' className={cx('container')}>
      <Toolbar title={t('estimate_detail_estimate')} />

      <div className={cx('body')}>
        <FormEstimateContainer
          mode='edit'
          onCancel={handleGoBack}
          data={estimateDetail}
          isTemplatePage
        />
      </div>
    </div>
  );
};

export default DetailEstimate;

import * as yup from 'yup';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IBodyUpdateExpensesDefault } from '~/utils/interface/expensesDefault';

export const initialValues = {
  items: [],
};

export const accountantExpensesDefault = () => {
  return yup.object().shape({
    items: yup
      .array(
        yup.object<IBodyUpdateExpensesDefault>().shape({
          vendorId: yup.string().optional(),
          quickBookExpenseAccountId: yup.string().optional(),
          quickBookPaymentAccountId: yup.string().optional(),
        })
      )
      .required(),
  });
};

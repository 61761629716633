// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { NavTab } from '~/components';
import {
  AdminJobDocumentsTab,
  AdminJobSummaryTab,
  JobEstimateTabPageTemplate,
  JobLogsTabTemplate,
  JobPOsTabPageTemplate,
  JobProposalTabPageTemplate,
  StaffJobDocumentsTab,
  StaffJobSummaryTab,
} from '~/pages';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getJobDetails } from '~/thunks/job/jobThunk';
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { manageJobsTabs } from '~/utils/constants/tab';
import {
  AccountRoleCodesEnum,
  JobDetailsTypeEnum,
  ManageJobsTabsEnum,
  SearchParamsEnum,
  StorageEnum,
  TypeJobEnum,
} from '~/utils/enum';
import { getFullName } from '~/utils/helper';
import { IGetJobDetailsReq, IJobDetails } from '~/utils/interface/job';
import { jobActions } from '~/thunks/job/jobSlice';
import { RootState } from '~/redux/store';
// Styles, images, icons
import styles from './JobDetails.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const JobDetails = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { jobId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshDetailContract } = useAppSelector((state: RootState) => state.job);

  //#endregion Selector

  //#region Declare State
  const [jobDetails, setJobDetails] = useState<IJobDetails>();
  //#endregion Declare State

  //#region Declare useMemo
  const renderTabTitle = useMemo(() => {
    if (!jobDetails?.estimateId) {
      return manageJobsTabs.filter((item) => item.params !== ManageJobsTabsEnum.PROPOSAL);
    }

    return manageJobsTabs;
  }, [jobDetails?.estimateId]);
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    if (!location.search) {
      searchParams.set(SearchParamsEnum.TAB, ManageJobsTabsEnum.SUMMARY);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location.search]);

  useEffect(() => {
    if (!isRefreshDetailContract || !jobId) return;

    handleGetJobDetails({ id: jobId, params: { type: JobDetailsTypeEnum.SUMMARY } });
    dispatch(jobActions.setRefreshDetailContract(false));
  }, [isRefreshDetailContract, jobId]);

  useEffect(() => {
    if (!jobId) return;

    handleGetJobDetails({ id: jobId, params: { type: JobDetailsTypeEnum.SUMMARY } });
  }, [jobId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetJobDetails = ({ id, params }: { id: string; params: IGetJobDetailsReq }) => {
    loadingData?.show();

    dispatch(getJobDetails({ id, params }))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setJobDetails(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const renderTabContent = () => {
    const currentTab = searchParams.get(SearchParamsEnum.TAB);
    const role = localStorage.getItem(StorageEnum.ROLE);

    if (!currentTab) return;

    if (role === AccountRoleCodesEnum.ADMIN) {
      switch (currentTab) {
        case ManageJobsTabsEnum.SUMMARY:
          return <AdminJobSummaryTab />;

        case ManageJobsTabsEnum.ESTIMATE:
          return <JobEstimateTabPageTemplate />;

        case ManageJobsTabsEnum.PROPOSAL:
          if (!jobDetails?.estimateId) return;
          return <JobProposalTabPageTemplate />;

        case ManageJobsTabsEnum.DOCUMENTS:
          return <AdminJobDocumentsTab />;

        case ManageJobsTabsEnum.PURCHASE_ORDER:
          return <JobPOsTabPageTemplate jobId={jobId} />;

        case ManageJobsTabsEnum.LOGS:
          return <JobLogsTabTemplate typeJob={TypeJobEnum.CONTRACT} />;

        default:
          return <div className=''>{t('common_empty_data')}</div>;
      }
    }

    if (role === AccountRoleCodesEnum.STAFF) {
      switch (currentTab) {
        case ManageJobsTabsEnum.SUMMARY:
          return <StaffJobSummaryTab />;

        case ManageJobsTabsEnum.ESTIMATE:
          return <JobEstimateTabPageTemplate />;

        case ManageJobsTabsEnum.PROPOSAL:
          if (!jobDetails?.estimateId) return;
          return <JobProposalTabPageTemplate />;

        case ManageJobsTabsEnum.DOCUMENTS:
          return <StaffJobDocumentsTab />;

        case ManageJobsTabsEnum.PURCHASE_ORDER:
          return <JobPOsTabPageTemplate jobId={jobId} />;

        case ManageJobsTabsEnum.LOGS:
          return <JobLogsTabTemplate typeJob={TypeJobEnum.CONTRACT} />;

        default:
          return <div className=''>{t('common_empty_data')}</div>;
      }
    }

    if (role === AccountRoleCodesEnum.PROJECT_MANAGER) {
      switch (currentTab) {
        case ManageJobsTabsEnum.SUMMARY:
          return <StaffJobSummaryTab />;

        case ManageJobsTabsEnum.ESTIMATE:
          return <JobEstimateTabPageTemplate />;

        case ManageJobsTabsEnum.PROPOSAL:
          if (!jobDetails?.estimateId) return;
          return <JobProposalTabPageTemplate />;

        case ManageJobsTabsEnum.DOCUMENTS:
          return <StaffJobDocumentsTab />;

        case ManageJobsTabsEnum.PURCHASE_ORDER:
          return <JobPOsTabPageTemplate jobId={jobId} />;

        case ManageJobsTabsEnum.LOGS:
          return <JobLogsTabTemplate typeJob={TypeJobEnum.CONTRACT} />;

        default:
          return <div className=''>{t('common_empty_data')}</div>;
      }
    }
  };

  const handleNavigateToClient = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.clients}/${jobDetails?.client?.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.clients}/${jobDetails?.client?.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.clients}/${jobDetails?.client?.id}`);
        break;
    }
  };

  const handleSearchParams = (tab: string) => {
    setSearchParams({
      tab,
      ...(tab === ManageJobsTabsEnum.DOCUMENTS || tab === ManageJobsTabsEnum.PURCHASE_ORDER
        ? { page: DEFAULT_NUMBER_ONE.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() }
        : {}),
    });
  };

  //#endregion Handle Function

  return (
    <div id='jobDetailsPage' className={cx('container')}>
      <div className={cx('toolBarWrap')}>
        <p className={cx('jobDetailsTitle')}>{jobDetails?.name || EMPTY_STRING}</p>

        <div className={cx('jobDetailsClient')}>
          <span className={cx('jobDetailsClientName')}>
            {t('admin_manage_jobs_details_client')}
          </span>

          <span className={cx('jobDetailsClientValue')} onClick={handleNavigateToClient}>
            {getFullName({ ...jobDetails?.client })}
          </span>

          <div className={cx('quickBookWrap')}>
            {jobDetails?.client?.quickBookCustomerId ? (
              <div className={cx('greenCircle')} />
            ) : (
              <div className={cx('grayCircle')} />
            )}
          </div>
        </div>
      </div>

      <div className={cx('body')}>
        <NavTab
          items={renderTabTitle}
          searchParams={SearchParamsEnum.TAB}
          onClick={handleSearchParams}
        />

        <div className={cx('tabContent')}>{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default JobDetails;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { profileActions, selectUserProfile } from '~/thunks/profile/profileSlice';
import { disconnect, getAuthUrl } from '~/thunks/quickBook/quickBookThunk';
import { IUserProfile } from '~/utils/interface/profile';
import { MESSAGE_CONNECTED_QUICK_BOOK } from '~/utils/constants/common';
import { AccountRoleCodesEnum, StatusQuickBookEnum } from '~/utils/enum';
import { adminRouteAbsolute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './Integration.module.scss';
import { icons, images } from '~/assets';

const cx = classNames.bind(styles);

type Props = {
  role: AccountRoleCodesEnum;
};

const Integration = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserProfile = useSelector(selectUserProfile);

  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === MESSAGE_CONNECTED_QUICK_BOOK) {
        dispatch(profileActions.connectedQuickBook());
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleConnectQuickBook = () => {
    loadingData?.show();

    dispatch(getAuthUrl())
      .unwrap()
      .then((res) => {
        if (!res.data) {
          return;
        }
        const url = res.data.url;
        window.open(url, '_blank');
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDisconnectQuickBook = () => {
    loadingData?.show();

    dispatch(disconnect())
      .unwrap()
      .then((res) => {
        return dispatch(profileActions.disconnectedQuickBook());
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleChangeStatusQuickBooks = () => {
    if (userInfo.quickBook?.status === StatusQuickBookEnum.CONNECTED) {
      handleDisconnectQuickBook();
    } else {
      handleConnectQuickBook();
    }
  };

  const handleNavigateAccountant = () => {
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.accountant);
        break;
      default:
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='integrationPage' className={cx('container')}>
      <Toolbar title={t('integration_title')} />

      <div className={cx('body')}>
        <div className={cx('card')}>
          <div className={cx('cardHeader')}>
            <div className={cx('cardHeaderLogo')}>
              <img
                src={icons.integrationIconIntuitQuickBooksLogo}
                className={cx('cardHeaderLogoImg')}
                alt={t('common_img_text_alt')}
              />

              {role === AccountRoleCodesEnum.ADMIN && (
                <img
                  className={cx('accountant')}
                  src={images.commonIconCalculator}
                  alt={t('common_img_text_alt')}
                  data-tooltip-content={t('integration_quick_books_accountant_tooltip')}
                  data-tooltip-id='accountant'
                  data-tooltip-place='top'
                  onClick={handleNavigateAccountant}
                />
              )}
            </div>
          </div>

          <div className={cx('cardBody')}>
            <div className={cx('cardTitle')}>
              {t('integration_quick_books_title')}
              {userInfo?.quickBook?.status === StatusQuickBookEnum.CONNECTED ? (
                <img
                  data-tooltip-id='status'
                  data-tooltip-place='top'
                  data-tooltip-content={t('integration_quick_books_status_connected')}
                  className={cx('iconStatus')}
                  src={icons.commonIconCheckMark}
                  alt={t('common_img_text_alt')}
                />
              ) : (
                <img
                  data-tooltip-id='status'
                  data-tooltip-place='top'
                  data-tooltip-content={t('integration_quick_books_status_not_connected')}
                  className={cx('iconStatus')}
                  src={icons.commonIconCheckMarkGray}
                  alt={t('common_img_text_alt')}
                />
              )}
            </div>

            <div className={cx('cardDesc')}>{t('integration_quick_books_desc')}</div>
          </div>

          <div className={cx('cardActions')}>
            {role === AccountRoleCodesEnum.ADMIN && (
              <button
                className={cx('cardActionsItem', {
                  disconnected: userInfo?.quickBook?.status === StatusQuickBookEnum.CONNECTED,
                })}
                onClick={handleChangeStatusQuickBooks}
              >
                {userInfo?.quickBook?.status === StatusQuickBookEnum.CONNECTED
                  ? t('integration_quick_books_button_disconnect')
                  : t('integration_quick_books_button_connection')}
              </button>
            )}
          </div>
        </div>
      </div>

      <Tooltip id='accountant' className={cx('tooltipWrap')} />
      <Tooltip id='status' className={cx('tooltipWrap')} />
    </div>
  );
};

export default Integration;

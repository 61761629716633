// Libs
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseImageCircle, LabelValueField, Status, Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import {
  DEFAULT_CURRENCY,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  PERCENT,
} from '~/utils/constants/common';
import { getDetailInvoice } from '~/thunks/invoice/invoiceThunk';
import { IInvoiceDetail } from '~/utils/interface/invoices';
import { formatCurrency, formattedTime, getFullName } from '~/utils/helper';
import { CurrencyEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './DetailInvoice.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailInvoice = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { invoiceId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetail>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!invoiceId) return;

    handleGetInvoiceDetail(invoiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetInvoiceDetail = (invoiceId: string) => {
    loadingData?.show();
    dispatch(getDetailInvoice(invoiceId))
      .unwrap()
      .then((response) => {
        if (!response.data) return;

        setInvoiceDetail(response.data);
      })
      .catch((_error) => {})
      .finally(() => loadingData?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='invoiceDetailPage' className={cx('container')}>
      <Toolbar title={t('template_invoices_detail_toolbar_title')} />

      <div className={cx('body')}>
        <div className={cx('content')}>
          <div className={cx('invoiceDetailWrap')}>
            <div className={cx('invoiceDetailTitle')}>
              {t('template_invoices_detail_basic_information_title')}
            </div>

            <div className={cx('invoiceDetailContent')}>
              <div className={cx('invoiceDetail')}>
                <LabelValueField
                  label={t('template_invoices_detail_invoice_id_label')}
                  value={invoiceDetail?.id || EMPTY_STRING}
                />

                <LabelValueField
                  label={t('template_invoices_detail_invoice_amount_label')}
                  value={
                    invoiceDetail?.total
                      ? formatCurrency(CurrencyEnum.USD, invoiceDetail.total)
                      : DEFAULT_CURRENCY
                  }
                />

                <LabelValueField
                  label={t('template_invoices_detail_status_label')}
                  value={invoiceDetail?.status && <Status status={invoiceDetail?.status} />}
                />
              </div>

              <div className={cx('invoiceDetail')}>
                <LabelValueField
                  label={t('template_invoices_detail_invoice_date_label')}
                  value={
                    invoiceDetail?.createdAt
                      ? formattedTime(invoiceDetail?.createdAt)
                      : EMPTY_STRING
                  }
                />

                <LabelValueField
                  label={t('template_invoices_detail_due_date_label')}
                  value={
                    invoiceDetail?.dueDate ? formattedTime(invoiceDetail?.dueDate) : EMPTY_STRING
                  }
                />

                <LabelValueField
                  label={t('template_invoices_detail_job_label')}
                  value={invoiceDetail?.jobName || EMPTY_STRING}
                />

                <LabelValueField
                  label={t('template_invoices_detail_client_label')}
                  value={
                    invoiceDetail?.client?.firstName || invoiceDetail?.client?.lastName ? (
                      <div className={cx('avatarFirstLastName')}>
                        <BaseImageCircle
                          firstText={invoiceDetail?.client?.firstName}
                          secondText={invoiceDetail?.client?.firstName}
                          url={
                            invoiceDetail?.client?.thumbnailUrl || invoiceDetail?.client?.avatarUrl
                          }
                          width={24}
                          height={24}
                          fontSize={12}
                        />
                        {getFullName({
                          firstName: invoiceDetail?.client?.firstName,
                          lastName: invoiceDetail?.client?.lastName,
                        })}
                      </div>
                    ) : (
                      <div>{EMPTY_STRING}</div>
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className={cx('lineItemsWrap')}>
            <div className={cx('titleGroup')}>{t('template_invoices_detail_line_items_title')}</div>

            {Array.isArray(invoiceDetail?.products) &&
            invoiceDetail?.products?.length > DEFAULT_NUMBER_ZERO ? (
              <div className={cx('lineItems')}>
                <div className={cx('lineItemsHeader')}>
                  <span className={cx('titleLineItemsHeader', 'items')}>
                    {t('template_invoices_detail_item_table_label')}
                  </span>

                  <span className={cx('titleLineItemsHeader')}>
                    {t('template_invoices_detail_value_table_label')}
                  </span>
                </div>

                {invoiceDetail?.products?.map((item, index) => (
                  <div key={index} className={cx('lineItemsBody')}>
                    <span className={cx('titleLineItemsBody', 'items')}>
                      {item?.productName || EMPTY_STRING}
                    </span>

                    <span className={cx('titleLineItemsBody')}>
                      {item?.subTotal
                        ? formatCurrency(CurrencyEnum.USD, item.subTotal)
                        : DEFAULT_CURRENCY}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <p className={cx('textNoData')}>{t('common_empty_data')}</p>
            )}
          </div>

          <div className={cx('totalWrap')}>
            <div className={cx('totalInvoice')}>
              <LabelValueField
                label={t('template_invoices_detail_subtotal_label')}
                value={
                  invoiceDetail?.subTotal
                    ? formatCurrency(CurrencyEnum.USD, invoiceDetail.subTotal)
                    : DEFAULT_CURRENCY
                }
              />

              <LabelValueField
                label={t('template_invoices_detail_tax_label')}
                value={invoiceDetail?.tax ? `${invoiceDetail?.tax}${PERCENT}` : DEFAULT_NUMBER_ZERO}
              />

              <LabelValueField
                label={t('template_invoices_detail_invoice_total_label')}
                value={
                  invoiceDetail?.total
                    ? formatCurrency(CurrencyEnum.USD, invoiceDetail.total)
                    : DEFAULT_CURRENCY
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailInvoice;

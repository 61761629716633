// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  ConfirmModal,
  ThreeDotOptions,
  AdminFormInclusionAndExclusion,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import {
  deleteInclusionExclusion,
  getListInclusionExclusion,
} from '~/thunks/termConditions/termConditionsThunk';
import { IInclusionAndExclusionItem } from '~/utils/interface/termsAndConditions';
import { ColumnType, IListQueryParams, IPagination, IQueryBase } from '~/utils/interface/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { termConditionsAction } from '~/thunks/termConditions/termConditionsSlice';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { BaseTableEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './InclusionAndExclusionItemsTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleShowEditInclusionAndExclusionItem: (item: IInclusionAndExclusionItem) => void,
  handleShowConfirmDelete: (data: IInclusionAndExclusionItem) => void
): ColumnType<IInclusionAndExclusionItem>[] => {
  return [
    {
      key: 'name',
      title: t('inclusion_and_exclusion_items_tab_table_label_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'action',
      title: t('inclusion_and_exclusion_items_tab_table_label_action'),
      render: (_, record) => (
        <ThreeDotOptions
          onEdit={() => handleShowEditInclusionAndExclusionItem(record)}
          onDelete={() => handleShowConfirmDelete(record)}
        />
      ),
      width: '12%',
    },
  ];
};

const InclusionAndExclusionItems = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListInclusionExclusion } = useAppSelector(
    (state: RootState) => state.termConditions
  );
  //#endregion Selector

  //#region Declare State
  const [inclusionAndExclusionItemList, setInclusionAndExclusionItemList] = useState<
    IInclusionAndExclusionItem[]
  >([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowFormInclusionExclusion, setIsShowFormInclusionExclusion] = useState<boolean>(false);
  const [detailInclusionExclusion, setDetailInclusionExclusion] =
    useState<IInclusionAndExclusionItem>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    loading?.show();
    const { tab, ...rest } = params;
    const newParams = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };
    handleGetListInclusionExclusion(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!pagination) return;

    if (textSearchParams) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearchParams]);

  useEffect(() => {
    if (!isRefreshListInclusionExclusion) return;
    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(textSearchParams ? { searchKey: textSearchParams } : {}),
    };

    handleGetListInclusionExclusion(newParams);
    dispatch(termConditionsAction.setRefreshListInclusionExclusion(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListInclusionExclusion]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListInclusionExclusion = (paramsInclusionExclusion: IListQueryParams) => {
    dispatch(getListInclusionExclusion(paramsInclusionExclusion))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res.data;

        pagination && setPagination(pagination);
        responses && setInclusionAndExclusionItemList(responses);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleDeleteInclusionExclusion = () => {
    if (!detailInclusionExclusion) return;

    loading?.show();
    dispatch(deleteInclusionExclusion(detailInclusionExclusion.id))
      .unwrap()
      .then((res) => {
        dispatch(termConditionsAction.setRefreshListInclusionExclusion(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleShowEditInclusionAndExclusionItem = (item?: IInclusionAndExclusionItem) => {
    if (!item) return;

    setIsShowFormInclusionExclusion(true);
    setDetailInclusionExclusion(item);
  };

  const handleCloseFormInclusionExclusion = () => {
    isShowFormInclusionExclusion && setIsShowFormInclusionExclusion(false);
  };

  const handleShowConfirmDelete = (data?: IInclusionAndExclusionItem) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);

    if (!data) return;
    setDetailInclusionExclusion(data);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCLickRow = (record: IInclusionAndExclusionItem) => {
    if (!record) return;

    setIsShowFormInclusionExclusion(true);
    setDetailInclusionExclusion(record);
  };
  //#endregion Handle Function

  return (
    <div id='inclusionAndExclusionItems' className={cx('container')}>
      <div className={cx('table')}>
        <BaseTable
          typeStyle={BaseTableEnum.COLOR_TABLE}
          columns={columns(t, handleShowEditInclusionAndExclusionItem, handleShowConfirmDelete)}
          dataSource={inclusionAndExclusionItemList || []}
          onClickRow={handleCLickRow}
        />
      </div>

      <div className={cx('pagination')}>
        <BasePagination
          defaultCurrentPage={pageSelected}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalItems}
          onChange={handlePaginationChange}
        />
      </div>

      {isShowConfirmDelete && detailInclusionExclusion && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: detailInclusionExclusion.name,
          })}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDelete}
          onAction={handleDeleteInclusionExclusion}
        />
      )}

      <AdminFormInclusionAndExclusion
        onClose={handleCloseFormInclusionExclusion}
        detailInclusionExclusion={detailInclusionExclusion}
        isOpen={isShowFormInclusionExclusion}
      />
    </div>
  );
};

export default InclusionAndExclusionItems;

import { createAsyncThunk } from '@reduxjs/toolkit';
import phasesApi from '~/apis/phases';
import { GET_LIST_JOB_PHASES } from '~/utils/constants/actionType';
import { IParamJobPhases } from '~/utils/interface/job';

export const getListJobPhases = createAsyncThunk(
  GET_LIST_JOB_PHASES,
  async (payload: IParamJobPhases, { rejectWithValue }) => {
    try {
      const res = await phasesApi.getListJobPhase(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

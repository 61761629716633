import { TFunction } from 'i18next';
import { EMPTY_STRING } from '~/utils/constants/common';
import * as yup from 'yup';

export const settingExpensesDefaultSchema = (payload: { t: TFunction }) => {
  const { t } = payload;

  return yup.object({
    quickBookExpenseAccountId: yup
      .string()
      .required(t('expenses_default_tab_default_expenses_account_required')),
    quickBookPaymentAccountId: yup
      .string()
      .required(t('expenses_default_tab_default_payment_account_required')),
  });
};

// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AdminDeactivatePopup,
  BaseFilter,
  BaseMoreAction,
  BasePagination,
  BaseSelect,
  BaseTable,
  Status,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { changeStatusVendor, getListVendors, sendToQBVendor } from '~/thunks/vendors/vendorsThunk';
import useDebounce from '~/utils/hooks/useDebounce';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  StorageEnum,
  FilterVendorByStatusEnum,
} from '~/utils/enum';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import { IQueryListVendor, IVendor, IFilterVendor } from '~/utils/interface/vendors';
import { formatNumber, formatVendorType } from '~/utils/helper';
import { vendorsActions } from '~/thunks/vendors/vendorSlice';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsFilterVendorByStatus,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { PRIMARY_RED_600 } from '~/utils/constants/color';
// Styles, images, icons
import { icons } from '~/assets';
import { CommonIconInactive } from '~/assets/svgComponents';
import styles from './Vendor.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetailAction: (id: string) => void,
  onEditAction: (values: IVendor) => void,
  onAcInVendorAction: (values: IVendor) => void,
  onSendToQBVendor: (vendorId: string) => void
): ColumnType<IVendor>[] => {
  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('admin_manage_vendors_table_name'),
      render: (_, record: IVendor) => {
        return <div>{record?.name || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'contactName',
      dataIndex: 'contactName',
      title: t('admin_manage_vendors_table_contact_name'),
      render: (_, record: IVendor) => {
        return <div>{record?.contactName || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: t('admin_manage_vendors_table_type'),
      render: (_, record: IVendor) => {
        return <div>{t(formatVendorType(record.type))}</div>;
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('admin_manage_vendors_table_status'),
      render: (_, record: IVendor) => {
        // return <div>{record?.status || EMPTY_STRING}</div>;
        return <Status type='tag' status={record?.status} />;
      },
    },
    {
      key: 'quickBookVendorId',
      title: (
        <span className={cx('titleQuickBook')}>{t('admin_manage_vendors_table_quick_book')}</span>
      ),
      dataIndex: 'quickBookVendorId',
      render: (_, record) => (
        <div className={cx('quickBookWrap')}>
          {record?.quickBookVendorId ? (
            <div className={cx('greenCircle')} />
          ) : (
            <div className={cx('grayCircle')} />
          )}
        </div>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_vendors_table_action'),
      dataIndex: 'id',
      render: (_, record) => {
        const isQBLinked = record?.quickBookVendorId;

        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetailAction(record.id),
          },
          {
            label: t('admin_manage_vendors_table_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onEditAction(record),
          },

          ...(!isQBLinked
            ? [
                {
                  label: t('common_action_send_quick_books'),
                  icon: (
                    <img
                      src={icons.commonIconSend}
                      alt={t('common_img_text_alt')}
                      width={16}
                      height={16}
                    />
                  ),
                  onClick: () => onSendToQBVendor(record?.id),
                },
              ]
            : []),
          {
            label: t('common_btn_update_status'),
            icon: <CommonIconInactive fill={PRIMARY_RED_600} width={16} height={16} />,
            hasOtherColor: true,
            onClick: () => onAcInVendorAction(record),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
      width: '10%',
    },
  ];
};

const ManageVendors = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshVendorList } = useAppSelector((state: RootState) => state.vendors);
  //#endregion Selector

  //#region Declare State
  const [pagination, setPagination] = useState<IPagination>();
  const [vendorList, setVendorList] = useState<IVendor[]>([]);

  const [vendorSelected, setVendorSelected] = useState<IVendor>();
  const [isShowConfirmChangeStatusVendor, setIsShowConfirmChangeStatusVendor] =
    useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === FilterVendorByStatusEnum.ALL
        ? {}
        : { status: status || FilterVendorByStatusEnum.ACTIVE }),
    };

    handleGetListVendors(newParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  // ** Re-fresh list when CRUD **

  useEffect(() => {
    if (!isRefreshVendorList) return;

    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
      ...(params?.status === FilterVendorByStatusEnum.ALL
        ? {}
        : { status: status || FilterVendorByStatusEnum.ACTIVE }),
    };

    handleGetListVendors(newParams);
    dispatch(vendorsActions.setRefreshVendorList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshVendorList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListVendors = (payload: IQueryListVendor) => {
    loadingData?.show();

    dispatch(getListVendors(payload))
      .unwrap()
      .then((res) => {
        const { pagination, responses } = res?.data;
        setVendorList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleNavigateAddVendor = () => {
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.createVendor}`);

      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.createVendor}`);

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.createVendor}`);
    }
  };

  const handleNavigateUpdateVendor = (values: IVendor) => {
    if (!values) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.editVendor}/${values.id}`);

      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.editVendor}/${values.id}`);

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.editVendor}/${values.id}`);
    }
  };

  const handleViewDetailAction = (vendorId: string) => {
    if (!vendorId) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.manageVendors}/${vendorId}`);
      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.manageVendors}/${vendorId}`);
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.manageVendors}/${vendorId}`);
    }
  };

  const handleAcInVendor = () => {
    if (!vendorSelected?.id) return;

    loadingData?.show();

    dispatch(changeStatusVendor(vendorSelected.id))
      .unwrap()
      .then((res) => {
        return dispatch(vendorsActions.setRefreshVendorList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
        loadingData?.hide();
      });
  };

  const handleShowConfirmChangeStatus = (values: IVendor) => {
    if (!values) return;

    setVendorSelected(values);
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSearchVendor = (value: string) => {
    setSearchKey(value);
  };

  const handleTogglePopupUpdateStatus = () => {
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };

  const handleCLickRow = (record: IVendor) => {
    const { id: vendorId } = record;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.manageVendors}/${vendorId}`);
      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.manageVendors}/${vendorId}`);
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.manageVendors}/${vendorId}`);
    }
  };

  const handleSendToQBVendor = (vendorId: string) => {
    if (!vendorId) return;

    loadingData?.show();

    dispatch(sendToQBVendor(vendorId))
      .unwrap()
      .then((res) => {
        dispatch(vendorsActions.setRefreshVendorList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleApplyFilterVendor = (valueFilter: IFilterVendor) => {
    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status ? { status: valueFilter?.status } : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='manageVendorsComponent' className={cx('manageVendorsContainer')}>
      <Toolbar
        title={t('admin_manage_vendors_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleNavigateAddVendor,
          label: t('admin_manage_vendors_btn_add'),
        }}
        onSearch={handleSearchVendor}
        valueSearch={searchKey || EMPTY_STRING}
      >
        <BaseFilter<IFilterVendor>
          defaultValue={{ status: FilterVendorByStatusEnum.ACTIVE }}
          onApply={handleApplyFilterVendor}
          valueFilter={{
            status: (params?.status as FilterVendorByStatusEnum) || FilterVendorByStatusEnum.ACTIVE,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('contentFilterWrap')}>
                <div className={cx('labelFilter')}>
                  {t('admin_manage_vendors_filter_by_status_label')}
                </div>

                <BaseSelect
                  name='status'
                  options={optionsFilterVendorByStatus || []}
                  height={31}
                  value={valueFilter?.status}
                  placeholder={t('common_placeholder_select')}
                  onChange={({ value }, name) => {
                    onChange({
                      name: name as keyof IFilterVendor,
                      value: value as FilterVendorByStatusEnum,
                    });
                  }}
                />
              </div>
            );
          }}
        </BaseFilter>
      </Toolbar>

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewDetailAction,
              handleNavigateUpdateVendor,
              handleShowConfirmChangeStatus,
              handleSendToQBVendor
            )}
            dataSource={vendorList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      <AdminDeactivatePopup
        activeDesc={t('admin_manage_vendors_change_status_title_active')}
        deactivateDesc={t('admin_manage_vendors_change_status_title_deactivate')}
        currentStatus={vendorSelected?.status}
        isOpen={isShowConfirmChangeStatusVendor}
        onClose={handleTogglePopupUpdateStatus}
        onUpdateStatus={handleAcInVendor}
      />
    </div>
  );
};

export default ManageVendors;

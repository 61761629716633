// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AdminCreateEmployeeModal,
  AdminDeactivatePopup,
  AdminEmployeeDetailsModal,
  AdminUpdateEmployeeModal,
  BaseFilter,
  BaseImageCircle,
  BaseMoreAction,
  BasePagination,
  BaseSelect,
  BaseTable,
  ModalUnderDevelopment,
  Status,
  Toolbar,
} from '~/components';
// Context
import { LoadingData } from '~/context';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { employeeActions } from '~/thunks/employee/employeeSlice';
import { getListEmployee, updateStatusEmployee } from '~/thunks/employee/employeeThunk';
import useDebounce from '~/utils/hooks/useDebounce';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsFilterEmployeeByStatus,
} from '~/utils/constants/common';
import {
  BaseTableEnum,
  FilterEmployeeByStatusEnum,
  FilterVendorByStatusEnum,
  IFilterEmployee,
} from '~/utils/enum';
import {
  convertCodeToRole,
  formatNumber,
  getAvatarWithName,
  getFullName,
  isAdminRole,
} from '~/utils/helper';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import {
  IEmployee,
  IQueryListEmployee,
  IUpdateStatusEmployeeBody,
} from '~/utils/interface/employee';
import { PRIMARY_RED_600 } from '~/utils/constants/color';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './Employee.module.scss';
import { CommonIconInactive } from '~/assets/svgComponents';

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  onViewDetailAction: (id: string) => void,
  onEditAction: (id: string) => void,
  onUpdateStatusAction: (record: IEmployee) => void
): ColumnType<IEmployee>[] => {
  const isAdmin = isAdminRole();

  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={() => handleSelectAll()}
    //     />
    //   ),
    //   render(_, record: IEmployee) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    // },
    {
      key: 'employeeName',
      title: t('admin_manage_employee_table_employee_name_title'),
      render: (_, record: IEmployee) => {
        return (
          <div className={cx('avatarFirstLastName')}>
            {getAvatarWithName(
              { firstName: record?.firstName, lastName: record?.lastName },
              record?.thumbnailUrl
            ) ? (
              <>
                <BaseImageCircle
                  url={record?.thumbnailUrl ?? record?.avatarUrl}
                  firstText={record?.firstName}
                  secondText={record?.lastName}
                  width={24}
                  height={24}
                  fontSize={12}
                />
                {getFullName({ firstName: record?.firstName, lastName: record?.lastName })}
              </>
            ) : (
              <div>{EMPTY_STRING}</div>
            )}
          </div>
        );
      },
    },
    {
      key: 'phone',
      title: t('admin_manage_employee_table_phone_title'),
      dataIndex: 'phoneNumber',
      render(_, record: IEmployee) {
        return <div>{record?.phoneNumber ?? EMPTY_STRING}</div>;
      },
    },
    {
      key: 'email',
      title: t('admin_manage_employee_table_email_title'),
      dataIndex: 'email',
      render(_, record: IEmployee) {
        return <div>{record?.email || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: t('admin_manage_employee_table_position_title'),
      render(_, record: IEmployee) {
        return <div>{t(convertCodeToRole(record?.role) ?? EMPTY_STRING)}</div>;
      },
    },
    {
      key: 'status',
      title: t('admin_manage_employee_table_status_title'),
      render(_, record: IEmployee) {
        return <Status type='tag' status={record?.status} />;
      },
    },
    {
      key: 'action',
      title: t('admin_manage_contract_jobs_table_action'),
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetailAction(record.id),
          },
          // {
          //   label: t('common_action_edit'),
          //   icon: (
          //     <img
          //       src={icons.commonIconEdit}
          //       alt={t('common_img_text_alt')}
          //       width={16}
          //       height={16}
          //     />
          //   ),
          //   onClick: () => onEditAction(record.id),
          // },
          ...(isAdmin
            ? [
                {
                  label: t('common_btn_update_status'),
                  icon: <CommonIconInactive fill={PRIMARY_RED_600} width={16} height={16} />,
                  hasOtherColor: true,
                  onClick: () => onUpdateStatusAction(record),
                } as MoreActionItem,
              ]
            : []),
        ];

        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const ManagementEmployee = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListEmployee } = useAppSelector((state: RootState) => state.employee);
  //#endregion Selector

  //#region Declare State
  const [pagination, setPagination] = useState<IPagination>();
  const [employeeList, setEmployeeList] = useState<IEmployee[]>([]);

  const [isShowFormAdd, setIsShowFormAdd] = useState<boolean>(false);
  const [isShowFormEdit, setIsShowFormEdit] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
  const [isShowConfirmUpdateStatus, setIsShowConfirmUpdateStatus] = useState<boolean>(false);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);

  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [idEmployee, setIdEmployee] = useState<string>(EMPTY_STRING);
  const [employeeSelected, setEmployeeSelected] = useState<IEmployee | null>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { status, ...restParams } = params;

    const newParams = {
      ...restParams,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.status === FilterVendorByStatusEnum.ALL
        ? {}
        : { status: status || FilterVendorByStatusEnum.ACTIVE }),
    };

    handleGetListEmployee(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  // ** Re-fresh list when CRUD **
  useEffect(() => {
    if (!isRefreshListEmployee) return;

    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
    };

    handleGetListEmployee(newParams);
    dispatch(employeeActions.setRefreshListEmployee(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListEmployee]);

  useEffect(() => {
    if (employeeList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = employeeList.every((employee: IEmployee) => employee.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = employeeList
      .filter((employee: IEmployee) => employee.isSelected)
      .map((employee: IEmployee) => employee.id);

    //@Todo: Perform interactions with the employee id list
  }, [employeeList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListEmployee = (payload: IQueryListEmployee) => {
    loadingData?.show();

    dispatch(getListEmployee(payload))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        const { responses, pagination } = res?.data;

        setEmployeeList(responses);
        setPagination(pagination);
      })
      .catch((error) => {
        loadingData?.hide();
      });
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleToggleModalAdd = () => {
    setIsShowFormAdd(!isShowFormAdd);
  };

  const handleToggleModalEdit = () => {
    setIsShowFormEdit(!isShowFormEdit);
  };

  const handleToggleModalDetail = () => {
    setIsShowDetail(!isShowDetail);
  };

  const handleTogglePopupUpdateStatus = () => {
    setIsShowConfirmUpdateStatus(!isShowConfirmUpdateStatus);
  };

  const handleRefreshList = () => {
    dispatch(employeeActions.setRefreshListEmployee(true));
    setIsShowFormAdd(false);
    setIsShowFormEdit(false);
    setIsShowDetail(false);
    setIsShowConfirmUpdateStatus(false);
    setIdEmployee('');
    setEmployeeSelected(null);
  };

  const handleViewDetailAction = (id: string) => {
    setIdEmployee(id);
    handleToggleModalDetail();
  };

  const handleEditAction = (id: string) => {
    setIdEmployee(id);
    handleToggleModalEdit();
  };

  const handleUpdateStatusAction = (employeeSelected: IEmployee) => {
    setEmployeeSelected(employeeSelected);
    handleTogglePopupUpdateStatus();
  };

  const handleDeactivate = () => {
    if (!employeeSelected) return;

    const payload: IUpdateStatusEmployeeBody = { id: employeeSelected.id };
    loadingData?.show();

    dispatch(updateStatusEmployee(payload))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        handleRefreshList();
      })
      .catch((error) => {
        loadingData?.hide();
      });
  };

  const handleSelectItem = (id: string) => {
    setEmployeeList((prevList: IEmployee[]) =>
      prevList.map((employee: IEmployee) =>
        employee.id === id ? { ...employee, isSelected: !employee.isSelected } : employee
      )
    );
  };

  const handleSelectAll = () => {
    setEmployeeList((prevList) =>
      prevList.map((employee: IEmployee) => ({
        ...employee,
        isSelected: !isSelectAll,
      }))
    );
  };

  const handleSearchEmployee = (value: string) => {
    setSearchKey(value);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleCLickRow = (record: IEmployee) => {
    setIdEmployee(record.id);
    handleToggleModalDetail();
  };

  const handleApplyFilterEmployee = (valueFilter: IFilterEmployee) => {
    const { status, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status ? { status: valueFilter?.status } : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='managementEmployeePage' className={cx('managementEmployeeContainer')}>
      <Toolbar
        title={t('admin_manage_employee_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        {...(isAdminRole() && {
          primaryBtn: {
            action: handleToggleModalAdd,
            label: t('admin_manage_employee_btn_add_employee'),
          },
        })}
        onSearch={handleSearchEmployee}
        valueSearch={searchKey || EMPTY_STRING}
      >
        <BaseFilter<IFilterEmployee>
          defaultValue={{ status: FilterEmployeeByStatusEnum.ACTIVE }}
          onApply={handleApplyFilterEmployee}
          valueFilter={{
            status:
              (params?.status as FilterEmployeeByStatusEnum) || FilterEmployeeByStatusEnum.ACTIVE,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('contentFilterWrap')}>
                <div className={cx('labelFilter')}>
                  {t('admin_manage_employee_filter_by_status_label')}
                </div>

                <BaseSelect
                  name='status'
                  options={optionsFilterEmployeeByStatus || []}
                  height={31}
                  value={valueFilter?.status}
                  placeholder={t('common_placeholder_select')}
                  onChange={({ value }, name) => {
                    onChange({
                      name: name as keyof IFilterEmployee,
                      value: value as FilterEmployeeByStatusEnum,
                    });
                  }}
                />
              </div>
            );
          }}
        </BaseFilter>
      </Toolbar>

      <div className={cx('body')}>
        <div className={cx('buttonAction')}>{/* Handle Action Later */}</div>

        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleViewDetailAction,
              handleEditAction,
              handleUpdateStatusAction
            )}
            dataSource={employeeList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowFormAdd && (
        <AdminCreateEmployeeModal onClose={handleToggleModalAdd} onSuccess={handleRefreshList} />
      )}

      {isShowFormEdit && idEmployee && (
        <AdminUpdateEmployeeModal
          employeeId={idEmployee}
          onClose={handleToggleModalEdit}
          onSuccess={handleRefreshList}
        />
      )}

      {isShowDetail && idEmployee && (
        <AdminEmployeeDetailsModal
          employeeId={idEmployee}
          onClose={handleToggleModalDetail}
          handleEdit={handleEditAction}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}

      <AdminDeactivatePopup
        activeDesc={t('admin_manage_employee_popup_activate_desc')}
        deactivateDesc={t('admin_manage_employee_popup_deactivate_desc')}
        currentStatus={employeeSelected?.status}
        isOpen={isShowConfirmUpdateStatus}
        onClose={handleTogglePopupUpdateStatus}
        onUpdateStatus={handleDeactivate}
      />
    </div>
  );
};

export default ManagementEmployee;

// Others
import { urlApiQuickBook } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse, IQueryBase } from '~/utils/interface/common';
import {
  IAccountQuickBook,
  ICustomerQuickBook,
  IQueryGetTokenQuickBook,
  IQuickBookItem,
  IQuickBooksVendor,
  IQuickBookTerm,
  IResponseGetAuthURL,
} from '~/utils/interface/quickBook';
import axiosClient from './axiosClient';
import {
  IBodyUpdateExpensesDefault,
  IExpensesDefault,
  IListExpensesResponse,
  ISettingExpensesDefault,
} from '~/utils/interface/expensesDefault';
import {
  IBodyUpdateIncomeDefault,
  IIncomeDefault,
  IListDataIncomeDefaultResponse,
  ISettingIncomeDefault,
} from '~/utils/interface/incomeDefault';

const quickBookApi = {
  getAuthUrl() {
    const url = `${urlApiQuickBook.getAuthUrl}`;
    return axiosClient.get<BaseResponse<IResponseGetAuthURL>>(url);
  },

  disconnect() {
    const url = `${urlApiQuickBook.disconnect}`;
    return axiosClient.post<BaseResponse>(url);
  },

  getTokenQuickBook(payload: IQueryGetTokenQuickBook) {
    const { code, state, realmId } = payload;
    const url = `${urlApiQuickBook.getToken}?code=${code}&state=${state}&realmId=${realmId}`;
    return axiosClient.get<BaseResponse>(url);
  },

  getListQuickBookCustomer(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListCustomer}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ICustomerQuickBook[]>>>(url, {
      params: params,
    });
  },

  getListQuickBookItems(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListItem}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IQuickBookItem[]>>>(url, { params });
  },

  getListQuickBookVendors(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListVendors}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IQuickBooksVendor[]>>>(url, { params });
  },

  getListQuickBookTerms(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListTerms}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IQuickBookTerm[]>>>(url, { params });
  },

  getListExpensesDefault(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListExpensesDefault}`;
    return axiosClient.get<BaseResponse<IListExpensesResponse<IExpensesDefault[]>>>(url, {
      params,
    });
  },

  getListAccountQuickBook(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListAccount}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IAccountQuickBook[]>>>(url, { params });
  },

  updateAccountSetting(body: ISettingExpensesDefault) {
    const url = `${urlApiQuickBook.updateAccountExpensesDefault}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  updateExpensesDefault(body: IBodyUpdateExpensesDefault[]) {
    const url = `${urlApiQuickBook.updateExpensesDefault}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getListIncomeDefault(params: IQueryBase) {
    const url = `${urlApiQuickBook.getListIncomeDefault}`;
    return axiosClient.get<BaseResponse<IListDataIncomeDefaultResponse<IIncomeDefault[]>>>(url, {
      params,
    });
  },

  updateAccountIncomeSetting(body: ISettingIncomeDefault) {
    const url = `${urlApiQuickBook.updateAccountIncomeDefault}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  updateIncomeDefault(body: IBodyUpdateIncomeDefault[]) {
    const url = `${urlApiQuickBook.updateIncomeDefault}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default quickBookApi;

import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import authReducer from '~/thunks/auth/authSlice';
import clientReducer from '~/thunks/client/clientSlice';
import documentReducer from '~/thunks/document/documentSlice';
import employeeReducer from '~/thunks/employee/employeeSlice';
import jobReducer from '~/thunks/job/jobSlice';
import noteReducer from '~/thunks/note/noteSlice';
import scheduleReducer from '~/thunks/schedule/scheduleSlice';
import technicianReducer from '~/thunks/technician/technicianSlice';
import timeClockReducer from '~/thunks/timeClock/timeClockSlice';
import vendorsReducer from '~/thunks/vendors/vendorSlice';
import profileReducer from '~/thunks/profile/profileSlice';
import toastReducer from '~/thunks/toast/toastSlice';
import taskReducer from '~/thunks/task/taskSlice';
import accountantReducer from '~/thunks/accountant/accountantSlice';
import productReducer from '~/thunks/product/productSlice';
import serviceReducer from '~/thunks/service/serviceSlice';
import fixtureLocationReducer from '~/thunks/fixtureLocation/fixtureLocationSlice';
import quickBookReducer from '~/thunks/quickBook/quickBookSlice';
import estimateReducer from '~/thunks/estimate/estimateSlice';
import overviewReducer from '~/thunks/overview/overviewSlice';
import termConditionsReducer from '~/thunks/termConditions/termConditionsSlice';
import laborReducer from '~/thunks/labor/LaborSlice';
import proposalReducer from '~/thunks/proposal/proposalSlice';
import invoiceReducer from '~/thunks/invoice/invoiceSlice';
import purchaseOrderReducer from '~/thunks/purchaseOrder/purchaseOrderSlice';
import statisticReducer from '~/thunks/statistic/statisticSlice';
import notificationsReducer from '~/thunks/notifications/notificationsSlice';
import phasesReducer from '~/thunks/phases/phasesSlice';
import billReducer from '~/thunks/bill/billSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  employee: employeeReducer,
  technician: technicianReducer,
  note: noteReducer,
  job: jobReducer,
  document: documentReducer,
  timeClock: timeClockReducer,
  client: clientReducer,
  vendors: vendorsReducer,
  scheduleState: scheduleReducer,
  profileState: profileReducer,
  toastState: toastReducer,
  task: taskReducer,
  accountant: accountantReducer,
  product: productReducer,
  service: serviceReducer,
  fixtureLocation: fixtureLocationReducer,
  quickBook: quickBookReducer,
  estimate: estimateReducer,
  overview: overviewReducer,
  termConditions: termConditionsReducer,
  labor: laborReducer,
  proposal: proposalReducer,
  invoice: invoiceReducer,
  purchaseOrder: purchaseOrderReducer,
  statistic: statisticReducer,
  notificationsState: notificationsReducer,
  phasesState: phasesReducer,
  billState: billReducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;

// Libs
import { Library } from '@googlemaps/js-api-loader';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import { LabelValueField, Status } from '~/components';
// Others
import { selectJobDetails } from '~/thunks/job/jobSlice';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  convertGarageHandOfJob,
  convertProjectTypeOfJob,
  formatAddress,
  formattedTime,
  getFullName,
} from '~/utils/helper';
import { IPosition } from '~/utils/interface/common';
// Styles, images, icons
import styles from './JobSummaryTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const libraries: Library[] = ['places'];

const JobDetailsTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? '',
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useSelector(selectJobDetails);
  //#endregion Selector

  //#region Declare State
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    getUserLocation();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  //#endregion Handle Function

  return (
    <div id={'adminJobSummaryTabComponent'} className={cx('container')}>
      <div className={cx('jobDetailsContainer')}>
        <div className={cx('jobDetailsWrap')}>
          <div className={cx('jobDetails')}>
            <div className={cx('jobDetailsTitle')}>
              {t('admin_manage_jobs_tabs_summary_job_details')}
            </div>
            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_status')}
              value={jobDetails?.status && <Status status={jobDetails?.status} />}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_type')}
              value={t(convertProjectTypeOfJob(jobDetails?.type)) || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_project_manager')}
              value={getFullName({
                ...jobDetails?.manager,
              })}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_bid_due_date')}
              value={jobDetails?.dueDate ? formattedTime(jobDetails?.dueDate) : EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_estimator')}
              value={getFullName({ ...jobDetails?.estimator })}
            />
          </div>

          <div className={cx('constructionDetail')}>
            <div className={cx('jobDetailsTitle')}>
              {t('admin_manage_jobs_tabs_summary_construction_detail')}
            </div>
            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_plan_number')}
              value={jobDetails?.planNumber || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_lot_number')}
              value={jobDetails?.lotNo || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_elevation')}
              value={jobDetails?.elevation || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_garage_hand')}
              value={t(convertGarageHandOfJob(jobDetails?.garageHand)) || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_jobs_tabs_summary_generate_note')}
              value={jobDetails?.note || EMPTY_STRING}
              isLongValue
            />
          </div>
        </div>

        <div className={cx('mapSection')}>
          <div className={cx('jobDetailsItem')}>
            <span className={cx('key')}>{`${t('admin_manage_jobs_tabs_summary_address')}`}</span>
            <span className={cx('value')}>
              {formatAddress({
                ...jobDetails,
                address: jobDetails?.streetAddress,
              })}
            </span>
          </div>

          {isLoaded && (
            <div className={cx('map')}>
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%', borderRadius: 8 }}
                center={{
                  lat: jobDetails?.latitude || positionSelected.lat,
                  lng: jobDetails?.longitude || positionSelected.lng,
                }}
                zoom={DEFAULT_MAP_ZOOM}
              >
                <Marker
                  position={{
                    lat: jobDetails?.latitude || positionSelected.lat,
                    lng: jobDetails?.longitude || positionSelected.lng,
                  }}
                />
              </GoogleMap>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsTab;

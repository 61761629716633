// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, ReactNode, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { IToolbarButton } from '~/utils/interface/common';
// Styles, images, icons
import styles from './Toolbar.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
  desc?: ReactNode;
  childrenRight?: () => ReactNode;
  primaryBtn?: IToolbarButton;
  secondaryBtn?: IToolbarButton;
  rounded?: boolean;
  onSearch?: (value: string) => void;
  valueSearch?: string;
  children?: React.ReactNode;
  actionButton?: IToolbarButton;
};

const Toolbar = (props: Props) => {
  //#region Destructuring Props
  const {
    title,
    desc,
    primaryBtn,
    secondaryBtn,
    rounded = true,
    onSearch,
    valueSearch,
    children,
    childrenRight,
    actionButton,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    onSearch && onSearch(value);
  };
  //#endregion Handle Function

  return (
    <div id='toolbarPage' className={cx('toolbarContainer', { rounded: rounded })}>
      <div className={cx('titleGroup')}>
        {title && <span className={cx('title')}>{title}</span>}
        {desc && (
          <>
            <img src={icons.commonIconChevronRight} alt={t('common_img_text_alt')} />
            {desc}
          </>
        )}
        {childrenRight && childrenRight()}
      </div>
      <div className={cx('actions')}>
        {actionButton && (
          <BaseButton
            label={actionButton.label}
            minWidth={120}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            onClick={actionButton.action}
          />
        )}

        {onSearch && (
          <div className={cx('searchGroup')}>
            <img
              src={icons.commonIconSearchToolBar}
              alt={t('common_img_text_alt')}
              className={cx('searchIcon')}
            />

            <input
              type='text'
              placeholder={t('common_search_placeholder')}
              className={cx('searchInput')}
              value={valueSearch}
              onChange={handleSearchValueChange}
            />
          </div>
        )}

        {secondaryBtn && (
          <BaseButton label={secondaryBtn.label} minWidth={117} onClick={secondaryBtn.action} />
        )}

        {children && children}

        {primaryBtn && (
          <BaseButton
            label={primaryBtn.label}
            minWidth={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            onClick={primaryBtn.action}
          />
        )}
      </div>
    </div>
  );
};

export default Toolbar;

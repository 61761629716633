// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
import { DEFAULT_CURRENCY } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IPurchaseOrderData } from '~/utils/interface/purchaseOrder';
// Styles, images, icons

type Props = {
  dataPdf?: IPurchaseOrderData;
};

const PurchaseOrderTotal = (props: Props) => {
  //#region Destructuring Props
  const { dataPdf } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <View style={styles.total}>
        <Text>Total Price: </Text>

        <Text>
          {dataPdf?.total
            ? formatCurrency(CurrencyEnum.USD, Number(dataPdf?.total))
            : DEFAULT_CURRENCY}
        </Text>
      </View>
    </View>
  );
};

export default PurchaseOrderTotal;

export const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderColor: '#333333',
    paddingTop: 4,
  },

  total: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    marginRight: 8,
  },
});

// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseMoreAction,
  BasePagination,
  BaseTable,
  CircleAvatar,
  CommonConfirmModal,
  ConfirmModal,
  FormServiceJobModal,
  ModalUnderDevelopment,
  Status,
  Table,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import {
  changeStatusServiceJob,
  convertToContractJob,
  deleteServiceJob,
  getListJob,
} from '~/thunks/job/jobThunk';
import { IChangeStatusJobPayload, IGetListJobReq } from '~/utils/interface/job';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
  EMPTY_STRING,
  defaultCurrentPage,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  CircleAvatarEnum,
  GetListJobTypeEnum,
  ServiceJobStatusEnum,
  StorageEnum,
} from '~/utils/enum';
import { formatNumber, getAvatarWithName, getFullName } from '~/utils/helper';
import { jobActions } from '~/thunks/job/jobSlice';
import { RootState } from '~/redux/store';
import { ColumnType, ITableParams, MoreActionItem } from '~/utils/interface/common';
import { IServiceJob, IServiceJobsRes } from '~/utils/interface/serviceJobs';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './ServiceJobs.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetails: (id: string) => void,
  onEdit: (job: IServiceJob) => void,
  onCloseInvoicing: (id: string) => void,
  onClosePerforming: (id: string) => void,
  onConvertContractJob: (id: string) => void,
  onDelete: (id: string) => void
): ColumnType<IServiceJob>[] => {
  return [
    {
      key: 'jobName',
      title: t('admin_manage_service_jobs_table_job_name'),
      dataIndex: 'jobName',
      render: (_, record) => <span>{record?.jobName || EMPTY_STRING}</span>,
    },
    {
      key: 'client',
      title: t('admin_manage_service_jobs_table_client'),
      dataIndex: 'client',
      render: (_, record) =>
        getAvatarWithName(
          { firstName: record?.client?.firstName, lastName: record?.client?.lastName },
          record?.client?.avatarUrl
        ) ? (
          <div className={cx('clientTable')}>
            <CircleAvatar
              type={record?.client?.avatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
              imageUrl={record?.client?.avatarUrl}
              firstName={record?.client?.firstName}
              lastName={record?.client?.lastName}
              width={24}
              height={24}
              fontSize={12}
            />

            {getFullName({
              firstName: record?.client?.firstName,
              lastName: record?.client?.lastName,
            })}
          </div>
        ) : (
          <span>{EMPTY_STRING}</span>
        ),
      width: '25%',
    },
    {
      key: 'resource',
      title: t('admin_manage_service_jobs_table_resource'),
      render: (_, record) =>
        getAvatarWithName(
          { firstName: record?.creator?.firstName, lastName: record?.creator?.lastName },
          record?.creator?.avatarUrl
        ) ? (
          <div className={cx('clientTable')}>
            <CircleAvatar
              type={record?.creator?.avatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
              imageUrl={record?.creator?.avatarUrl}
              firstName={record?.creator?.firstName}
              lastName={record?.creator?.lastName}
              width={24}
              height={24}
              fontSize={12}
            />

            {getFullName({
              firstName: record?.creator?.firstName,
              lastName: record?.creator?.lastName,
            })}
          </div>
        ) : (
          <span>{EMPTY_STRING}</span>
        ),
      width: '25%',
    },
    {
      key: 'status',
      title: t('admin_manage_service_jobs_table_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record?.status} />,
      width: '20%',
    },
    {
      key: 'profit',
      title: t('admin_manage_service_jobs_table_profit'),
      dataIndex: 'profit',
      render: (_, record) => (
        <span>
          {t('common_percent_value', {
            value: record?.profit ? record.profit : DEFAULT_NUMBER_ZERO,
          })}
        </span>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_service_jobs_table_action'),
      width: '10%',

      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetails(record.id),
          },
          {
            label: t('common_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onEdit(record),
          },
          {
            label: t('common_action_convert_contract_job'),
            icon: (
              <img
                src={icons.commonIconConvert}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onConvertContractJob(record.id),
          },
          {
            label: t('common_action_close_invoicing'),
            icon: (
              <img
                src={icons.commonIconCloseRed}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => onCloseInvoicing(record.id),
          },
          {
            label: t('common_action_close_performing'),
            icon: (
              <img
                src={icons.commonIconCloseRed}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => onClosePerforming(record.id),
          },

          // {
          //   label: t('common_btn_delete'),
          //   icon: (
          //     <img
          //       src={icons.commonIconTrash}
          //       alt={t('common_img_text_alt')}
          //       width={16}
          //       height={16}
          //     />
          //   ),
          //   onClick: () => onDelete(record.id),
          //   hasOtherColor: true,
          // },
        ];
        return <BaseMoreAction actions={actions} width={260} />;
      },
    },
  ];
};

const ManageServiceJobs = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshJobList } = useAppSelector((state: RootState) => state.job);
  //#endregion Selector

  //#region Declare State
  const [serviceJobs, setServiceJobs] = useState<IServiceJobsRes>();
  const [serviceJobsList, setServiceJobsList] = useState<IServiceJob[]>([]);
  const [pagination, setPagination] = useState();
  const [isShowAddServiceJob, setIsShowAddServiceJob] = useState<boolean>(false);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isShowRestrictedEdit, setIsShowRestrictedEdit] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowConfirmCloseInvoicing, setIsShowConfirmCloseInvoicing] = useState<boolean>(false);
  const [isShowConfirmClosePerforming, setIsShowConfirmClosePerforming] = useState<boolean>(false);
  const [serviceJobIdSelected, setServiceJobIdSelected] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListServiceJob({ ...newParams, type: GetListJobTypeEnum.SERVICE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!isRefreshJobList) return;

    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
      type: GetListJobTypeEnum.SERVICE,
    };

    handleGetListServiceJob(newParams);
    dispatch(jobActions.setRefreshList(false));
  }, [isRefreshJobList]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (serviceJobsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = serviceJobsList.every((serviceJob: IServiceJob) => serviceJob.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = serviceJobsList
      .filter((serviceJob: IServiceJob) => serviceJob.isSelected)
      .map((serviceJob: IServiceJob) => serviceJob.id);

    //@Todo: Perform interactions with the service job id list
    console.log('Selected Service Job IDs:', selectedIds);
  }, [serviceJobsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListServiceJob = (payload: IGetListJobReq) => {
    loadingData?.show();

    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setServiceJobs(res?.data);
        setServiceJobsList(res?.data?.responses);
        setPagination(res?.data?.pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAddServiceJob = () => {
    setIsShowAddServiceJob(true);
  };

  const handleViewDetails = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.serviceJobs}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.serviceJobs}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.serviceJobs}/${id}`);
        break;

      default:
        break;
    }
  };

  const handleChangePagination = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCloseAddServiceJob = () => {
    setIsShowAddServiceJob(false);
    setServiceJobIdSelected(EMPTY_STRING);
  };

  const handleCloseUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handleCloseRestrictedEdit = () => {
    setIsShowRestrictedEdit(false);
  };

  const handleOnEdit = (job: IServiceJob) => {
    if (job.status !== ServiceJobStatusEnum.PENDING) {
      setIsShowRestrictedEdit(!isShowRestrictedEdit);
      return;
    }
    setServiceJobIdSelected(job.id);
    setIsShowAddServiceJob(true);
  };

  const changeServiceJobStatus = (status: ServiceJobStatusEnum) => {
    if (!serviceJobIdSelected) return;

    const statusType: IChangeStatusJobPayload = { status };

    loadingData?.show();

    dispatch(changeStatusServiceJob({ id: serviceJobIdSelected, status: statusType }))
      .unwrap()
      .then(() => {
        return dispatch(jobActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        if (isShowConfirmCloseInvoicing) {
          setIsShowConfirmCloseInvoicing(false);
        } else if (isShowConfirmClosePerforming) {
          setIsShowConfirmClosePerforming(false);
        }
      });
  };

  const handleOnCloseInvoicing = (id: string) => {
    setServiceJobIdSelected(id);
    setIsShowConfirmCloseInvoicing(true);
  };

  const handleOnClonePerforming = (id: string) => {
    setServiceJobIdSelected(id);
    setIsShowConfirmClosePerforming(true);
  };

  const handleConventContractJob = (id: string) => {
    if (!id) return;

    loadingData?.show();

    dispatch(convertToContractJob({ id: id }))
      .unwrap()
      .then(() => {
        return dispatch(jobActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleOnDelete = (id: string) => {
    setServiceJobIdSelected(id);
    setIsShowConfirmDelete(true);
  };

  const handleDelete = () => {
    if (!serviceJobIdSelected) return;

    loadingData?.show();
    dispatch(deleteServiceJob(serviceJobIdSelected))
      .unwrap()
      .then((res) => {
        setIsShowConfirmDelete(false);
        dispatch(jobActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleCancelModal = () => {
    setServiceJobIdSelected(EMPTY_STRING);

    if (isShowConfirmDelete) {
      setIsShowConfirmDelete(false);
    } else if (isShowConfirmCloseInvoicing) {
      setIsShowConfirmCloseInvoicing(false);
    } else if (isShowConfirmClosePerforming) {
      setIsShowConfirmClosePerforming(false);
    }
  };

  const handleActionModal = () => {
    if (!serviceJobIdSelected) return;

    if (isShowConfirmDelete) {
      handleDelete();
    } else if (isShowConfirmCloseInvoicing) {
      changeServiceJobStatus(ServiceJobStatusEnum.CLOSED_WITHOUT_INVOICING);
    } else if (isShowConfirmClosePerforming) {
      changeServiceJobStatus(ServiceJobStatusEnum.CLOSED_WITHOUT_PERFORMING);
    }
  };

  const handleFormServiceJobSuccess = () => {
    dispatch(jobActions.setRefreshList(true));
    handleCloseAddServiceJob();
  };

  const handleCLickRow = (record: IServiceJob) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.serviceJobs}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.serviceJobs}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.serviceJobs}/${record.id}`);
        break;

      default:
        break;
    }
  };

  const handleSearchServiceJob = (value: string) => {
    setSearchKey(value);
  };
  //#endregion Handle Function

  return (
    <div id='manageServiceJobsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_service_jobs_title', {
          total: formatNumber(serviceJobs?.pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleAddServiceJob,
          label: t('admin_manage_service_jobs_btn_add'),
        }}
        onSearch={handleSearchServiceJob}
        valueSearch={searchKey || EMPTY_STRING}
      />

      <div className={cx('tableSection')}>
        <div className={cx('actions')}></div>

        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewDetails,
              handleOnEdit,
              handleOnCloseInvoicing,
              handleOnClonePerforming,
              handleConventContractJob,
              handleOnDelete
            )}
            dataSource={serviceJobsList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={serviceJobs?.pagination?.totalPages || DEFAULT_TOTAL_PAGES}
            totalItems={serviceJobs?.pagination?.totalItems || DEFAULT_TOTAL_ITEMS}
            onChange={handleChangePagination}
          />
        </div>
      </div>

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}

      {isShowConfirmDelete && (
        <ConfirmModal
          title={t('common_modal_do_you_want_to_title', {
            name: t('common_btn_delete'),
          })}
          titleAction={t('common_btn_delete')}
          onCancel={handleCancelModal}
          onAction={handleActionModal}
        />
      )}

      {(isShowConfirmCloseInvoicing || isShowConfirmClosePerforming) && (
        <ConfirmModal
          title={t('common_modal_do_you_want_to_title', {
            name: isShowConfirmCloseInvoicing
              ? t('common_action_close_invoicing')
              : t('common_action_close_performing'),
          })}
          titleAction={t('common_btn_close')}
          onCancel={handleCancelModal}
          onAction={handleActionModal}
        />
      )}

      <FormServiceJobModal
        jobId={serviceJobIdSelected}
        isOpen={isShowAddServiceJob}
        onClose={handleCloseAddServiceJob}
        onSuccess={handleFormServiceJobSuccess}
      />

      <CommonConfirmModal
        isOpen={isShowRestrictedEdit}
        onClose={handleCloseRestrictedEdit}
        title={t('common_text_edit_pending_status')}
      />
    </div>
  );
};

export default ManageServiceJobs;

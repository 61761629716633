// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { LabelValueField } from '~/components';
// Others
import { IDetailProduct } from '~/utils/interface/product';
import { EMPTY_STRING } from '~/utils/constants/common';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getDetailProduct } from '~/thunks/product/productThunk';
import { convertToTitleCase } from '~/utils/helper';
// Styles, images, icons
import styles from './BasicInformationProductTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const BasicInformationProductTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [productDetail, setProductDetail] = useState<IDetailProduct>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!productId) return;

    handleGetDetailProduct(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailProduct = (productId: string) => {
    loadingData?.show();

    dispatch(getDetailProduct(productId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setProductDetail(res.data);
      })
      .catch((_error) => {})
      .finally(() => loadingData?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='basicInformationProductTabComponent' className={cx('container')}>
      <div className={cx('basicInformationProductTabWrap')}>
        <LabelValueField
          label={t('template_product_detail_tabs_basic_information_product_name_label')}
          value={productDetail?.name || EMPTY_STRING}
        />

        <LabelValueField
          label={t('template_product_detail_tabs_basic_information_quick_books_label')}
          value={
            productDetail?.quickBookItemId ? (
              <div className={cx('greenCircle')} />
            ) : (
              <div className={cx('grayCircle')} />
            )
          }
        />

        <LabelValueField
          label={t('template_product_detail_tabs_basic_information_category_label')}
          value={
            productDetail?.category ? convertToTitleCase(productDetail?.category) : EMPTY_STRING
          }
        />

        <LabelValueField
          label={t('template_product_detail_tabs_basic_information_description_label')}
          value={productDetail?.description || EMPTY_STRING}
        />

        <LabelValueField
          label={t('template_product_detail_tabs_basic_information_extended_description_label')}
          value={productDetail?.extendedDescription || EMPTY_STRING}
          isLongValue
        />
      </div>
    </div>
  );
};

export default BasicInformationProductTab;

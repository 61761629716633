// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseImageCircle, BaseModal, LabelValueField, Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getClientDetails } from '~/thunks/client/clientThunk';
import { IClientDetail } from '~/utils/interface/client';
import { getFullName } from '~/utils/helper';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './AdminClientDetailsModal.module.scss';
import { icons } from '~/assets';

type Props = {
  idClient?: string;
  onClose: () => void;
  isOpen: boolean;
};

const cx = classNames.bind(styles);

const AdminClientDetailsModal = (props: Props) => {
  //#region Destructuring Props
  const { idClient, onClose, isOpen } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [clientDetail, setClientDetail] = useState<IClientDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!idClient) return;
    handleGetDetailClient(idClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailClient = (id: string) => {
    setIsLoading(true);

    dispatch(getClientDetails(id))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (!res?.data) return;

        setClientDetail(res?.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onCloseModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <BaseModal id='adminClientDetailsModal' isOpen={isOpen} onClose={onCloseModal}>
      <div className={cx('modal')}>
        <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={onCloseModal}
        />

        <div className={cx('title')}>
          {t('admin_manage_clients_modal_details_title_client_detail')}
        </div>

        <div className={cx('body')}>
          <div className={cx('viewInfo')}>
            <LabelValueField
              label={t('admin_manage_clients_modal_details_label_client_name')}
              value={getFullName({
                firstName: clientDetail?.firstName,
                lastName: clientDetail?.lastName,
              })}
            />

            <LabelValueField
              label={t('admin_manage_clients_modal_details_label_main_contact')}
              value={clientDetail?.contactName ? clientDetail.contactName : EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_clients_modal_details_label_phone')}
              value={clientDetail?.phoneNumber ? clientDetail.phoneNumber : EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_clients_modal_details_label_email')}
              value={clientDetail?.email ? clientDetail.email : EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_clients_modal_details_label_other')}
              value={clientDetail?.other ? clientDetail.other : EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_clients_modal_details_title_note')}
              value={clientDetail?.note ? clientDetail.note : EMPTY_STRING}
              isLastItem
              isLongValue
            />
          </div>

          <div className={cx('viewAvatar')}>
            {clientDetail && (
              <BaseImageCircle
                url={clientDetail?.avatar}
                firstText={clientDetail?.firstName}
                secondText={clientDetail?.lastName}
                width={175}
                height={175}
                fontSize={12}
              />
            )}
          </div>
        </div>

        {isLoading && <Loading />}
      </div>
    </BaseModal>
  );
};

export default AdminClientDetailsModal;

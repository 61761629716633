// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseSelect,
  CompleteAddress,
  FieldContactClient,
  FormInput,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { createVendor, getVendorDetails, updateVendor } from '~/thunks/vendors/vendorsThunk';
import { getListQuickBookVendors } from '~/thunks/quickBook/quickBookThunk';
import { IFormVendor, IUpdateVendor } from '~/utils/interface/vendors';
import { IAddress, IBaseOption, IQueryBase } from '~/utils/interface/common';
import { IQuickBooksVendor } from '~/utils/interface/quickBook';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
  linkToQuickBookCustomerOptions,
  NAVIGATE_GO_BACK,
  optionType,
  REGEX,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  KeyFormVendorEnum,
  LinkToQuickBookCustomerEnum,
  StorageEnum,
} from '~/utils/enum';
import { defaultContactVendor, defaultValuesFormVendor, schemaFormVendor } from './helper';
// Styles, images, icons
import styles from './CreateVendor.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const CreateVendor = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { vendorId } = useParams();
  const isEditMode = useMemo(() => !!vendorId, [vendorId]);
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IFormVendor>({
    resolver: yupResolver(schemaFormVendor({ t })),
    defaultValues: defaultValuesFormVendor,
  });

  const {
    fields: contactFields,
    append: appendEquipment,
    remove: removeEquipment,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const watchTypeLinkQuickBooks = useWatch({ control, name: 'typeLinkQuickBook' });
  const watchQuickBooksVendorId = useWatch({ control, name: 'quickBookVendorId' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listQuickBooksVendors, setListQuickBooksVendors] = useState<IQuickBooksVendor[]>([]);
  const [isLoadingQuickBooks, setIsLoadingQuickBooks] = useState<boolean>(false);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!vendorId) return;

    handleGetVendorDetail(vendorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditMode) return;

    const dataForm: IFormVendor = {
      ...defaultValuesFormVendor,
      typeLinkQuickBook: watchTypeLinkQuickBooks,
    };

    reset(dataForm);

    if (watchTypeLinkQuickBooks !== LinkToQuickBookCustomerEnum.ADD_EXISTING) return;

    const payloadGetListQuickBooksVendor: IQueryBase = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    handleGetListQuickBooksVendor(payloadGetListQuickBooksVendor);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTypeLinkQuickBooks]);

  useEffect(() => {
    if (watchTypeLinkQuickBooks) {
      appendEquipment(defaultContactVendor);
    }
  }, [watchTypeLinkQuickBooks, appendEquipment]);

  useEffect(() => {
    if (!watchQuickBooksVendorId || !Array.isArray(listQuickBooksVendors)) return;

    handleQuickBooksVendorChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchQuickBooksVendorId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetVendorDetail = (vendorId: string) => {
    loadingData?.show();

    dispatch(getVendorDetails(vendorId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const formatData: IFormVendor = {
          name: res?.data?.name || EMPTY_STRING,
          contactName: res?.data?.contactName || EMPTY_STRING,
          address: res?.data?.address || EMPTY_STRING,
          country: res?.data?.country || EMPTY_STRING,
          city: res?.data?.city || EMPTY_STRING,
          state: res?.data?.state || EMPTY_STRING,
          zipCode: res?.data?.zipCode || EMPTY_STRING,
          addressSecond: res?.data?.addressSecond || EMPTY_STRING,
          phoneNumber: res?.data?.phoneNumber || EMPTY_STRING,
          email: res?.data?.email || EMPTY_STRING,
          countryCode: res?.data?.countryCode || EMPTY_STRING,
          other: res?.data?.other || EMPTY_STRING,
          type: res?.data?.type || EMPTY_STRING,
          contacts:
            res?.data?.contacts.map((contact) => {
              return {
                email: contact.email || EMPTY_STRING,
                name: contact.name || EMPTY_STRING,
                phoneNumber: contact.phoneNumber || EMPTY_STRING,
                title: contact.title || EMPTY_STRING,
              };
            }) || [],
          isSendToQuickBooks: res?.data?.quickBookVendorId ? true : false,
        };
        reset(formatData);
      })
      .catch((_error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleGetListQuickBooksVendor = (payload: IQueryBase) => {
    setIsLoadingQuickBooks(true);

    dispatch(getListQuickBookVendors(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const { responses: quickBooksVendors } = res?.data;

        setListQuickBooksVendors(quickBooksVendors);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingQuickBooks(false);
      });
  };

  const handleCreateVendor = (payload: IFormVendor) => {
    loadingData?.show();

    dispatch(createVendor(payload))
      .unwrap()
      .then((res) => {
        switch (currentRole) {
          case AccountRoleCodesEnum.ADMIN:
            return navigate(`${adminRouteAbsolute.manageVendors}`);

          case AccountRoleCodesEnum.STAFF:
            return navigate(`${staffRouteAbsolute.manageVendors}`);

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            return navigate(`${projectManageRouteAbsolute.manageVendors}`);
        }
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdateVendor = (body: IFormVendor) => {
    if (!vendorId) return;

    const payload: IUpdateVendor = {
      vendorId,
      body,
    };

    loadingData?.show();

    dispatch(updateVendor(payload))
      .unwrap()
      .then((res) => {
        switch (currentRole) {
          case AccountRoleCodesEnum.ADMIN:
            return navigate(`${adminRouteAbsolute.manageVendors}`);

          case AccountRoleCodesEnum.STAFF:
            return navigate(`${staffRouteAbsolute.manageVendors}`);

          case AccountRoleCodesEnum.PROJECT_MANAGER:
            return navigate(`${projectManageRouteAbsolute.manageVendors}`);
        }
      })
      .catch(() => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleSubmitFormVendor = (data: IFormVendor) => {
    const formattedData: IFormVendor = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== EMPTY_STRING && value !== null && value !== undefined
      )
    ) as IFormVendor;

    if (!isEditMode) {
      handleCreateVendor(formattedData);
    } else {
      handleUpdateVendor(data);
    }
  };

  const handleQuickBooksVendorChange = () => {
    const vendorSelected = listQuickBooksVendors?.find(
      (vendor) => vendor.id === watchQuickBooksVendorId
    );
    if (!vendorSelected) return;

    setValue('name', vendorSelected?.name || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('email', vendorSelected?.email || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(
      'phoneNumber',
      vendorSelected?.phone?.replace(REGEX.REPLACE_TEXT_ALL, EMPTY_STRING) || EMPTY_STRING,
      { shouldValidate: true, shouldDirty: true }
    );
    setValue('city', vendorSelected?.city || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('country', vendorSelected?.country || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('address', vendorSelected?.address || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('addressSecond', vendorSelected?.addressSecond || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('zipCode', vendorSelected?.zipCode || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('syncToken', vendorSelected?.syncToken || EMPTY_STRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangeAddress = (address: IAddress, onChange: (address?: string) => void) => {
    const fieldsToUpdate = [
      { key: 'country', value: address.country },
      { key: 'city', value: address.city },
      { key: 'state', value: address.state },
      { key: 'zipCode', value: address.zipCode },
    ] as const;

    fieldsToUpdate.forEach(({ key, value }) => {
      if (value) {
        setValue(key, value, { shouldDirty: true });
        trigger(key);
      }
    });

    onChange(address?.address);
    setValue('lat', address?.lat);
    setValue('lng', address?.lng);
  };

  const handleCancelFromVendor = () => {
    navigate(NAVIGATE_GO_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='createVendorPage' className={cx('container')}>
      <Toolbar
        title={
          !isEditMode ? t('form_vendor_create_vendor_title') : t('form_vendor_edit_vendor_title')
        }
      />

      <section className={cx('body')}>
        <FormProvider
          {...({
            control,
            setValue,
            formState: { errors },
          } as UseFormReturn<IFormVendor>)}
        >
          <form className={cx('form')} onSubmit={handleSubmit(handleSubmitFormVendor)}>
            <section className={cx('formBody')}>
              {!isEditMode && (
                <div className={cx('twoCol')}>
                  <Controller
                    name={KeyFormVendorEnum.TYPE_LINK_QUICK_BOOK}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        label={t('form_vendor_link_to_quick_books_label')}
                        placeholder={t('common_placeholder_select')}
                        value={value || EMPTY_STRING}
                        options={linkToQuickBookCustomerOptions || []}
                        onChange={(optionSelected: IBaseOption) => onChange(optionSelected?.value)}
                        isRequired
                        errorMessage={errors.typeLinkQuickBook?.message}
                      />
                    )}
                  />

                  {watchTypeLinkQuickBooks === LinkToQuickBookCustomerEnum.ADD_EXISTING && (
                    <Controller
                      name={KeyFormVendorEnum.QUICK_BOOK_VENDOR_ID}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseSelect
                          label={t('form_vendor_choose_quick_books_vendor_label')}
                          placeholder={
                            isLoadingQuickBooks
                              ? t('common_text_loading')
                              : t('common_placeholder_select')
                          }
                          value={value}
                          options={
                            (listQuickBooksVendors?.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            })) as IBaseOption[]) || []
                          }
                          isRequired
                          onChange={(optionSelected: IBaseOption) =>
                            onChange(optionSelected?.value)
                          }
                          errorMessage={errors.quickBookVendorId?.message}
                        />
                      )}
                    />
                  )}
                </div>
              )}

              {(isEditMode ||
                (!isEditMode &&
                  (watchTypeLinkQuickBooks === LinkToQuickBookCustomerEnum.NO ||
                    watchTypeLinkQuickBooks === LinkToQuickBookCustomerEnum.ADD_NEW ||
                    (watchTypeLinkQuickBooks === LinkToQuickBookCustomerEnum.ADD_EXISTING &&
                      watchQuickBooksVendorId)))) && (
                <>
                  <div className={cx('twoCol')}>
                    <Controller
                      name={KeyFormVendorEnum.NAME}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_name_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          required
                          errorMessage={errors.name?.message}
                        />
                      )}
                    />

                    <Controller
                      name={KeyFormVendorEnum.CONTACT_NAME}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_contact_name_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          required
                          errorMessage={errors.contactName?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx('twoCol')}>
                    <Controller
                      name={KeyFormVendorEnum.ADDRESS}
                      control={control}
                      shouldUnregister={true}
                      render={({ field: { value, onChange } }) => (
                        <CompleteAddress
                          label={t('form_vendor_address_label')}
                          value={value || EMPTY_STRING}
                          onChange={(address) => handleChangeAddress(address, onChange)}
                          errorMessage={errors.address?.message}
                        />
                      )}
                    />

                    <Controller
                      name={KeyFormVendorEnum.ADDRESS_SECOND}
                      control={control}
                      shouldUnregister={true}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_address_second_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          errorMessage={errors.addressSecond?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx('twoCol')}>
                    <Controller
                      name={KeyFormVendorEnum.COUNTRY}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_country_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          errorMessage={errors.country?.message}
                        />
                      )}
                    />

                    <div className={cx('threeCol')}>
                      <Controller
                        name={KeyFormVendorEnum.CITY}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormInput
                            label={t('form_vendor_city_label')}
                            value={value || EMPTY_STRING}
                            onChange={onChange}
                            errorMessage={errors.city?.message}
                          />
                        )}
                      />

                      <Controller
                        name={KeyFormVendorEnum.STATE}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormInput
                            label={t('form_vendor_state_label')}
                            value={value || EMPTY_STRING}
                            onChange={onChange}
                            errorMessage={errors.state?.message}
                          />
                        )}
                      />

                      <Controller
                        name={KeyFormVendorEnum.ZIP_CODE}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormInput
                            label={t('form_vendor_zip_code_label')}
                            value={value || EMPTY_STRING}
                            onChange={onChange}
                            errorMessage={errors.zipCode?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className={cx('twoCol')}>
                    <Controller
                      name={KeyFormVendorEnum.PHONE_NUMBER}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_phone_label')}
                          value={value || EMPTY_STRING}
                          type='number'
                          onChange={onChange}
                          errorMessage={errors.phoneNumber?.message}
                        />
                      )}
                    />

                    <Controller
                      name={KeyFormVendorEnum.EMAIL}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_email_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          errorMessage={errors.email?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx('twoCol')}>
                    <Controller
                      name={KeyFormVendorEnum.OTHER}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          label={t('form_vendor_other_label')}
                          value={value || EMPTY_STRING}
                          onChange={onChange}
                          errorMessage={errors.other?.message}
                        />
                      )}
                    />

                    <Controller
                      name={KeyFormVendorEnum.TYPE}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <BaseSelect
                          label={t('form_vendor_vendor_type_label')}
                          placeholder={t('common_placeholder_select')}
                          value={value || EMPTY_STRING}
                          options={optionType || []}
                          onChange={(optionSelected: IBaseOption) =>
                            onChange(optionSelected?.value)
                          }
                          isRequired
                          errorMessage={errors.type?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx('group')}>
                    <div className={cx('groupTitle')}>
                      {t('form_vendor_additional_contact_title')}
                    </div>

                    {contactFields.map((row, index) => (
                      <FieldContactClient
                        key={row.id}
                        fieldIndex={index}
                        removeField={removeEquipment}
                      />
                    ))}

                    <button
                      type='button'
                      onClick={() => appendEquipment(defaultContactVendor)}
                      className={cx('btnAddField', 'group')}
                    >
                      <img
                        src={icons.commonIconAddField}
                        alt={t('common_img_text_alt')}
                        className={cx('iconAddField')}
                      />
                      <span className={cx('btnTextAdd')}>
                        {t('form_vendor_btn_add_contact_field')}
                      </span>
                    </button>
                  </div>
                </>
              )}
            </section>

            <section className={cx('formFooter')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                onClick={handleCancelFromVendor}
                typeStyle={ButtonTypeEnum.CANCEL}
              />

              <BaseButton
                label={t('common_btn_save')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                type='submit'
                isDisable={isEditMode ? !isDirty : false}
              />
            </section>
          </form>
        </FormProvider>
      </section>
    </div>
  );
};

export default CreateVendor;

// Libs
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { CircleAvatar, LabelValueField, Loading, Toolbar } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { CircleAvatarEnum, TimeFormatEnum } from '~/utils/enum';
import {
  convertTime,
  formatAddress,
  formattedTime,
  formatTotalTimeToHour,
  getFullName,
} from '~/utils/helper';
import { ITimeClockDetails } from '~/utils/interface/timeClock';
import { IPosition } from '~/utils/interface/common';
import { getTimeClockDetails } from '~/thunks/timeClock/timeClockThunk';
// Styles, images, icons
import styles from './DetailEmployeeTimeClock.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailEmployeeTimeClock = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { timeClockId } = useParams();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [timeClockDetails, setTimeClockDetails] = useState<ITimeClockDetails>();
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!timeClockId) return;

    handleGetTimeClockDetails(timeClockId);
    getUserLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeClockId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTimeClockDetails = (id: string) => {
    if (!id) return;

    setIsLoading(true);

    dispatch(getTimeClockDetails(id))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setTimeClockDetails(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  //#endregion Handle

  return (
    <div id='detailPoTemplate' className={cx('container')}>
      <Toolbar title={t('template_employee_time_clock_detail_toolbar_title')} />

      <div className={cx('body')}>
        <div className={cx('contentWrap')}>
          <div className={cx('timeClockDetailsSection')}>
            <div className={cx('timeClockDetails')}>
              <div className={cx('timeClockDetailsTitle')}>
                {t('admin_time_clock_details_title')}
              </div>

              <LabelValueField
                label={t('admin_time_clock_details_resource_name')}
                value={
                  timeClockDetails?.assignee?.firstName || timeClockDetails?.assignee?.lastName ? (
                    <div className={cx('avatarGroup')}>
                      <CircleAvatar
                        type={
                          timeClockDetails?.assignee?.avatar
                            ? CircleAvatarEnum.IMAGE
                            : CircleAvatarEnum.TEXT
                        }
                        imageUrl={timeClockDetails?.assignee?.avatar}
                        firstName={timeClockDetails?.assignee?.firstName}
                        lastName={timeClockDetails?.assignee?.lastName}
                        width={24}
                        height={24}
                        fontSize={12}
                      />

                      <span>
                        {getFullName({
                          firstName: timeClockDetails?.assignee?.firstName,
                          lastName: timeClockDetails?.assignee?.lastName,
                        })}
                      </span>
                    </div>
                  ) : (
                    EMPTY_STRING
                  )
                }
              />

              <LabelValueField
                label={t('admin_time_clock_details_date')}
                value={
                  timeClockDetails?.startTime
                    ? formattedTime(timeClockDetails?.startTime)
                    : EMPTY_STRING
                }
              />

              <LabelValueField
                label={t('admin_time_clock_details_job')}
                value={timeClockDetails?.job?.name || EMPTY_STRING}
              />

              <LabelValueField
                label={t('admin_time_clock_details_client_name')}
                value={
                  timeClockDetails?.client?.firstName || timeClockDetails?.client?.lastName ? (
                    <div className={cx('avatarGroup')}>
                      <CircleAvatar
                        type={
                          timeClockDetails?.client?.avatar
                            ? CircleAvatarEnum.IMAGE
                            : CircleAvatarEnum.TEXT
                        }
                        imageUrl={timeClockDetails?.client?.avatar}
                        firstName={timeClockDetails?.client?.firstName}
                        lastName={timeClockDetails?.client?.lastName}
                        width={24}
                        height={24}
                        fontSize={12}
                      />

                      <span>
                        {getFullName({
                          firstName: timeClockDetails?.client?.firstName,
                          lastName: timeClockDetails?.client?.lastName,
                        })}
                      </span>
                    </div>
                  ) : (
                    EMPTY_STRING
                  )
                }
              />

              <LabelValueField
                label={t('admin_time_clock_details_check_in')}
                value={
                  timeClockDetails?.startTime
                    ? convertTime(timeClockDetails?.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
                    : EMPTY_STRING
                }
              />

              <LabelValueField
                label={t('admin_time_clock_details_check_out')}
                value={
                  timeClockDetails?.endTime
                    ? convertTime(timeClockDetails?.endTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
                    : EMPTY_STRING
                }
              />

              <LabelValueField
                label={t('admin_time_clock_details_total_time')}
                value={
                  typeof timeClockDetails?.hours === 'number'
                    ? formatTotalTimeToHour(Number(timeClockDetails?.hours))
                    : EMPTY_STRING
                }
              />
            </div>
          </div>

          <div className={cx('mapSection')}>
            <div className={cx('timeClockDetailsItem')}>
              <span className={cx('key')}>{`${t('admin_time_clock_details_location')}`}</span>
              <span className={cx('value')}>
                {formatAddress({
                  ...timeClockDetails,
                  address: timeClockDetails?.location,
                })}
              </span>
            </div>

            {isLoaded && (
              <div className={cx('map')}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%', borderRadius: 8 }}
                  center={{
                    lat: timeClockDetails?.latitude || positionSelected.lat,
                    lng: timeClockDetails?.longitude || positionSelected.lng,
                  }}
                  zoom={DEFAULT_MAP_ZOOM}
                >
                  {timeClockDetails?.latitude && timeClockDetails?.longitude && (
                    <Marker
                      position={{
                        lat: timeClockDetails?.latitude || positionSelected.lat,
                        lng: timeClockDetails?.longitude || positionSelected.lng,
                      }}
                    />
                  )}
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
      </div>

      {isLoading && <Loading />}
    </div>
  );
};

export default DetailEmployeeTimeClock;

// Auth
export const LOGIN = 'LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const VERIFY_CODE = 'VERIFY_CODE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// Employee
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const GET_LIST_EMPLOYEE = 'GET_LIST_EMPLOYEE';
export const GET_DETAIL_EMPLOYEE = 'GET_DETAIL_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_STATUS_EMPLOYEE = 'UPDATE_STATUS_EMPLOYEE';

// Technician
export const GET_LIST_TECHNICIAN = 'GET_LIST_TECHNICIAN';
export const CREATE_TECHNICIAN = 'CREATE_TECHNICIAN';

// Note
export const GET_LIST_NOTE = 'GET_LIST_NOTE';
export const GET_NOTE_DETAIL = 'GET_NOTE_DETAIL';
export const CREATE_NEW_NOTE = 'CREATE_NEW_NOTE';

// Job
export const GET_LIST_JOB = 'GET_LIST_JOB';
export const GET_JOB_DETAILS = 'GET_JOB_DETAILS';
export const ADD_NEW_JOB = 'ADD_NEW_JOB';
export const GET_JOB_ASSIGNEES = 'GET_JOB_ASSIGNEES';
export const CREATE_NEW_CONTRACT_JOB = 'CREATE_NEW_CONTRACT_JOB';
export const CREATE_NEW_SERVICE_JOB = 'CREATE_NEW_SERVICE_JOB';
export const CHANGE_STATUS_SERVICE_JOB = 'CHANGE_STATUS_SERVICE_JOB';
export const CONVERT_TO_CONTRACT_JOB = 'CONVERT_TO_CONTRACT_JOB';
export const GET_LOGS_ACTIVITY_SERVICE_JOB = 'GET_LOGS_ACTIVITY_SERVICE_JOB';
export const DELETE_SERVICE_JOB = 'DELETE_SERVICE_JOB';
export const GET_MATERIAL_PRODUCT_SERVICE_JOB = 'GET_MATERIAL_PRODUCT_SERVICE_JOB';
export const GET_FIXTURE_LOCATION_OPTION_SERVICE_JOB = 'GET_FIXTURE_LOCATION_OPTION_SERVICE_JOB';
export const UPDATE_SERVICE_JOB = 'UPDATE_SERVICE_JOB';

// Document
export const GET_LIST_DOCUMENT = 'GET_LIST_DOCUMENT';
export const GET_LIST_DOCUMENT_JOB = 'GET_LIST_DOCUMENT_JOB';
export const CREATE_DOCUMENT_JOB = 'CREATE_DOCUMENT_JOB';
export const DOWNLOAD_DOCUMENT_JOB = 'DOWNLOAD_DOCUMENT_JOB';
export const GET_DOCUMENT_JOB_DETAILS = 'GET_DOCUMENT_JOB_DETAILS';
export const UPDATE_DOCUMENT_JOB = 'UPDATE_DOCUMENT_JOB';
export const DELETE_DOCUMENT_JOB = 'DELETE_DOCUMENT_JOB';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const GET_LIST_COMMON_DOCUMENT = 'GET_LIST_COMMON_DOCUMENT';
export const GET_DOCUMENT_DETAIL = 'GET_DOCUMENT_DETAIL';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DELETE_COMMON_DOCUMENT = 'DELETE_COMMON_DOCUMENT';

// Client
export const GET_LIST_CLIENT = 'GET_LIST_CLIENT';
export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const CREATE_NEW_CLIENT = 'CREATE_NEW_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const SEND_TO_QUICK_BOCKS_CLIENT = 'SEND_TO_QUICK_BOCKS_CLIENT';

// Time Clock
export const GET_LIST_TIME_CLOCK = 'GET_LIST_TIME_CLOCK';
export const GET_TIME_CLOCK_DETAILS = 'GET_TIME_CLOCK_DETAILS';
export const DELETE_TIME_CLOCK = 'DELETE_TIME_CLOCK';
export const CREATE_TIME_CLOCK = 'CREATE_TIME_CLOCK';

// Vendor
export const GET_LIST_VENDORS = 'GET_LIST_VENDORS';
export const CREATE_VENDOR = 'CREATE_VENDOR';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const UPDATE_CONTACTS_VENDOR = 'UPDATE_CONTACTS_VENDOR';
export const GET_LIST_VENDORS_BY_SUBCONTRACTORS = 'GET_LIST_VENDORS_BY_SUBCONTRACTORS';
export const SEND_TO_QB_VENDOR = 'SEND_TO_QB_VENDOR';
export const GET_LIST_VENDOR_BY_PRODUCT = 'GET_LIST_VENDOR_BY_PRODUCT';

// Schedule
export const GET_SCHEDULE_EVENTS = 'GET_FULL_SCHEDULE_EVENTS';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';

// Profile
export const GET_DETAIL_PROFILE = 'GET_DETAIL_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// Task
export const GET_TASK_LIST = 'GET_TASK_LIST';
export const GET_TASK_LIST_TEMPLATE = 'GET_TASK_LIST_TEMPLATE';
export const CREATE_TASK = 'CREATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const UPDATE_TASK = 'UPDATE_TASK';

// Accountant
export const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
export const GET_ACCOUNT_MULTIPLE_ROLES = 'GET_ACCOUNT_MULTIPLE_ROLES';

// Product
export const GET_LIST_PRODUCT = 'GET_LIST_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const GET_DETAIL_PRODUCT = 'GET_DETAIL_PRODUCT';
export const GET_LIST_PRODUCT_BY_VENDOR = 'GET_LIST_PRODUCT_BY_VENDOR';
export const SEND_TO_QUICK_BOCKS_PRODUCT = 'SEND_TO_QUICK_BOCKS_PRODUCT';

// Service
export const GET_LIST_SERVICE = 'GET_LIST_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';

// Fixture Location
export const GET_LIST_FIXTURE_LOCATION = 'GET_LIST_FIXTURE_LOCATION';
export const CREATE_FIXTURE_LOCATION = 'CREATE_FIXTURE_LOCATION';
export const UPDATE_FIXTURE_LOCATION = 'UPDATE_FIXTURE_LOCATION';
export const DELETE_FIXTURE_LOCATION = 'DELETE_FIXTURE_LOCATION';

// Quick Book
export const GET_AUTH_URL = 'GET_AUTH_URL';
export const GET_TOKEN_QUICK_BOOK = 'GET_TOKEN_QUICK_BOOK';
export const DISCONNECT_QUICK_BOOK = 'DISCONNECT_QUICK_BOOK';
export const GET_LIST_QUICK_BOOK_CUSTOMER = 'GET_LIST_QUICK_BOOK_CUSTOMER';
export const GET_LIST_QUICK_BOOK_ITEM = 'GET_LIST_QUICK_BOOK_ITEM';
export const GET_LIST_QUICK_BOOK_VENDOR = 'GET_LIST_QUICK_BOOK_VENDOR';
export const GET_LIST_QUICK_BOOK_TERM = 'GET_LIST_QUICK_BOOK_TERM';
export const GET_LIST_EXPENSES_DEFAULT = 'GET_LIST_EXPENSES_DEFAULT';
export const GET_LIST_ACCOUNT_QUICK_BOOK = 'GET_LIST_ACCOUNT_QUICK_BOOK';
export const UPDATE_ACCOUNT_SETTING = 'UPDATE_ACCOUNT_SETTING';
export const UPDATE_EXPENSES_DEFAULT = 'UPDATE_EXPENSES_DEFAULT';
export const GET_LIST_INCOME_DEFAULT = 'GET_LIST_INCOME_DEFAULT';
export const UPDATE_ACCOUNT_INCOME_SETTING = 'UPDATE_ACCOUNT_INCOME_SETTING';
export const UPDATE_INCOME_DEFAULT = 'UPDATE_INCOME_DEFAULT';

// Estimate
export const GET_ESTIMATE_LIST = 'GET_ESTIMATE_LIST';
export const GET_ESTIMATE_TEMPLATE_LIST = 'GET_ESTIMATE_TEMPLATE_LIST';
export const CREATE_ESTIMATE = 'CREATE_ESTIMATE';
export const UPDATE_ESTIMATE = 'UPDATE_ESTIMATE';
export const DELETE_ESTIMATE = 'DELETE_ESTIMATE';
export const GET_DETAIL_ESTIMATE = 'GET_DETAIL_ESTIMATE';
export const GET_DETAIL_ESTIMATE_BY_JOB = 'GET_DETAIL_ESTIMATE_BY_JOB';
export const GET_PRODUCT_BY_BUDGET = 'GET_PRODUCT_BY_BUDGET';
export const GET_LINE_ITEM_LIST = 'GET_LINE_ITEM_LIST';
export const EST_GET_FIXTURE_LOCATION_OPTION = 'EST_GET_FIXTURE_LOCATION_OPTION';

// Overview
export const GET_OVERVIEW_LIST = 'GET_OVERVIEW_LIST';

// Term Conditions
export const CREATE_INCLUSION_EXCLUSION = 'CREATE_INCLUSION_EXCLUSION';
export const GET_LIST_INCLUSION_EXCLUSION = 'GET_LIST_INCLUSION_EXCLUSION';
export const CREATE_MAIN_CONTENT = 'CREATE_MAIN_CONTENT';
export const DELETE_INCLUSION_EXCLUSION = 'DELETE_INCLUSION_EXCLUSION';
export const GET_LIST_TERM_MAIN_CONTENT = 'GET_LIST_TERM_MAIN_CONTENT';
export const DELETE_TERM_MAIN_CONTENT = 'DELETE_TERM_MAIN_CONTENT';
export const UPDATE_INCLUSION_EXCLUSION = 'UPDATE_INCLUSION_EXCLUSION';
export const GET_DETAIL_TERM_MAIN_CONTENT = 'GET_DETAIL_TERM_MAIN_CONTENT';
export const UPDATE_TERM_MAIN_CONTENT = 'UPDATE_TERM_MAIN_CONTENT';
export const GET_LIST_TERM_TEMPLATE = 'GET_LIST_TERM_TEMPLATE';

// Labor
export const GET_LIST_LABOR = 'GET_LIST_LABOR';

// Proposal
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const GET_PRODUCT_PROPOSAL_BY_BUDGET = 'GET_PRODUCT_PROPOSAL_BY_BUDGET';
export const GET_LIST_VENDORS_PROPOSAL = 'GET_LIST_VENDORS_PROPOSAL';
export const GET_DETAIL_PROPOSAL = 'GET_DETAIL_PROPOSAL';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const PROPOSAL_GET_FIXTURE_LOCATION_OPTION = 'PROPOSAL_GET_FIXTURE_LOCATION_OPTION';
export const SEND_DOCUMENT_TO_SIGN = 'SEND_DOCUMENT_TO_SIGN';
export const SEND_PROPOSAL = 'SEND_PROPOSAL';

// Invoice
export const GET_LIST_INVOICE = 'GET_LIST_INVOICE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const GET_DETAIL_INVOICE = 'GET_DETAIL_INVOICE';
export const RECORD_PAYMENT_INVOICE = 'RECORD_PAYMENT_INVOICE';

// Purchase Order
export const CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER';
export const GET_LIST_PO = 'GET_LIST_PO';
export const GET_DETAIL_PO = 'GET_DETAIL_PO';
export const GET_LIST_PO_BY_JOB = 'GET_LIST_PO_BY_JOB';
export const GET_DETAIL_PO_FOR_BILL = 'GET_DETAIL_PO_FOR_BILL';
export const SEND_EMAIL_PO = 'SEND_EMAIL_PO';

// Telehealth Statistics
export const GET_STATISTIC = 'GET_STATISTIC';

// Notifications
export const GET_LIST_NOTIFICATIONS = 'GET_LIST_NOTIFICATIONS';
export const MARKED_READ_NOTIFICATION = 'MARKED_READ_NOTIFICATION';

// Phases
export const GET_LIST_JOB_PHASES = 'GET_LIST_JOB_PHASES';

// Bill
export const GET_LIST_BILL = 'GET_LIST_BILL';
export const GET_BILL_DETAIL = 'GET_BILL_DETAIL';
export const CREATE_BILL = 'CREATE_BILL';
export const RECORD_PAYMENT_BILL = 'RECORD_PAYMENT_BILL';

// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons

type Props = {};

const PurchaseOrderProductHeader = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text style={styles.product}>Product</Text>
      <Text style={styles.qty}>Quantity</Text>
      <Text style={styles.price}>Unit Cost</Text>
      <Text style={styles.total}>Subtotal</Text>
    </View>
  );
};

export default PurchaseOrderProductHeader;

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#333333',
    backgroundColor: '#333333',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    flexGrow: 1,
    color: 'white',
  },

  product: {
    width: '60%',
  },

  qty: {
    width: '15%',
  },

  price: {
    width: '15%',
  },

  total: {
    width: '15%',
  },
});

// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others
import { IContactVendor, IFormVendor } from '~/utils/interface/vendors';
import { EMPTY_STRING, REGEX } from '~/utils/constants/common';
import { LinkToQuickBookCustomerEnum } from '~/utils/enum';

export const schemaFormVendor = (payload: { t: TFunction }) => {
  const { t } = payload;

  return yup.object().shape({
    name: yup.string().required(t('form_vendor_require_name')),
    contactName: yup.string().required(t('form_vendor_require_contact_name')),
    countryCode: yup.string().optional(),
    phoneNumber: yup.string().optional(),
    email: yup
      .string()
      .optional()
      .test('isValidEmail', t('common_validate_format_email'), (value) => {
        if (value && !REGEX.EMAIL.test(value)) return false;
        return true;
      }),
    other: yup.string().optional(),
    address: yup.string().optional(),
    addressSecond: yup.string().optional(),
    state: yup.string().optional(),
    city: yup.string().optional(),
    zipCode: yup.string().optional(),
    country: yup.string().optional(),
    lat: yup.number().optional(),
    lng: yup.number().optional(),
    isSendToQuickBooks: yup.boolean().optional(),
    type: yup.string().required(t('form_vendor_require_type')),
    quickBookVendorId: yup
      .string()
      .optional()
      .when('typeLinkQuickBook', {
        is: (value: string) => value === LinkToQuickBookCustomerEnum.ADD_EXISTING,
        then: (schema) => schema.required(t('form_vendor_require_choose_quick_books_vendors')),
        otherwise: (schema) => schema.optional(),
      }),
    typeLinkQuickBook: yup
      .string()
      .nullable()
      .notOneOf([EMPTY_STRING], t('form_vendor_require_link_to_quick_books')),
    syncToken: yup.string().optional(),
    contacts: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().trim().required(t('common_validate_require_name')),
          title: yup.string().optional(),
          email: yup
            .string()
            .test('isValidEmail', t('common_validate_format_email'), (value) => {
              if (value && !REGEX.EMAIL.test(value)) return false;
              return true;
            })
            .required(t('common_validate_require_email')),
          countryCode: yup.string().optional(),
          phoneNumber: yup.string().optional(),
        })
      )
      .optional(),
  });
};

export const defaultContactVendor: IContactVendor = {
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  title: EMPTY_STRING,
};

export const defaultValuesFormVendor: IFormVendor = {
  name: EMPTY_STRING,
  contactName: EMPTY_STRING,
  type: EMPTY_STRING,
  typeLinkQuickBook: EMPTY_STRING,
  address: EMPTY_STRING,
  addressSecond: EMPTY_STRING,
  city: EMPTY_STRING,
  contacts: [],
  country: EMPTY_STRING,
  countryCode: EMPTY_STRING,
  email: EMPTY_STRING,
  isSendToQuickBooks: false,
  other: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  quickBookVendorId: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  syncToken: EMPTY_STRING,
};

// Libs
import classNames from 'classnames/bind';
import {
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  KeyboardEvent,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { authActions } from '~/thunks/auth/authSlice';
import { forgotPassword, verifyCode } from '~/thunks/auth/authThunk';
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NUMBER_COLUMNS_VERIFY,
  REGEX,
} from '~/utils/constants/common';
import { publicRouteAbsolute } from '~/utils/constants/route';
import { ButtonTypeEnum, InputTypeEnum, KeyboardEnum } from '~/utils/enum';
import { validateWithRegex } from '~/utils/helper';
import { IForgotPasswordPayload } from '~/utils/interface/auth';
// Styles, images, icons
import styles from './VerifyCode.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const VerifyCode = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state }: Location<IForgotPasswordPayload> = useLocation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [dataOTP, setDataOTP] = useState<string[]>(Array(NUMBER_COLUMNS_VERIFY).fill(EMPTY_STRING));
  const [hasErrorMessage, setHasErrorMessage] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      !validateWithRegex(event.key, REGEX.NUMBER) &&
      event.key !== KeyboardEnum.BACKSPACE &&
      event.key !== KeyboardEnum.DELETE &&
      event.key !== KeyboardEnum.TAB &&
      !event.metaKey
    ) {
      event.preventDefault();
    }

    if (event.key === KeyboardEnum.BACKSPACE || event.key === KeyboardEnum.DELETE) {
      const index = inputRefs.current.indexOf(event.currentTarget);

      if (index >= DEFAULT_NUMBER_ZERO) {
        setDataOTP((prevOtp) => [
          ...prevOtp.slice(DEFAULT_NUMBER_ZERO, index),
          EMPTY_STRING,
          ...prevOtp.slice(index + DEFAULT_NUMBER_ONE),
        ]);
        inputRefs.current[index - DEFAULT_NUMBER_ONE]?.focus();
      }
    }
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const index = inputRefs.current.indexOf(event.currentTarget);

    if (value) {
      setDataOTP((prevOtp) => {
        const newOtp = [
          ...prevOtp.slice(DEFAULT_NUMBER_ZERO, index),
          value,
          ...prevOtp.slice(index + DEFAULT_NUMBER_ONE),
        ];

        if (newOtp.every((otp) => otp !== EMPTY_STRING)) {
          setHasErrorMessage(false);
        }

        return newOtp;
      });

      if (index < dataOTP.length - DEFAULT_NUMBER_ONE) {
        inputRefs.current[index + DEFAULT_NUMBER_ONE]?.focus();
      }
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text');

    if (!validateWithRegex(text, REGEX.NUMBER_MAX_LENGTH_FOUR)) {
      return;
    }

    const digits = text.split(EMPTY_STRING);
    setDataOTP(digits);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  };

  const handleRefInput = (element: HTMLInputElement, index: number) => {
    inputRefs.current[index] = element;
  };

  const handleSubmit = () => {
    const hasError = validateOTP(dataOTP);
    setHasErrorMessage(hasError);
    if (hasError) return;

    loadingContext?.show();
    const valueOTP = dataOTP.join(EMPTY_STRING);

    dispatch(verifyCode({ code: valueOTP }))
      .unwrap()
      .then((res) => {
        navigate(publicRouteAbsolute.createPassword);
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleResend = () => {
    if (!state?.email) return;

    const payload: IForgotPasswordPayload = {
      email: state.email,
    };

    loadingContext?.show();
    dispatch(forgotPassword(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        const { data } = res;
        dispatch(authActions.setAccountId(data.accountId));
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const validateOTP = (otpInput: string[]) => {
    return otpInput.some((otp) => otp === EMPTY_STRING);
  };
  //#endregion Handle Function

  return (
    <div id='verifyCodePage' className='container'>
      <div className={cx('verifyCodeTitle')}>{t('auth_verify_code_title')}</div>

      <div className={cx('sendToMail')}>
        {t('auth_verify_code_send_to_email', { email: state?.email })}
      </div>

      <div className={cx('formVerifyCode')}>
        {dataOTP.map((value, index) => (
          <input
            key={index}
            type={InputTypeEnum.TEXT}
            maxLength={1}
            value={value}
            onChange={handleChangeValue}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onPaste={handlePaste}
            ref={(element: HTMLInputElement) => handleRefInput(element, index)}
            className={cx('inputVerifyCode')}
          />
        ))}
      </div>

      {hasErrorMessage && (
        <span className={cx('errMessage')}>{t('auth_verify_code_validate_err_message')}</span>
      )}

      <div className={cx('btnWrap')}>
        <BaseButton
          borderRadius={8}
          height={36}
          typeStyle={ButtonTypeEnum.AUTH}
          isBorder={true}
          onClick={handleSubmit}
          label={t('common_btn_submit')}
        />
      </div>

      <div className={cx('resendWrap')}>
        <span>{t('auth_verify_code_question_receive_otp')}</span>
        <span className={cx('resendBtn')} onClick={handleResend}>
          {t('auth_verify_code_question_resend')}
        </span>
      </div>
    </div>
  );
};

export default VerifyCode;

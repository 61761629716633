// Libs
import classNames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseModal,
  BaseButton,
  UploadFile,
  FormInput,
  BaseSelect,
  BaseDatePicker,
  Loading,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { addDocument, getDocumentDetail, updateDocument } from '~/thunks/document/documentThunk';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { documentActions } from '~/thunks/document/documentSlice';
import { IQueryAccount } from '~/utils/interface/account';
import { IFormDocument } from '~/utils/interface/document';
import { IBaseOption } from '~/utils/interface/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  AccountRoleCodesEnum,
  BusinessTypeEnum,
  ButtonTypeEnum,
  KeyFormDocumentVendorEnum,
  StatusEnum,
} from '~/utils/enum';
import { getFullName } from '~/utils/helper';
import { defaultValuesFormDocumentBill, schemaFormDocumentBill } from './helper';
// Styles, images, icons
import styles from './FormDocumentBillModal.module.scss';

type Props = {
  isOpen?: boolean;
  documentId?: string;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const FormDocumentBillModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen = false, documentId, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { billId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<IFormDocument>({
    resolver: yupResolver(schemaFormDocumentBill(t, documentId)),
    defaultValues: defaultValuesFormDocumentBill,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileUrl, setFileUrl] = useState<string | undefined>(EMPTY_STRING);
  const [isResetFile, setIsResetFile] = useState<boolean>(false);
  const [optionCreator, setOptionCreator] = useState<IBaseOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListAccount({
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [
        AccountRoleCodesEnum.ADMIN,
        AccountRoleCodesEnum.STAFF,
        AccountRoleCodesEnum.TECHNICIAN,
        AccountRoleCodesEnum.PROJECT_MANAGER,
      ],
      status: StatusEnum.ACTIVE,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!documentId) return;

    handleGetDocumentDetail(documentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseFormDocumentBillModal = () => {
    setIsResetFile(true);
    setFileUrl(EMPTY_STRING);
    reset(defaultValuesFormDocumentBill);
    onClose && onClose();
  };

  const handleGetListAccount = (payload: IQueryAccount) => {
    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res.data) return;

        const { responses } = res.data;

        if (!responses) return;

        setOptionCreator(
          responses.map((res) => ({
            label: getFullName({ ...res }),
            value: String(res?.id),
          }))
        );
      })
      .catch((_error) => {});
  };

  const handleGetDocumentDetail = (id: string) => {
    setIsLoading(true);

    dispatch(getDocumentDetail(id))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const { name, documentUrl, updatedAt, creator } = res?.data;

        setFileUrl(documentUrl);

        reset({
          name: name || EMPTY_STRING,
          createdBy: String(creator?.id) || EMPTY_STRING,
          updatedAt: updatedAt || EMPTY_STRING,
        });
      })
      .catch((_error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddDocument = (formData: FormData) => {
    setIsLoading(true);

    dispatch(addDocument(formData))
      .unwrap()
      .then((res) => {
        handleCloseFormDocumentBillModal();

        dispatch(documentActions.setRefreshListCommonDocument(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateDocument = (formData: FormData) => {
    if (!documentId) return;

    setIsLoading(true);

    dispatch(updateDocument({ documentId, body: formData }))
      .unwrap()
      .then((res) => {
        handleCloseFormDocumentBillModal();

        dispatch(documentActions.setRefreshListCommonDocument(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmitFormDocumentBill = (data: IFormDocument) => {
    const formData = new FormData();

    if (!billId) return;

    setValue('businessId', billId);
    setValue('businessType', BusinessTypeEnum.BILL);

    const { businessId, businessType, ...rest } = data;

    Object.entries(documentId ? rest : data).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value instanceof File ? value : String(value));
      }
    });

    if (!documentId) {
      handleAddDocument(formData);
    } else {
      handleUpdateDocument(formData);
    }
  };
  //#endregion Handle Function

  return (
    <BaseModal
      id='formDocumentVendorModalComponent'
      isOpen={isOpen}
      onClose={handleCloseFormDocumentBillModal}
    >
      <div className={cx('title')}>
        {documentId
          ? t('form_document_bill_edit_document_title')
          : t('form_document_bill_add_document_title')}
      </div>

      <form onSubmit={handleSubmit(handleSubmitFormDocumentBill)} className={cx('formDocument')}>
        <div className={cx('bodyContainer')}>
          <Controller
            name={KeyFormDocumentVendorEnum.DOCUMENT}
            control={control}
            render={({ field: { value, onChange } }) => (
              <UploadFile
                name={KeyFormDocumentVendorEnum.DOCUMENT}
                resetFile={isResetFile}
                height={35}
                onFileChange={(name, file) => onChange(file)}
                fileUrl={fileUrl}
                isRequired
                errorMessage={errors.document?.message}
              />
            )}
          />

          <div className={cx('body')}>
            <Controller
              name={KeyFormDocumentVendorEnum.NAME}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  name={KeyFormDocumentVendorEnum.NAME}
                  label={t('form_document_bill_document_name_label')}
                  value={value}
                  onChange={onChange}
                  errorMessage={errors.name?.message}
                  required
                />
              )}
            />

            <div className={cx('twoCol')}>
              <Controller
                name={KeyFormDocumentVendorEnum.CREATED_BY}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    name={KeyFormDocumentVendorEnum.CREATED_BY}
                    label={t('form_document_bill_created_by_label')}
                    placeholder={t('common_placeholder_select')}
                    options={optionCreator || []}
                    value={value}
                    onChange={({ value }: IBaseOption) => {
                      onChange(value);
                    }}
                    errorMessage={errors.createdBy?.message}
                  />
                )}
              />

              <Controller
                name={KeyFormDocumentVendorEnum.UPDATED_AT}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseDatePicker
                    name={KeyFormDocumentVendorEnum.UPDATED_AT}
                    label={t('form_document_bill_updated_at_label')}
                    placeholderText={t('common_placeholder_select_date')}
                    value={value}
                    onDateSelected={(date) => onChange(date)}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className={cx('actions')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              onClick={handleCloseFormDocumentBillModal}
              typeStyle={ButtonTypeEnum.CANCEL}
              type='button'
            />
          </div>

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
            isDisable={documentId ? !isDirty : false}
          />
        </div>
      </form>

      {isLoading && <Loading />}
    </BaseModal>
  );
};

export default FormDocumentBillModal;

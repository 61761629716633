// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { DEFAULT_FILTER_HEIGHT_BTN, DEFAULT_FILTER_WIDTH_BTN } from '~/utils/constants/component';
import { IFilterValueChange } from '~/utils/interface/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseFilter.module.scss';

type ChildrenActions<T> = {
  isChecked?: { [K in keyof T]?: boolean };
  valueFilter: T;
  onChange: (data: IFilterValueChange<T>) => void;
  onCheckboxChange: (key: keyof T, checked: boolean) => void;
};

type Props<T> = {
  widthBtn?: string | number;
  heightBtn?: string | number;
  valueFilter?: T;
  defaultValue: T;
  children: (actions: ChildrenActions<T>) => React.ReactNode;
  onApply?: (appliedFilter: T) => void;
};

const cx = classNames.bind(styles);

const BaseFilter = <T,>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    children,
    widthBtn = DEFAULT_FILTER_WIDTH_BTN,
    heightBtn = DEFAULT_FILTER_HEIGHT_BTN,
    valueFilter,
    defaultValue,
    onApply,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State\
  const [isDisableApply, setIsDisableApply] = useState<boolean>(false);
  const [rootValueFilter, setRootValueFilter] = useState<T>(defaultValue);
  const [tempValueFilter, setTempValueFilter] = useState<T>(defaultValue);
  const [isChecked, setIsChecked] = useState<{ [K in keyof T]?: boolean }>({});
  const [tempIsChecked, setTempIsChecked] = useState<{ [K in keyof T]?: boolean }>({});
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!valueFilter) {
      return;
    }

    setTempValueFilter(valueFilter);
    setRootValueFilter(valueFilter);

    const newCheckedState = Object.keys(valueFilter).reduce((acc, key) => {
      acc[key as keyof T] = !!valueFilter[key as keyof T];
      return acc;
    }, {} as { [K in keyof T]?: boolean });

    setIsChecked(newCheckedState);
    setTempIsChecked(newCheckedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilter]);

  useEffect(() => {
    if (!valueFilter) {
      return;
    }

    const isDisableBtnApply = JSON.stringify(tempValueFilter) === JSON.stringify(rootValueFilter);

    setIsDisableApply(isDisableBtnApply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempValueFilter, rootValueFilter]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleApply = (closeFn: Function) => {
    setRootValueFilter(tempValueFilter);
    onApply && onApply(tempValueFilter);
    setIsChecked(tempIsChecked);
    closeFn();
  };

  const onValueChange = (data: IFilterValueChange<T>) => {
    const { name, value } = data;
    const updatedValueFilter = {
      ...tempValueFilter,
      [name]: value,
    };
    setTempValueFilter(updatedValueFilter as T);
  };

  const handleClose = (closeFn: Function) => {
    setTempValueFilter(rootValueFilter);
    setTempIsChecked(isChecked);
    closeFn();
  };

  const onCheckboxChange = (key: keyof T, checked: boolean) => {
    setTempIsChecked((prev) => ({
      ...prev,
      [key]: checked,
    }));

    setTempValueFilter((prev) => {
      if (!prev) {
        return prev;
      }

      const updatedValue = { ...prev };
      if (!checked) {
        delete updatedValue[key];
      }
      return updatedValue as T;
    });
  };
  //#endregion Handle Function

  return (
    <div id='baseFilterComponent'>
      <Popover className='relative'>
        <PopoverButton className={cx('btnGroup')} style={{ height: heightBtn, width: widthBtn }}>
          <img
            src={icons.commonIconFilter}
            alt={t('common_img_text_alt')}
            className={cx('btnImg')}
          />
          <div className={cx('btnLabel')}>{t('common_btn_filter')}</div>
        </PopoverButton>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '10px' }}
          className={cx('popupFilterContainer')}
        >
          {({ open, close }) => {
            if (!open) handleClose(close);
            return (
              <>
                <h3 className={cx('popTitle')}>{t('common_btn_filter')}</h3>

                <div className={cx('popContent')}>
                  {children ? (
                    children({
                      isChecked: tempIsChecked,
                      valueFilter: tempValueFilter,
                      onChange: onValueChange,
                      onCheckboxChange: onCheckboxChange,
                    })
                  ) : (
                    <p className={cx('emptyData')}>{t('common_empty_data')}</p>
                  )}
                </div>

                <div className={cx('popBtnWrap')}>
                  <div className={cx('popBtnGroup')}>
                    <BaseButton
                      label={t('common_btn_cancel')}
                      type='button'
                      typeStyle={ButtonTypeEnum.CANCEL}
                      onClick={() => handleClose(close)}
                      minWidth={50}
                    />

                    <BaseButton
                      label={t('common_btn_apply')}
                      type='button'
                      onClick={() => handleApply(close)}
                      typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                      minWidth={50}
                      isDisable={isDisableApply}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default BaseFilter;

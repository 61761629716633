// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { FormInput } from '~/components';
// Others
import { DEFAULT_INPUT_HEIGHT } from '~/utils/constants/component';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IPurchaseOrderProduct } from '~/utils/interface/purchaseOrder';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './FieldItemProductBill.module.scss';

type Props = {
  data: IPurchaseOrderProduct;
};

const cx = classNames.bind(styles);

const FieldItemProductBill = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare Form Watch
  //#endregion Form Watch

  //#region Declare Memo

  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldItemProductBillComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className='col-span-4'>
          <FormInput
            label={t('form_bill_field_product_label')}
            disabled={true}
            value={data?.productName || EMPTY_STRING}
          />
        </div>
        <div className='col-span-1'>
          <FormInput
            label={t('form_bill_field_quantity_label')}
            disabled={true}
            value={data?.quantity || EMPTY_STRING}
          />
        </div>

        <div className='col-span-1'>
          <FormInput
            label={t('form_bill_field_unit_cost_label')}
            disabled={true}
            value={formatCurrency(CurrencyEnum.USD, data.unitCost ?? DEFAULT_NUMBER_ZERO)}
          />
        </div>

        <div className={'readOnlyField'}>
          <div className={cx('label')}>{t('form_bill_field_total_label')}</div>
          <div className={cx('value')} style={{ height: DEFAULT_INPUT_HEIGHT }}>
            {formatCurrency(CurrencyEnum.USD, data.subTotal ?? DEFAULT_NUMBER_ZERO)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldItemProductBill;

// Libs
import classNames from 'classnames/bind';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { EventProps, View, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSchedule, EventContainer, ScheduleAssignee } from '~/components';
// Others
import { BaseCalendarProps } from '~/components/common/baseSchedule/BaseSchedule';
import {
  prepareMonthEventsData,
  prepareTimeEventsData,
} from '~/components/specific/scheduleModules/helper';
import { MINUTES_IN_HOUR } from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_EVENT_COLOR } from '~/utils/constants/component';
import { IScheduleEvent } from '~/utils/interface/schedule';
// Styles, images, icons
import styles from './MySchedule.module.scss';
import './MySchedule.scss';

type Props = Omit<BaseCalendarProps, 'view' | 'date' | 'onSelectEvent' | 'events'> & {
  view: View;
  date: string;
  events: IScheduleEvent[];
  onSelectEvent?: (event: IScheduleEvent) => void;
};

const cx = classNames.bind(styles);

const CollapseMonthView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  return (
    <div
      className={cx('eventContainer', 'eventContainerCollapse')}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className={cx('eventName')}>{event.jobTitle}</div>
    </div>
  );
};

const ExpandMonthView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;

  return (
    <div
      className={cx('eventContainer', 'eventContainerExpand')}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className={cx('eventName')}>{event.jobTitle}</div>
      <div className={cx('eventTime')}>{`${event?.startTime} - ${event?.endTime}`}</div>

      <div className={cx('assigneeContainer')}>
        {event.assignees && (
          <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />
        )}
      </div>
    </div>
  );
};

const ExpandWeekView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor: string = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;

  return (
    <div
      className={cx('eventContainer', 'expandWeekViewContainer')}
      style={{
        borderColor: DEFAULT_SCHEDULE_EVENT_COLOR,
        backgroundColor: eventColor,
      }}
    >
      <div className={cx('eventName')}>{event.jobTitle}</div>
      <div className={cx('eventTime')}>{`${event?.startTime} - ${event?.endTime}`}</div>
      <div className={cx('assigneeContainer')}>
        {event.assignees && (
          <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />
        )}
      </div>
    </div>
  );
};

const CollapseWeekView = (props: { event: IScheduleEvent; view?: View }) => {
  const { event, view } = props;
  const eventColor: string = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;

  return (
    <div
      className={cx(
        'eventContainer',
        'collapseWeekViewContainer',
        view === Views.DAY && 'collapseDayViewContainer'
      )}
      style={{
        borderColor: DEFAULT_SCHEDULE_EVENT_COLOR,
        backgroundColor: eventColor,
      }}
    >
      <div className={cx('eventName')}>{event.jobTitle}</div>
      <div className={cx('eventTime')}>{`${event?.startTime} - ${event?.endTime}`}</div>
      <div className={cx('assigneeContainer')}>
        {event.assignees && (
          <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />
        )}
      </div>
    </div>
  );
};

const MySchedule = (props: Props) => {
  //#region Destructuring Props
  const { ref, view, date, events, onSelectEvent, ...restProps } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const preparedEvents = useMemo(() => {
    if (props.view === Views.MONTH) {
      return prepareMonthEventsData(props.events);
    }
    return prepareTimeEventsData(props.events);
  }, [view, events]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='myScheduleComponent' className={cx('container')}>
      <div
        className={cx(
          { 'h-[780px]': view === Views.MONTH },
          { myScheduleWeekView: view === Views.WEEK },
          { myScheduleDayView: view === Views.DAY }
        )}
      >
        {preparedEvents && (
          <BaseSchedule
            events={preparedEvents}
            selectable={true}
            toolbar={false}
            date={date}
            view={view}
            components={{
              event: ({ event }: EventProps) => {
                if (view === Views.MONTH) {
                  return (
                    <div className={cx('monthEventWrap')}>
                      {event?.resource?.map((eventData: IScheduleEvent, index: number) => (
                        <EventContainer
                          key={index}
                          data={eventData}
                          onClick={() => onSelectEvent && onSelectEvent(eventData)}
                        >
                          {event.resource.length > 1 ? (
                            <CollapseMonthView event={eventData} />
                          ) : (
                            <ExpandMonthView event={eventData} />
                          )}
                        </EventContainer>
                      ))}
                    </div>
                  );
                }

                if (view === Views.WEEK || view === Views.DAY) {
                  const eventData = event.resource[0] as IScheduleEvent;
                  const duration = moment(event.end).diff(moment(event.start), 'minutes');

                  return (
                    <EventContainer
                      height={'100%'}
                      data={eventData}
                      onClick={() => onSelectEvent && onSelectEvent(eventData)}
                    >
                      <div className={cx(`weekEventWrap`)}>
                        {duration > MINUTES_IN_HOUR ? (
                          <ExpandWeekView event={eventData} />
                        ) : (
                          <CollapseWeekView event={eventData} view={view} />
                        )}
                      </div>
                    </EventContainer>
                  );
                }
              },
            }}
            {...restProps}
          />
        )}
      </div>
    </div>
  );
};

export default memo(MySchedule);

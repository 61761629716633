// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseImageCircle,
  BaseMoreAction,
  BasePagination,
  BaseTable,
  ConfirmModal,
  FormDocumentVendorModal,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  documentActions,
  selectIsRefreshListCommonDocument,
} from '~/thunks/document/documentSlice';
import {
  deleteCommonDocument,
  downloadDocument,
  getListCommonDocument,
} from '~/thunks/document/documentThunk';
import {
  A_ELEMENT,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  OBJECT,
} from '~/utils/constants/common';
import { BaseTableEnum, BusinessTypeEnum } from '~/utils/enum';
import { formattedTime, getAvatarWithName, getFileNameFromUri, getFullName } from '~/utils/helper';
import useDebounce from '~/utils/hooks/useDebounce';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import {
  ICommonDocument,
  IFileDocumentInfo,
  IGetCommonDocumentReq,
} from '~/utils/interface/document';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './DocumentVendorTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleDownloadDocument: (document: IFileDocumentInfo) => void,
  handleUpdateDocument: (documentId: string) => void,
  handDeleteDocument: (documentId: string) => void
): ColumnType<ICommonDocument>[] => {
  return [
    {
      key: 'name',
      title: t('admin_manage_jobs_tabs_documents_table_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'creator',
      title: t('admin_manage_jobs_tabs_documents_table_created_by'),
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName(
            { firstName: record?.creator?.firstName, lastName: record?.creator?.lastName },
            record?.creator?.thumbnailUrl
          ) ? (
            <>
              <BaseImageCircle
                url={record?.creator?.thumbnailUrl ?? record?.creator?.avatarUrl}
                firstText={record?.creator?.firstName}
                secondText={record?.creator?.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record?.creator?.firstName,
                lastName: record?.creator?.lastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'updatedAt',
      title: t('admin_manage_jobs_tabs_documents_table_update_at'),
      render: (_, record) => (
        <span>
          {record?.updatedAt ? (
            <div className={cx('date')}>
              <img
                src={icons.commonIconCalender}
                alt={t('common_img_text_alt')}
                className={cx('icon')}
              />
              <span>{formattedTime(record?.updatedAt)}</span>
            </div>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </span>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_jobs_tabs_documents_table_action'),
      width: '10%',
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_download'),
            icon: (
              <img
                src={icons.commonIconDownload}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () =>
              handleDownloadDocument({
                documentId: record?.id,
                documentExtension: 'application/msword',
                documentName: getFileNameFromUri(record?.documentUrl ?? EMPTY_STRING),
              }),
          },
          {
            label: t('common_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleUpdateDocument(record?.id),
          },
          {
            label: t('common_btn_delete'),
            hasOtherColor: true,
            icon: (
              <img
                src={icons.commonIconTrash}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handDeleteDocument(record?.id),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const DocumentVendorTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const loading = useContext(LoadingData);
  const { vendorId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshListCommonDocument = useAppSelector(selectIsRefreshListCommonDocument);
  //#endregion Selector

  //#region Declare State
  const [isShowFormDocument, setIsShowFormDocument] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  const [documentsList, setDocumentsList] = useState<ICommonDocument[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowDeleteDocument, setIsShowDeleteDocument] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!vendorId) return;
    const { tab, ...rest } = params;
    const newParams = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListDocument({
      ...newParams,
      businessId: vendorId,
      businessType: BusinessTypeEnum.VENDOR,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, vendorId]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  // ** Re-fresh list when CRUD **
  useEffect(() => {
    if (!isRefreshListCommonDocument) return;
    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
    };

    handleGetListDocument({
      ...newParams,
      businessId: vendorId!,
      businessType: BusinessTypeEnum.VENDOR,
    });
    dispatch(documentActions.setRefreshListCommonDocument(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListCommonDocument]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSearchDocument = (value: string) => {
    setSearchKey(value);
  };

  const handleShowFormDocument = () => {
    setIsShowFormDocument(true);
  };

  const handleCloseFormDocument = () => {
    setIsShowFormDocument(false);
    setDocumentId(EMPTY_STRING);
  };

  const handleUpdateDocument = (id: string) => {
    if (!id) return;
    setDocumentId(id);
    setIsShowFormDocument(true);
  };

  const handleDownloadDocument = (item: IFileDocumentInfo) => {
    if (!item || !item?.documentId) return;

    loadingData?.show();

    dispatch(downloadDocument(item?.documentId))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const data: ArrayBuffer = res?.data;

        if (data && typeof data === OBJECT && Object.keys(data).length > DEFAULT_NUMBER_ZERO) {
          const array: number[] = Object.values(data);
          const uint8Array: Uint8Array = new Uint8Array(array);
          const blob: Blob = new Blob([uint8Array]);

          const url: string = window.URL.createObjectURL(blob);
          const link: HTMLAnchorElement = document.createElement(A_ELEMENT);
          link.href = url;
          link.download = item?.documentName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleCLickRow = (item: ICommonDocument) => {
    if (!item || !item?.id) return;

    setDocumentId(item?.id);
    setIsShowFormDocument(true);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const deleteDocument = (documentId: string) => {
    setIsShowDeleteDocument(true);
    setDocumentId(documentId);
  };

  const handleDeleteDocument = () => {
    if (!documentId) return;
    loading?.show();

    dispatch(deleteCommonDocument(documentId))
      .unwrap()
      .then((res) => {
        handleCloseModalDelete();
        dispatch(documentActions.setRefreshListCommonDocument(true));
        setDocumentId(EMPTY_STRING);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleCloseModalDelete = () => {
    isShowDeleteDocument && setIsShowDeleteDocument(false);
  };

  const handleGetListDocument = (payload: IGetCommonDocumentReq) => {
    loading?.show();

    dispatch(getListCommonDocument(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses, pagination } = res?.data;

        setDocumentsList(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='documentVendorTabComponent' className={cx('container')}>
      <Toolbar
        onSearch={handleSearchDocument}
        primaryBtn={{
          action: handleShowFormDocument,
          label: t('template_documents_jobs_btn_add_document'),
        }}
        rounded={false}
        valueSearch={searchKey || EMPTY_STRING}
      />

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(t, handleDownloadDocument, handleUpdateDocument, deleteDocument)}
            dataSource={documentsList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowFormDocument && (
        <FormDocumentVendorModal
          isOpen={isShowFormDocument}
          onClose={handleCloseFormDocument}
          documentId={documentId}
        />
      )}

      {isShowDeleteDocument && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleCloseModalDelete}
          onAction={handleDeleteDocument}
        />
      )}
    </div>
  );
};

export default DocumentVendorTab;

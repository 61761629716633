// Libs
import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// Components, Layouts, Pages
import { InputSearch, Loading } from '~/components';
// Others
import useDebounce from '~/utils/hooks/useDebounce';
import {
  ASTERISK_SYMBOL,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  SPACE,
} from '~/utils/constants/common';
import { icons } from '~/assets';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SELECT_HEIGHT, DEFAULT_SELECT_WIDTH } from '~/utils/constants/component';
import {
  Description,
  Field,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { InputSearchTypeStyleEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './SearchDropdown.module.scss';

type Props<T> = {
  fetchOptions: (search: string, page: number) => Promise<{ items: T[]; hasMore: boolean }>;
  height?: number | string;
  width?: number | string;
  optionsWidth?: number | string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  name?: string;
  value?: string | number | null;
  disabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: T | null, name: string) => void;
  renderOption: (item: T, selectedValue?: T | null) => React.ReactNode;
  renderLabel: (item: T, onClose: () => void) => React.ReactNode;
  getOptionKey: (item: T) => string;
  isSearch?: boolean;
  isLoading?: boolean;
};

const cx = classNames.bind(styles);

const SearchDropdown = <T extends { id: string }>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_SELECT_WIDTH,
    optionsWidth,
    height = DEFAULT_SELECT_HEIGHT,
    label,
    placeholder,
    errorMessage,
    name,
    value,
    disabled = false,
    onChange,
    isRequired,
    fetchOptions,
    renderOption,
    renderLabel,
    getOptionKey,
    isSearch = true,
    isLoading = false,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<T[]>([]);
  const [selectedValue, setSelectedValue] = useState<T | null>(null);
  const [page, setPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debounceSearchKey = useDebounce(searchKey.trim(), DEFAULT_DELAY_TIME);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  const loadOptions = useCallback(
    async (reset: boolean = false) => {
      try {
        isLoading && setIsFetching(true);
        const currentPage = reset ? DEFAULT_CURRENT_PAGE : page;
        const response = await fetchOptions(debounceSearchKey, currentPage);
        setSelectOption((prev) => (reset ? response.items : [...prev, ...response.items]));
        setHasMore(response.hasMore);
        setPage(reset ? 2 : page + 1);
      } catch (error) {
      } finally {
        isLoading && setIsFetching(false);
      }
    },
    [fetchOptions, debounceSearchKey, page]
  );

  useEffect(() => {
    loadOptions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchKey, fetchOptions]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
      return;
    }

    const matchedOption = selectOption.find((item) => String(getOptionKey(item)) === String(value));
    setSelectedValue(matchedOption || null);
  }, [value, selectOption, getOptionKey, selectedValue]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSearchKeyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
  }, []);

  const handleCloseItem = () => {
    setSelectedValue(null);
    onChange && onChange(null, name ?? EMPTY_STRING);
  };

  const renderOptionsList = useMemo(() => {
    if (!selectOption || selectOption?.length === DEFAULT_NUMBER_ZERO) {
      return <p className={cx('textNoData')}>{t('common_empty_data')}</p>;
    }

    return selectOption.map((item, index) => (
      <ListboxOption
        value={item}
        key={index}
        className={({ focus }) =>
          cx('optionItem', item.id === selectedValue?.id && 'optionActive', focus && 'optionHover')
        }
      >
        {renderOption(item, selectedValue)}
      </ListboxOption>
    ));
  }, [selectOption, renderOption, t]);

  const renderLabelContent = useMemo(() => {
    if (!selectedValue) {
      return (
        <span className={cx('btnPlaceholder')}>
          {placeholder || t('common_placeholder_select')}
        </span>
      );
    }

    return <div className={cx('btnText')}>{renderLabel(selectedValue, handleCloseItem)}</div>;
  }, [selectedValue, renderLabel, placeholder, t]);

  const handleOptionChange = (value: T) => {
    setSelectedValue(value);
    onChange && onChange(value, name ?? EMPTY_STRING);
  };
  //#endregion Handle Function

  return (
    <Field
      id='baseSearchDropdownComponent'
      className={cx('container')}
      style={{ width }}
      disabled={disabled}
    >
      {label && (
        <h3 className={cx('titleSelect')}>
          {label} {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </h3>
      )}

      <Listbox value={selectedValue} onChange={handleOptionChange}>
        {({ open }) => (
          <>
            <ListboxButton className={cx('btnSelect')} style={{ height, width }}>
              {renderLabelContent}

              <img
                src={icons.commonIconArrowBottom}
                className={cx('iconDown')}
                alt={t('common_img_text_alt')}
              />
            </ListboxButton>

            <ListboxOptions
              onKeyDownCapture={(e) => {
                if (e.key === SPACE) {
                  e.stopPropagation();
                }
              }}
              className={cx('optionList', isSearch && 'optionListSearch')}
              style={{ width: optionsWidth }}
              transition
              anchor={{ to: 'bottom end', gap: '4px' }}
            >
              {isSearch && (
                <div className={cx('search')}>
                  <InputSearch
                    placeholder={t('common_search_placeholder')}
                    typeStyle={InputSearchTypeStyleEnum.WITH_FULL}
                    onChange={handleSearchKeyChange}
                    value={searchKey || EMPTY_STRING}
                  />
                </div>
              )}

              <InfiniteScroll
                dataLength={selectOption?.length}
                hasMore={hasMore}
                next={() => loadOptions(false)}
                height={200}
                loader={EMPTY_STRING}
                scrollableTarget='baseSearchDropdownComponent'
              >
                <div className={cx('options')}>{renderOptionsList}</div>
              </InfiniteScroll>
            </ListboxOptions>
          </>
        )}
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
      {isFetching && <Loading />}
    </Field>
  );
};

export default React.memo(SearchDropdown) as <T>(props: Props<T>) => JSX.Element;

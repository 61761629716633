// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { BaseImageCircle, LogoutModal, ModalUnderDevelopment } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { IUserProfile } from '~/utils/interface/profile';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
import { convertCodeToRole, getFullName } from '~/utils/helper';

// Styles, images, icons
import { icons } from '~/assets';
import styles from './DropdownProfile.module.scss';

type Props = {
  data: IUserProfile;
};

const cx = classNames.bind(styles);

const DropdownProfile = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLogoutModal, setIsLogoutModal] = useState<boolean>(false);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  const [shouldShowOptions, setShouldShowOptions] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangePassword = (closeFn: Function) => {
    if (!currentRole) return;

    closeFn();
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(adminRouteAbsolute.changePassword);
      case AccountRoleCodesEnum.STAFF:
        return navigate(staffRouteAbsolute.changePassword);
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(projectManageRouteAbsolute.changePassword);
    }
  };

  const handleAdminSection = (closeFn: Function) => {
    closeFn();
    navigate(adminRouteAbsolute.adminSection);
  };

  const handleNotificationSettings = (closeFn: Function) => {
    if (!currentRole) return;

    closeFn();
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(adminRouteAbsolute.notificationSetting);
      case AccountRoleCodesEnum.STAFF:
        return navigate(staffRouteAbsolute.notificationSetting);
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(projectManageRouteAbsolute.notificationSetting);
    }
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleLogout = (closeFn: Function) => {
    closeFn();
    handleLogoutModal();
  };

  const handleLogoutModal = () => {
    setIsLogoutModal(!isLogoutModal);
  };

  const handleOpenProfile = (closeFn: Function) => {
    if (!currentRole) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(adminRouteAbsolute.profile);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(staffRouteAbsolute.profile);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(projectManageRouteAbsolute.profile);
        break;

      default:
        break;
    }

    closeFn();
  };
  //#endregion Handle Function

  return (
    <Popover>
      <div id='dropdownProfileComponent' className={cx('dropdownProfileComponent')}>
        <PopoverButton className={cx('headerAvatarWrap')}>
          <div className={cx('headerAvatarImg')}>
            <div className={cx('imageAvatar')}>
              <BaseImageCircle
                url={data?.avatar}
                firstText={data?.firstName}
                secondText={data?.lastName}
                width={40}
                height={40}
                fontSize={16}
              />
            </div>

            <div className={cx('headerNameWrap')}>
              <span className={cx('headerName')}>
                {getFullName({ firstName: data?.firstName, lastName: data?.lastName })}
              </span>

              {data?.role && (
                <span className={cx('headerRole')}>{t(convertCodeToRole(data?.role))}</span>
              )}
            </div>
          </div>

          <div className={cx('headerDropdownWrap')}>
            <img
              src={icons.commonIconDropdown}
              className={cx('headerDropdown')}
              alt={t('common_img_text_alt')}
            />
          </div>
        </PopoverButton>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '18px' }}
          className={cx('headerAvatarOption')}
        >
          {({ close }) => (
            <>
              <div className={cx('arrow')}></div>

              <div className={cx('headerOptionAvatar')}>
                <div className={cx('imageAvatar')}>
                  <BaseImageCircle
                    url={data?.avatar}
                    firstText={data?.firstName}
                    secondText={data?.lastName}
                    width={40}
                    height={40}
                    fontSize={16}
                  />
                </div>

                <div className={cx('headerNameWrap')}>
                  <span className={cx('headerName')}>
                    {(data?.firstName && data?.lastName) || data?.firstName || data?.lastName
                      ? `${data.firstName} ${data.lastName}`
                      : EMPTY_STRING}
                  </span>

                  {data?.role && (
                    <span className={cx('headerRole')}>{t(convertCodeToRole(data?.role))}</span>
                  )}
                </div>
              </div>

              <div className={cx('horizontalLine')} />

              <div className={cx('headerOptionGroup')}>
                <div className={cx('headerOptionWrap')}>
                  <button
                    className={cx('headerOptionText')}
                    onClick={() => handleOpenProfile(close)}
                  >
                    <img
                      src={icons.commonIconProfile}
                      className={cx('headerDropdown')}
                      alt={t('common_img_text_alt')}
                    />
                    {t('template_sidebar_label_profile')}
                  </button>
                </div>

                <div className={cx('headerOptionWrap')}>
                  <button
                    className={cx('headerOptionText')}
                    onClick={() => handleChangePassword(close)}
                  >
                    <img
                      src={icons.commonIconLock}
                      className={cx('headerDropdown')}
                      alt={t('common_img_text_alt')}
                    />
                    {t('template_sidebar_label_change_password')}
                  </button>
                </div>

                {currentRole === AccountRoleCodesEnum.ADMIN && shouldShowOptions && (
                  <div className={cx('headerOptionWrap')}>
                    <button
                      className={cx('headerOptionText')}
                      onClick={() => handleAdminSection(close)}
                    >
                      <img
                        src={icons.commonIconSettingDropdownProfile}
                        className={cx('headerDropdown')}
                        alt={t('common_img_text_alt')}
                      />
                      {t('template_sidebar_label_admin_section')}
                    </button>
                  </div>
                )}

                {shouldShowOptions && (
                  <div className={cx('headerOptionWrap')}>
                    <button
                      className={cx('headerOptionText')}
                      onClick={() => handleNotificationSettings(close)}
                    >
                      <img
                        src={icons.commonIconNotificationSettings}
                        className={cx('headerDropdown')}
                        alt={t('common_img_text_alt')}
                      />
                      {t('template_sidebar_label_notification_settings')}
                    </button>
                  </div>
                )}
              </div>

              <div className={cx('horizontalLine')} />

              <div className={cx('headerOptionWrap', 'headerOptionWrapLogout')}>
                <button className={cx('headerOptionLogout')} onClick={() => handleLogout(close)}>
                  <img
                    src={icons.commonIconLogoutDropdownProfile}
                    className={cx('headerDropdown')}
                    alt={t('common_img_text_alt')}
                  />
                  {t('admin_sidebar_label_logout')}
                </button>
              </div>
            </>
          )}
        </PopoverPanel>

        {isLogoutModal && <LogoutModal onClose={handleLogoutModal} />}

        {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
      </div>
    </Popover>
  );
};

export default DropdownProfile;

// Libs
import classNames from 'classnames/bind';
import { Controller, FormProvider, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  FormInput,
  BaseButton,
  BaseSelect,
  BaseTextarea,
  BaseUploadImage,
  FieldContactClient,
  UploadAvatar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { TFormModalMode } from '~/utils/type/common';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FORM_MAIN_TERM_MODE_CREATE,
  FORM_MODE_CREATE,
  FORM_MODE_EDIT,
  linkToQuickBookCustomerOptions,
  REGEX,
} from '~/utils/constants/common';
import { ClientNameEnum, ButtonTypeEnum, LinkToQuickBookCustomerEnum } from '~/utils/enum';
import { IFormClient, IUpdateClient, IUpdateClientBody } from '~/utils/interface/client';
import { compressImage, getFullName } from '~/utils/helper';
import { getListQuickBookCustomer } from '~/thunks/quickBook/quickBookThunk';
import { ICustomerQuickBook } from '~/utils/interface/quickBook';
import { IBaseOption, IQueryBase, IUploadImage } from '~/utils/interface/common';
import { createNewClient, getClientDetails, updateClient } from '~/thunks/client/clientThunk';
import { clientSchema, DEFAULT_CONTACT_CLIENT, initialValues } from './helper';
// Styles, images, icons
import styles from './FormClient.module.scss';
import { icons } from '~/assets';

type Props<T> = {
  onClose: () => void;
  mode?: T;
};

const cx = classNames.bind(styles);

const FormClient = <T extends TFormModalMode>(props: Props<T>) => {
  //#region Destructuring Props
  const { onClose, mode = FORM_MODE_CREATE } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const isEditMode = useMemo(() => !!clientId, [clientId]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useForm<IFormClient>({
    resolver: yupResolver(clientSchema({ t, isEditMode })),
    defaultValues: initialValues,
  });

  // Contact useFieldArray
  const {
    fields: contactFields,
    append: appendEquipment,
    remove: removeEquipment,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const linkQuickBookType = watch('typeLinkQuickBook');
  const quickBookIdSelected = watch('quickBookCustomerId');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [quickBookCustomers, setQuickBookCustomers] = useState<ICustomerQuickBook[]>([]);
  const [loadingQuickBock, setIsLoadingQuickBook] = useState<boolean>(false);
  const [initDataForm, setInitDataForm] = useState<IFormClient>(initialValues);
  const [avatarFile, setAvatarFile] = useState<File | null>();
  const [thumbnailFile, setThumbnailFile] = useState<File | null>();

  const isCreateMode = mode === FORM_MAIN_TERM_MODE_CREATE;
  const isLinkQuickBookValid =
    linkQuickBookType === LinkToQuickBookCustomerEnum.NO ||
    linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_NEW ||
    (linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING && quickBookIdSelected);

  const isShouldRender =
    mode !== FORM_MAIN_TERM_MODE_CREATE || (isCreateMode && isLinkQuickBookValid);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (mode === FORM_MODE_CREATE) {
      const dataForm: IFormClient = {
        ...initialValues,
        typeLinkQuickBook: linkQuickBookType,
      };
      reset(dataForm);

      if (linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING) {
        const body: IQueryBase = {
          page: DEFAULT_CURRENT_PAGE,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
        };
        handleGetListQuickBookCustomer(body);
      }
      return;
    }

    if (mode === FORM_MODE_EDIT && clientId) {
      handleGetDetailClient(clientId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, linkQuickBookType, clientId]);

  useEffect(() => {
    if (quickBookCustomers && quickBookIdSelected) {
      handleCustomerQuickBooksChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickBookIdSelected]);

  useEffect(() => {
    if (linkQuickBookType && mode === FORM_MODE_CREATE) {
      appendEquipment(DEFAULT_CONTACT_CLIENT);
    }
  }, [linkQuickBookType, appendEquipment, mode]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListQuickBookCustomer = (body: IQueryBase) => {
    setIsLoadingQuickBook(true);
    dispatch(getListQuickBookCustomer(body))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses: customersQuickBook } = res.data;
        setQuickBookCustomers(customersQuickBook);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingQuickBook(false);
      });
  };

  const handleAddClient = (data: IFormClient) => {
    const newData = handlePreparedData(data);
    if (!newData || Object.keys(newData).length === DEFAULT_NUMBER_ZERO) return;

    const formData = new FormData();

    Object.entries(newData).forEach(([key, value]) => {
      if (value) {
        if (key === 'contacts' && Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value instanceof File ? value : String(value));
        }
      }
    });

    loadingData?.show();

    dispatch(createNewClient(formData))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        handleCancelClient();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handlePreparedData = (data: IFormClient) => {
    if (!data) return {} as IFormClient;

    const formattedData: IFormClient = { ...data };
    const bodyData: Partial<IFormClient> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IFormClient] = value;
      }
    });

    return bodyData as IFormClient;
  };

  const handleCancelClient = () => {
    onClose && onClose();
  };

  const handleCustomerQuickBooksChange = () => {
    const customerSelected = quickBookCustomers.find(
      (customer) => customer.id === quickBookIdSelected
    );
    if (customerSelected) {
      setValue('name', customerSelected.name || EMPTY_STRING);
      trigger('name');
      setValue('note', customerSelected.note || EMPTY_STRING);
      setValue(
        'phoneNumber',
        customerSelected.phone?.replace(REGEX.REPLACE_TEXT_ALL, EMPTY_STRING) || EMPTY_STRING
      );
      setValue('email', customerSelected.email || EMPTY_STRING);
      setValue('syncToken', customerSelected.syncToken || EMPTY_STRING);
      setValue('contactName', customerSelected.contactName || EMPTY_STRING);
      setValue('other', customerSelected.other || EMPTY_STRING);
    }
  };

  const handleGetDetailClient = (id: string) => {
    loadingData?.show();
    dispatch(getClientDetails(id))
      .unwrap()
      .then((res) => {
        loadingData?.hide();

        if (!res?.data) return;

        const mainContentPrepareData: IFormClient = {
          id: res?.data.id || EMPTY_STRING,
          name: getFullName({ ...res?.data }) || EMPTY_STRING,
          avatar: res?.data.avatar || null,
          thumbnail: res?.data.thumbnail || null,
          contactName: res?.data.contactName || EMPTY_STRING,
          contacts: res?.data.contacts || [],
          email: res?.data.email || EMPTY_STRING,
          note: res?.data.note || EMPTY_STRING,
          other: res?.data.other || EMPTY_STRING,
          phoneNumber: res?.data.phoneNumber || EMPTY_STRING,
          quickBookCustomerId: res?.data.quickBookCustomerId || EMPTY_STRING,
        };
        setInitDataForm(mainContentPrepareData);
        reset(mainContentPrepareData);
      })
      .catch((error) => {})
      .then(() => loadingData?.hide());
  };

  const handleFileChange = async (file: File, name: keyof IUpdateClient) => {
    if (!name || !file) return;
    const thumbnailImageFile = await compressImage(file);

    thumbnailImageFile && setThumbnailFile(thumbnailImageFile);
    setAvatarFile(file);

    const imageURL = URL.createObjectURL(file);
    setValue(name, imageURL, { shouldDirty: true });
  };

  const handleUpdateClient = (data: IFormClient) => {
    if (!data) return;

    const formData = new FormData();
    const formattedData: IFormClient = { ...data };
    const skipAppendKeys = new Set([ClientNameEnum.THUMBNAIL]);

    Object.entries(formattedData).forEach(([key, value]) => {
      const initialValue = initDataForm[key as keyof IUpdateClient];

      if (
        value && // Skip empty values
        value !== initialValue && // Only append changed values
        !skipAppendKeys.has(key as ClientNameEnum) // Skip keys in the skip list
      ) {
        const valueToAppend = typeof value === 'object' ? JSON.stringify(value) : value;
        formData.append(key, valueToAppend);
      }
    });

    avatarFile
      ? formData.set(ClientNameEnum.AVATAR, avatarFile)
      : formData.delete(ClientNameEnum.AVATAR);
    thumbnailFile
      ? formData.set(ClientNameEnum.THUMBNAIL, thumbnailFile)
      : formData.delete(ClientNameEnum.THUMBNAIL);

    const updateClientBody: IUpdateClientBody = {
      id: initDataForm.id?.toString() || EMPTY_STRING,
      body: formData,
    };

    loadingData?.show();

    dispatch(updateClient(updateClientBody))
      .unwrap()
      .then((res) => {
        if (!res) return;
        handleCancelClient();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleSaveFormClient = (data: IFormClient) => {
    if (mode === FORM_MODE_CREATE) {
      handleAddClient(data);
      return;
    }

    if (mode === FORM_MODE_EDIT && clientId) {
      handleUpdateClient(data);
      return;
    }
  };
  //#endregion Handle Function

  return (
    <>
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          formState: { errors },
        } as UseFormReturn<IFormClient>)}
      >
        <form className={cx('form')} onSubmit={handleSubmit(handleSaveFormClient)}>
          <div className={cx('body')}>
            {mode === FORM_MAIN_TERM_MODE_CREATE ? (
              <div className={cx('twoColumns')}>
                <Controller
                  name={ClientNameEnum.TYPE_LINK_QUICK_BOOK}
                  control={control}
                  render={({ field }) => (
                    <BaseSelect
                      label={t('admin_manage_clients_link_to_quick_book_customer_label')}
                      placeholder={t('common_placeholder_select')}
                      value={field.value || EMPTY_STRING}
                      options={linkToQuickBookCustomerOptions || []}
                      onChange={({ value }: IBaseOption) => {
                        field.onChange(value);
                      }}
                      errorMessage={errors.typeLinkQuickBook?.message}
                      isRequired
                    />
                  )}
                />

                {linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING && (
                  <Controller
                    name={ClientNameEnum.QUICK_BOOK_CUSTOMER_ID}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseSelect
                        label={t('admin_manage_clients_choose_quick_book_customer_label')}
                        placeholder={
                          loadingQuickBock
                            ? t('common_text_loading')
                            : t('common_placeholder_select')
                        }
                        value={value || EMPTY_STRING}
                        options={
                          (quickBookCustomers.map((item) => ({
                            label: item.name,
                            value: item.id,
                          })) as IBaseOption[]) || []
                        }
                        onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                        errorMessage={errors.quickBookCustomerId?.message}
                        isRequired
                      />
                    )}
                  />
                )}
              </div>
            ) : (
              <div className={cx('quickBooks')}>
                <span className={cx('quickBooksLabel')}>{`${t(
                  'admin_manage_clients_quick_books_label'
                )}:`}</span>

                <div
                  className={cx('quickBooksStatus', {
                    active: initDataForm.quickBookCustomerId,
                  })}
                ></div>
              </div>
            )}

            {isShouldRender && (
              <>
                <div className={cx('avatarContent')}>
                  <Controller
                    name={ClientNameEnum.AVATAR}
                    control={control}
                    render={({ field: { value, onChange } }) =>
                      mode === FORM_MODE_CREATE ? (
                        <BaseUploadImage
                          label={t('admin_manage_clients_profile_image_label')}
                          onChange={async (imageList: IUploadImage[]) => {
                            if (!imageList || imageList.length <= DEFAULT_NUMBER_ZERO) {
                              onChange(null);
                              setValue('thumbnail', null);
                              return;
                            }
                            const profileImage = imageList[DEFAULT_NUMBER_ZERO].file;

                            onChange(profileImage);
                            if (profileImage) {
                              const thumbnail = await compressImage(profileImage);

                              if (thumbnail) {
                                const thumbnailFile = new File([thumbnail], profileImage.name, {
                                  type: profileImage.type,
                                  lastModified: Date.now(),
                                });
                                thumbnailFile && setValue('thumbnail', thumbnailFile);
                              }
                            }
                          }}
                          errorMessage={errors.avatar?.message}
                        />
                      ) : (
                        <UploadAvatar
                          name={ClientNameEnum.AVATAR}
                          urlAvatar={
                            value instanceof File
                              ? URL.createObjectURL(value)
                              : value || EMPTY_STRING
                          }
                          firstName={initDataForm.name}
                          onFileChange={(_: string, file: File) => handleFileChange(file, 'avatar')}
                        />
                      )
                    }
                  />
                </div>

                <Controller
                  name={ClientNameEnum.NAME}
                  control={control}
                  render={({ field }) => (
                    <FormInput
                      required
                      label={t('admin_manage_clients_client_name_label')}
                      value={field.value || ''}
                      onChange={field.onChange}
                      errorMessage={errors.name?.message}
                    />
                  )}
                />

                <div className={cx('twoColumns')}>
                  <Controller
                    name={ClientNameEnum.CONTACT_NAME}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        label={t('admin_manage_clients_main_contact_label')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        errorMessage={errors.contactName?.message}
                      />
                    )}
                  />

                  <Controller
                    name={ClientNameEnum.PHONE_NUMBER}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        label={t('admin_manage_clients_phone_number_label')}
                        value={field.value || ''}
                        type='number'
                        onChange={field.onChange}
                        errorMessage={errors.phoneNumber?.message}
                      />
                    )}
                  />
                </div>

                <div className={cx('twoColumns')}>
                  <Controller
                    name={ClientNameEnum.EMAIL}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        label={t('admin_manage_clients_email_label')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        errorMessage={errors.email?.message}
                      />
                    )}
                  />

                  <Controller
                    name={ClientNameEnum.OTHER}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        label={t('admin_manage_clients_other_label')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        errorMessage={errors.other?.message}
                      />
                    )}
                  />
                </div>

                <Controller
                  name={ClientNameEnum.NOTE}
                  control={control}
                  render={({ field }) => (
                    <BaseTextarea
                      label={t('admin_manage_clients_notes_label')}
                      height={118}
                      maxLength={100}
                      value={field.value}
                      onTextareaChange={field.onChange}
                      errorMessage={errors.note?.message}
                    />
                  )}
                />

                <div className={cx('titleLine')}>
                  <p className={cx('cLabel')}>{t('admin_manage_clients_detail_contacts_title')}</p>
                  <div className={cx('lineHr')} />

                  <div className={cx('formListGroup')}>
                    {contactFields.map((row, index) => (
                      <FieldContactClient
                        key={row.id}
                        fieldIndex={index}
                        removeField={removeEquipment}
                      />
                    ))}

                    <button
                      type='button'
                      onClick={() => appendEquipment(DEFAULT_CONTACT_CLIENT)}
                      className={cx('btnAddField', 'group')}
                    >
                      <img
                        src={icons.commonIconAddField}
                        alt={t('common_img_text_alt')}
                        className={cx('iconAddField')}
                      />
                      <span className={cx('btnTextAdd')}>
                        {t('template_form_client_btn_add_contact_field')}
                      </span>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={cx('actions')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                typeStyle={ButtonTypeEnum.CANCEL}
                onClick={handleCancelClient}
              />
            </div>

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              type='submit'
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              isDisable={mode !== FORM_MODE_CREATE && !isDirty}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormClient;

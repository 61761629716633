// Libs
import classNames from 'classnames/bind';
import { ReactNode, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
// Others
import { generateGuid } from '~/utils/helper';
// Styles, images, icons
import styles from './LabelValueField.module.scss';

type Props = {
  label: string;
  value?: string | number | ReactNode;
  isLastItem?: boolean;
  isLongValue?: boolean;
};

const cx = classNames.bind(styles);

const LabelValueField = (props: Props) => {
  //#region Destructuring Props
  const { label, value, isLastItem, isLongValue } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const noteRef = useRef<HTMLDivElement | null>(null);
  //#endregion Declare Hook

  //#region Declare State
  const [isShowMore, setIsShowMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const uniqueId = `${generateGuid()}`;
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    checkIfTruncated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isLongValue]);
  //#endregion Implement Hook

  //#region Handle Function
  const toggleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  const checkIfTruncated = () => {
    if (isLongValue && noteRef.current) {
      const { scrollHeight, clientHeight } = noteRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  };
  //#endregion Handle Function

  return (
    <div className={cx('container', { noBorder: isLastItem })}>
      <div
        className={cx('item', 'key', { alignSelfStart: isLongValue })}
        data-tooltip-id={uniqueId}
        data-tooltip-place='top'
        data-tooltip-variant='light'
      >
        {label}
        <Tooltip id={uniqueId} className={cx('tooltipWrap')}>
          <span className={cx('tooltipText')}>{label}</span>
        </Tooltip>
      </div>

      <div className={cx('item', 'val', { truncateValue: !isLongValue })}>
        {isLongValue ? (
          <>
            <div
              ref={noteRef}
              className={cx('noteValue', {
                expanded: isShowMore,
              })}
            >
              {value}
            </div>
            {isLongValue && isTruncated && (
              <button onClick={toggleShowMore} className={cx('toggleButton')}>
                {isShowMore ? t('common_label_show_less') : t('common_label_show_more')}
              </button>
            )}
          </>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

export default LabelValueField;

import { TFunction } from 'i18next';
import * as yup from 'yup';
import { EMPTY_STRING } from '~/utils/constants/common';
import { LinkToQuickBookCustomerEnum } from '~/utils/enum';
import { IFormProductPayload, IVendorFieldProduct } from '~/utils/interface/product';

export const productSchema = (payload: { t: TFunction; isEditMode?: boolean }) => {
  const { t, isEditMode } = payload;

  return yup
    .object({
      name: yup.string().required(t('admin_manage_products_validate_required_name')),
      description: yup.string().optional(),
      extendedDescription: yup.string().optional(),
      category: yup.string().required(t('admin_manage_products_validate_required_category')),
      typeLinkQuickBook: yup
        .string()
        .nullable()
        .when([], {
          is: () => isEditMode !== true,
          then: (schema) =>
            schema.notOneOf(
              [EMPTY_STRING],
              t('admin_manage_products_link_to_quick_book_customer_required')
            ),
          otherwise: (schema) => schema.optional(),
        }),
      quickBookItemId: yup
        .string()
        .nullable()
        .when('typeLinkQuickBook', {
          is: (value: string) => value === LinkToQuickBookCustomerEnum.ADD_EXISTING && !isEditMode,
          then: (schema) =>
            schema.required(t('admin_manage_products_choose_quick_book_customer_required')),
          otherwise: (schema) => schema.optional(),
        }),
      vendors: yup
        .array()
        .of(
          yup.object().shape({
            sku: yup.string().required(t('admin_manage_products_validate_required_sku')),
            unitPrice: yup
              .number()
              .typeError(t('admin_manage_products_validate_number_unit_price'))
              .required(t('admin_manage_products_validate_required_unit_price')),
            unitCost: yup
              .number()
              .typeError(t('admin_manage_products_validate_number_unit_cost'))
              .required(t('admin_manage_products_validate_required_unit_cost')),
            vendorId: yup.string().required(t('admin_manage_products_validate_required_vendor')),
            unitMeasure: yup
              .string()
              .required(t('admin_manage_products_validate_required_unit_of_measure')),
            costCode: yup.string().required(t('admin_manage_products_validate_required_cost_code')),
          })
        )
        .default([])
        .required(),
    })
    .required();
};

export const DEFAULT_VENDOR_PRODUCT: IVendorFieldProduct = {
  costCode: EMPTY_STRING,
  sku: EMPTY_STRING,
  unitCost: 0,
  unitMeasure: EMPTY_STRING,
  unitPrice: 0,
  vendorId: EMPTY_STRING,
};

export const defaultData: IFormProductPayload = {
  name: EMPTY_STRING,
  description: EMPTY_STRING,
  extendedDescription: EMPTY_STRING,
  category: EMPTY_STRING,
  quickBookItemId: EMPTY_STRING,
  typeLinkQuickBook: EMPTY_STRING,
  syncToken: EMPTY_STRING,
  invStartDate: EMPTY_STRING,
  vendors: [],
};

// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseFilter,
  BaseMoreAction,
  BasePagination,
  BaseSelect,
  ModalUnderDevelopment,
  Status,
  TableSelected,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { billActions } from '~/thunks/bill/billSlice';
import { getListBill, recordPaymentBill } from '~/thunks/bill/billThunk';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  POUND,
  optionsFilterBillByStatus,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  CurrencyEnum,
  FilterBillByStatusEnum,
  StorageEnum,
} from '~/utils/enum';
import { formatCurrency, formattedTime } from '~/utils/helper';
import useDebounce from '~/utils/hooks/useDebounce';
import { IBill, IBodyRecordBillsPayments, IFilterBill } from '~/utils/interface/bill';
import { ColumnType, IPagination, IQueryBase, MoreActionItem } from '~/utils/interface/common';
// Styles, images, icons
import styles from './Bill.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<'translation'>,
  onViewDetail: (id: string) => void,
  onViewQuickBooks: (url?: string) => void,
  handleRecordPaymentBillSelected: (id: string) => void
): ColumnType<IBill>[] => {
  return [
    {
      key: 'id',
      title: t('template_bill_id_label'),
      render(_, record) {
        return <>{record?.id}</>;
      },
    },
    {
      key: 'poNumber',
      title: t('template_bill_po_number_label'),
      render(_, record) {
        return (
          <>
            <span className={cx('poNumber')}>{POUND}</span>
            {record?.purchaseOrderId}
          </>
        );
      },
    },
    {
      key: 'vendor',
      title: t('template_bill_vendor_label'),
      render(_, record) {
        return record?.vendor?.name || EMPTY_STRING;
      },
    },
    {
      key: 'invoiceDate',
      title: t('template_bill_invoice_date_label'),
      render(_, record) {
        return <div>{record?.invoiceDate ? formattedTime(record?.invoiceDate) : EMPTY_STRING}</div>;
      },
    },
    {
      key: 'dueDate',
      title: t('template_bill_due_date_label'),
      render(_, record) {
        return <div>{record?.dueDate ? formattedTime(record?.dueDate) : EMPTY_STRING}</div>;
      },
    },
    {
      key: 'total',
      title: t('template_bill_total_label'),
      render(_, record) {
        return record?.total ? formatCurrency(CurrencyEnum.USD, record?.total) : DEFAULT_CURRENCY;
      },
    },
    {
      key: 'status',
      title: t('template_bill_status_label'),
      render(_, record) {
        return record?.status ? (
          <Status type='tag' status={record?.status} />
        ) : (
          <span>{EMPTY_STRING}</span>
        );
      },
    },
    {
      key: 'action',
      title: t('template_bill_action_label'),
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetail(record.id),
          },
          {
            label: t('common_action_view_on_quick_books'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewQuickBooks(record.externalLinkQB),
          },
          {
            label: t('common_action_record_payment'),
            icon: (
              <img
                src={icons.commonIconRecordPayment}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleRecordPaymentBillSelected(record.id),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const Bill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshBillList } = useAppSelector((state: RootState) => state.billState);
  //#endregion Selector

  //#region Declare State
  const [billList, setBillList] = useState<IBill[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  const [isDevelopment, setIsDevelopment] = useState<boolean>(false);
  const [isShowRecordPayment, setIsShowRecordPayment] = useState<boolean>(false);
  const [idBills, setIdBills] = useState<number[]>([]);
  const [idBillsSelected, setIdBillsSelected] = useState<number[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListBill(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshBillList) return;

    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
    };

    handleGetListBill(newParams);
    dispatch(billActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshBillList]);

  useEffect(() => {
    if (!idBillsSelected.length) return;

    handleRecordPaymentBill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idBillsSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListBill = (params: IQueryBase) => {
    loading?.show();
    dispatch(getListBill(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses, pagination } = res.data;

        responses && setBillList(responses);
        pagination && setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleAddBill = () => {
    const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;

    const routes = {
      [AccountRoleCodesEnum.ADMIN]: adminRouteAbsolute.createBill,
      [AccountRoleCodesEnum.STAFF]: staffRouteAbsolute.createBill,
      [AccountRoleCodesEnum.PROJECT_MANAGER]: projectManageRouteAbsolute.createBill,
    };

    if (role && routes[role as keyof typeof routes]) {
      navigate(routes[role as keyof typeof routes]);
    }
  };

  const handleBillSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleViewDetail = (id: string) => {
    if (!id) return;
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.bill}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.bill}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.bill}/${id}`);
        break;

      default:
        break;
    }
  };

  const handleViewQuickBooks = (url?: string) => {
    if (url) window.open(url);
  };

  const handleChangePagination = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCLickRow = (record: IBill) => {
    if (!record.id) return;
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.bill}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.bill}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.bill}/${record.id}`);
        break;

      default:
        break;
    }
  };

  const handleRowSelection = (selectedRowKeys: React.Key[], selectedRows: IBill[]) => {
    const hasData = selectedRowKeys.length > 0 || selectedRows.length > 0;
    const listIdBills = selectedRowKeys.map((key) => Number(key));

    setIsShowRecordPayment(hasData);
    setIdBills(listIdBills);
  };

  const handleApplyFilterBill = (valueFilter: IFilterBill) => {
    const { status, ...restParams } = params;

    if (
      !valueFilter ||
      Object.keys(valueFilter).length === DEFAULT_NUMBER_ZERO ||
      valueFilter.status === FilterBillByStatusEnum.ALL
    ) {
      setSearchParams({
        ...restParams,
        page: DEFAULT_NUMBER_ONE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      });
      return;
    }

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status ? { status: valueFilter?.status } : {}),
    });
  };

  const handleUnderDevelopment = () => {
    setIsDevelopment(!isDevelopment);
  };

  const handleRecordPaymentBillSelected = (billIdSelected: string) => {
    if (!billIdSelected) return;

    const billIdAsNumber = Number(billIdSelected);
    if (!isNaN(billIdAsNumber)) {
      setIdBillsSelected([billIdAsNumber]);
    }
  };

  const handleRecordPaymentBill = () => {
    loading?.show();

    const payload: IBodyRecordBillsPayments = {
      billIds: idBillsSelected.length ? idBillsSelected : idBills,
    };

    dispatch(recordPaymentBill(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        dispatch(billActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loading?.hide();
        setIdBillsSelected([]);
      });
  };
  //#endregion Handle Function

  return (
    <div id='billPage' className={cx('container')}>
      <Toolbar
        title={t('template_bill_toolbar_title')}
        primaryBtn={{
          action: handleAddBill,
          label: t('template_bill_toolbar_btn_add_bill_label'),
        }}
        onSearch={handleBillSearch}
        {...(isShowRecordPayment && {
          actionButton: {
            action: handleRecordPaymentBill,
            label: t('template_bill_toolbar_btn_record_bill_label'),
          },
        })}
        valueSearch={searchKey || EMPTY_STRING}
      >
        <BaseFilter<IFilterBill>
          defaultValue={{ status: FilterBillByStatusEnum.ALL }}
          onApply={handleApplyFilterBill}
          valueFilter={{
            status: (params?.status as FilterBillByStatusEnum) || FilterBillByStatusEnum.ALL,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('contentFilterWrap')}>
                <div className={cx('labelFilter')}>{t('template_bill_filter_by_status_label')}</div>

                <BaseSelect
                  name='status'
                  options={optionsFilterBillByStatus || []}
                  height={31}
                  value={valueFilter?.status}
                  placeholder={t('common_placeholder_select')}
                  onChange={({ value }, name) => {
                    onChange({
                      name: name as keyof IFilterBill,
                      value: value as FilterBillByStatusEnum,
                    });
                  }}
                />
              </div>
            );
          }}
        </BaseFilter>
      </Toolbar>

      <section className={cx('body')}>
        <div className={cx('statisticTable')}>
          <TableSelected
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewDetail,
              handleViewQuickBooks,
              handleRecordPaymentBillSelected
            )}
            dataSource={billList || []}
            onClickRow={handleCLickRow}
            rowSelection={{
              type: 'checkbox',
              onChange: handleRowSelection,
            }}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handleChangePagination}
          />
        </div>
      </section>

      {isDevelopment && <ModalUnderDevelopment onClose={handleUnderDevelopment} />}
    </div>
  );
};

export default Bill;

// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { DropdownProfile, ReceivedNotification } from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { getListNotifications } from '~/thunks/notifications/notificationsThunk';

import { WebsocketContext } from '~/context';
import { RootState } from '~/redux/store';
import { notificationActions } from '~/thunks/notifications/notificationsSlice';
import { WebSocketEventEnum } from '~/utils/enum';
import { IQueryBase } from '~/utils/interface/common';
import { IUserProfile } from '~/utils/interface/profile';
// Styles, images, icons
import styles from './Header.module.scss';

type Props = {
  userInfo: IUserProfile;
};

const cx = classNames.bind(styles);

const Header = (props: Props) => {
  //#region Destructuring Props
  const { userInfo } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const dispatch = useAppDispatch();
  const { wsData } = useContext(WebsocketContext);
  //#endregion Declare Hook

  //#region Selector
  const { queryParams, refreshNotificationList } = useAppSelector(
    (state: RootState) => state.notificationsState
  );
  //#endregion Selector

  //#region Declare State
  const [hasMore, setHasMore] = useState(true);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListNotifications(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (!refreshNotificationList) return;
    handleGetListNotifications(queryParams);
    dispatch(notificationActions.setRefreshState(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshNotificationList]);

  useEffect(() => {
    if (!wsData) return;

    switch (wsData?.type) {
      case WebSocketEventEnum.NEW_NOTIFICATION:
        dispatch(notificationActions.refreshData(true));
        break;
      default:
        break;
    }
  }, [wsData]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListNotifications = (params: IQueryBase) => {
    dispatch(getListNotifications(params))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data?.notifications;

        if (responses.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((error) => {});
  };

  const loadMore = () => {
    dispatch(
      notificationActions.setParamsNotification({
        ...queryParams,
        page: queryParams.page + 1,
      })
    );
  };
  //#endregion Handle Function
  return (
    <div id='headerMainLayout' className={cx('headerContainer')}>
      <div className={cx('headerRight')}>
        <div className={cx('headerIconGroup')}>
          <div className={cx('notifications')}>
            <ReceivedNotification hasMore={hasMore} onLoadMore={loadMore} />
          </div>
        </div>

        <div className={cx('line')}></div>

        <DropdownProfile data={userInfo} />
      </div>
    </div>
  );
};

export default Header;

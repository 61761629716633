import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import { InitEditModeValues } from '~/components/specific/formTaskModal/FormTaskModal';
import { EMPTY_STRING } from '~/utils/constants/common';
import { IBodyFormTask } from '~/utils/interface/task';

export const taskSchema = (payload: {
  t: TFunction;
  isEditMode: boolean;
  initEditValue: InitEditModeValues;
  isShowAddNameTemplate: boolean;
}) => {
  const { t, isEditMode, initEditValue, isShowAddNameTemplate } = payload;

  return yup.object({
    name: yup.string().required(t('common_validate_form_task_field_name')),
    assigneeIds: yup
      .array(yup.number().required(t('common_validate_form_task_field_assignees')))
      .min(1, t('common_validate_form_task_field_assignees'))
      .required(),
    jobId: yup.number().nullable().notOneOf([null], t('common_validate_form_task_field_job')),
    phaseId: yup.number().nullable().optional(),
    phaseName: yup.string().nullable().optional(),
    dueDate: yup
      .string()
      .required(t('common_validate_form_task_field_due_date'))
      .test('isFuture', t('common_validate_form_task_field_date_in_the_past'), (value) => {
        if (isEditMode && value === initEditValue.dueDate) {
          return true;
        }

        return value ? moment(value).isSameOrAfter(moment(), 'day') : true;
      }),
    completionDate: yup
      .string()
      .optional()
      .nullable()
      .test('isFuture', t('common_validate_form_task_field_date_in_the_past'), (value) => {
        if (isEditMode && value === initEditValue?.completionDate) {
          return true;
        }
        return value ? moment(value).isSameOrAfter(moment(), 'day') : true;
      }),
    streetAddress: yup.string().nullable().optional(),
    state: yup.string().nullable().optional(),
    city: yup.string().nullable().optional(),
    zipCode: yup.string().nullable().optional(),
    country: yup.string().nullable().optional(),
    latitude: yup.number().nullable().optional(),
    longitude: yup.number().nullable().optional(),
    isTemplate: yup.boolean().optional(),
    templateName: isShowAddNameTemplate
      ? yup.string().required(t('common_validate_form_task_field_template_name'))
      : yup.string().nullable().optional(),
    note: yup.string().nullable().optional(),
  });
};

export const DEFAULT_DATA_FROM_TASK: IBodyFormTask = {
  name: EMPTY_STRING,
  jobId: null,
  assigneeIds: [],
  dueDate: EMPTY_STRING,
  isTemplate: false,
};

export const DEFAULT_INIT_EDIT_VALUES: InitEditModeValues = {
  dueDate: '',
  completionDate: '',
};

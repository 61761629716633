import { Formats, ViewsProps } from 'react-big-calendar';

// Base Select
export const DEFAULT_SELECT_WIDTH = '100%';
export const DEFAULT_SELECT_HEIGHT = 35;

// Base Schedule
export const DEFAULT_BASE_SCHEDULE_VIEWS: ViewsProps = { month: true, week: true, day: true };
export const DEFAULT_BASE_SCHEDULE_FORMATS: Formats = {
  dateFormat: 'D',
  weekdayFormat: 'dddd',
  dayFormat: 'dddd',
  timeGutterFormat: 'h A',
};

// Schedule Module
export const DEFAULT_SCHEDULE_VIEWS: ViewsProps = { month: true, week: true, day: true };
export const DEFAULT_SCHEDULE_VIEW = 'month';
export const DEFAULT_SCHEDULE_EVENT_COLOR = '#E1F9F1';
export const SCHEDULE_MODE_FULL = 'full';
export const SCHEDULE_MODE_MYSELF = 'my';

// Schedule Timeline
export const DEFAULT_SCHEDULE_TIMELINE_VIEWS = {
  week: 'week',
  day: 'day',
};
export const DEFAULT_SCHEDULE_TIMELINE_VISIBLE_HOUR = 8;

// Event Assignee
export const DEFAULT_ASSIGNEE_SLICE_START = 0;
export const DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM = 3;
export const DEFAULT_ASSIGNEE_NUMBER_MORE = 0;
export const DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT = 18;

// Date Picker
export const DEFAULT_DATE_PICKER_WIDTH = '100%';
export const DEFAULT_DATE_PICKER_HEIGHT = 35;

// Time Picker
export const DEFAULT_TIME_PICKER_WIDTH = '100%';
export const DEFAULT_TIME_PICKER_HEIGHT = 35;

// Base Upload Image
export const DEFAULT_UPLOAD_IMAGE_WIDTH = '100%';
export const DEFAULT_UPLOAD_IMAGE_HEIGHT = 35;
export const DEFAULT_UPLOAD_IMAGE_LIMIT = 1;
export const DEFAULT_UPLOAD_IMAGE_LIST = [];

// Form Input
export const DEFAULT_INPUT_TYPE = 'text';
export const DEFAULT_INPUT_WIDTH = '100%';
export const DEFAULT_INPUT_HEIGHT = 35;

// Base Image Circle
export const DEFAULT_IMAGE_WIDTH = 24;
export const DEFAULT_IMAGE_HEIGHT = 24;

// FormList
export const DEFAULT_FORM_LIST_INITIAL_VALUE = [];
export const DEFAULT_FORM_LIST_NUMBER_REMAINING_FIELD = 1;

// Checkbox
export const DEFAULT_CHECKBOX_VALUE = false;

// Form Tag Input
export const DEFAULT_TAG_INPUT_WIDTH = '100%';
export const DEFAULT_TAG_INPUT_HEIGHT = 35;
export const DEFAULT_TAG_INPUT_MAX_TAG = 5;
export const DEFAULT_TAG_INPUT_VALUES = [];

//Base More Action
export const DEFAULT_CONTAINER_WIDTH = 240;

// Base Filter
export const DEFAULT_FILTER_WIDTH_BTN = 67;
export const DEFAULT_FILTER_HEIGHT_BTN = 31;

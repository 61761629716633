// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
import PurchaseOrderProductHeader from './purchaseOrderProductHeader/PurchaseOrderProductHeader';
import PurchaseOrderProductRow from './purchaseOrderProductRow/PurchaseOrderProductRow';
import PurchaseOrderTotal from '../total/PurchaseOrderTotal';
// Others
import { IPurchaseOrderData } from '~/utils/interface/purchaseOrder';
import { formatCurrency } from '~/utils/helper';
import { CurrencyEnum } from '~/utils/enum';
import { DEFAULT_CURRENCY } from '~/utils/constants/common';
// Styles, images, icons

type Props = {
  data: IPurchaseOrderData;
};

const PurchaseOrderProduct = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View>
      <View style={styles.tableContainer}>
        <PurchaseOrderProductHeader />
        <PurchaseOrderProductRow data={data.products || []} />
      </View>
      <View style={styles.totalContainer}>
        <Text>Subtotal: </Text>

        <Text>
          {data?.total ? formatCurrency(CurrencyEnum.USD, Number(data?.total)) : DEFAULT_CURRENCY}
        </Text>
      </View>

      <PurchaseOrderTotal dataPdf={data} />
    </View>
  );
};

export default PurchaseOrderProduct;

export const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderWidth: 1,
    borderColor: '#333333',
  },

  totalContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
  },
});

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
// Components, Layouts, Pages
import { FormInput, SearchDropdown } from '~/components';
// Others
import { DEFAULT_NUMBER_OPTIONS_SELECT, EMPTY_STRING } from '~/utils/constants/common';
import { IBodyFormTask, IJobBodyTask } from '~/utils/interface/task';
import { CYAN600, WHITE } from '~/utils/constants/color';
import { getListJob } from '~/thunks/job/jobThunk';
import { useAppDispatch } from '~/redux/hooks';
import { getListJobPhases } from '~/thunks/phases/phasesThunk';
// Styles, images, icons
import styles from './JobAddressFields.module.scss';
import { CommonIconPlus } from '~/assets/svgComponents';

type Props = {};

const cx = classNames.bind(styles);

const JobAddressFields = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IBodyFormTask>();

  const jobId = useWatch({ control, name: 'jobId' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const fetchOptionsJobs = useCallback(async (search: string, page: number) => {
    try {
      const listJobTask = await dispatch(
        getListJob({
          page: page,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listJobTask?.data?.responses,
        hasMore:
          listJobTask?.data?.pagination.page < listJobTask?.data?.pagination?.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchOptionsJobPhases = useCallback(
    async (search: string, page: number) => {
      try {
        const listJobPhasesTask = await dispatch(
          getListJobPhases({
            page: page,
            limit: DEFAULT_NUMBER_OPTIONS_SELECT,
            jobId: String(jobId)!,
            ...(search ? { searchKey: search } : {}),
          })
        ).unwrap();

        return {
          items: listJobPhasesTask?.data?.responses,
          hasMore:
            listJobPhasesTask?.data?.pagination.page <
              listJobPhasesTask?.data?.pagination?.totalPages || false,
        };
      } catch (error) {
        return {
          items: [],
          hasMore: false,
        };
      }
    },
    [jobId]
  );
  //#endregion Handle Function

  return (
    <>
      <div className={cx('twoColumns')}>
        <Controller
          name='job'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SearchDropdown<IJobBodyTask>
              fetchOptions={fetchOptionsJobs}
              label={t('common_form_task_field_job')}
              placeholder={t('common_placeholder_select')}
              value={value?.id.toString() || EMPTY_STRING}
              onChange={(item) => {
                onChange(item);
                setValue('jobId', Number(item?.id));
                setValue('streetAddress', item?.streetAddress ?? EMPTY_STRING);
                setValue('city', item?.city ?? EMPTY_STRING);
                setValue('state', item?.state ?? EMPTY_STRING);
                setValue('zipCode', item?.zipCode ?? EMPTY_STRING);
                setValue('phaseId', null);
              }}
              renderLabel={(item) => {
                return <>{item.jobName}</>;
              }}
              renderOption={(item, selectedValue) => {
                return (
                  <div className={cx('optionSelect')}>
                    <div className={cx('jobName')}>{item?.jobName}</div>

                    <CommonIconPlus
                      width={16}
                      height={16}
                      strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                    />
                  </div>
                );
              }}
              getOptionKey={(item) => {
                return item.id;
              }}
              isRequired
              errorMessage={errors?.jobId?.message}
            />
          )}
        />

        {jobId && (
          <Controller
            name='phaseId'
            control={control}
            render={({ field: { value, onChange } }) => (
              <SearchDropdown
                fetchOptions={fetchOptionsJobPhases}
                label={t('common_form_task_field_phase')}
                placeholder={t('common_placeholder_select')}
                value={value || EMPTY_STRING}
                onChange={(value) => onChange(Number(value?.id))}
                renderLabel={(item) => {
                  return <>{item.name}</>;
                }}
                renderOption={(item, selectedValue) => {
                  return (
                    <div className={cx('optionSelect')}>
                      <div className={cx('jobName')}>{item?.name}</div>

                      <CommonIconPlus
                        width={16}
                        height={16}
                        strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                      />
                    </div>
                  );
                }}
                getOptionKey={(item) => {
                  return item.id.toString();
                }}
                errorMessage={errors?.phaseId?.message}
              />
            )}
          />
        )}
      </div>

      <div className={cx('twoColumns')}>
        <Controller
          name='streetAddress'
          control={control}
          shouldUnregister={true}
          render={({ field: { value } }) => {
            return (
              <FormInput
                label={t('common_form_task_field_address')}
                value={value || EMPTY_STRING}
                disabled
              />
            );
          }}
        />

        <Controller
          name='city'
          control={control}
          shouldUnregister={true}
          render={({ field: { value } }) => (
            <FormInput
              label={t('common_form_task_field_city')}
              value={value || EMPTY_STRING}
              disabled
            />
          )}
        />
      </div>

      <div className={cx('twoColumns')}>
        <Controller
          name='state'
          control={control}
          shouldUnregister={true}
          render={({ field: { value } }) => (
            <FormInput
              label={t('common_form_task_field_state')}
              value={value || EMPTY_STRING}
              disabled
            />
          )}
        />

        <Controller
          name='zipCode'
          control={control}
          shouldUnregister={true}
          render={({ field: { value } }) => (
            <FormInput
              label={t('common_form_task_field_zip_code')}
              value={value || EMPTY_STRING}
              disabled
            />
          )}
        />
      </div>
    </>
  );
};

export default JobAddressFields;

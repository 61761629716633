// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  addDocument,
  createDocumentJob,
  deleteCommonDocument,
  deleteDocumentJob,
  downloadDocument,
  downloadDocumentJob,
  getDocumentDetail,
  getDocumentJobDetails,
  getListCommonDocument,
  getListDocument,
  getListDocumentJob,
  updateDocument,
  updateDocumentJob,
} from './documentThunk';
import { IDocumentResp } from '~/utils/interface/document';

export interface DocumentState {
  documentResp: IDocumentResp;
  isRefreshListDocument?: boolean;
  isRefreshListCommonDocument?: boolean;
}

const initialState: DocumentState = {
  documentResp: {} as IDocumentResp,
  isRefreshListDocument: false,
  isRefreshListCommonDocument: false,
};

const documentSlice = createSlice({
  name: 'documentState',
  initialState,
  reducers: {
    setRefreshListDocument(state, action) {
      state.isRefreshListDocument = action.payload;
    },
    setRefreshListCommonDocument(state, action) {
      state.isRefreshListCommonDocument = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list document
    builder
      .addCase(getListDocument.pending, (state) => {})
      .addCase(getListDocument.fulfilled, (state, action) => {})
      .addCase(getListDocument.rejected, (state) => {});

    // Get list document job
    builder
      .addCase(getListDocumentJob.pending, (state) => {})
      .addCase(getListDocumentJob.fulfilled, (state, action) => {})
      .addCase(getListDocumentJob.rejected, (state) => {});

    // Add document job
    builder
      .addCase(createDocumentJob.pending, (state) => {})
      .addCase(createDocumentJob.fulfilled, (state, action) => {})
      .addCase(createDocumentJob.rejected, (state) => {});

    // Download document job
    builder
      .addCase(downloadDocumentJob.pending, (state) => {})
      .addCase(downloadDocumentJob.fulfilled, (state, action) => {})
      .addCase(downloadDocumentJob.rejected, (state) => {});

    // Get document job details
    builder
      .addCase(getDocumentJobDetails.pending, (state) => {})
      .addCase(getDocumentJobDetails.fulfilled, (state, action) => {})
      .addCase(getDocumentJobDetails.rejected, (state) => {});

    // Update document job
    builder
      .addCase(updateDocumentJob.pending, (state) => {})
      .addCase(updateDocumentJob.fulfilled, (state, action) => {})
      .addCase(updateDocumentJob.rejected, (state) => {});

    // Delete document job
    builder
      .addCase(deleteDocumentJob.pending, (state) => {})
      .addCase(deleteDocumentJob.fulfilled, (state, action) => {})
      .addCase(deleteDocumentJob.rejected, (state) => {});

    // Add document
    builder
      .addCase(addDocument.pending, (state) => {})
      .addCase(addDocument.fulfilled, (state, action) => {})
      .addCase(addDocument.rejected, (state) => {});

    // Get list common document
    builder
      .addCase(getListCommonDocument.pending, (state) => {})
      .addCase(getListCommonDocument.fulfilled, (state, action) => {})
      .addCase(getListCommonDocument.rejected, (state) => {});

    // Get document detail
    builder
      .addCase(getDocumentDetail.pending, (state) => {})
      .addCase(getDocumentDetail.fulfilled, (state, action) => {})
      .addCase(getDocumentDetail.rejected, (state) => {});

    // Update document
    builder
      .addCase(updateDocument.pending, (state) => {})
      .addCase(updateDocument.fulfilled, (state, action) => {})
      .addCase(updateDocument.rejected, (state) => {});

    // Download document
    builder
      .addCase(downloadDocument.pending, (state) => {})
      .addCase(downloadDocument.fulfilled, (state, action) => {})
      .addCase(downloadDocument.rejected, (state) => {});

    // Delete document
    builder
      .addCase(deleteCommonDocument.pending, (state) => {})
      .addCase(deleteCommonDocument.fulfilled, (state, action) => {})
      .addCase(deleteCommonDocument.rejected, (state) => {});
  },
});

// Action
export const documentActions = documentSlice.actions;

// Selectors
export const selectIsRefreshListDocument = (state: RootState) =>
  state.document.isRefreshListDocument;
export const selectIsRefreshListCommonDocument = (state: RootState) =>
  state.document.isRefreshListCommonDocument;

// Get list document
export const selectDocumentResp = (state: RootState) => state.document.documentResp;

const documentReducer = documentSlice.reducer;
export default documentReducer;

// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createProposal,
  getDetailProposal,
  getLineItemList,
  getListLabor,
  getListVendorsProposal,
  getOptionFixtureLocations,
  getOptionProductByBudget,
  sendDocumentToSigner,
  updateProposal,
} from './proposalThunk';
import { IBaseOption } from '~/utils/interface/common';
import { TypeProductEnum } from '~/utils/enum';
import { EMPTY_VALUE } from '~/utils/constants/common';
import { IProductForJob, IProductOptionForJob } from '~/utils/interface/product';
import { formatProductIdSelected, formatProductName } from '~/utils/helper';

export interface ProposalSate {
  productMaterials?: IProductOptionForJob[];
  productEquipment?: IProductOptionForJob[];
  productMiscellanea?: IProductOptionForJob[];
  fixtureLocations?: IBaseOption[];
  vendorProposalList?: IBaseOption[];
  laborProposalList?: IBaseOption[];
}

const initialState: ProposalSate = {};

const proposalSlice = createSlice({
  name: 'proposalState',
  initialState,
  reducers: {
    setVendorProposalList(state, action) {
      state.vendorProposalList = action.payload;
    },
  },
  extraReducers(builder) {
    // Create new proposal
    builder
      .addCase(createProposal.pending, (state) => {})
      .addCase(createProposal.fulfilled, (state, action) => {})
      .addCase(createProposal.rejected, (state) => {});

    // Get options product by budget
    builder
      .addCase(getOptionProductByBudget.pending, (state) => {})
      .addCase(getOptionProductByBudget.fulfilled, (state, action) => {
        const { data, type } = action.payload;
        const productOptionList: IProductOptionForJob[] = data.responses.map(
          (item: IProductForJob) => ({
            label: formatProductName(item.productName, item.vendorName, item.unitCost),
            value: formatProductIdSelected(item.productId, item.vendorId),
            unitCost: item.unitCost,
            productId: item.productId,
            productName: item.productName,
            vendorName: item.vendorName,
            vendorId: item.vendorId,
          })
        );

        switch (type) {
          case TypeProductEnum.MATERIAL_SUPPLIER:
            state.productMaterials = productOptionList;
            break;
          case TypeProductEnum.EQUIPMENT_SUPPLIER:
            state.productEquipment = productOptionList;
            break;
          case TypeProductEnum.MISCELLANEOUS_EXPENSES:
            state.productMiscellanea = productOptionList;
            break;
        }
      })
      .addCase(getOptionProductByBudget.rejected, (state) => {});

    // Get option fixture location
    builder
      .addCase(getOptionFixtureLocations.pending, (state) => {})
      .addCase(getOptionFixtureLocations.fulfilled, (state, action) => {
        const dataRes = action.payload.responses;
        const fixtureLocationOptionList: IBaseOption[] = dataRes.map((item) => ({
          label: item.name,
          value: item.name,
        }));

        state.fixtureLocations = fixtureLocationOptionList;
      })
      .addCase(getOptionFixtureLocations.rejected, (state) => {});

    // Get list vendor proposal
    builder
      .addCase(getListVendorsProposal.pending, (state) => {})
      .addCase(getListVendorsProposal.fulfilled, (state, action) => {
        const dataRes = action.payload.responses;

        const vendorOptionList: IBaseOption[] = dataRes.map((item) => ({
          label: item.name || EMPTY_VALUE,
          value: item.id,
        }));

        state.vendorProposalList = vendorOptionList;
      })
      .addCase(getListVendorsProposal.rejected, (state) => {});

    // Get list labor
    builder
      .addCase(getListLabor.pending, (state) => {})
      .addCase(getListLabor.fulfilled, (state, action) => {
        const { data } = action.payload;

        const laborOptionList: IBaseOption[] = data.responses.map((item) => ({
          label: item.name || EMPTY_VALUE,
          value: item.id,
        }));
        state.laborProposalList = laborOptionList;
      })
      .addCase(getListLabor.rejected, (state) => {});

    // Get Detail proposal
    builder
      .addCase(getDetailProposal.pending, (state) => {})
      .addCase(getDetailProposal.fulfilled, (state, action) => {})
      .addCase(getDetailProposal.rejected, (state) => {});

    // Update proposal
    builder
      .addCase(updateProposal.pending, (state) => {})
      .addCase(updateProposal.fulfilled, (state, action) => {})
      .addCase(updateProposal.rejected, (state) => {});

    // Get line items list
    builder
      .addCase(getLineItemList.pending, (state) => {})
      .addCase(getLineItemList.fulfilled, (state, action) => {})
      .addCase(getLineItemList.rejected, (state) => {});

    // Send document for signer
    builder
      .addCase(sendDocumentToSigner.pending, (state) => {})
      .addCase(sendDocumentToSigner.fulfilled, (state, action) => {})
      .addCase(sendDocumentToSigner.rejected, (state) => {});
  },
});

// Action
export const proposalActions = proposalSlice.actions;

const proposalReducer = proposalSlice.reducer;
export default proposalReducer;

// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import purchaseOrderApi from '~/apis/purchaseOrder';
import {
  CREATE_PURCHASE_ORDER,
  GET_DETAIL_PO,
  GET_DETAIL_PO_FOR_BILL,
  GET_LIST_PO,
  GET_LIST_PO_BY_JOB,
  SEND_EMAIL_PO,
} from '~/utils/constants/actionType';
import { IQueryBase } from '~/utils/interface/common';
import { IQueryListPO, IQueryListPOByJob } from '~/utils/interface/purchaseOrder';

export const createPurchaseOrder = createAsyncThunk(
  CREATE_PURCHASE_ORDER,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.createPurchaseOrder(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListPO = createAsyncThunk(
  GET_LIST_PO,
  async (payload: IQueryListPO, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.getListPurchaseOrder(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailPurchaseOrder = createAsyncThunk(
  GET_DETAIL_PO,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.getPurchaseOrderDetail(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListPurchaseOrderByJob = createAsyncThunk(
  GET_LIST_PO_BY_JOB,
  async (payload: IQueryListPOByJob, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.getListPurchaseOrderByJob(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailPurchaseOrderForBill = createAsyncThunk(
  GET_DETAIL_PO_FOR_BILL,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.getPurchaseOrderForBill(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendEmailPO = createAsyncThunk(
  SEND_EMAIL_PO,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.sendEmailPO(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

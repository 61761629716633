// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
// Components, Layouts, Pages
import { AvatarStack, CircleAvatar, LabelValueField, Status, Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getTaskDetails } from '~/thunks/task/taskThunk';
import { ITaskDetails } from '~/utils/interface/task';
import { IPosition } from '~/utils/interface/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, CircleAvatarEnum, StorageEnum } from '~/utils/enum';
import { formatAddress, formattedTime, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './DetailTask.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailTask = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const navigate = useNavigate();
  const { taskId } = useParams();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [taskDetail, setTaskDetail] = useState<ITaskDetails>();
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    getUserLocation();

    handleGetTaskDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleNavigateToClientDetail = () => {
    const clientId = taskDetail?.client?.id;

    if (!clientId) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.clients}/${clientId}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.clients}/${clientId}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.clients}/${clientId}`);
        break;
    }
  };

  const handleGetTaskDetail = () => {
    if (!taskId) return;

    loadingData?.show();

    dispatch(getTaskDetails(taskId))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        setTaskDetail(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='detailTaskPage' className={cx('container')}>
      <Toolbar title={taskDetail?.name || EMPTY_STRING}>
        {taskDetail?.client && (
          <div className={cx('clientGroup')}>
            <span className={cx('clientTitle')}>{t('template_task_detail_client_label')}</span>

            <span className={cx('clientName')} onClick={handleNavigateToClientDetail}>
              {getFullName({ ...taskDetail?.client })}
            </span>

            <div className={cx('quickBookWrap')}>
              {taskDetail?.client?.quickBookCustomerId ? (
                <div className={cx('greenCircle')} />
              ) : (
                <div className={cx('grayCircle')} />
              )}
            </div>
          </div>
        )}
      </Toolbar>

      <div className={cx('body')}>
        <div className={cx('taskDetailContainer')}>
          <div className={cx('taskDetail')}>
            <LabelValueField
              label={t('template_task_detail_job_label')}
              value={taskDetail?.job?.name || EMPTY_STRING}
            />

            <LabelValueField
              label={t('template_task_detail_job_phase_label')}
              value={taskDetail?.phase?.name || EMPTY_STRING}
            />

            <LabelValueField
              label={t('template_task_detail_status_label')}
              value={taskDetail?.status && <Status status={taskDetail?.status} />}
            />

            <LabelValueField
              label={t('template_task_detail_client_name_label')}
              value={
                <div className={cx('valueGroup')}>
                  {taskDetail?.client?.avatar && (
                    <CircleAvatar
                      type={
                        taskDetail?.client?.avatar ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT
                      }
                      imageUrl={taskDetail?.client?.avatar}
                      firstName={taskDetail?.client?.firstName}
                      lastName={taskDetail?.client?.lastName}
                      width={24}
                      height={24}
                      fontSize={12}
                    />
                  )}

                  <span>{getFullName({ ...taskDetail?.client })}</span>
                </div>
              }
            />

            <LabelValueField
              label={t('template_task_detail_assignees_label')}
              value={
                Array.isArray(taskDetail?.assignees) &&
                taskDetail?.assignees?.length > DEFAULT_NUMBER_ZERO ? (
                  <AvatarStack
                    avatars={taskDetail?.assignees?.map((item) => ({
                      ...item,
                      avatarUrl: item?.avatar,
                    }))}
                    remaining
                  />
                ) : (
                  EMPTY_STRING
                )
              }
            />

            <LabelValueField
              label={t('template_task_detail_due_date_label')}
              value={taskDetail?.dueDate ? formattedTime(taskDetail?.dueDate) : EMPTY_STRING}
            />

            <LabelValueField
              label={t('template_task_detail_completion_date_label')}
              value={
                taskDetail?.completionDate
                  ? formattedTime(taskDetail?.completionDate)
                  : EMPTY_STRING
              }
            />

            <LabelValueField
              label={t('template_task_detail_note_label')}
              value={taskDetail?.note || EMPTY_STRING}
            />
          </div>

          <div className={cx('taskMap')}>
            <div className={cx('locationGroup')}>
              <span className={cx('locationKey')}>{t('template_task_detail_location_label')}</span>

              <span className={cx('locationValue')}>
                {formatAddress({ ...taskDetail, address: taskDetail?.streetAddress })}
              </span>
            </div>

            {isLoaded && (
              <div className={cx('map')}>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%', borderRadius: 8 }}
                  center={{
                    lat: taskDetail?.latitude || positionSelected.lat,
                    lng: taskDetail?.longitude || positionSelected.lng,
                  }}
                  zoom={DEFAULT_MAP_ZOOM}
                >
                  <Marker
                    position={{
                      lat: taskDetail?.latitude || positionSelected.lat,
                      lng: taskDetail?.longitude || positionSelected.lng,
                    }}
                  />
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTask;

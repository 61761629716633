import { BaseResponse, IListDataResponse, IQueryBase } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import { urlApiBill } from '~/utils/constants/common';
import { IBill, IBillDetail, IBodyRecordBillsPayments, IFormBill } from '~/utils/interface/bill';

const billApi = {
  createBill(body: IFormBill) {
    const url = `${urlApiBill.createBill}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListBill(params: IQueryBase) {
    const url = `${urlApiBill.getListBill}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IBill[]>>>(url, {
      params: params,
    });
  },

  getBillDetail(billId: string) {
    const url = `${urlApiBill.getBillDetail(billId)}`;
    return axiosClient.get<BaseResponse<IBillDetail>>(url);
  },

  recordPaymentBill(body: IBodyRecordBillsPayments) {
    const url = `${urlApiBill.recordPaymentBill}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default billApi;

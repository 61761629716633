// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import {
  CREATE_PROPOSAL,
  GET_LIST_VENDORS_PROPOSAL,
  PROPOSAL_GET_FIXTURE_LOCATION_OPTION,
  GET_PRODUCT_PROPOSAL_BY_BUDGET,
  GET_DETAIL_PROPOSAL,
  GET_LINE_ITEM_LIST,
  GET_LIST_LABOR,
  UPDATE_PROPOSAL,
  SEND_DOCUMENT_TO_SIGN,
  SEND_PROPOSAL,
} from '~/utils/constants/actionType';
import { IQueryListProductForJob } from '~/utils/interface/product';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import fixtureLocationApi from '~/apis/fixtureLocation';
import productApi from '~/apis/product';
import proposalApi from '~/apis/proposal';
import { IQueryListVendor } from '~/utils/interface/vendors';
import vendorsApi from '~/apis/vendors';
import {
  IPayloadSendDocument,
  IPayloadSendProposal,
  IProposal,
  IUpdateProposal,
} from '~/utils/interface/proposal';
import { ITableParams } from '~/utils/interface/common';
import laborApi from '~/apis/labor';
import { IQueryGetListLineItem } from '~/utils/interface/proposal';

export const createProposal = createAsyncThunk(
  CREATE_PROPOSAL,
  async (payload: IProposal, { rejectWithValue }) => {
    try {
      const res = await proposalApi.createProposal(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/** Get list product by budget **/
export const getOptionProductByBudget = createAsyncThunk(
  GET_PRODUCT_PROPOSAL_BY_BUDGET,
  async (payload: IQueryListProductForJob, { rejectWithValue }) => {
    try {
      const res = await productApi.getListProductForJob(payload);
      return { data: res.data.data, type: payload.type };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOptionFixtureLocations = createAsyncThunk(
  PROPOSAL_GET_FIXTURE_LOCATION_OPTION,
  async (payload: IQueryListFixtureLocations, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.getListFixtureLocations(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListVendorsProposal = createAsyncThunk(
  GET_LIST_VENDORS_PROPOSAL,
  async (payload: IQueryListVendor, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.getListVendor(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailProposal = createAsyncThunk(
  GET_DETAIL_PROPOSAL,
  async (jobId: string, { rejectWithValue }) => {
    try {
      const res = await proposalApi.detailProposal(jobId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLineItemList = createAsyncThunk(
  GET_LINE_ITEM_LIST,
  async (payload: IQueryGetListLineItem, { rejectWithValue }) => {
    try {
      const res = await proposalApi.getLineItemList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListLabor = createAsyncThunk(
  GET_LIST_LABOR,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await laborApi.getListLabor(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProposal = createAsyncThunk(
  UPDATE_PROPOSAL,
  async (payload: IUpdateProposal, { rejectWithValue }) => {
    try {
      const res = await proposalApi.updateProposal(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendDocumentToSigner = createAsyncThunk(
  SEND_DOCUMENT_TO_SIGN,
  async (payload: IPayloadSendDocument<FormData>, { rejectWithValue }) => {
    try {
      const res = await proposalApi.sendDocument(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendProposal = createAsyncThunk(
  SEND_PROPOSAL,
  async (payload: IPayloadSendProposal<FormData>, { rejectWithValue }) => {
    try {
      const res = await proposalApi.sendProposal(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

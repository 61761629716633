// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { NavTab } from '~/components';
import {
  JobDocumentsTabPageTemplate,
  JobLogsTabTemplate,
  ServiceJobSummaryTabTemplate,
} from '~/pages';
// Others
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { serviceJobsTabs } from '~/utils/constants/tab';
import { useAppDispatch } from '~/redux/hooks';

import {
  AccountRoleCodesEnum,
  JobDetailsTypeEnum,
  SearchParamsEnum,
  ServiceJobTabsEnum,
  StorageEnum,
  TypeJobEnum,
} from '~/utils/enum';
import { getFullName } from '~/utils/helper';
import { LoadingData } from '~/context';
import { getJobDetails } from '~/thunks/job/jobThunk';
import { IGetJobDetailsReq, IJobDetails } from '~/utils/interface/job';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
// Styles, images, icons
import styles from './ServiceJobDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const ServiceJobDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { jobId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [jobDetails, setJobDetails] = useState<IJobDetails>();
  //#endregion Declare State

  //#region Implement
  useEffect(() => {
    if (!location.search) {
      searchParams.set(SearchParamsEnum.TAB, ServiceJobTabsEnum.SUMMARY);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location.search]);
  //#region Implement Hook
  useEffect(() => {
    if (!jobId) return;

    handleGetJobDetails({ id: jobId, params: { type: JobDetailsTypeEnum.SUMMARY } });
  }, [jobId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetJobDetails = ({ id, params }: { id: string; params: IGetJobDetailsReq }) => {
    loadingData?.show();

    dispatch(getJobDetails({ id, params }))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setJobDetails(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const renderTabContent = () => {
    const currentTab = searchParams.get(SearchParamsEnum.TAB);

    if (!currentTab) return;

    switch (currentTab) {
      case ServiceJobTabsEnum.SUMMARY:
        return <ServiceJobSummaryTabTemplate />;
      case ServiceJobTabsEnum.DOCUMENTS:
        return <JobDocumentsTabPageTemplate />;
      case ServiceJobTabsEnum.LOGS:
        return <JobLogsTabTemplate typeJob={TypeJobEnum.SERVICE} />;
      default:
        return <div>{t('common_empty_data')}</div>;
    }
  };

  const handleNavigateDetailClient = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.clients}/${jobDetails?.client?.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.clients}/${jobDetails?.client?.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.clients}/${jobDetails?.client?.id}`);
        break;
    }
  };

  const handleSearchParams = (tab: string) => {
    setSearchParams({
      tab,
      ...(tab === ServiceJobTabsEnum.DOCUMENTS
        ? { page: DEFAULT_NUMBER_ONE.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() }
        : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='serviceJobDetail' className={cx('container')}>
      <section className={cx('toolBarWrap')}>
        <p className={cx('jobName')}>{jobDetails?.name || EMPTY_STRING}</p>

        <div className={cx('clientGroup')}>
          <span className={cx('clientNameLabel')}>
            {t('template_service_job_detail_client_label')}
          </span>
          <span className={cx('clientNameValue')} onClick={handleNavigateDetailClient}>
            {getFullName({ ...jobDetails?.client })}
          </span>

          <div className={cx('quickBookWrap')}>
            {jobDetails?.client?.quickBookCustomerId ? (
              <div className={cx('greenCircle')} />
            ) : (
              <div className={cx('grayCircle')} />
            )}
          </div>
        </div>
      </section>

      <section className={cx('body')}>
        <NavTab
          items={serviceJobsTabs}
          searchParams={SearchParamsEnum.TAB}
          onClick={handleSearchParams}
        />
        <div className={cx('tabContent')}>{renderTabContent()}</div>
      </section>
    </div>
  );
};

export default ServiceJobDetail;

// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import documentApi from '~/apis/document';
import {
  ADD_DOCUMENT,
  CREATE_DOCUMENT_JOB,
  DELETE_COMMON_DOCUMENT,
  DELETE_DOCUMENT_JOB,
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_DOCUMENT_JOB,
  GET_DOCUMENT_DETAIL,
  GET_DOCUMENT_JOB_DETAILS,
  GET_LIST_COMMON_DOCUMENT,
  GET_LIST_DOCUMENT,
  GET_LIST_DOCUMENT_JOB,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_JOB,
} from '~/utils/constants/actionType';
import { ITableParams } from '~/utils/interface/common';
import {
  IGetCommonDocumentReq,
  IFormDocumentUpdate,
  IGetDocumentJobReq,
  IUpdateDocumentJobReq,
} from '~/utils/interface/document';

export const getListDocument = createAsyncThunk(
  GET_LIST_DOCUMENT,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await documentApi.getListDocument(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListDocumentJob = createAsyncThunk(
  GET_LIST_DOCUMENT_JOB,
  async (payload: IGetDocumentJobReq, { rejectWithValue }) => {
    try {
      const res = await documentApi.getListDocumentJob(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createDocumentJob = createAsyncThunk(
  CREATE_DOCUMENT_JOB,
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const res = await documentApi.addDocumentJob(formData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadDocumentJob = createAsyncThunk(
  DOWNLOAD_DOCUMENT_JOB,
  async (payload: string, { rejectWithValue }) => {
    try {
      const res = await documentApi.downloadDocumentJob(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocumentJobDetails = createAsyncThunk(
  GET_DOCUMENT_JOB_DETAILS,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await documentApi.getDocumentJobDetails(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDocumentJob = createAsyncThunk(
  UPDATE_DOCUMENT_JOB,
  async (request: IUpdateDocumentJobReq, { rejectWithValue }) => {
    try {
      const res = await documentApi.updateDocumentJob(request);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDocumentJob = createAsyncThunk(
  DELETE_DOCUMENT_JOB,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await documentApi.deleteDocumentJob(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addDocument = createAsyncThunk(
  ADD_DOCUMENT,
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const res = await documentApi.addDocument(formData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListCommonDocument = createAsyncThunk(
  GET_LIST_COMMON_DOCUMENT,
  async (payload: IGetCommonDocumentReq, { rejectWithValue }) => {
    try {
      const res = await documentApi.getListCommonDocument(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocumentDetail = createAsyncThunk(
  GET_DOCUMENT_DETAIL,
  async (documentId: string, { rejectWithValue }) => {
    try {
      const res = await documentApi.getDocumentDetail(documentId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDocument = createAsyncThunk(
  UPDATE_DOCUMENT,
  async (payload: IFormDocumentUpdate, { rejectWithValue }) => {
    try {
      const res = await documentApi.updateDocument(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadDocument = createAsyncThunk(
  DOWNLOAD_DOCUMENT,
  async (documentId: string, { rejectWithValue }) => {
    try {
      const res = await documentApi.downloadDocument(documentId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCommonDocument = createAsyncThunk(
  DELETE_COMMON_DOCUMENT,
  async (documentId: string, { rejectWithValue }) => {
    try {
      const res = await documentApi.deleteCommonDocument(documentId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

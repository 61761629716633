// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// Components, Layouts, Pages
import {
  AdminDeactivatePopup,
  BaseButton,
  BaseSelect,
  CompleteAddress,
  FormInput,
  Loading,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import {
  changeStatusVendor,
  createVendor,
  getVendorDetails,
  updateVendor,
} from '~/thunks/vendors/vendorsThunk';
import { ButtonTypeEnum } from '~/utils/enum';
import { IAddress, IBaseOption, ISingleSelectOption } from '~/utils/interface/common';
import { EMPTY_STRING, optionsState, optionType } from '~/utils/constants/common';
import { IFormVendorBody, IUpdateVendor, IVendor } from '~/utils/interface/vendors';
import { vendorsActions } from '~/thunks/vendors/vendorSlice';
// Styles, images, icons
import styles from './FormVendorModal.module.scss';
import { icons } from '~/assets';
import { CommonIconInactive } from '~/assets/svgComponents';

type Props = {
  vendor?: IVendor;
  onClose: () => void;
  onSuccess: () => void;
};

const cx = classNames.bind(styles);

const vendorSchema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('admin_add_vendor_modal_label_vendor_name_require')),
      email: yup.string().email(t('common_validate_email_required')),
      phoneNumber: yup.string().optional(),
      other: yup.string().optional(),
      address: yup.string().optional(),
      addressSecond: yup.string().optional(),
      country: yup.string().optional(),
      zipCode: yup.string().optional(),
      state: yup.string().optional(),
      city: yup.string().optional(),
      type: yup.string().required(t('admin_add_vendor_modal_label_vendor_type_require')),
    })
    .required();
};

const FormVendorModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose, onSuccess, vendor } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData: IFormVendorBody = useMemo(() => {
    return {
      name: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      email: EMPTY_STRING,
      contactName: EMPTY_STRING,
      address: EMPTY_STRING,
      addressSecond: EMPTY_STRING,
      type: EMPTY_STRING,
      other: EMPTY_STRING,
      city: EMPTY_STRING,
      country: EMPTY_STRING,
      state: EMPTY_STRING,
      zipCode: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<IFormVendorBody>({
    resolver: yupResolver(vendorSchema(t)),
    defaultValues: defaultData,
    mode: 'onChange',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initDataForm, setInitDataForm] = useState<IFormVendorBody>(defaultData);
  const [isShowConfirmChangeStatusVendor, setIsShowConfirmChangeStatusVendor] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (vendor) {
      handleGetDetailVendor(vendor.id);
    }
  }, [vendor]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangeSelect = (option: ISingleSelectOption, name: keyof IFormVendorBody) => {
    setValue(name, option.value, { shouldDirty: true });
    trigger(name);
  };

  const handlePrepareVendorDetailData = (vendorDetail: IFormVendorBody) => {
    const vendorDetailPrepareData: IFormVendorBody = {
      name: vendorDetail.name || EMPTY_STRING,
      address: vendorDetail.address || EMPTY_STRING,
      addressSecond: vendorDetail.addressSecond || EMPTY_STRING,
      contactName: vendorDetail.contactName || EMPTY_STRING,
      country: vendorDetail.country || EMPTY_STRING,
      type: vendorDetail.type || EMPTY_STRING,
      zipCode: vendorDetail.zipCode || EMPTY_STRING,
      phoneNumber: vendorDetail.phoneNumber || EMPTY_STRING,
      email: vendorDetail.email || EMPTY_STRING,
      state: vendorDetail.state || EMPTY_STRING,
      other: vendorDetail.other || EMPTY_STRING,
      city: vendorDetail.city || EMPTY_STRING,
    };
    setInitDataForm(vendorDetailPrepareData);
    reset(vendorDetailPrepareData);
  };

  const handleGetDetailVendor = (vendorId: string) => {
    setIsLoading(true);

    dispatch(getVendorDetails(vendorId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        handlePrepareVendorDetailData(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePreparedData = (data: IFormVendorBody) => {
    if (!data) return {} as IFormVendorBody;

    const formattedData: IFormVendorBody = { ...data };
    const bodyData: Partial<IFormVendorBody> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IFormVendorBody] = value;
      }
    });

    return bodyData as IFormVendorBody;
  };

  const handleAddVendor = (data: IFormVendorBody) => {
    const bodyData = handlePreparedData(data);

    setIsLoading(true);
    dispatch(createVendor(bodyData))
      .unwrap()
      .then(() => {
        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getChangedValues = (originalValues: IFormVendorBody, newValues: IFormVendorBody) => {
    const changes: Partial<IFormVendorBody> = {};

    Object.entries(newValues).forEach(([key, newValue]) => {
      const typedKey = key as keyof IFormVendorBody;
      const originalValue = originalValues[typedKey];

      if (newValue !== originalValue) {
        changes[typedKey] = newValue;
      }
    });

    return changes as IFormVendorBody;
  };

  const handleUpdateVendor = (data: IFormVendorBody) => {
    if (!vendor) return;

    const dataChanges = getChangedValues(initDataForm, data);

    const payload: IUpdateVendor = {
      vendorId: vendor.id,
      body: dataChanges,
    };

    setIsLoading(true);
    dispatch(updateVendor(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;

        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAcInVendor = () => {
    if (!vendor?.id) return;

    setIsLoading(true);

    dispatch(changeStatusVendor(vendor.id))
      .unwrap()
      .then((res) => {
        onClose && onClose();
        return dispatch(vendorsActions.setRefreshVendorList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
        setIsLoading(false);
      });
  };

  const handleActiveVendor = () => {
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };

  const handleDeactivateVendor = () => {
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };

  const handleCloseConfirmChangeStatusVendor = () => {
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };

  const onCancelModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };

  const handleChangeAddress = (address: IAddress) => {
    setValue('address', address?.address, { shouldDirty: true });
    setValue('country', address?.country, { shouldDirty: true });
    setValue('city', address?.city, { shouldDirty: true });
    setValue('state', address?.state, { shouldDirty: true });
    setValue('zipCode', address?.zipCode, { shouldDirty: true });
    setValue('lat', address?.lat);
    setValue('lng', address?.lng);
  };
  //#endregion Handle Function

  return (
    <form
      id='formVendorModalComponent'
      className={cx('formVendorModalContainer')}
      onSubmit={handleSubmit(vendor ? handleUpdateVendor : handleAddVendor)}
    >
      <div className={cx('formModalVendor')}>
        {/* <div className={cx('headerAddVendorModal')}>
          <img
            src={icons.commonIconCloseModal}
            className={cx('closeIcon')}
            alt={t('common_img_text_alt')}
            onClick={onCloseModal}
          />
        </div> */}
        <div className={cx('modalContent')}>
          <div className={cx('formVendorTitleModal')}>
            {vendor
              ? t('admin_manage_vendors_edit_title')
              : t('admin_add_vendor_modal_add_new_vendor_title')}
          </div>
          <div className={cx('line')} />
          <div className={cx('bodyFormVendorModal')}>
            <div className={cx('inputContent')}>
              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_vendor_modal_label_vendor_name')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    required
                    errorMessage={errors.name?.message}
                  />
                )}
              />

              <Controller
                name='contactName'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_vendor_modal_label_contact_name')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.contactName?.message}
                  />
                )}
              />
            </div>

            <Controller
              name='address'
              control={control}
              render={({ field }) => (
                <CompleteAddress
                  value={field.value || EMPTY_STRING}
                  onChange={(address) => handleChangeAddress(address)}
                  errorMessage={errors.address?.message}
                />
              )}
            />

            <div className={cx('inputContent')}>
              <Controller
                name='country'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_vendors_form_field_country')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    errorMessage={errors.country?.message}
                  />
                )}
              />

              <div className={cx('inputContentThree')}>
                <Controller
                  name='city'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('admin_manage_vendors_form_field_city')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.city?.message}
                    />
                  )}
                />

                <Controller
                  name='state'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('admin_manage_vendors_form_field_state')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.state?.message}
                    />
                  )}
                />

                <Controller
                  name='zipCode'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('admin_manage_vendors_form_field_zip_code')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.zipCode?.message}
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              name='addressSecond'
              control={control}
              render={({ field: { value, onChange } }) => (
                <CompleteAddress
                  label={t('admin_manage_vendors_form_field_address_second')}
                  value={value || EMPTY_STRING}
                  onChange={(address) => onChange(address.address)}
                  errorMessage={errors.addressSecond?.message}
                />
              )}
            />

            <div className={cx('inputContent')}>
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_vendor_modal_label_phone')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.phoneNumber?.message}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_vendor_modal_label_email')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className={cx('inputContent')}>
              <Controller
                name='other'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_add_vendor_modal_label_other')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.other?.message}
                  />
                )}
              />

              <Controller
                name='type'
                control={control}
                render={({ field: { value, name } }) => (
                  <BaseSelect
                    label={t('admin_add_vendor_modal_label_vendor_type')}
                    placeholder={t('common_placeholder_select')}
                    value={value}
                    options={optionType}
                    isRequired
                    onChange={(optionSelected: IBaseOption) =>
                      handleChangeSelect(optionSelected, name)
                    }
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('footerFormVendorModal')}>
            <div className={cx('leftFooter')}>
              {vendor &&
                (vendor?.status !== 'ACTIVE' ? (
                  <button
                    className={cx('changeStatusBtn', 'activeBtn')}
                    type='button'
                    onClick={handleActiveVendor}
                  >
                    <img src={icons.commonIconActivateStatus} alt={t('common_img_text_alt')} />
                  </button>
                ) : (
                  <button
                    className={cx('changeStatusBtn', 'inactiveBtn')}
                    type='button'
                    onClick={handleDeactivateVendor}
                  >
                    <CommonIconInactive width={16} height={16} />
                  </button>
                ))}
            </div>

            <div className={cx('rightFooter')}>
              <div className={cx('buttonCancel')}>
                <BaseButton
                  label={t('common_btn_cancel')}
                  width={117}
                  onClick={onCancelModal}
                  typeStyle={ButtonTypeEnum.CANCEL}
                />
              </div>

              <BaseButton
                label={t('common_btn_save')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                type='submit'
                isDisable={!isValid || !isDirty}
              />
            </div>
          </div>
        </div>

        <AdminDeactivatePopup
          activeDesc={t('admin_manage_vendors_change_status_title_active')}
          deactivateDesc={t('admin_manage_vendors_change_status_title_deactivate')}
          currentStatus={vendor?.status}
          isOpen={isShowConfirmChangeStatusVendor}
          onClose={handleCloseConfirmChangeStatusVendor}
          onUpdateStatus={handleAcInVendor}
        />

        {isLoading && <Loading />}
      </div>
    </form>
  );
};

export default FormVendorModal;

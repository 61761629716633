// Others
import { urlApiEstimate } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import {
  IDetailEstimate,
  IEstimate,
  IEstimateContent,
  IEstimateTableParams,
  IEstimateTemplate,
  IUpdateEstimatePayload,
} from '~/utils/interface/estimate';
import axiosClient from './axiosClient';

const estimateApi = {
  getEstimateList(params: IEstimateTableParams) {
    const url = `${urlApiEstimate.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IEstimate[]>>>(url, {
      params: params,
    });
  },

  createEstimate(body: IEstimateContent) {
    const url = `${urlApiEstimate.create}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateEstimate(payload: IUpdateEstimatePayload) {
    const url = `${urlApiEstimate.update(payload.estimateId)}`;
    return axiosClient.put<BaseResponse>(url, payload.body);
  },

  deleteEstimate(id: string) {
    const url = `${urlApiEstimate.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getDetailEstimate(estimateId: number) {
    const url = `${urlApiEstimate.detail(estimateId)}`;
    return axiosClient.get<BaseResponse<IDetailEstimate>>(url);
  },

  getDetailEstimateByJob(jobId: string) {
    const url = `${urlApiEstimate.detailByJob(jobId)}`;
    return axiosClient.get<BaseResponse<IEstimateContent>>(url);
  },

  getEstimateTemplateList(params: ITableParams) {
    const url = `${urlApiEstimate.getListTemplate}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IEstimateTemplate[]>>>(url, {
      params,
    });
  },
};

export default estimateApi;

// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Location, useLocation, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, BaseSelect, BaseTextarea, FieldVendorProduct, FormInput } from '~/components';
// Context
// Others
import { useAppDispatch } from '~/redux/hooks';
import { createProduct, getDetailProduct, updateProduct } from '~/thunks/product/productThunk';
import { getListQuickBookItem } from '~/thunks/quickBook/quickBookThunk';
import { getListVendors } from '~/thunks/vendors/vendorsThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  ISO_DATE_TIME_SEPARATOR,
  PRODUCT_CATEGORY_OPTIONS,
  linkToQuickBookCustomerOptions,
} from '~/utils/constants/common';
import { ButtonTypeEnum, DateFormatEnum, LinkToQuickBookCustomerEnum } from '~/utils/enum';
import { IBaseOption, IQueryBase, ISingleSelectOption } from '~/utils/interface/common';
import { IFormProductPayload, IUpdateProduct } from '~/utils/interface/product';
import { IQuickBookItem } from '~/utils/interface/quickBook';
import { IVendor } from '~/utils/interface/vendors';
import { LoadingData } from '~/context';
import { DEFAULT_VENDOR_PRODUCT, defaultData, productSchema } from './helper';
// Styles, images, icons
import styles from './FormProduct.module.scss';
import { icons } from '~/assets';

type Props = {
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const FormProductModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const isEditMode = useMemo(() => !!productId, [productId]);
  const loadingData = useContext(LoadingData);
  const { state: vendorId }: Location<string> = useLocation();

  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<IFormProductPayload>({
    resolver: yupResolver(productSchema({ t, isEditMode })),
    defaultValues: defaultData,
  });

  // Vendors useFieldArray
  const {
    fields: vendorFields,
    append: appendEquipment,
    remove: removeEquipment,
  } = useFieldArray({
    control,
    name: 'vendors',
  });

  const defaultValuesFormByAVendor = useMemo(() => {
    return {
      ...DEFAULT_VENDOR_PRODUCT,
      vendorId: vendorId || EMPTY_STRING,
    };
  }, [vendorId]);

  const linkQuickBookType = watch('typeLinkQuickBook');
  const quickBookIdSelected = watch('quickBookItemId');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initDataForm, setInitDataForm] = useState<IFormProductPayload>(defaultData);
  const [paramObject] = useState<IQueryBase>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_NUMBER_OPTIONS_SELECT,
  });
  const [vendorOptions, setVendorOptions] = useState<IBaseOption[]>([]);
  const [quickBookItems, setQuickBookItems] = useState<IQuickBookItem[]>([]);
  const [quickBookOptions, setQuickBookOptions] = useState<IBaseOption[]>([]);
  const [loadingQuickBock, setIsLoadingQuickBook] = useState<boolean>(false);

  const isShouldEnableFeature =
    isEditMode ||
    linkQuickBookType === LinkToQuickBookCustomerEnum.NO ||
    linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_NEW ||
    (linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING && quickBookIdSelected);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    fetchListVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEditMode) {
      const dataForm: IFormProductPayload = {
        ...initDataForm,
        typeLinkQuickBook: linkQuickBookType,
      };
      reset(dataForm);

      if (linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING) {
        const body: IQueryBase = {
          page: DEFAULT_CURRENT_PAGE,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
        };
        fetchListQuickBookItems(body);
      }
      return;
    }

    if (isEditMode && productId) {
      handleGetDetailProduct(productId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, linkQuickBookType, productId]);

  useEffect(() => {
    if (quickBookItems && quickBookIdSelected) {
      handleCustomerQuickBooksChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickBookIdSelected]);

  useEffect(() => {
    if (vendorId && !isEditMode) {
      appendEquipment(defaultValuesFormByAVendor);
    } else if (linkQuickBookType && !isEditMode) {
      appendEquipment(DEFAULT_VENDOR_PRODUCT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkQuickBookType, appendEquipment, isEditMode, vendorId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCustomerQuickBooksChange = () => {
    const customerSelected = quickBookItems.find((customer) => customer.id === quickBookIdSelected);
    const currentDate = new Date().toISOString().split(ISO_DATE_TIME_SEPARATOR)[
      DEFAULT_NUMBER_ZERO
    ];
    if (customerSelected) {
      setValue('name', customerSelected.name ?? EMPTY_STRING);
      trigger('name');
      setValue('description', customerSelected.description ?? EMPTY_STRING);
      setValue('extendedDescription', customerSelected.extendedDescription ?? EMPTY_STRING);
      setValue('syncToken', customerSelected.syncToken || EMPTY_STRING);
      setValue('invStartDate', currentDate);
    }
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const fetchListQuickBookItems = (body: IQueryBase) => {
    setIsLoadingQuickBook(true);
    dispatch(getListQuickBookItem(body))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses } = res.data;
        const quickBookOptionList: IBaseOption[] = responses.map(
          (quickBookItem: IQuickBookItem) => ({
            label: quickBookItem.name ?? EMPTY_STRING,
            value: quickBookItem.id,
          })
        );

        setQuickBookItems(responses);
        setQuickBookOptions(quickBookOptionList);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingQuickBook(false);
      });
  };

  const fetchListVendor = () => {
    dispatch(getListVendors(paramObject))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;
        const { responses } = res?.data;
        const vendorOptionList: IBaseOption[] = responses.map((vendorOption: IVendor) => ({
          label: vendorOption.name ?? EMPTY_STRING,
          value: vendorOption?.id.toString(),
        }));

        setVendorOptions(vendorOptionList);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleChangeSelect = (option: ISingleSelectOption, name: keyof IFormProductPayload) => {
    setValue(name, option.value, { shouldDirty: true });
    trigger(name);
  };

  const handlePreparedData = (data: IFormProductPayload) => {
    if (!data) return {} as IFormProductPayload;

    const formattedData: IFormProductPayload = { ...data };
    const bodyData: Partial<IFormProductPayload> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IFormProductPayload] = value;
      }
    });

    return bodyData as IFormProductPayload;
  };

  const handleAddProduct = (data: IFormProductPayload) => {
    loadingData?.show();
    const bodyData = handlePreparedData(data);
    const updatedVendors = data.vendors?.map((vendor) => {
      const { name, ...vendorWithoutName } = vendor;
      return vendorWithoutName;
    });
    const requestData = {
      ...bodyData,
      vendors: updatedVendors,
    };

    dispatch(createProduct(requestData))
      .unwrap()
      .then(() => {
        loadingData?.show();
        handleClose();
      })
      .catch(() => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleGetDetailProduct = (id: string) => {
    loadingData?.show();
    dispatch(getDetailProduct(id))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        if (!res?.data) return;
        const currentDate = moment().format(DateFormatEnum['YYYY-MM-DD']);
        const productDetailPrepareData: IFormProductPayload = {
          category: res?.data.category || EMPTY_STRING,
          name: res?.data.name || EMPTY_STRING,
          vendors: res?.data?.vendors || [],
          description: res?.data.description || EMPTY_STRING,
          extendedDescription: res?.data.extendedDescription || EMPTY_STRING,
          invStartDate: res?.data.quickBookItemId ? currentDate : undefined,
        };

        setInitDataForm(productDetailPrepareData);
        reset(productDetailPrepareData);
      })
      .catch((error) => {})
      .finally(() => loadingData?.hide());
  };

  const handleUpdateProduct = (data: IFormProductPayload) => {
    if (!productId) return;

    const updatedVendors = data.vendors?.map((vendor) => {
      const { name, ...vendorWithoutName } = vendor;
      return vendorWithoutName;
    });

    const payload: IUpdateProduct = {
      productId: productId,
      body: {
        ...data,
        vendors: updatedVendors,
      },
    };

    loadingData?.show();
    dispatch(updateProduct(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        handleClose();
      })
      .catch((error) => {})
      .finally(() => loadingData?.hide());
  };

  const handleSaveFormProduct = (data: IFormProductPayload) => {
    if (!isEditMode) {
      handleAddProduct(data);
      return;
    }

    if (isEditMode && productId) {
      handleUpdateProduct(data);
      return;
    }
  };
  //#endregion Handle Function

  return (
    <FormProvider
      {...({
        control,
        watch,
        setValue,
        formState: { errors },
      } as UseFormReturn<IFormProductPayload>)}
    >
      <form className={cx('formContent')} onSubmit={handleSubmit(handleSaveFormProduct)}>
        <div className={cx('body')}>
          {!isEditMode && (
            <div className={cx('inputContent')}>
              <Controller
                name='typeLinkQuickBook'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('admin_manage_products_link_to_quick_book_customer_label')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    options={linkToQuickBookCustomerOptions || []}
                    onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                    errorMessage={errors.typeLinkQuickBook?.message}
                    isRequired
                  />
                )}
              />

              {linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING && (
                <Controller
                  name='quickBookItemId'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      label={t('admin_manage_products_choose_quick_book_items_label')}
                      placeholder={
                        loadingQuickBock ? t('common_text_loading') : t('common_placeholder_select')
                      }
                      value={value || EMPTY_STRING}
                      options={quickBookOptions}
                      onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                      errorMessage={errors.quickBookItemId?.message}
                      isRequired
                    />
                  )}
                />
              )}
            </div>
          )}

          {isShouldEnableFeature && (
            <>
              <div className={cx('inputContent')}>
                <Controller
                  name='name'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('admin_manage_products_add_name_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      required
                      errorMessage={errors.name?.message}
                    />
                  )}
                />

                <Controller
                  name='category'
                  control={control}
                  render={({ field: { value, name } }) => (
                    <BaseSelect
                      label={t('admin_manage_products_add_category_label')}
                      placeholder={t('common_placeholder_select')}
                      value={value}
                      options={PRODUCT_CATEGORY_OPTIONS}
                      onChange={(optionSelected: IBaseOption) =>
                        handleChangeSelect(optionSelected, name)
                      }
                      isRequired
                      errorMessage={errors.category?.message}
                    />
                  )}
                />
              </div>

              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_products_add_description_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name='extendedDescription'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseTextarea
                    label={t('admin_manage_products_add_extended_description_label')}
                    value={value || EMPTY_STRING}
                    height={120}
                    maxLength={100}
                    onTextareaChange={onChange}
                  />
                )}
              />

              <div className={cx('titleLine')}>
                <p className={cx('title')}>{t('admin_manage_products_vendors_title')}</p>
                <div className={cx('lineHr')} />

                <div className={cx('formListGroup')}>
                  {vendorFields.map((row, index) => (
                    <FieldVendorProduct
                      key={row.id}
                      fieldIndex={index}
                      removeField={removeEquipment}
                      vendorOptions={vendorOptions}
                      isProductByVendor={!!vendorId}
                    />
                  ))}

                  {!vendorId && (
                    <button
                      type='button'
                      onClick={() => appendEquipment(DEFAULT_VENDOR_PRODUCT)}
                      className={cx('btnAddField', 'group')}
                    >
                      <img
                        src={icons.commonIconAddField}
                        alt={t('common_img_text_alt')}
                        className={cx('iconAddField')}
                      />
                      <span className={cx('btnTextAdd')}>
                        {t('template_form_product_btn_add_vendor_field')}
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        <div className={cx('modalFooter')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleClose}
            />
          </div>

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
            isDisable={isEditMode && !isDirty}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default FormProductModal;

// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseSelect,
  BaseTextarea,
  BaseUploadDocument,
  CommonConfirmModal,
  FormInput,
  ProductPurchaseOrder,
  SearchDropdown,
} from '~/components';
// Others
import { schema } from '~/components/specific/formPurchaseOrder/helper';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListJob } from '~/thunks/job/jobThunk';
import { getListProducts } from '~/thunks/product/productThunk';
import {
  createPurchaseOrder,
  getDetailPurchaseOrder,
} from '~/thunks/purchaseOrder/purchaseOrderThunk';
import { getListVendors } from '~/thunks/vendors/vendorsThunk';
import {
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  MAX_PO_NUMBER,
  NAVIGATE_GO_BACK,
  defaultProducts,
  purchaseTypeOptions,
} from '~/utils/constants/common';
import { ButtonTypeEnum, CurrencyEnum, VendorStatusEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBaseOption } from '~/utils/interface/common';
import { IJob } from '~/utils/interface/job';
import { IDocumentPurchaseOrder, IFormCreatePurchaseOrder } from '~/utils/interface/purchaseOrder';
import { CommonIconPlus } from '~/assets/svgComponents';
import { CYAN600, WHITE } from '~/utils/constants/color';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FormPurchaseOrder.module.scss';

type Props = {
  purchaseId?: string;
  jobId?: string;
};

const cx = classNames.bind(styles);

const FormPurchaseOrder = (props: Props) => {
  //#region Destructuring Props
  const { purchaseId, jobId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);

  const defaultValuesForm = useMemo(
    () => ({ products: defaultProducts, ...(jobId ? { jobId } : {}) }),
    [jobId]
  );
  const isDisableJob = useMemo(() => !!jobId, [jobId]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<IFormCreatePurchaseOrder>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValuesForm,
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'products' });
  const watchProduct = useWatch({ control, name: 'products' });
  const watchTotal = watch('total');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowPurchaseDate, setIsShowPurchaseDate] = useState<boolean>(false);
  const [isShowOrderNumber, setIsShowOrderNumber] = useState<boolean>(false);
  const [isShowUploadDocuments, setIsShowUploadDocuments] = useState<boolean>(false);
  const [isShowConfirmItem, setIsShowConfirmItem] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!purchaseId) return;

    handleGetDetailPurchaseOrder(purchaseId);
  }, [purchaseId]);

  useEffect(() => {
    const total = watchProduct?.reduce(
      (total, currentTotal) => total + (currentTotal?.subTotal || 0),
      0
    );

    setValue('total', total);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProduct]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBack = () => {
    navigate(NAVIGATE_GO_BACK);
    reset({ products: defaultProducts });
  };

  const handleChangeCheckboxPurChaseDate = (checked: boolean, name: string) => {
    setIsShowPurchaseDate(checked);

    if (!checked) {
      setValue('date', EMPTY_STRING);
    }
  };

  const handleChangeCheckboxOrderNumber = (checked: boolean, name: string) => {
    setIsShowOrderNumber(checked);

    if (!checked) {
      setValue('orderNumber', DEFAULT_NUMBER_ZERO);
      setValue('isManualOrderNumber', false);
    } else {
      setValue('isManualOrderNumber', true);
    }
  };

  const handleGetDetailPurchaseOrder = (id: string) => {
    loadingData?.show();

    dispatch(getDetailPurchaseOrder(id))
      .unwrap()
      .then((res) => {
        const { data } = res;
        if (!data) return;

        const newData: IFormCreatePurchaseOrder = {
          type: data.type || EMPTY_STRING,
          vendorId: data.vendor?.id?.toString() || EMPTY_STRING,
          products: data.products
            ? data.products.map((product) => {
                return {
                  ...product,
                  productId: product?.productId?.toString(),
                };
              })
            : [],
          total: Number(data.total) ?? DEFAULT_NUMBER_ZERO,
          notes: data.notes || EMPTY_STRING,
          date: data.date || EMPTY_STRING,
          documents: data?.documents,
          jobId: data?.jobId?.toString() || EMPTY_STRING,
        };

        if (newData.date) {
          setIsShowPurchaseDate(true);
        }

        if (newData.documents && newData.documents?.length > 0) {
          setIsShowUploadDocuments(true);
        }

        reset(newData);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleChangeCheckboxUploadDocuments = (checked: boolean, name: string) => {
    setIsShowUploadDocuments(checked);

    if (!checked) {
      setValue('documents', null);
    }
  };

  const handleSubmitPurchaseOrder = (data: IFormCreatePurchaseOrder) => {
    if (purchaseId) {
      handleUpdatePurchaseOrder(data);
    } else {
      handleCreatePurchaseOrder(data);
    }
  };

  const handleCreatePurchaseOrder = (data: IFormCreatePurchaseOrder) => {
    loadingData?.show();

    if (!data?.products || data?.products?.length <= 0) {
      loadingData?.hide();
      setIsShowConfirmItem(true);
      return;
    }

    const formData = new FormData();

    const formDataFields = {
      type: data?.type,
      vendorId: data?.vendorId,
      jobId: data?.jobId,
      jobName: data?.jobName,
      products: data?.products ? JSON.stringify(data?.products) : EMPTY_STRING,
      date: data?.date,
      orderNumber: data?.orderNumber ? JSON.stringify(data?.orderNumber) : EMPTY_STRING,
      notes: data?.notes,
      total: data?.total ? JSON.stringify(data?.total) : EMPTY_STRING,
      ...(data?.isManualOrderNumber
        ? { isManualOrderNumber: String(data?.isManualOrderNumber) }
        : {}),
    };

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (data?.documents) {
      data.documents.forEach((document) => {
        const { file } = document;
        file && formData.append('documents', file);
      });
    }

    if (!formData) return;

    dispatch(createPurchaseOrder(formData))
      .unwrap()
      .then((res) => {
        handleBack();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdatePurchaseOrder = (data: IFormCreatePurchaseOrder) => {
    // TODO: Handle Logic Later
  };

  const handleAddItem = () => {
    append(defaultProducts);
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const handleCloseConfirmItem = () => {
    setIsShowConfirmItem(false);
  };

  const fetchOptionsVendor = useCallback(async (search: string, page: number) => {
    try {
      const listVendors = await dispatch(
        getListVendors({
          page: page,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
          status: VendorStatusEnum.ACTIVE,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listVendors?.data?.responses,
        hasMore:
          listVendors?.data?.pagination.page < listVendors?.data?.pagination?.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchOptionsJob = useCallback(async (search: string, page: number) => {
    try {
      const listJobs = await dispatch(
        getListJob({
          page: page,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listJobs?.data?.responses,
        hasMore: listJobs?.data?.pagination.page < listJobs?.data?.pagination?.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);
  //#endregion Handle Function

  return (
    <div id='formPurchaseOrderComponent' className={cx('container')}>
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          formState: { errors },
        } as UseFormReturn<IFormCreatePurchaseOrder>)}
      >
        <form className={cx('formContainer')} onSubmit={handleSubmit(handleSubmitPurchaseOrder)}>
          <div className={cx('formContent')}>
            <p className={cx('poLabel')}>{t('create_purchase_label_purchase_details')}</p>
            <div className={cx('line')} />
            <div className={cx('twoCol')}>
              <Controller
                name='type'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('create_purchase_label_purchase_type')}
                    placeholder={t('common_placeholder_select')}
                    value={value}
                    options={purchaseTypeOptions || []}
                    isRequired
                    onChange={(optionSelected: IBaseOption) => onChange(optionSelected?.value)}
                    errorMessage={errors.type?.message}
                  />
                )}
              />
            </div>
            <div className={cx('twoCol')}>
              <Controller
                name='vendorId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchDropdown
                    fetchOptions={fetchOptionsVendor}
                    label={t('create_purchase_label_vendor')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    onChange={(item) => {
                      onChange(item?.id);
                    }}
                    isRequired
                    errorMessage={errors.vendorId?.message}
                    renderLabel={(item) => {
                      return <>{item.name}</>;
                    }}
                    renderOption={(item, selectedValue) => {
                      return (
                        <div className={cx('optionSelect')}>
                          <div className={cx('name')}>{item.name}</div>

                          <CommonIconPlus
                            width={16}
                            height={16}
                            strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                          />
                        </div>
                      );
                    }}
                    getOptionKey={(item) => {
                      return item.id;
                    }}
                  />
                )}
              />

              <Controller
                name={`jobId`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchDropdown<IJob>
                    fetchOptions={fetchOptionsJob}
                    label={t('create_purchase_label_job')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    disabled={isDisableJob}
                    onChange={(item) => {
                      onChange(item?.id);
                      setValue(`jobName`, item?.jobName);
                    }}
                    renderLabel={(item) => {
                      return <>{item.jobName}</>;
                    }}
                    renderOption={(item, selectedValue) => {
                      return (
                        <div className={cx('optionSelect')}>
                          <div className={cx('name')}>{item.jobName}</div>

                          <CommonIconPlus
                            width={16}
                            height={16}
                            strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                          />
                        </div>
                      );
                    }}
                    getOptionKey={(item) => {
                      return item.id;
                    }}
                  />
                )}
              />
            </div>

            <div className={cx('items')}>
              <p className={cx('itemsLabel')}>{t('create_purchase_label_items')}</p>
              {fields && fields?.length > 0 && (
                <div className={cx('listItem')}>
                  <div className={cx('listItemGroup')}>
                    {fields?.map((item, index) => (
                      <ProductPurchaseOrder
                        index={index}
                        key={item?.id}
                        onRemoveItem={handleRemoveItem}
                      />
                    ))}
                  </div>

                  <p className={cx('errorMessage')}>{errors.products?.message}</p>
                </div>
              )}

              <div className={cx('itemsActions')}>
                <div className={cx('addItem', 'group')} onClick={handleAddItem}>
                  <img
                    src={icons.commonIconAddField}
                    alt={t('common_img_text_alt')}
                    className={cx('addItemIcon')}
                  />
                  <span className={cx('addItemLabel')}>{t('create_purchase_action_add_item')}</span>
                </div>

                <span className={cx('totalGroup')}>
                  {t('create_purchase_order_total', {
                    total: formatCurrency(CurrencyEnum.USD, watchTotal ?? 0),
                  })}
                </span>
              </div>
            </div>

            <p className={cx('poLabel')}>{t('create_purchase_label_additional_information')}</p>
            <div className={cx('line')} />
            <div className={cx('twoCol')}>
              <BaseCheckbox
                name='purchaseDate'
                label={t('create_purchase_label_purchase_date')}
                value={isShowPurchaseDate}
                onChange={handleChangeCheckboxPurChaseDate}
              />

              {isShowPurchaseDate && (
                <Controller
                  name='date'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseDatePicker
                      value={value}
                      onDateSelected={onChange}
                      errorMessage={errors.date?.message}
                    />
                  )}
                />
              )}
            </div>

            <div className={cx('twoCol')}>
              <BaseCheckbox
                name='orderNumber'
                label={t('create_purchase_label_set_po_number')}
                value={isShowOrderNumber}
                onChange={handleChangeCheckboxOrderNumber}
              />

              {isShowOrderNumber && (
                <Controller
                  name='orderNumber'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      value={value || EMPTY_STRING}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        let { value } = event.target;

                        if (value.length > MAX_PO_NUMBER) {
                          value = value.slice(0, MAX_PO_NUMBER);
                        }

                        if (!isNaN(Number(value))) {
                          onChange(value);
                        }
                      }}
                      errorMessage={errors.orderNumber?.message}
                    />
                  )}
                />
              )}
            </div>

            <div className={cx('twoCol')}>
              <BaseCheckbox
                name='documents'
                label={t('create_purchase_label_upload_documents')}
                value={isShowUploadDocuments}
                onChange={handleChangeCheckboxUploadDocuments}
              />

              <div></div>
            </div>

            {isShowUploadDocuments && (
              <Controller
                name='documents'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseUploadDocument
                    multiple
                    values={value ?? []}
                    onFilesChange={(data: IDocumentPurchaseOrder[]) => {
                      onChange(data);
                    }}
                    errorMessage={errors.documents?.message}
                  />
                )}
              />
            )}

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  label={t('create_purchase_label_notes')}
                  value={value || EMPTY_STRING}
                  height={200}
                  onTextareaChange={onChange}
                />
              )}
            />
          </div>

          <div className={cx('formActions')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleBack}
            />

            {!purchaseId && (
              <BaseButton
                label={t('common_btn_save')}
                width={117}
                isDisable={!isDirty}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                type='submit'
              />
            )}
          </div>
        </form>
      </FormProvider>

      <CommonConfirmModal
        isOpen={isShowConfirmItem}
        onClose={handleCloseConfirmItem}
        title={t('create_purchase_no_item')}
      />
    </div>
  );
};

export default FormPurchaseOrder;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import BaseButton from '~/components/common/button/BaseButton';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ConfirmModal.module.scss';

type Props = {
  title: string;
  titleCancel?: string;
  titleAction?: string;
  onCancel: () => void;
  onAction: () => void;
  typeStyleAction?: ButtonTypeEnum;
};

const cx = classNames.bind(styles);

const ConfirmModal = (props: Props) => {
  //#region Destructuring Props
  const {
    title,
    titleCancel,
    titleAction,
    typeStyleAction = ButtonTypeEnum.RED_SYSTEM,
    onCancel,
    onAction,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='confirmModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        <p className={cx('title')}>{title}</p>

        <div className={cx('actions')}>
          <BaseButton
            label={titleCancel ? titleCancel : t('common_btn_cancel')}
            width={'100%'}
            onClick={onCancel}
            typeStyle={ButtonTypeEnum.CANCEL}
          />

          <BaseButton
            label={titleAction ? titleAction : t('common_btn_save')}
            width={'100%'}
            typeStyle={typeStyleAction}
            onClick={onAction}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;

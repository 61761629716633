// Libs
import classNames from 'classnames/bind';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_TIME_PICKER_HEIGHT, DEFAULT_TIME_PICKER_WIDTH } from '~/utils/constants/component';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './TimePickerFieldControl.module.scss';

export type TimePickerFieldControlProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  value?: string;
  placeholderText?: string;
  errorMessage?: string;
  height?: number | string;
  width?: number | string;
};

const cx = classNames.bind(styles);

const TimePickerFieldControl = forwardRef<HTMLButtonElement, TimePickerFieldControlProps>(
  (props, ref) => {
    //#region Destructuring Props
    const {
      width = DEFAULT_TIME_PICKER_WIDTH,
      height = DEFAULT_TIME_PICKER_HEIGHT,
      value,
      placeholderText,
      errorMessage,
      onClick,
      ...restProps
    } = props;
    //#endregion Destructuring Props

    //#region Declare Hook
    const { t } = useTranslation();
    //#endregion Declare Hook

    //#region Selector
    //#endregion Selector

    //#region Declare State
    //#endregion Declare State

    //#region Implement Hook
    //#endregion Implement Hook

    //#region Handle Function
    //#endregion Handle Function

    return (
      <div id='timePickerFieldControlComponent' className={cx('container')} style={{ width }}>
        <button
          {...restProps}
          ref={ref}
          type='button'
          style={{ height }}
          className={cx('fieldControl')}
          onClick={onClick}
        >
          {value ? (
            <div className={cx('valueField')}>{value}</div>
          ) : (
            <div className={cx('valueField', { placeholderText: placeholderText })}>
              {placeholderText}
            </div>
          )}
          <div>
            <img
              className={cx('icon')}
              src={icons.commonIconTimePicker}
              alt={t('common_img_text_alt')}
            />
          </div>
        </button>

        {errorMessage && <div className={cx('errMessage')}>{errorMessage}</div>}
      </div>
    );
  }
);

export default TimePickerFieldControl;

// Libs
import classNames from 'classnames/bind';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Location, useLocation, useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import {
  FormInput,
  BaseButton,
  BaseSelect,
  SearchDropdown,
  BaseDatePicker,
  FieldItemProductBill,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import {
  billTypeOptions,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  BillNameEnum,
  BillTypeEnum,
  CurrencyEnum,
  StorageEnum,
  AccountRoleCodesEnum,
} from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { getListQuickBookTerms } from '~/thunks/quickBook/quickBookThunk';
import { IBaseOption } from '~/utils/interface/common';
import { billSchema, initialValues } from './helper';
import { IFormBill } from '~/utils/interface/bill';
import { CommonIconPlus } from '~/assets/svgComponents';
import { CYAN600, WHITE } from '~/utils/constants/color';
import {
  getDetailPurchaseOrder,
  getDetailPurchaseOrderForBill,
} from '~/thunks/purchaseOrder/purchaseOrderThunk';
import { IPurchaseOrderDetail } from '~/utils/interface/purchaseOrder';
import { createBill } from '~/thunks/bill/billThunk';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
// Styles, images, icons
import styles from './FormBill.module.scss';

type Props = {
  onClose: () => void;
};

const cx = classNames.bind(styles);

const FormBill = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { state: purchaseOrderId }: Location<string> = useLocation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [paymentTermDueDate, setPaymentTermDueDate] = useState<string>();
  const [purchaseOrderSelected, setPurchaseOrderSelected] = useState<IPurchaseOrderDetail>();
  const [detailPo, setDetailPo] = useState<IPurchaseOrderDetail>();
  const products = purchaseOrderSelected?.products || detailPo?.products;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IFormBill>({
    resolver: yupResolver(billSchema({ t, paymentTermDueDate })),
    defaultValues: initialValues,
  });

  const typeBillSelected = watch('type');
  const invoiceDateSelected = watch('invoiceDate');

  const isShowPurchaseOrder = typeBillSelected === BillTypeEnum.PURCHASE_ORDER || purchaseOrderId;
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const dataForm: IFormBill = {
      ...initialValues,
      type: purchaseOrderId ? BillTypeEnum.PURCHASE_ORDER : typeBillSelected,
      purchaseOrderId: purchaseOrderId || EMPTY_STRING,
    };

    setPurchaseOrderSelected(undefined);
    setPaymentTermDueDate(EMPTY_STRING);

    reset(dataForm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeBillSelected, purchaseOrderId]);

  useEffect(() => {
    if (!invoiceDateSelected && paymentTermDueDate) {
      const calculatedDueDate = paymentTermDueDate ? moment().format('YYYY-MM-DD') : EMPTY_STRING;

      setValue(BillNameEnum.INVOICE_DATE, calculatedDueDate, { shouldDirty: true });
      trigger(BillNameEnum.INVOICE_DATE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTermDueDate]);

  useEffect(() => {
    if (paymentTermDueDate) {
      const calculatedDueDate = moment(invoiceDateSelected)
        .add(Number(paymentTermDueDate ?? DEFAULT_NUMBER_ZERO), 'days')
        .format('YYYY-MM-DD');

      setValue(BillNameEnum.DUE_DATE, calculatedDueDate, { shouldDirty: true });
      trigger(BillNameEnum.DUE_DATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTermDueDate, invoiceDateSelected]);

  useEffect(() => {
    if (!purchaseOrderId) return;

    fetchDetailPo(purchaseOrderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddBill = (data: IFormBill) => {
    const newData = handlePreparedData(data);

    loadingData?.show();
    dispatch(createBill(newData))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        if (purchaseOrderId) {
          const role = localStorage.getItem(StorageEnum.ROLE);

          switch (role) {
            case AccountRoleCodesEnum.ADMIN:
              navigate(`${adminRouteAbsolute.bill}`);
              break;

            case AccountRoleCodesEnum.STAFF:
              navigate(`${staffRouteAbsolute.bill}`);
              break;

            case AccountRoleCodesEnum.PROJECT_MANAGER:
              navigate(`${projectManageRouteAbsolute.bill}`);
              break;
          }
          return;
        }
        handleCancelClient();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handlePreparedData = (data: IFormBill) => {
    if (!data) return {} as IFormBill;

    const formattedData: IFormBill = { ...data };
    const bodyData: Partial<IFormBill> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IFormBill] = value;
      }
    });

    return bodyData as IFormBill;
  };

  const handleCancelClient = () => {
    onClose && onClose();
  };

  const fetchOptionsPaymentTerm = useCallback(async (search: string, page: number) => {
    try {
      const listPaymentTerm = await dispatch(
        getListQuickBookTerms({
          page: page,
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listPaymentTerm?.data?.responses,
        hasMore:
          listPaymentTerm?.data?.pagination.page < listPaymentTerm?.data?.pagination?.totalPages ||
          false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchOptionsPurchaseOrder = useCallback(async (search: string, page: number) => {
    try {
      const listPurchaseOrder = await dispatch(
        getDetailPurchaseOrderForBill({
          page: page,
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listPurchaseOrder?.data?.responses,
        hasMore:
          listPurchaseOrder?.data?.pagination.page <
            listPurchaseOrder?.data?.pagination?.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchDetailPo = async (purchaseId: string) => {
    if (!purchaseId) return;
    loadingData?.show();

    try {
      const resp = await dispatch(getDetailPurchaseOrder(purchaseId)).unwrap();
      if (!resp) return;
      const { data } = resp;
      setDetailPo(data);
    } catch (error) {
    } finally {
      loadingData?.hide();
    }
  };
  //#endregion Handle Function

  return (
    <form className={cx('form')} onSubmit={handleSubmit(handleAddBill)}>
      <div className={cx('body')}>
        <div className={cx('twoColumns')}>
          <Controller
            name={BillNameEnum.TYPE}
            control={control}
            render={({ field }) => (
              <BaseSelect
                label={t('form_bill_type_bill_label')}
                placeholder={t('common_placeholder_select')}
                value={
                  (purchaseOrderId && BillTypeEnum.PURCHASE_ORDER) || field.value || EMPTY_STRING
                }
                options={billTypeOptions || []}
                onChange={({ value }: IBaseOption) => {
                  field.onChange(value);
                }}
                errorMessage={errors.type?.message}
                isRequired
                disabled={!!purchaseOrderId}
              />
            )}
          />
        </div>

        {isShowPurchaseOrder ? (
          <>
            <div className={cx('twoColumns')}>
              <Controller
                name={BillNameEnum.PURCHASE_ORDER_ID}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchDropdown
                    disabled={!!purchaseOrderId}
                    isRequired
                    fetchOptions={fetchOptionsPurchaseOrder}
                    label={t('form_bill_type_purchase_order_label')}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    onChange={(item) => {
                      onChange(item?.id);
                      if (item) {
                        setPurchaseOrderSelected(item);
                      }
                    }}
                    renderLabel={(item) => {
                      return <>{item.orderNumber}</>;
                    }}
                    renderOption={(item, selectedValue) => {
                      return (
                        <div className={cx('optionSelect')}>
                          <div className={cx('name')}>{item.orderNumber}</div>

                          <CommonIconPlus
                            width={16}
                            height={16}
                            strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                          />
                        </div>
                      );
                    }}
                    getOptionKey={(item) => {
                      return item.id.toString();
                    }}
                    errorMessage={errors.purchaseOrderId?.message}
                  />
                )}
              />

              <FormInput
                name={BillNameEnum.VENDOR}
                label={t('form_bill_type_vender_label')}
                value={purchaseOrderSelected?.vendor.name || detailPo?.vendor.name || EMPTY_STRING}
                disabled
              />
            </div>

            <div className={cx('twoColumns')}>
              <Controller
                name={BillNameEnum.INVOICE_NUMBER}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('form_bill_type_invoice_label')}
                    value={field.value || EMPTY_STRING}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      let { value } = event.target;

                      if (!isNaN(Number(value.trim()))) {
                        field.onChange(value);
                      }
                    }}
                    errorMessage={errors.invoiceNumber?.message}
                  />
                )}
              />

              <Controller
                name={BillNameEnum.INVOICE_DATE}
                control={control}
                render={({ field: { value, onChange } }) => {
                  const calculatedDueDate =
                    !invoiceDateSelected && paymentTermDueDate
                      ? moment().format('YYYY-MM-DD')
                      : value || EMPTY_STRING;

                  return (
                    <BaseDatePicker
                      label={t('form_bill_type_invoice_date_label')}
                      placeholderText={t('common_placeholder_select')}
                      onDateSelected={onChange}
                      value={calculatedDueDate}
                      errorMessage={errors.invoiceDate?.message}
                    />
                  );
                }}
              />
            </div>

            <div className={cx('twoColumns')}>
              <Controller
                name={BillNameEnum.QUICK_BOOK_TERM_ID}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchDropdown
                    label={t('form_bill_type_payment_term_label')}
                    fetchOptions={fetchOptionsPaymentTerm}
                    placeholder={t('common_placeholder_select')}
                    value={value || EMPTY_STRING}
                    onChange={(item) => {
                      onChange(item?.id);
                      if (item?.dueDays?.toString()) {
                        setPaymentTermDueDate(item.dueDays.toString());
                      }
                    }}
                    renderLabel={(item) => {
                      return <>{item.name}</>;
                    }}
                    renderOption={(item, selectedValue) => {
                      return (
                        <div className={cx('optionSelect')}>
                          <div className={cx('name')}>{item.name}</div>

                          <CommonIconPlus
                            width={16}
                            height={16}
                            strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                          />
                        </div>
                      );
                    }}
                    getOptionKey={(item) => {
                      return item.id;
                    }}
                  />
                )}
              />

              <Controller
                name={BillNameEnum.DUE_DATE}
                control={control}
                render={({ field: { value, onChange } }) => {
                  const calculatedDueDate = paymentTermDueDate
                    ? moment(invoiceDateSelected)
                        .add(Number(paymentTermDueDate ?? DEFAULT_NUMBER_ZERO), 'days')
                        .format('YYYY-MM-DD')
                    : value || EMPTY_STRING;

                  return (
                    <BaseDatePicker
                      label={t('form_bill_type_due_date_label')}
                      placeholderText={t('common_placeholder_select')}
                      onDateSelected={onChange}
                      value={calculatedDueDate}
                      errorMessage={errors.dueDate?.message}
                    />
                  );
                }}
              />
            </div>

            <div className={cx('fieldListProductContent')}>
              <p className={cx('cLabel')}>{t('form_bill_placeholder_items_date')}</p>
              <div className={cx('fieldListProduct')}>
                {products?.length ? (
                  products.map((item, index) => <FieldItemProductBill key={index} data={item} />)
                ) : (
                  <p className={cx('textNoData')}>{t('common_empty_data')}</p>
                )}
              </div>
            </div>
            <div className={cx('totalContent')}>
              <p className={cx('textTotal')}>{`${t('form_bill_type_total_label')}: ${formatCurrency(
                CurrencyEnum.USD,
                purchaseOrderSelected?.products?.reduce(
                  (total, item) => total + (item?.subTotal ?? DEFAULT_NUMBER_ZERO),
                  DEFAULT_NUMBER_ZERO
                ) ?? DEFAULT_NUMBER_ZERO
              )}`}</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className={cx('actions')}>
        <div className={cx('buttonCancel')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            onClick={handleCancelClient}
          />
        </div>

        <BaseButton
          label={t('common_btn_save')}
          width={117}
          type='submit'
          typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
        />
      </div>
    </form>
  );
};

export default FormBill;

// Libs
import classNames from 'classnames/bind';
import { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// Components, Layouts, Pages
import { FormInput, BaseButton, BaseModal, Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import {
  createInclusionExclusion,
  updateInclusionExclusion,
} from '~/thunks/termConditions/termConditionsThunk';
import { termConditionsAction } from '~/thunks/termConditions/termConditionsSlice';
import {
  IAddInclusionAndExclusion,
  IInclusionAndExclusionItem,
} from '~/utils/interface/termsAndConditions';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './AdminFormInclusionAndExclusion.module.scss';

type Props = {
  detailInclusionExclusion?: IInclusionAndExclusionItem;
  isOpen?: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('common_validate_require_template_name')),
    })
    .required();
};

const AdminFormInclusionAndExclusion = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, detailInclusionExclusion, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const defaultValues: IAddInclusionAndExclusion = useMemo(() => {
    return {
      name: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IAddInclusionAndExclusion>({
    resolver: yupResolver(schema(t)),
    defaultValues,
  });

  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!detailInclusionExclusion) return;

    reset(detailInclusionExclusion);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailInclusionExclusion]);

  useEffect(() => {
    if (isDirty) {
      setHasChanged(true);
    }
  }, [isDirty]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCancelModal = () => {
    onClose && onClose();
    reset();
    setIsLoading(false);
    setHasChanged(false);
  };

  const handleAddInclusionAndExclusion = (data?: IAddInclusionAndExclusion) => {
    if (!data) return;

    setIsLoading(true);

    dispatch(createInclusionExclusion(data))
      .unwrap()
      .then((res) => {
        dispatch(termConditionsAction.setRefreshListInclusionExclusion(true));
        handleCancelModal();
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false));
  };

  const handleEditInclusionExclusion = (data?: IAddInclusionAndExclusion) => {
    if (!data || !detailInclusionExclusion) return;

    setIsLoading(true);

    dispatch(updateInclusionExclusion({ id: detailInclusionExclusion?.id, name: data.name }))
      .unwrap()
      .then((res) => {
        dispatch(termConditionsAction.setRefreshListInclusionExclusion(true));
        handleCancelModal();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
        setHasChanged(false);
      });
  };
  //#endregion Handle Function

  return (
    <BaseModal
      id='adminFormInclusionExclusionComponent'
      isOpen={isOpen}
      onClose={handleCancelModal}
    >
      <form
        className={cx('modalContent')}
        onSubmit={handleSubmit(
          detailInclusionExclusion ? handleEditInclusionExclusion : handleAddInclusionAndExclusion
        )}
      >
        <div className={cx('modalHeader')}>
          {t(
            detailInclusionExclusion
              ? 'update_inclusion_and_exclusion_title'
              : 'add_inclusion_and_exclusion_title'
          )}
        </div>

        <div className={cx('line')} />

        <div className={cx('modalBody')}>
          <Controller
            name='name'
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('add_inclusion_and_exclusion_label_name')}
                value={value}
                onChange={onChange}
                required
                errorMessage={errors.name?.message}
              />
            )}
          />
        </div>

        <div className={cx('modalFooter')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleCancelModal}
            />
          </div>

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
            isDisable={detailInclusionExclusion && !hasChanged}
          />
        </div>

        {isLoading && <Loading />}
      </form>
    </BaseModal>
  );
};

export default AdminFormInclusionAndExclusion;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Field, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
// Components, Layouts, Pages
import { BaseButton, CircleAvatar, InputSearch } from '~/components';
// Others
import { IManageTechnicians } from '~/utils/interface/technician';
import { IAddAssignee } from '~/utils/interface/common';
import {
  ASTERISK_SYMBOL,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  LIMIT_TAG_ASSIGNEE,
  PLUS_CHARACTER,
} from '~/utils/constants/common';
import { ButtonTypeEnum, CircleAvatarEnum, InputSearchTypeStyleEnum } from '~/utils/enum';
import { getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './BaseSelectSearchLocal.module.scss';
import { icons } from '~/assets';

type Props = {
  label?: string;
  width?: number | string;
  height?: number | string;
  widthOptions?: number | string;
  isOpen: boolean;
  technicianList: IManageTechnicians[];
  isRequired?: boolean;
  value?: string;
  onAdd: (assignee: IAddAssignee) => void;
  onCloseAssignee: (assignee: IAddAssignee) => void;
};

const cx = classNames.bind(styles);

const BaseSelectSearchLocal = (props: Props) => {
  //#region Destructuring Props
  const {
    label,
    width,
    height,
    widthOptions,
    isOpen,
    technicianList,
    isRequired,
    value,
    onAdd,
    onCloseAssignee,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [filteredAssignees, setFilteredAssignees] = useState<IAddAssignee[]>([]);
  const [listSelectedAssign, setListSelectedAssign] = useState<IAddAssignee[]>([]);
  const [listAssign, setListAssign] = useState<IAddAssignee[]>([]);
  //#endregion Declare State

  //#region Implement Hook

  useEffect(() => {
    if (!value) return;

    const itemSelected = filteredAssignees?.find((item) => String(item.id) === value);

    if (!itemSelected) return;

    setListSelectedAssign([itemSelected]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!technicianList) return;

    const newListDataAssignee: IAddAssignee[] = technicianList.map((technician) => ({
      id: parseInt(technician.id),
      firstName: technician.firstName,
      lastName: technician.lastName,
      avatarUrl: technician.avatarUrl ?? EMPTY_STRING,
      status: technician.status,
    }));

    const updatedFilteredAssignees = newListDataAssignee.filter(
      (item) => !listSelectedAssign.some((selected) => selected.id === item.id)
    );
    setFilteredAssignees(updatedFilteredAssignees);
    setListAssign(newListDataAssignee);
  }, [technicianList, listSelectedAssign]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddAssignee = (assignee: IAddAssignee) => {
    onAdd(assignee);
    setListSelectedAssign([assignee]);
  };

  const handleCloseAssignee = (assignee: IAddAssignee) => {
    onCloseAssignee(assignee);
    setFilteredAssignees((prevAssignees) => [...prevAssignees, assignee]);
    setListSelectedAssign((prev) => prev.filter((item) => item.id !== assignee.id));
  };

  const handleSearchAssignee = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filtered = listAssign
      .filter((assignee) =>
        `${assignee.firstName?.toLowerCase()} ${assignee.lastName?.toLowerCase()}`.includes(
          value.toLowerCase()
        )
      )
      .filter((assignee) => !listSelectedAssign.some((selected) => selected.id === assignee.id));

    setFilteredAssignees(filtered);
  };
  //#endregion Handle Function

  return (
    <Field id='baseSelectSearchLocalComponent' style={{ width }}>
      {label && (
        <h3 className={cx('titleSelect')}>
          {t('admin_manage_schedule_select_assignee_label')}

          {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </h3>
      )}

      <Listbox value={listSelectedAssign}>
        {({ open }) => (
          <>
            <ListboxButton className={cx('selectAssign')} style={{ height }}>
              {listSelectedAssign?.length > DEFAULT_NUMBER_ZERO ? (
                <>
                  {Array.isArray(listSelectedAssign) &&
                    listSelectedAssign?.length > DEFAULT_NUMBER_ZERO && (
                      <div className={cx('assigneeSelected')}>
                        {listSelectedAssign
                          .slice(DEFAULT_NUMBER_ZERO, LIMIT_TAG_ASSIGNEE)
                          .map((assigneeItem, index) => {
                            return (
                              <div className={cx('tagAssigneeSelected')}>
                                <CircleAvatar
                                  width={16}
                                  height={16}
                                  fontSize={8}
                                  imageUrl={assigneeItem.avatarUrl}
                                  firstName={assigneeItem.firstName}
                                  lastName={assigneeItem.lastName}
                                  type={
                                    assigneeItem.avatarUrl
                                      ? CircleAvatarEnum.IMAGE
                                      : CircleAvatarEnum.TEXT
                                  }
                                />

                                <span className={cx('tagFirstName')}>{assigneeItem.firstName}</span>

                                <img
                                  src={icons.commonIconCloseModal}
                                  className={cx('iconCloseAssignee')}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleCloseAssignee(assigneeItem);
                                  }}
                                  alt={t('common_img_text_alt')}
                                />
                              </div>
                            );
                          })}

                        {listSelectedAssign.length > LIMIT_TAG_ASSIGNEE && (
                          <span className={cx('tagMore')}>{`${PLUS_CHARACTER}${
                            listSelectedAssign.length - LIMIT_TAG_ASSIGNEE
                          }`}</span>
                        )}
                      </div>
                    )}
                </>
              ) : (
                <span className={cx('titleAssignee')}>{t('common_placeholder_select')}</span>
              )}

              <img
                src={icons.commonIconArrowBottom}
                className={cx('iconDown')}
                alt={t('common_img_text_alt')}
              />
            </ListboxButton>

            <ListboxOptions
              style={{ width: widthOptions }}
              className={cx('optionList')}
              transition
              anchor={{ to: 'bottom end', gap: '4px' }}
            >
              <div className={cx('search')}>
                <InputSearch
                  placeholder={t('common_search_placeholder')}
                  typeStyle={InputSearchTypeStyleEnum.WITH_FULL}
                  onChange={handleSearchAssignee}
                />
              </div>

              <div className={cx('assignee')}>
                {filteredAssignees.length > DEFAULT_NUMBER_ZERO ? (
                  filteredAssignees.map((assigneeItem, index) => {
                    return (
                      <ListboxOption
                        value={assigneeItem.id}
                        key={index}
                        className={cx('assigneeItem')}
                      >
                        <div className={cx('avatarFirstLastName')}>
                          <CircleAvatar
                            fontSize={14}
                            firstName={assigneeItem.firstName}
                            lastName={assigneeItem.lastName}
                            imageUrl={assigneeItem.avatarUrl}
                            type={
                              assigneeItem.avatarUrl
                                ? CircleAvatarEnum.IMAGE
                                : CircleAvatarEnum.TEXT
                            }
                          />

                          <div className={cx('firstLastName')}>
                            {getFullName({ ...assigneeItem })}
                          </div>
                        </div>

                        <BaseButton
                          label={t('common_btn_add')}
                          typeStyle={ButtonTypeEnum.SMALL}
                          width={45}
                          height={24}
                          minWidth={45}
                          onClick={() => {
                            handleAddAssignee(assigneeItem);
                          }}
                        />
                      </ListboxOption>
                    );
                  })
                ) : (
                  <p className={cx('textNoData')}>{t('common_empty_data')}</p>
                )}
              </div>
            </ListboxOptions>
          </>
        )}
      </Listbox>
    </Field>
  );
};

export default BaseSelectSearchLocal;

// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { LabelValueField } from '~/components';
// Others
import {
  DEFAULT_CURRENCY,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  POUND,
} from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { convertEnumToString, formatCurrency, formattedTime } from '~/utils/helper';
import { IBillDetail } from '~/utils/interface/bill';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getBillDetail } from '~/thunks/bill/billThunk';
// Styles, images, icons
import styles from './BasicInformationBillTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const BasicInformationBillTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loading = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { billId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [billDetail, setBillDetail] = useState<IBillDetail>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!billId) return;

    handleGetBillDetail(billId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetBillDetail = (billId: string) => {
    loading?.show();
    dispatch(getBillDetail(billId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setBillDetail(res.data);
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };
  //#endregion Handle Function

  return (
    <div id='billDetailPage' className={cx('container')}>
      <div className={cx('billDetailWrap')}>
        <div className={cx('billDetail')}>
          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_type_bill_label')}
            value={billDetail?.type ? convertEnumToString(billDetail?.type) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_po_label')}
            value={
              billDetail?.purchaseOrderId ? (
                <>
                  <span className={cx('poNumber')}>{POUND}</span>
                  {billDetail?.purchaseOrderId}
                </>
              ) : (
                EMPTY_STRING
              )
            }
          />

          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_vendor_label')}
            value={billDetail?.vendor?.name || EMPTY_STRING}
          />
        </div>
        <div className={cx('billDetail')}>
          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_invoice_id_label')}
            value={billDetail?.invoiceNumber || EMPTY_STRING}
          />

          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_invoice_date_label')}
            value={billDetail?.invoiceDate ? formattedTime(billDetail?.invoiceDate) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_due_date_label')}
            value={billDetail?.dueDate ? formattedTime(billDetail?.dueDate) : EMPTY_STRING}
          />

          <LabelValueField
            label={t('template_bill_detail_tabs_basic_information_payment_term_label')}
            value={billDetail?.quickBookTerm?.name || EMPTY_STRING}
          />
        </div>
      </div>

      <div className={cx('itemWraps')}>
        <div className={cx('titleItem')}>{t('template_bill_detail_items_title')}</div>

        {Array.isArray(billDetail?.products) &&
        billDetail?.products?.length > DEFAULT_NUMBER_ZERO ? (
          <div className={cx('items')}>
            <div className={cx('itemsHeader')}>
              <span className={cx('titleItemsHeader')}>
                {t('template_bill_detail_item_table_label')}
              </span>

              <span className={cx('titleItemsHeader')}>
                {t('template_bill_detail_qty_table_label')}
              </span>

              <span className={cx('titleItemsHeader')}>
                {t('template_bill_detail_unit_cost_table_label')}
              </span>

              <span className={cx('titleItemsHeader')}>
                {t('template_bill_detail_sub_total_table_label')}
              </span>
            </div>

            {billDetail?.products?.map((item, index) => (
              <div key={index} className={cx('itemsBody')}>
                <span className={cx('titleItemsBody')}>{item?.productName || EMPTY_STRING}</span>

                <span className={cx('titleItemsBody')}>
                  {item?.quantity ?? DEFAULT_NUMBER_ZERO}
                </span>

                <span className={cx('titleItemsBody')}>
                  {item?.unitCost
                    ? formatCurrency(CurrencyEnum.USD, item.unitCost)
                    : DEFAULT_CURRENCY}
                </span>

                <span className={cx('titleItemsBody')}>
                  {item?.subTotal
                    ? formatCurrency(CurrencyEnum.USD, item.subTotal)
                    : DEFAULT_CURRENCY}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <p className={cx('textNoData')}>{t('common_empty_data')}</p>
        )}
      </div>

      <div className={cx('totalWrap')}>
        <div className={cx('total')}>
          <LabelValueField
            label={t('template_bill_detail_total_table_label')}
            value={
              billDetail?.total
                ? formatCurrency(CurrencyEnum.USD, billDetail?.total)
                : DEFAULT_CURRENCY
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInformationBillTab;

import { urlApiProduct } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IDetailProduct,
  IFormProductPayload,
  IProduct,
  IQueryListProduct,
  IQueryListProductByVendor,
  IUpdateProduct,
  IQueryListProductForJob,
  IProductForJob,
} from '~/utils/interface/product';
import axiosClient from './axiosClient';

const productApi = {
  getListProducts(params: IQueryListProduct) {
    const url = `${urlApiProduct.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IProduct[]>>>(url, {
      params,
    });
  },

  createProduct(body: IFormProductPayload) {
    const url = `${urlApiProduct.create}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateProduct({ productId, body }: IUpdateProduct) {
    const url = `${urlApiProduct.updateProduct(productId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  deleteProduct(id: string) {
    const url = `${urlApiProduct.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getDetailProduct(productId: string) {
    const url = `${urlApiProduct.getDetail(productId)}`;
    return axiosClient.get<BaseResponse<IDetailProduct>>(url);
  },

  getListProductsByVendor(vendorId: string, params: IQueryListProductByVendor) {
    const url = `${urlApiProduct.getListByVendor(vendorId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IProduct[]>>>(url, {
      params,
    });
  },

  getListProductForJob(params: IQueryListProductForJob) {
    const url = `${urlApiProduct.getProductForJob}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IProductForJob[]>>>(url, {
      params,
    });
  },

  sendToQuickBocksProduct(productId: string) {
    const url = `${urlApiProduct.sendToQB(productId)}`;
    return axiosClient.put<BaseResponse>(url);
  },
};

export default productApi;

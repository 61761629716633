// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { FormBill, Toolbar } from '~/components';
// Others
import { NAVIGATE_GO_BACK } from '~/utils/constants/common';
// Styles, images, icons
import styles from './CreateBill.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CreateBill = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleGoBack = () => {
    navigate(NAVIGATE_GO_BACK);
  };
  //#endregion Handle Function

  return (
    <div id='createBillPage' className={cx('container')}>
      <Toolbar title={t('form_bill_add_bill_title')} />

      <div className={cx('body')}>
        <FormBill onClose={handleGoBack} />
      </div>
    </div>
  );
};

export default CreateBill;

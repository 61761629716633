// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseRichEditor, LabelValueField, Toolbar } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { IFormMainContent } from '~/utils/interface/termsAndConditions';
import { getMainContentDetail } from '~/thunks/termConditions/termConditionsThunk';
import { ModeTypeEnum, ThemeTextEditorEnum, TypeTermConditionEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './DetailMainContent.module.scss';
import './DetailMainContent.scss';

type Props = {};

const cx = classNames.bind(styles);

const DetailMainContent = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const { mainContentId } = useParams();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataDetailTerm, setDataDetailTerm] = useState<IFormMainContent>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (mainContentId) {
      handleGetDetailMainContent(mainContentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContentId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailMainContent = (id: string) => {
    if (!id) return;

    loadingData?.show();

    dispatch(getMainContentDetail(id))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        if (!res?.data) return;

        const mainContentData: IFormMainContent = {
          name: res?.data.name || EMPTY_STRING,
          lowerContent: res?.data.lowerContent || EMPTY_STRING,
          upperContent: res?.data.upperContent || EMPTY_STRING,
          isTemplate: res?.data.isTemplate || false,
          type: res.data.type || EMPTY_STRING,
        };
        setDataDetailTerm(mainContentData);
      })
      .catch((error) => {
        loadingData?.hide();
      });
  };

  const getTypeLabel = (type: string | undefined) => {
    switch (type) {
      case TypeTermConditionEnum.PAYMENT:
        return t('detail_main_terms_label_payment');
      case TypeTermConditionEnum.DISCOUNT:
        return t('detail_main_terms_label_discount');
      default:
        return EMPTY_STRING;
    }
  };
  //#endregion Handle Function

  return (
    <div id='detailMainContentPage' className={cx('container')}>
      <Toolbar title={t('detail_main_content_title')} />

      <div className={cx('detailMainContent')}>
        <div className={cx('body')}>
          <div className={cx('contentGroup')}>
            <div className={cx('contentWrap')}>
              <div className={cx('content')}>
                <LabelValueField
                  label={t('detail_main_terms_title_name')}
                  value={dataDetailTerm?.name}
                />
                <LabelValueField
                  label={t('detail_main_terms_title_type_term')}
                  value={getTypeLabel(dataDetailTerm?.type)}
                />
              </div>
            </div>

            <div className={cx('contentWrap')}>
              <div className={cx('content')}>
                <LabelValueField
                  isLongValue
                  label={t('detail_main_terms_title_upper')}
                  value={
                    <div className={cx('editorServiceRequest')}>
                      <BaseRichEditor
                        mode={ModeTypeEnum.VIEW}
                        theme={ThemeTextEditorEnum.BUBBLE}
                        value={dataDetailTerm?.upperContent}
                      />
                    </div>
                  }
                />
                <LabelValueField
                  isLongValue
                  label={t('detail_main_terms_title_lower')}
                  value={
                    <div className={cx('editorServiceRequest')}>
                      <BaseRichEditor
                        mode={ModeTypeEnum.VIEW}
                        theme={ThemeTextEditorEnum.BUBBLE}
                        value={dataDetailTerm?.lowerContent}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailMainContent;

import * as yup from 'yup';
import { TFunction } from 'i18next';

export const estimateSchema = (t: TFunction) => {
  return yup.object().shape({
    name: yup.string().optional(),
    isTemplate: yup.boolean().optional(),
    jobId: yup.number().optional(),
    budget: yup
      .object()
      .shape({
        material: yup.number().optional(),
        labor: yup.number().optional(),
        subcontractor: yup.number().optional(),
        equipment: yup.number().optional(),
        miscellanea: yup.number().optional(),
      })
      .optional(),
    total: yup.number().optional(),
    phases: yup.array().of(phaseSchema(t)).required('Required'),
  });
};

const phaseSchema = (t: TFunction) => {
  return yup.object().shape({
    name: yup.string().required('Required'),
    percentage: yup
      .number()
      .typeError(t('template_form_phase_content_validate_field_must_be_number'))
      .min(0, 'Min 0')
      .max(100, 'Max 100')
      .optional()
      .nullable(),
    budget: yup
      .object()
      .shape({
        material: yup.number().optional(),
        labor: yup.number().optional(),
        subcontractor: yup.number().optional(),
        equipment: yup.number().optional(),
        miscellanea: yup.number().optional(),
      })
      .optional(),
    total: yup.number().optional(),
    materials: yup.array().of(budgetProduct(t)).required(),
    labors: yup.array().of(budgetLabors(t)).optional(),
    subcontractor: yup
      .object()
      .shape({
        unassignedWorks: yup.array().of(unassignedWork(t)).optional(),
        assignedSubcontractors: yup.array().of(assignedSubContractor(t)).optional(),
      })
      .optional(),
    equipments: yup.array().of(budgetProduct(t)).optional(),
    miscellanea: yup.array().of(budgetProduct(t)).optional(),
  });
};

const budgetProduct = (t: TFunction) => {
  return yup.object().shape({
    vendorId: yup.number().optional(),
    productId: yup.number().optional().nullable(),
    productIdSelected: yup.string().optional(),
    productName: yup.string().optional().nullable(),
    fixtureLocationName: yup.string().optional().nullable(),
    fixtureLocationId: yup.number().optional().nullable(),
    quantity: yup
      .number()
      .typeError(t('template_form_phase_content_validate_category_field_qty_must_be_number'))
      .min(1, 'Min 1')
      .optional(),
    subTotal: yup.number().optional(),
    unitCost: yup.number().optional(),
  });
};

const unassignedWork = (t: TFunction) => {
  return yup.object().shape({
    name: yup.string().optional().nullable(),
    value: yup.number().optional().nullable(),
  });
};

const assignedSubContractor = (t: TFunction) => {
  return yup.object().shape({
    vendorId: yup.string().optional().nullable(),
    vendorName: yup.string().optional().nullable(),
    value: yup.number().optional(),
  });
};

const budgetLabors = (t: TFunction) => {
  return yup.object().shape({
    laborId: yup.mixed<string | number>().optional().nullable(),
    laborName: yup.string().optional().nullable(),
    hours: yup.number().optional(),
    hourlyCost: yup.number().optional(),
    subTotal: yup.number().optional(),
  });
};

// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseMoreAction, BasePagination, BaseTable, ConfirmModal } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import {
  ColumnType,
  IListQueryParams,
  IPagination,
  IQueryBase,
  MoreActionItem,
} from '~/utils/interface/common';
import { IMainContent } from '~/utils/interface/termsAndConditions';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, BaseTableEnum, StorageEnum } from '~/utils/enum';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  deleteTermMainContent,
  getListTermMainContent,
} from '~/thunks/termConditions/termConditionsThunk';
import { convertToTitleCase } from '~/utils/helper';
import { RootState } from '~/redux/store';
import { termConditionsAction } from '~/thunks/termConditions/termConditionsSlice';
// Styles, images, icons
import styles from './MainContentTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleShowDetailMainContent: (id: string) => void,
  handleShowConfirmDelete: (item: IMainContent) => void,
  handleEditMainContent: (id: string) => void
): ColumnType<IMainContent>[] => {
  return [
    {
      key: 'name',
      title: t('main_content_tab_table_label_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'type',
      title: t('main_content_tab_table_label_type'),
      dataIndex: 'type',
      render: (_, record) => (
        <span>{record?.type ? convertToTitleCase(record.type) : EMPTY_STRING}</span>
      ),
      width: '50%',
    },
    {
      key: 'action',
      title: t('main_content_tab_table_label_action'),
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleShowDetailMainContent(record.id),
          },
          {
            label: t('common_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleEditMainContent(record.id),
          },
          {
            label: t('common_btn_delete'),
            icon: (
              <img
                src={icons.commonIconTrash}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => handleShowConfirmDelete(record),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
      width: '12%',
    },
  ];
};

const MainContentTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );

  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListMainContent } = useAppSelector((state: RootState) => state.termConditions);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });

  const [mainContentList, setMainContentList] = useState<IMainContent[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [itemMainContent, setItemMainContent] = useState<IMainContent>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    loading?.show();
    const { tab, ...rest } = params;
    const newParams = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListTermMainContent(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!isRefreshListMainContent) return;
    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(textSearchParams ? { searchKey: textSearchParams } : {}),
    };

    handleGetListTermMainContent(newParams);
    dispatch(termConditionsAction.setRefreshListMainContent(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListMainContent]);

  useEffect(() => {
    if (!pagination) return;

    if (textSearchParams) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearchParams]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListTermMainContent = (paramsTermMainContent: IListQueryParams) => {
    dispatch(getListTermMainContent(paramsTermMainContent))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res.data;

        pagination && setPagination(pagination);
        responses && setMainContentList(responses);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleShowDetailMainContent = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.detailMainContent}/${id}`);
        break;
      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.detailMainContent}/${id}`);
        break;
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.detailMainContent}/${id}`);
        break;
    }
  };

  const handleEditMainContent = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editMainContent}/${id}`);
        break;
      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.editMainContent}/${id}`);
        break;
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.editMainContent}/${id}`);
        break;
    }
  };

  const handleShowConfirmDelete = (item?: IMainContent) => {
    if (!item) return;

    setItemMainContent(item);
    setIsShowConfirmDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    isShowConfirmDelete && setIsShowConfirmDelete(false);
  };

  const handleDeleteMainContent = () => {
    if (!itemMainContent) return;

    loading?.show();

    dispatch(deleteTermMainContent(itemMainContent.id))
      .unwrap()
      .then((res) => {
        dispatch(termConditionsAction.setRefreshListMainContent(true));
        handleCloseConfirmDelete();
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCLickRow = (record: IMainContent) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.detailMainContent}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.detailMainContent}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.detailMainContent}/${record.id}`);
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='mainContentTab' className={cx('container')}>
      <div className={cx('table')}>
        <BaseTable
          typeStyle={BaseTableEnum.COLOR_TABLE}
          columns={columns(
            t,
            handleShowDetailMainContent,
            handleShowConfirmDelete,
            handleEditMainContent
          )}
          dataSource={mainContentList || []}
          onClickRow={handleCLickRow}
        />
      </div>

      <div className={cx('pagination')}>
        <BasePagination
          defaultCurrentPage={pageSelected}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalItems}
          onChange={handlePaginationChange}
        />
      </div>

      {isShowConfirmDelete && (
        <ConfirmModal
          onAction={handleDeleteMainContent}
          onCancel={handleCloseConfirmDelete}
          title={t('common_confirm_delete_title', { name: itemMainContent?.name })}
          titleAction={t('common_btn_delete')}
        />
      )}
    </div>
  );
};

export default MainContentTab;

// Libs
// Components, Layouts, Pages
// Others
import { ZINC800 } from '~/utils/constants/color';
// Styles, images, icons

type Props = {
  strokePath?: string;
  width?: number | string;
  height?: number | string;
};

const CommonIconPlus = (props: Props) => {
  //#region Destructuring Props
  const { strokePath = ZINC800, width = 24, height = 24 } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.49984 8.49984H3.6665V7.49984H7.49984V3.6665H8.49984V7.49984H12.3332V8.49984H8.49984V12.3332H7.49984V8.49984Z'
        fill={strokePath}
      />
    </svg>
  );
};

export default CommonIconPlus;

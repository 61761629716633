import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { IFormBill } from '~/utils/interface/bill';

export const billSchema = (payload: { t: TFunction; paymentTermDueDate?: string }) => {
  const { t, paymentTermDueDate } = payload;

  return yup
    .object()
    .shape({
      type: yup.string().required(t('form_bill_validate_require_type_bill')),
      purchaseOrderId: yup.string().required(t('form_bill_validate_require_purchase_order')),
      dueDate: yup
        .string()
        .optional()
        .test('is-endDate-valid', t('form_bill_schedule_end_date_invalid'), function (value) {
          const { invoiceDate } = this.parent;
          return !value || !invoiceDate || new Date(value) >= new Date(invoiceDate);
        })
        .test(
          'is-due-date-later-than-paymentTerm',
          t('form_bill_due_date_should_be_later_than_payment_term'),
          function (value) {
            const { invoiceDate } = this.parent;
            const paymentTermDate = moment(invoiceDate)
              .add(Number(paymentTermDueDate ?? DEFAULT_NUMBER_ZERO), 'days')
              .format('YYYY-MM-DD');

            return !value || !moment(value).isBefore(paymentTermDate);
          }
        ),
    })
    .required();
};

export const initialValues: IFormBill = {
  type: EMPTY_STRING,
  purchaseOrderId: EMPTY_STRING,
  invoiceNumber: null,
  invoiceDate: EMPTY_STRING,
  dueDate: EMPTY_STRING,
  quickBookTermId: EMPTY_STRING,
};

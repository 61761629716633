// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import productApi from '~/apis/product';
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_DETAIL_PRODUCT,
  GET_LIST_PRODUCT,
  GET_LIST_PRODUCT_BY_VENDOR,
  SEND_TO_QUICK_BOCKS_PRODUCT,
  UPDATE_PRODUCT,
} from '~/utils/constants/actionType';
import { IFormProductPayload, IQueryListProduct, IUpdateProduct } from '~/utils/interface/product';

export const getListProducts = createAsyncThunk(
  GET_LIST_PRODUCT,
  async (payload: IQueryListProduct, { rejectWithValue }) => {
    try {
      const res = await productApi.getListProducts(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProduct = createAsyncThunk(
  CREATE_PRODUCT,
  async (body: IFormProductPayload, { rejectWithValue }) => {
    try {
      const res = await productApi.createProduct(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  UPDATE_PRODUCT,
  async (payload: IUpdateProduct, { rejectWithValue }) => {
    try {
      const res = await productApi.updateProduct(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  DELETE_PRODUCT,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await productApi.deleteProduct(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailProduct = createAsyncThunk(
  GET_DETAIL_PRODUCT,
  async (productId: string, { rejectWithValue }) => {
    try {
      const res = await productApi.getDetailProduct(productId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListProductsByVendor = createAsyncThunk(
  GET_LIST_PRODUCT_BY_VENDOR,
  async (
    { vendorId, payload }: { vendorId: string; payload: IQueryListProduct },
    { rejectWithValue }
  ) => {
    try {
      const res = await productApi.getListProductsByVendor(vendorId, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendToQuickBocksProduct = createAsyncThunk(
  SEND_TO_QUICK_BOCKS_PRODUCT,
  async (clientId: string, { rejectWithValue }) => {
    try {
      const res = await productApi.sendToQuickBocksProduct(clientId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  publicRoute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
import packageJson from '../../../package.json';
// Styles, images, icons
import styles from './Auth.module.scss';
import { images } from '~/assets';

type AuthProps = {};

const cx = classNames.bind(styles);

const Auth = (props: AuthProps) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const location = useLocation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [accessToken] = useState<string | null>(
    localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null
  );
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleNavigateByRole = () => {
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return <Navigate to={`${adminRouteAbsolute.dashboard}`} />;
      case AccountRoleCodesEnum.STAFF:
        return <Navigate to={`${staffRouteAbsolute.dashboard}`} />;
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return <Navigate to={`${projectManageRouteAbsolute.dashboard}`} />;
      default:
        localStorage.clear();
        break;
    }
  };
  //#endregion Handle Function

  return accessToken ? (
    <>{handleNavigateByRole()}</>
  ) : (
    <div id='authLayout' className={cx('container')}>
      <section className={cx('logoSection')}>
        <div className={cx('authBackgroundImage')}></div>
      </section>

      <div className={cx('bodyAuthWrap')}>
        <div
          className={cx(
            'body',
            location.pathname === `${publicRoute.auth}/${publicRoute.createPasswordSuccess}` &&
              'bodySuccess'
          )}
        >
          <div className={cx('logo')}>
            <img
              className={cx('images')}
              src={images.commonImageLogoTextBlack}
              alt={t('common_img_text_alt')}
            />
          </div>

          <div className={cx('content')}>
            <Outlet />
          </div>
        </div>
        <div className={cx('textVersion')}>{packageJson.version}</div>
      </div>
    </div>
  );
};

export default Auth;

// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createProduct,
  deleteProduct,
  getDetailProduct,
  getListProducts,
  getListProductsByVendor,
  sendToQuickBocksProduct,
  updateProduct,
} from './productThunk';
import { RootState } from '~/redux/store';

export interface ProductState {
  isRefreshProductList: boolean;
  isRefreshProductByVendorList: boolean;
}

const initialState: ProductState = {
  isRefreshProductList: false,
  isRefreshProductByVendorList: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshProductList = action.payload;
    },
    setRefreshByVendorList(state, action) {
      state.isRefreshProductByVendorList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list product
    builder
      .addCase(getListProducts.pending, (state) => {})
      .addCase(getListProducts.fulfilled, (state) => {})
      .addCase(getListProducts.rejected, (state) => {});

    // Create product
    builder
      .addCase(createProduct.pending, (state) => {})
      .addCase(createProduct.fulfilled, (state) => {})
      .addCase(createProduct.rejected, (state) => {});

    // Update product
    builder
      .addCase(updateProduct.pending, (state) => {})
      .addCase(updateProduct.fulfilled, (state) => {})
      .addCase(updateProduct.rejected, (state) => {});

    // Delete product
    builder
      .addCase(deleteProduct.pending, (state) => {})
      .addCase(deleteProduct.fulfilled, (state) => {})
      .addCase(deleteProduct.rejected, (state) => {});

    // Detail product
    builder
      .addCase(getDetailProduct.pending, (state) => {})
      .addCase(getDetailProduct.fulfilled, (state) => {})
      .addCase(getDetailProduct.rejected, (state) => {});

    // Get list product by vendor
    builder
      .addCase(getListProductsByVendor.pending, (state) => {})
      .addCase(getListProductsByVendor.fulfilled, (state) => {})
      .addCase(getListProductsByVendor.rejected, (state) => {});

    // Send to quick bocks
    builder
      .addCase(sendToQuickBocksProduct.pending, (state) => {})
      .addCase(sendToQuickBocksProduct.fulfilled, (state) => {})
      .addCase(sendToQuickBocksProduct.rejected, (state) => {});
  },
});

// Action
export const productActions = productSlice.actions;

// Selectors
export const selectIsRefreshProductList = (state: RootState) => state.product.isRefreshProductList;
export const selectIsRefreshProductByVendorList = (state: RootState) =>
  state.product.isRefreshProductByVendorList;

// Reducer
const productReducer = productSlice.reducer;
export default productReducer;

// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelect, FormInput, ProductSelect } from '~/components';
// Others
import { useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_INPUT_HEIGHT } from '~/utils/constants/component';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBaseOption } from '~/utils/interface/common';
import { IProposal } from '~/utils/interface/proposal';
import { IProductOptionForJob } from '~/utils/interface/product';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldBudgetProductProposal.module.scss';

type IBudgetProductProposalType = 'materials' | 'miscellanea' | 'equipments';

type Props = {
  type: IBudgetProductProposalType;
  lineItemIndex: number;
  fieldIndex: number;
  removeField: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldBudgetProductProposal = (props: Props) => {
  //#region Destructuring Props
  const { lineItemIndex, fieldIndex, type, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<IProposal>();
  //#endregion Declare Hook

  //#region Selector
  const { productMaterials, fixtureLocations, productEquipment, productMiscellanea } =
    useAppSelector((state: RootState) => state.proposal);
  //#endregion Selector

  //#region Declare Form Watch
  const productSelected = useWatch({
    control,
    name: `lineItems.${lineItemIndex}.${type}.${fieldIndex}.productIdSelected`,
  });
  const quantity = useWatch({
    control,
    name: `lineItems.${lineItemIndex}.${type}.${fieldIndex}.quantity`,
  });
  //#endregion Form Watch

  //#region Declare Memo
  const dataProduct = useMemo(() => {
    switch (type) {
      case 'materials':
        return productMaterials;
      case 'equipments':
        return productEquipment;
      case 'miscellanea':
        return productMiscellanea;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, productMaterials, productEquipment, productMiscellanea]);
  const unitCost = useMemo(() => {
    const findUnitCost = dataProduct?.find((item) => item.value === productSelected)?.unitCost;
    return Number(findUnitCost);
  }, [productSelected, dataProduct]);

  const subTotal = useMemo(() => unitCost * (quantity ?? 0), [unitCost, quantity]);
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setValue(
      `lineItems.${lineItemIndex}.${type}.${fieldIndex}.unitCost`,
      unitCost || DEFAULT_NUMBER_ZERO
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitCost]);

  useEffect(() => {
    setValue(
      `lineItems.${lineItemIndex}.${type}.${fieldIndex}.subTotal`,
      subTotal || DEFAULT_NUMBER_ZERO
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldBudgetProductProposalComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className='col-span-3'>
          <Controller
            name={`lineItems.${lineItemIndex}.${type}.${fieldIndex}.productIdSelected`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ProductSelect
                name={`lineItems.${lineItemIndex}.${type}.${fieldIndex}.productIdSelected`}
                label={t('template_form_phase_content_category_field_item')}
                placeholder={t('common_placeholder_select')}
                options={dataProduct ?? []}
                value={value?.toString() || EMPTY_STRING}
                onChange={(data: IProductOptionForJob) => {
                  setValue(
                    `lineItems.${lineItemIndex}.${type}.${fieldIndex}.productName`,
                    data.productName || EMPTY_STRING
                  );
                  setValue(
                    `lineItems.${lineItemIndex}.${type}.${fieldIndex}.productId`,
                    data.productId
                  );
                  setValue(
                    `lineItems.${lineItemIndex}.${type}.${fieldIndex}.vendorId`,
                    data.vendorId
                  );
                  onChange(data.value);
                }}
              />
            )}
          />
        </div>
        <div className='col-span-2'>
          <Controller
            name={`lineItems.${lineItemIndex}.${type}.${fieldIndex}.fixtureLocationName`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                name={`lineItems.${lineItemIndex}.${type}.${fieldIndex}.fixtureLocationName`}
                label={t('template_form_phase_content_category_field_location')}
                placeholder={t('common_placeholder_select')}
                options={fixtureLocations ?? []}
                value={value || EMPTY_STRING}
                onChange={(data: IBaseOption) => onChange(data.value)}
              />
            )}
          />
        </div>
        <Controller
          name={`lineItems.${lineItemIndex}.${type}.${fieldIndex}.quantity`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('template_form_phase_content_category_field_qty')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              type='number'
            />
          )}
        />

        <FormInput
          label={t('template_form_phase_content_category_field_unit_cost')}
          disabled={true}
          value={formatCurrency(CurrencyEnum.USD, unitCost ?? 0)}
        />

        <div className={'readOnlyField'}>
          <div className={cx('label')}>
            {t('template_form_phase_content_category_field_subtotal')}
          </div>
          <div className={cx('value')} style={{ height: DEFAULT_INPUT_HEIGHT }}>
            {formatCurrency(CurrencyEnum.USD, subTotal ?? 0)}
          </div>
        </div>
      </div>

      <button className={cx('buttonClose')} type='button' onClick={() => removeField(fieldIndex)}>
        <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
      </button>
    </div>
  );
};

export default FieldBudgetProductProposal;

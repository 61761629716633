// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons

type Props = {};

const PurchaseOrderShipTo = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.mainShipTo}>
      <View style={styles.headerBg}>
        <Text style={styles.textHeader}>Ship To</Text>
      </View>

      <View style={styles.body}>
        <Text>Ancla Plumbing, LLC</Text>
      </View>
    </View>
  );
};

export default PurchaseOrderShipTo;

export const styles = StyleSheet.create({
  mainShipTo: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#333333',
  },

  headerBg: {
    backgroundColor: '#333333',
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },

  textHeader: {
    color: 'white',
  },

  body: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
});

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
// Components, Layouts, Pages
import { BaseButton, SearchDropdown } from '~/components';
// Others
import { settingIncomeDefaultSchema } from './helper';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import {
  getListAccountQuickBook,
  updateAccountIncomeSetting,
} from '~/thunks/quickBook/quickBookThunk';
import { DEFAULT_NUMBER_OPTIONS_SELECT, typeDefaultPaymentAccount } from '~/utils/constants/common';
import { CommonIconPlus } from '~/assets/svgComponents';
import { CYAN600, WHITE } from '~/utils/constants/color';
import { ButtonTypeEnum } from '~/utils/enum';
import { ISettingIncomeDefault } from '~/utils/interface/incomeDefault';
// Styles, images, icons
import styles from './SettingIncomeDefault.module.scss';
import { icons } from '~/assets';

type Props = {
  incomeSettingDefault: ISettingIncomeDefault;
  onApplySetting: () => void;
};

const cx = classNames.bind(styles);

const SettingIncomeDefault = (props: Props) => {
  //#region Destructuring Props
  const { incomeSettingDefault, onApplySetting } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ISettingIncomeDefault>({
    resolver: yupResolver(settingIncomeDefaultSchema({ t })),
    defaultValues: incomeSettingDefault,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!incomeSettingDefault.quickBookIncomePaymentAccountId) {
      return;
    }

    reset(incomeSettingDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeSettingDefault.quickBookIncomePaymentAccountId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitSetting = (data: ISettingIncomeDefault, closeFn: Function) => {
    loading?.show();
    dispatch(updateAccountIncomeSetting(data))
      .unwrap()
      .then((_res) => {
        reset();
        closeFn();
        onApplySetting();
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const fetchOptionsListDefaultPaymentAccount = useCallback(
    async (search: string, page: number) => {
      try {
        const listDefaultIncomeAccount = await dispatch(
          getListAccountQuickBook({
            page: page,
            limit: DEFAULT_NUMBER_OPTIONS_SELECT,
            type: typeDefaultPaymentAccount,
            ...(search ? { searchKey: search } : {}),
          })
        ).unwrap();

        return {
          items: listDefaultIncomeAccount?.data?.responses,
          hasMore:
            listDefaultIncomeAccount?.data?.pagination.page <
              listDefaultIncomeAccount?.data?.pagination?.totalPages || false,
        };
      } catch (error) {
        return {
          items: [],
          hasMore: false,
        };
      }
    },
    []
  );

  const handleClose = (closeFn: Function) => {
    reset();
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='settingPopupComponent'>
      <Popover className='relative'>
        <PopoverButton type='button' className={cx('btnGroup')}>
          <img
            src={icons.commonIconSettingDropdownProfile}
            alt={t('common_img_text_alt')}
            className={cx('btnImg')}
          />
        </PopoverButton>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '10px' }}
          className={cx('popupFilterContainer')}
        >
          {({ close }) => {
            return (
              <form onSubmit={handleSubmit((data) => handleSubmitSetting(data, close))}>
                <h3 className={cx('popTitle')}>{t('common_btn_setting')}</h3>

                <div className={cx('popContent')}>
                  <div className={cx('contentFilterWrap')}>
                    <Controller
                      name='quickBookIncomePaymentAccountId'
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <SearchDropdown
                            fetchOptions={fetchOptionsListDefaultPaymentAccount}
                            placeholder={t('common_placeholder_select')}
                            label={t('income_default_tab_default_payment_account_label')}
                            value={value}
                            width={250}
                            onChange={(item) => onChange(item?.id)}
                            renderLabel={(item) => {
                              return <>{item.name}</>;
                            }}
                            optionsWidth={250}
                            renderOption={(item, selectedValue) => {
                              return (
                                <div className={cx('optionSelect')}>
                                  <div className={cx('name')}>{item.name}</div>

                                  <CommonIconPlus
                                    width={16}
                                    height={16}
                                    strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                  />
                                </div>
                              );
                            }}
                            getOptionKey={(item) => {
                              return item.id;
                            }}
                            isRequired
                            errorMessage={errors.quickBookIncomePaymentAccountId?.message}
                            isLoading
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className={cx('popBtnWrap')}>
                  <div className={cx('popBtnGroup')}>
                    <BaseButton
                      label={t('common_btn_cancel')}
                      type='button'
                      onClick={() => handleClose(close)}
                      typeStyle={ButtonTypeEnum.CANCEL}
                      minWidth={50}
                    />

                    <BaseButton
                      label={t('common_btn_apply')}
                      type='submit'
                      typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                      minWidth={50}
                      isDisable={!isDirty}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default SettingIncomeDefault;

// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createTask,
  getTaskList,
  deleteTask,
  getTaskDetails,
  updateTask,
  getTaskListTemplate,
} from './taskThunk';

export interface EmployeeState {
  isRefreshTaskList?: boolean;
}

const initialState: EmployeeState = {
  isRefreshTaskList: false,
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setRefreshTaskList(state, action) {
      state.isRefreshTaskList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get task list
    builder
      .addCase(getTaskList.pending, (state) => {})
      .addCase(getTaskList.fulfilled, (state, action) => {})
      .addCase(getTaskList.rejected, (state) => {});

    // Get task list template
    builder
      .addCase(getTaskListTemplate.pending, (state) => {})
      .addCase(getTaskListTemplate.fulfilled, (state, action) => {})
      .addCase(getTaskListTemplate.rejected, (state) => {});

    // Create task
    builder
      .addCase(createTask.pending, (state) => {})
      .addCase(createTask.fulfilled, (state, action) => {})
      .addCase(createTask.rejected, (state) => {});

    // Delete task
    builder
      .addCase(deleteTask.pending, (state) => {})
      .addCase(deleteTask.fulfilled, (state, action) => {})
      .addCase(deleteTask.rejected, (state) => {});

    // Get task details
    builder
      .addCase(getTaskDetails.pending, (state) => {})
      .addCase(getTaskDetails.fulfilled, (state, action) => {})
      .addCase(getTaskDetails.rejected, (state) => {});

    // Update task
    builder
      .addCase(updateTask.pending, (state) => {})
      .addCase(updateTask.fulfilled, (state, action) => {})
      .addCase(updateTask.rejected, (state) => {});
  },
});

export const taskActions = taskSlice.actions;

const taskReducer = taskSlice.reducer;
export default taskReducer;

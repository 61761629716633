// Others
import { INavTabItem } from '~/utils/interface/common';
import {
  AccountantTabsEnum,
  DetailBillTabsEnum,
  DetailProductTabEnum,
  DetailVendorTabsEnum,
  ManageJobsTabsEnum,
  ManageTermsAndConditionsTabEnum,
  ServiceJobTabsEnum,
} from '~/utils/enum';

// Manage jobs tabs
export const manageJobsTabs: INavTabItem[] = [
  {
    name: 'admin_manage_jobs_tabs_summary',
    params: ManageJobsTabsEnum.SUMMARY,
  },
  {
    name: 'admin_manage_jobs_tabs_estimate',
    params: ManageJobsTabsEnum.ESTIMATE,
  },
  {
    name: 'admin_manage_jobs_tabs_proposal',
    params: ManageJobsTabsEnum.PROPOSAL,
  },
  {
    name: 'admin_manage_jobs_tabs_documents',
    params: ManageJobsTabsEnum.DOCUMENTS,
  },
  {
    name: 'admin_manage_jobs_tabs_pos',
    params: ManageJobsTabsEnum.PURCHASE_ORDER,
  },
  {
    name: 'template_service_job_detail_tab_logs',
    params: ManageJobsTabsEnum.LOGS,
  },
];

// Manage Service job tabs
export const serviceJobsTabs: INavTabItem[] = [
  {
    name: 'template_service_job_detail_tab_summary',
    params: ServiceJobTabsEnum.SUMMARY,
  },
  {
    name: 'template_service_job_detail_tab_documents',
    params: ServiceJobTabsEnum.DOCUMENTS,
  },
  {
    name: 'template_service_job_detail_tab_logs',
    params: ServiceJobTabsEnum.LOGS,
  },
];

// Manage terms and conditions tabs
export const manageTermsAndConditionsTabs: INavTabItem[] = [
  {
    name: 'terms_and_conditions_tab_main_content',
    params: ManageTermsAndConditionsTabEnum.MAIN_CONTENT,
  },
  {
    name: 'terms_and_conditions_tab_inclusion_and_exclusion_items',
    params: ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS,
  },
];

// Manage vendor tabs
export const vendorDetailTab: INavTabItem[] = [
  {
    name: 'template_vendor_detail_tabs_basic_information',
    params: DetailVendorTabsEnum.BASIC_INFORMATIONAL,
  },
  {
    name: 'template_vendor_detail_tabs_products',
    params: DetailVendorTabsEnum.PRODUCTS,
  },
  {
    name: 'template_vendor_detail_tabs_documents',
    params: DetailVendorTabsEnum.DOCUMENTS,
  },
];

// Product Tabs
export const productDetailTab: INavTabItem[] = [
  {
    name: 'template_product_detail_tabs_basic_information',
    params: DetailProductTabEnum.BASIC_INFORMATIONAL,
  },
  {
    name: 'template_product_detail_tabs_vendors',
    params: DetailProductTabEnum.VENDORS,
  },
];

// Manage vendor tabs
export const billDetailTab: INavTabItem[] = [
  {
    name: 'template_bill_detail_tabs_basic_information',
    params: DetailBillTabsEnum.BASIC_INFORMATIONAL,
  },
  {
    name: 'template_bill_detail_tabs_documents',
    params: DetailBillTabsEnum.DOCUMENTS,
  },
];

// Manage accountant tabs
export const accountantTabs: INavTabItem[] = [
  {
    name: 'template_accountant_tabs_expenses_defaults',
    params: AccountantTabsEnum.EXPENSES_DEFAULTS,
  },
  {
    name: 'template_accountant_tabs_income_defaults',
    params: AccountantTabsEnum.INCOME_DEFAULTS,
  },
];

// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createEstimate,
  deleteEstimate,
  getDetailEstimate,
  getDetailEstimateByJob,
  getEstimateList,
  getEstimateTemplateList,
  getOptionFixtureLocations,
  getOptionProductByBudget,
  updateEstimate,
} from './estimateThunk';
import { IBaseOption } from '~/utils/interface/common';
import { TypeProductEnum } from '~/utils/enum';
import { IProductForJob, IProductOptionForJob } from '~/utils/interface/product';
import { formatProductIdSelected, formatProductName } from '~/utils/helper';

export interface EstimateState {
  isRefreshEstimateList: boolean;
  isRefreshEstimateDetail: boolean;
  productMaterials?: IProductOptionForJob[];
  productEquipment?: IProductOptionForJob[];
  productMiscellanea?: IProductOptionForJob[];
  fixtureLocations?: IBaseOption[];
}

const initialState: EstimateState = {
  isRefreshEstimateList: false,
  isRefreshEstimateDetail: false,
};

const estimateSlice = createSlice({
  name: 'estimate',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshEstimateList = action.payload;
    },
    setRefreshDetail(state, action) {
      state.isRefreshEstimateDetail = action.payload;
    },
  },
  extraReducers(builder) {
    // Get estimate list
    builder
      .addCase(getEstimateList.pending, (state) => {})
      .addCase(getEstimateList.fulfilled, (state) => {})
      .addCase(getEstimateList.rejected, (state) => {});

    // Create estimate
    builder
      .addCase(createEstimate.pending, (state) => {})
      .addCase(createEstimate.fulfilled, (state) => {})
      .addCase(createEstimate.rejected, (state) => {});

    // Update estimate
    builder
      .addCase(updateEstimate.pending, (state) => {})
      .addCase(updateEstimate.fulfilled, (state) => {})
      .addCase(updateEstimate.rejected, (state) => {});

    // Delete estimate
    builder
      .addCase(deleteEstimate.pending, (state) => {})
      .addCase(deleteEstimate.fulfilled, (state) => {})
      .addCase(deleteEstimate.rejected, (state) => {});

    // Get detail estimate
    builder
      .addCase(getDetailEstimate.pending, (state) => {})
      .addCase(getDetailEstimate.fulfilled, (state) => {})
      .addCase(getDetailEstimate.rejected, (state) => {});

    // Get detail estimate by job
    builder
      .addCase(getDetailEstimateByJob.pending, (state) => {})
      .addCase(getDetailEstimateByJob.fulfilled, (state) => {})
      .addCase(getDetailEstimateByJob.rejected, (state) => {});

    // Get estimate template list
    builder
      .addCase(getEstimateTemplateList.pending, (state) => {})
      .addCase(getEstimateTemplateList.fulfilled, (state) => {})
      .addCase(getEstimateTemplateList.rejected, (state) => {});

    //** Helper for flow estimate **//
    // Get options product by budget
    builder
      .addCase(getOptionProductByBudget.pending, (state) => {})
      .addCase(getOptionProductByBudget.fulfilled, (state, action) => {
        const { data, type } = action.payload;
        const productOptionList: IProductOptionForJob[] = data.responses.map(
          (item: IProductForJob) => ({
            label: formatProductName(item.productName, item.vendorName, item.unitCost),
            value: formatProductIdSelected(item.productId, item.vendorId),
            unitCost: item.unitCost,
            productId: item.productId,
            productName: item.productName,
            vendorName: item.vendorName,
            vendorId: item.vendorId,
          })
        );

        switch (type) {
          case TypeProductEnum.MATERIAL_SUPPLIER:
            state.productMaterials = productOptionList;
            break;
          case TypeProductEnum.EQUIPMENT_SUPPLIER:
            state.productEquipment = productOptionList;
            break;
          case TypeProductEnum.MISCELLANEOUS_EXPENSES:
            state.productMiscellanea = productOptionList;
            break;
        }
      })
      .addCase(getOptionProductByBudget.rejected, (state) => {});

    // Get option product by budget
    builder
      .addCase(getOptionFixtureLocations.pending, (state) => {})
      .addCase(getOptionFixtureLocations.fulfilled, (state, action) => {
        const dataRes = action.payload.responses;
        const fixtureLocationOptionList: IBaseOption[] = dataRes.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        state.fixtureLocations = fixtureLocationOptionList;
      })
      .addCase(getOptionFixtureLocations.rejected, (state) => {});
  },
});

// Action
export const estimateActions = estimateSlice.actions;

// Reducer
const estimateReducer = estimateSlice.reducer;
export default estimateReducer;

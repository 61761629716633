// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelect, FormInput, ProductSelect } from '~/components';
// Others
import { useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_INPUT_HEIGHT } from '~/utils/constants/component';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBaseOption } from '~/utils/interface/common';
import { IFormServiceJob } from '~/utils/interface/job';
import { IProductOptionForJob } from '~/utils/interface/product';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldMaterialServiceJob.module.scss';

type Props = {
  fieldIndex: number;
  removeField: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldMaterialServiceJob = (props: Props) => {
  //#region Destructuring Props
  const { fieldIndex, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<IFormServiceJob>();
  //#endregion Declare Hook

  //#region Selector
  const { productMaterials, fixtureLocations } = useAppSelector((state: RootState) => state.job);
  //#endregion Selector

  //#region Declare Form Watch
  const productSelected = useWatch({ control, name: `materials.${fieldIndex}.productIdSelected` });
  const quantity = useWatch({ control, name: `materials.${fieldIndex}.quantity` });
  //#endregion Form Watch

  //#region Declare Memo
  const unitCost = useMemo(() => {
    const findUnitCost = productMaterials?.find((item) => item.value === productSelected)?.unitCost;

    return Number(findUnitCost);
  }, [productSelected, productMaterials]);
  const subTotal = useMemo(() => unitCost * (quantity ?? 0), [unitCost, quantity]);
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setValue(`materials.${fieldIndex}.unitCost`, unitCost || 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected, productMaterials]);

  useEffect(() => {
    setValue(`materials.${fieldIndex}.subTotal`, subTotal || 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSelected, quantity, productMaterials]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldMaterialServiceJobComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className='col-span-3'>
          <Controller
            name={`materials.${fieldIndex}.productIdSelected`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ProductSelect
                name={`materials.${fieldIndex}..productIdSelected`}
                label={t('template_form_phase_content_category_field_item')}
                placeholder={t('common_placeholder_select')}
                options={productMaterials ?? []}
                value={value?.toString() || EMPTY_STRING}
                onChange={(data: IProductOptionForJob) => {
                  setValue(`materials.${fieldIndex}.productName`, data.productName || EMPTY_STRING);
                  setValue(`materials.${fieldIndex}.productId`, data.productId);
                  setValue(`materials.${fieldIndex}.vendorId`, data.vendorId);
                  onChange(data.value);
                }}
              />
            )}
          />
        </div>

        <div className='col-span-2'>
          <Controller
            name={`materials.${fieldIndex}.fixtureLocationId`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                name={`materials.${fieldIndex}.fixtureLocationId`}
                label={t('common_form_service_field_material_location')}
                placeholder={t('common_placeholder_select')}
                options={fixtureLocations ?? []}
                value={value?.toString() || EMPTY_STRING}
                onChange={(data: IBaseOption) => {
                  setValue(`materials.${fieldIndex}.fixtureLocationName`, data.label);
                  const fixtureLocationId = +data.value;
                  onChange(fixtureLocationId);
                }}
              />
            )}
          />
        </div>

        <Controller
          name={`materials.${fieldIndex}.quantity`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('common_form_service_field_material_qty')}
              value={value || EMPTY_STRING}
              onChange={onChange}
            />
          )}
        />

        <FormInput
          label={t('common_form_service_field_material_unit_cost')}
          disabled={true}
          value={formatCurrency(CurrencyEnum.USD, unitCost ?? 0)}
        />

        <div className={'readOnlyField'}>
          <div className={cx('label')}>{t('common_form_service_field_material_subtotal')}</div>
          <div className={cx('value')} style={{ height: DEFAULT_INPUT_HEIGHT }}>
            {formatCurrency(CurrencyEnum.USD, subTotal ?? 0)}
          </div>
        </div>
      </div>

      <div className={cx('btnRemoveContainer')}>
        <button
          className={cx('btnRemoveField')}
          type='button'
          onClick={() => removeField(fieldIndex)}
        >
          <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
        </button>
      </div>
    </div>
  );
};

export default FieldMaterialServiceJob;

// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
import { IPurchaseOrderData } from '~/utils/interface/purchaseOrder';
import { getFullAddress } from '~/utils/helper';
// Styles, images, icons

type Props = {
  data: IPurchaseOrderData;
};

const PurchaseOrderBillTo = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.mainBillTo}>
      <View style={styles.headerBg}>
        <Text style={styles.textHeader}>Bill To</Text>
      </View>

      <View style={styles.body}>
        <Text>{data?.vendor?.name}</Text>
        <Text>
          {getFullAddress({
            ...data?.vendor,
          })}
        </Text>
      </View>
    </View>
  );
};

export default PurchaseOrderBillTo;

export const styles = StyleSheet.create({
  mainBillTo: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#333333',
  },

  headerBg: {
    backgroundColor: '#333333',
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },

  textHeader: {
    color: 'white',
  },

  body: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
});

// Libs
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { publicRoute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './LogoutModal.module.scss';

type Props = {
  onClose: () => void;
};

const cx = classNames.bind(styles);

const LogoutModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = publicRoute.auth;
  };
  //#endregion Handle Function

  return (
    <div id='logoutModalComponent' className={cx('modalContainer')}>
      <div className={cx('modalContent')}>
        <div className={cx('modalHead')}>
          <div className={cx('titleHead')}>{t('admin_sidebar_logout_confirm_content')}</div>
        </div>
        <div className={cx('modalBody')}>
          <BaseButton
            onClick={onClose}
            width={117}
            height={32}
            label={t('common_btn_cancel')}
            typeStyle={ButtonTypeEnum.CANCEL}
          />

          <BaseButton
            onClick={handleLogout}
            width={117}
            height={32}
            label={t('common_btn_yes')}
            typeStyle={ButtonTypeEnum.RED_SYSTEM}
          />
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;

// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getBillDetail, getListBill, createBill, recordPaymentBill } from '~/thunks/bill/billThunk';

export interface BillState {
  isRefreshBillList: boolean;
}

const initialState: BillState = {
  isRefreshBillList: false,
};

const billSlice = createSlice({
  name: 'bill',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshBillList = action.payload;
    },
  },
  extraReducers(builder) {
    // Create purchase order
    builder
      .addCase(createBill.pending, (state) => {})
      .addCase(createBill.fulfilled, (state) => {})
      .addCase(createBill.rejected, (state) => {});

    // Get list bill
    builder
      .addCase(getListBill.pending, (state) => {})
      .addCase(getListBill.fulfilled, (state) => {})
      .addCase(getListBill.rejected, (state) => {});

    // Get bill detail
    builder
      .addCase(getBillDetail.pending, (state) => {})
      .addCase(getBillDetail.fulfilled, (state) => {})
      .addCase(getBillDetail.rejected, (state) => {});

    // Record Payment Bill
    builder
      .addCase(recordPaymentBill.pending, (state) => {})
      .addCase(recordPaymentBill.fulfilled, (state) => {})
      .addCase(recordPaymentBill.rejected, (state) => {});
  },
});

// Action
export const billActions = billSlice.actions;

// Selectors
export const selectIsRefreshBillList = (state: RootState) => state.billState.isRefreshBillList;

// Reducer
const billReducer = billSlice.reducer;
export default billReducer;

import { useState, useMemo } from 'react';
import { IRowSelection } from '~/utils/interface/common';

export const useRowSelection = <T extends { id: React.Key }>(
  dataSource: T[],
  rowSelection?: IRowSelection<T>,
  visibleData: T[] = []
) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const handleSelectRow = (key: React.Key, selected: boolean) => {
    const newSelectedRowKeys = selected
      ? [...selectedRowKeys, key]
      : selectedRowKeys.filter((k) => k !== key);

    const updatedSelectedRows = selected
      ? [...selectedRows, ...dataSource.filter((data) => data.id === key)]
      : selectedRows.filter((row) => row.id !== key);

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(updatedSelectedRows);

    if (rowSelection) {
      rowSelection.onChange(newSelectedRowKeys, updatedSelectedRows);
    }
  };

  const handleSelectAllRows = (selected: boolean) => {
    const visibleRowKeys = visibleData.map((data) => data.id);
    const newSelectedRowKeys = selected
      ? [...new Set([...selectedRowKeys, ...visibleRowKeys])]
      : selectedRowKeys.filter((key) => !visibleRowKeys.includes(key));

    const updatedSelectedRows = selected
      ? [...selectedRows, ...visibleData.filter((data) => !selectedRowKeys.includes(data.id))]
      : selectedRows.filter((row) => !visibleRowKeys.includes(row.id));

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(updatedSelectedRows);

    if (rowSelection) {
      rowSelection.onChange(newSelectedRowKeys, updatedSelectedRows);
    }
  };

  const isAllRowsSelected = useMemo(
    () => visibleData.length > 0 && visibleData.every((data) => selectedRowKeys.includes(data.id)),
    [visibleData, selectedRowKeys]
  );

  return {
    selectedRowKeys,
    selectedRows,
    handleSelectRow,
    handleSelectAllRows,
    isAllRowsSelected,
  };
};

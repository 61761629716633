// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AddTermsAndConditionsModal,
  AdminFormInclusionAndExclusion,
  BaseCheckbox,
  BaseFilter,
  BaseSelect,
  ModalUnderDevelopment,
  NavTab,
  Toolbar,
} from '~/components';
import { InclusionAndExclusionItemsTabTemplate, MainContentTabTemplate } from '~/pages';
// Others
import {
  AccountRoleCodesEnum,
  ManageTermsAndConditionsTabEnum,
  SearchParamsEnum,
  StorageEnum,
  TypeFilterTermConditionEnum,
} from '~/utils/enum';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { manageTermsAndConditionsTabs } from '~/utils/constants/tab';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_FILTER_TERM_CONDITION,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsTypeFilterTerm,
} from '~/utils/constants/common';
import useDebounce from '~/utils/hooks/useDebounce';
import { IFilterMainContent } from '~/utils/interface/termsAndConditions';
// Styles, images, icons
import styles from './TermsAndConditions.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TermsAndConditions = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const currentTab = searchParams.get(SearchParamsEnum.TAB);
  const [isShowDevelopment, setIsShowDevelopment] = useState<boolean>(false);
  const [isShowFormAdd, setIsShowFormAdd] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (params?.tab) return;

    setSearchParams({
      tab: ManageTermsAndConditionsTabEnum.MAIN_CONTENT,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.tab]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!currentTab) return;

    const { searchKey, ...remainingParams } = params;

    if (debouncedSearchKey === searchKey) return;

    setSearchParams({
      ...remainingParams,
      ...(debouncedSearchKey
        ? { searchKey: debouncedSearchKey, page: DEFAULT_CURRENT_PAGE.toString() }
        : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAdd = () => {
    if (!currentTab) return;

    switch (currentTab) {
      case ManageTermsAndConditionsTabEnum.MAIN_CONTENT:
        const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;

        const routes = {
          [AccountRoleCodesEnum.ADMIN]: adminRouteAbsolute.createMainContent,
          [AccountRoleCodesEnum.STAFF]: staffRouteAbsolute.createMainContent,
          [AccountRoleCodesEnum.PROJECT_MANAGER]: projectManageRouteAbsolute.createMainContent,
        };

        if (role && routes[role as keyof typeof routes]) {
          navigate(routes[role as keyof typeof routes]);
        }
        break;

      case ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS:
        setIsShowFormAdd(!isShowFormAdd);
        break;
    }
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowDevelopment(!isShowDevelopment);
  };

  const renderTabContent = () => {
    if (!currentTab) return;

    switch (currentTab) {
      case ManageTermsAndConditionsTabEnum.MAIN_CONTENT:
        return <MainContentTabTemplate />;

      case ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS:
        return <InclusionAndExclusionItemsTabTemplate />;

      default:
        return <div className=''>{t('common_empty_data')}</div>;
    }
  };

  const handleShowModalFormAdd = () => {
    setIsShowFormAdd(!isShowFormAdd);
  };

  const handleSearchParams = (tab: string) => {
    setSearchParams({
      tab,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
    });

    setSearchKey(EMPTY_STRING);
  };

  const handleSearchTermAndConditions = (value: string) => {
    setSearchKey(value);
  };

  const handleApplyFilter = (valueFilter: IFilterMainContent) => {
    if (
      !valueFilter ||
      Object.keys(valueFilter).length === DEFAULT_NUMBER_ZERO ||
      valueFilter.type === TypeFilterTermConditionEnum.ALL
    ) {
      const { type, ...rest } = params;
      setSearchParams({
        ...rest,
        page: DEFAULT_NUMBER_ONE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      });
      return;
    }

    setSearchParams({
      ...params,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.type ? { type: valueFilter?.type } : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='termsAndConditionsPage' className={cx('container')}>
      <Toolbar
        title={t('terms_and_conditions_title')}
        primaryBtn={{ action: handleAdd, label: t('terms_and_conditions_btn_add') }}
        onSearch={handleSearchTermAndConditions}
        valueSearch={searchKey || EMPTY_STRING}
      >
        {params?.tab === ManageTermsAndConditionsTabEnum.MAIN_CONTENT && (
          <BaseFilter<IFilterMainContent>
            defaultValue={DEFAULT_FILTER_TERM_CONDITION}
            onApply={handleApplyFilter}
            valueFilter={{
              type:
                (params?.type as TypeFilterTermConditionEnum) || TypeFilterTermConditionEnum.ALL,
            }}
          >
            {({ valueFilter, onChange }) => {
              return (
                <div className={cx('contentFilterWrap')}>
                  <div className={cx('label')}>
                    {t('terms_and_conditions_tab_main_content_filter_type_label')}
                  </div>

                  <BaseSelect
                    name='type'
                    options={optionsTypeFilterTerm}
                    height={31}
                    value={valueFilter?.type}
                    placeholder={t('common_placeholder_select')}
                    onChange={({ value }, name) => {
                      onChange({
                        name: name as keyof IFilterMainContent,
                        value: value as TypeFilterTermConditionEnum,
                      });
                    }}
                  />
                </div>
              );
            }}
          </BaseFilter>
        )}
      </Toolbar>

      <div className={cx('body')}>
        <NavTab
          items={manageTermsAndConditionsTabs}
          searchParams={SearchParamsEnum.TAB}
          onClick={handleSearchParams}
        />

        <div className={cx('tabContent')}>{renderTabContent()}</div>
      </div>

      {isShowFormAdd && currentTab === ManageTermsAndConditionsTabEnum.MAIN_CONTENT && (
        <AddTermsAndConditionsModal onClose={handleShowModalFormAdd} />
      )}

      <AdminFormInclusionAndExclusion
        onClose={handleShowModalFormAdd}
        isOpen={
          isShowFormAdd &&
          currentTab === ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS
        }
      />

      {isShowDevelopment && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />}
    </div>
  );
};

export default TermsAndConditions;

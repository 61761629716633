// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { formattedTime } from '~/utils/helper';
// Components, Layouts, Pages
// Others
import { IPurchaseOrderData } from '~/utils/interface/purchaseOrder';
// Styles, images, icons

type Props = {
  dataPdf: IPurchaseOrderData;
};

const PurchaseOrderContact = (props: Props) => {
  //#region Destructuring Props
  const { dataPdf } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.mainContact}>
      <View style={styles.contactVendorLeft}>
        <Text>Contact:</Text>
        <View>
          <Text>{dataPdf?.emailSender?.name}</Text>
          <Text>{dataPdf?.emailSender?.email}</Text>
        </View>
      </View>

      <View style={styles.contactVendorRight}>
        <Text>P.O Number: {dataPdf?.orderNumber}</Text>
        <Text>Date Ordered: {formattedTime(new Date().toISOString())}</Text>
        <Text>Vendor: {dataPdf?.vendor?.name}</Text>
      </View>
    </View>
  );
};

export default PurchaseOrderContact;

export const styles = StyleSheet.create({
  mainContact: {
    display: 'flex',
    gap: 40,
    flexDirection: 'row',
  },

  contactVendorRight: {
    flex: 1,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  contactVendorLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
});

// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, memo, useEffect, useState } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { FormInput } from '~/components';
// Others
import { keyGoogleMap } from '~/utils/constants/env';
import { DEFAULT_GG_MAP_LOAD_SCRIPT_LIB, EMPTY_STRING } from '~/utils/constants/common';
import { IAddress } from '~/utils/interface/common';
import { TypeAddressEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './CompleteAddress.module.scss';

type Props = {
  label?: string;
  value?: string;
  onChange?: (value: IAddress) => void;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
};

const cx = classNames.bind(styles);

const CompleteAddress = (props: Props) => {
  //#region Destructuring Props
  const { label, value, onChange, errorMessage, required, disabled = false } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: keyGoogleMap ?? EMPTY_STRING,
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [autoCompleteAddress, setAutoCompleteAddress] = useState<google.maps.places.Autocomplete>();
  const [inputValue, setInputValue] = useState<string>(value || EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;

    setInputValue(value || EMPTY_STRING);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoCompleteAddress(autocomplete);
  };

  const onPlaceChanged = () => {
    if (!autoCompleteAddress) return;

    const place = autoCompleteAddress?.getPlace();

    const addressComponents = place.address_components || [];
    const getAddressComponent = (type: string) =>
      addressComponents.find((component) => component.types.includes(type))?.long_name || '';

    const fullAddress = place.formatted_address || '';
    const city = getAddressComponent(TypeAddressEnum.LOCALITY);
    const state = getAddressComponent(TypeAddressEnum.STATE);
    const zipCode = getAddressComponent(TypeAddressEnum.ZIP_CODE);
    const country = getAddressComponent(TypeAddressEnum.COUNTRY);

    const location = place.geometry?.location;
    const latitude = location?.lat();
    const longitude = location?.lng();

    const updatedAddress: IAddress = {
      address: `${fullAddress} `,
      city: city,
      state: state,
      country: country,
      zipCode: zipCode,
      lat: latitude,
      lng: longitude,
    };

    setInputValue(updatedAddress.address as string);
    if (onChange) {
      onChange(updatedAddress);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (onChange) {
      onChange({
        address: newValue,
      });
    }
  };
  //#endregion Handle Function

  return (
    isLoaded && (
      <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
        <FormInput
          disabled={disabled}
          required={required}
          placeholder=''
          label={label || t('admin_add_vendor_modal_label_address')}
          value={inputValue || EMPTY_STRING}
          onChange={handleInputChange}
          errorMessage={errorMessage}
        />
      </Autocomplete>
    )
  );
};

export default memo(CompleteAddress);

import { TFunction } from 'i18next';
import * as yup from 'yup';
import { EMPTY_STRING } from '~/utils/constants/common';
import { LinkToQuickBookCustomerEnum } from '~/utils/enum';
import { IContactClient, IFormClient } from '~/utils/interface/client';

export const clientSchema = (payload: { t: TFunction; isEditMode?: boolean }) => {
  const { t, isEditMode } = payload;

  return yup
    .object()
    .shape({
      name: yup.string().required(t('common_validate_require_name')),
      avatar: yup.mixed<File>().nullable().optional(),
      thumbnail: yup.mixed<File>().nullable().optional(),
      contactName: yup.string().optional(),
      phoneNumber: yup.string().optional(),
      email: yup.string().email(t('common_validate_format_email')).optional(),
      other: yup.string().optional(),
      note: yup.string().optional(),
      typeLinkQuickBook: yup
        .string()
        .nullable()
        .when([], {
          is: () => isEditMode !== true,
          then: (schema) =>
            schema.notOneOf(
              [EMPTY_STRING],
              t('admin_manage_clients_link_to_quick_book_customer_required')
            ),
          otherwise: (schema) => schema.optional(),
        }),
      quickBookCustomerId: yup
        .string()
        .nullable()
        .when('typeLinkQuickBook', {
          is: (value: string) => value === LinkToQuickBookCustomerEnum.ADD_EXISTING && !isEditMode,
          then: (schema) =>
            schema.required(t('admin_manage_clients_choose_quick_book_customer_required')),
          otherwise: (schema) => schema.optional(),
        }),
      contacts: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().trim().required(t('common_validate_require_name')),
            email: yup
              .string()
              .email(t('common_validate_format_email'))
              .required(t('common_validate_require_email')),
          })
        )
        .optional(),
    })
    .required();
};

export const DEFAULT_CONTACT_CLIENT: IContactClient = {
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  title: EMPTY_STRING,
};

export const initialValues: IFormClient = {
  name: EMPTY_STRING,
  contactName: EMPTY_STRING,
  email: EMPTY_STRING,
  other: EMPTY_STRING,
  note: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  avatar: null,
  thumbnail: null,
  typeLinkQuickBook: EMPTY_STRING,
  quickBookCustomerId: EMPTY_STRING,
  syncToken: EMPTY_STRING,
  contacts: [],
};

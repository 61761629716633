import { urlApiVendors } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IFormVendorBody,
  IDetailVendorData,
  IQueryListVendor,
  IUpdateContactsVendor,
  IVendor,
  IUpdateVendor,
  IFormVendor,
  IQueryListVendorByProduct,
  IVendorProduct,
} from '~/utils/interface/vendors';
import axiosClient from './axiosClient';

const vendorsApi = {
  getListVendor(params: IQueryListVendor) {
    const url = `${urlApiVendors.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IVendor[]>>>(url, { params });
  },

  createVendor(body: IFormVendorBody) {
    const url = `${urlApiVendors.create}`;
    return axiosClient.post<BaseResponse<IFormVendor>>(url, body);
  },

  changeStatusVendor(id: string) {
    const url = `${urlApiVendors.changeStatus(id)}`;
    return axiosClient.put<BaseResponse<IVendor>>(url);
  },

  getVendorDetails(id: string) {
    const url = `${urlApiVendors.getDetails(id)}`;
    return axiosClient.get<BaseResponse<IDetailVendorData>>(url);
  },

  updateVendor({ vendorId, body }: IUpdateVendor) {
    const url = `${urlApiVendors.updateVendor(vendorId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  updateContactsVendor({ id, body }: { id: string; body: IUpdateContactsVendor }) {
    const url = `${urlApiVendors.updateContactVendor(id)}`;
    return axiosClient.put(url, body);
  },

  sendToQBVendor(vendorId: string) {
    const url = `${urlApiVendors.sendToQB(vendorId)}`;
    return axiosClient.put<BaseResponse>(url);
  },

  getLisVendorByProduct(productId: string, params: IQueryListVendorByProduct) {
    const url = `${urlApiVendors.getListByProduct(productId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IVendorProduct[]>>>(url, {
      params,
    });
  },
};

export default vendorsApi;

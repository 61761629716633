// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import quickBookApi from '~/apis/quickBook';
import {
  DISCONNECT_QUICK_BOOK,
  GET_AUTH_URL,
  GET_LIST_ACCOUNT_QUICK_BOOK,
  GET_LIST_EXPENSES_DEFAULT,
  GET_LIST_INCOME_DEFAULT,
  GET_LIST_QUICK_BOOK_CUSTOMER,
  GET_LIST_QUICK_BOOK_ITEM,
  GET_LIST_QUICK_BOOK_TERM,
  GET_LIST_QUICK_BOOK_VENDOR,
  GET_TOKEN_QUICK_BOOK,
  UPDATE_ACCOUNT_INCOME_SETTING,
  UPDATE_ACCOUNT_SETTING,
  UPDATE_EXPENSES_DEFAULT,
  UPDATE_INCOME_DEFAULT,
} from '~/utils/constants/actionType';
import { IQueryBase } from '~/utils/interface/common';
import {
  IBodyUpdateExpensesDefault,
  ISettingExpensesDefault,
} from '~/utils/interface/expensesDefault';
import { IParamsListPaymentExpenses, IQueryGetTokenQuickBook } from '~/utils/interface/quickBook';
import { IBodyUpdateIncomeDefault, ISettingIncomeDefault } from '~/utils/interface/incomeDefault';

export const getAuthUrl = createAsyncThunk(GET_AUTH_URL, async () => {
  try {
    const res = await quickBookApi.getAuthUrl();
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getToken = createAsyncThunk(
  GET_TOKEN_QUICK_BOOK,
  async (payload: IQueryGetTokenQuickBook) => {
    try {
      const res = await quickBookApi.getTokenQuickBook(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const disconnect = createAsyncThunk(DISCONNECT_QUICK_BOOK, async () => {
  try {
    const res = await quickBookApi.disconnect();
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getListQuickBookCustomer = createAsyncThunk(
  GET_LIST_QUICK_BOOK_CUSTOMER,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListQuickBookCustomer(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListQuickBookItem = createAsyncThunk(
  GET_LIST_QUICK_BOOK_ITEM,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListQuickBookItems(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListQuickBookVendors = createAsyncThunk(
  GET_LIST_QUICK_BOOK_VENDOR,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListQuickBookVendors(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListQuickBookTerms = createAsyncThunk(
  GET_LIST_QUICK_BOOK_TERM,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListQuickBookTerms(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListExpensesDefault = createAsyncThunk(
  GET_LIST_EXPENSES_DEFAULT,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListExpensesDefault(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListAccountQuickBook = createAsyncThunk(
  GET_LIST_ACCOUNT_QUICK_BOOK,
  async (payload: IParamsListPaymentExpenses, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListAccountQuickBook(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccountSetting = createAsyncThunk(
  UPDATE_ACCOUNT_SETTING,
  async (body: ISettingExpensesDefault, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.updateAccountSetting(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateExpensesDefault = createAsyncThunk(
  UPDATE_EXPENSES_DEFAULT,
  async (body: IBodyUpdateExpensesDefault[], { rejectWithValue }) => {
    try {
      const res = await quickBookApi.updateExpensesDefault(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListIncomeDefault = createAsyncThunk(
  GET_LIST_INCOME_DEFAULT,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListIncomeDefault(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccountIncomeSetting = createAsyncThunk(
  UPDATE_ACCOUNT_INCOME_SETTING,
  async (body: ISettingIncomeDefault, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.updateAccountIncomeSetting(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateIncomeDefault = createAsyncThunk(
  UPDATE_INCOME_DEFAULT,
  async (body: IBodyUpdateIncomeDefault[], { rejectWithValue }) => {
    try {
      const res = await quickBookApi.updateIncomeDefault(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

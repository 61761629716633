// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '~/redux/hooks';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
// Components, Layouts, Pages
import {
  AdminAddAssigneeModal,
  BaseButton,
  BaseDatePicker,
  BaseSelect,
  BaseTextarea,
  BaseTimePicker,
  Loading,
} from '~/components';
// Others
import { getListJob } from '~/thunks/job/jobThunk';
import { createSchedule } from '~/thunks/schedule/scheduleThunk';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { IBaseOption } from '~/utils/interface/common';
import { IGetListJobReq, IJob, IParamJobPhases } from '~/utils/interface/job';
import { IScheduleBody } from '~/utils/interface/schedule';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_LIST,
  EMPTY_STRING,
  LENGTH_LIST_SELECTION,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, StatusEnum } from '~/utils/enum';
import { IAccount, IQueryAccount } from '~/utils/interface/account';
import { scheduleSchema } from '~/components/specific/adminAddScheduleModal/helper';
import { getListJobPhases } from '~/thunks/phases/phasesThunk';
import { getDateTimeInUTC } from '~/components/specific/scheduleModules/helper';
// Styles, images, icons
import styles from './AdminAddScheduleModal.module.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const AdminAddScheduleModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const initialFormState: IScheduleBody = useMemo(
    () => ({
      jobId: EMPTY_STRING,
      startDate: EMPTY_STRING,
      endDate: EMPTY_STRING,
      assigneeIds: EMPTY_LIST,
    }),
    []
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<IScheduleBody>({
    resolver: yupResolver(scheduleSchema(t)),
    defaultValues: initialFormState,
  });

  const jobId = watch('jobId');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [listAssignee, setListAssignee] = useState<IAccount[]>();
  const [dataJobList, setDataJobList] = useState<IBaseOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingJobPhase, setIsLoadingJobPhase] = useState<boolean>(false);
  const [jobPhaseList, setJobPhaseList] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListJob({ page: DEFAULT_CURRENT_PAGE, limit: LENGTH_LIST_SELECTION });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload: IQueryAccount = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      roles: [
        AccountRoleCodesEnum.STAFF,
        AccountRoleCodesEnum.PROJECT_MANAGER,
        AccountRoleCodesEnum.TECHNICIAN,
      ],
      status: StatusEnum.ACTIVE,
    };

    handleGetListAssignee(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!jobId) return;
    const payload: IParamJobPhases = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      jobId: jobId,
    };
    handleGetJobPhaseByJob(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListAssignee = (payload: IQueryAccount) => {
    if (!payload) return;

    dispatch(getListAccountMultipleRole(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res?.data;

        setListAssignee(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListJob = (payload: IGetListJobReq) => {
    setIsLoading(true);

    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const newListJob: IBaseOption[] = res?.data.responses?.map((prevJob: IJob) => ({
          value: prevJob.id,
          label: prevJob.jobName ?? EMPTY_STRING,
        }));

        setDataJobList(newListJob);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetJobPhaseByJob = (payload: IParamJobPhases) => {
    setIsLoadingJobPhase(true);

    dispatch(getListJobPhases(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;
        const { responses } = res.data;

        const listJobPhase: IBaseOption[] = responses.map((jobPhase) => ({
          label: jobPhase.name || EMPTY_STRING,
          value: jobPhase.id,
        }));

        setJobPhaseList(listJobPhase);
      })
      .catch((_error) => {})
      .finally(() => setIsLoadingJobPhase(false));
  };

  const handleAddSchedule = (data: IScheduleBody) => {
    if (!data) return;

    setIsLoading(true);
    const { note, startTime, endTime, ...rest } = data;
    const startOfDay = moment(data.startDate).startOf('day').toISOString();
    const endOfDay = moment(data.endDate).endOf('day').toISOString();

    const body: IScheduleBody = {
      ...rest,
      ...(note?.trim() ? { note: note } : {}),
      ...(startTime
        ? { startTime: getDateTimeInUTC(data.startDate, startTime) }
        : {
            startTime: startOfDay,
          }),
      ...(endTime
        ? { endTime: getDateTimeInUTC(data.endDate, endTime) }
        : {
            endTime: endOfDay,
          }),
    };

    dispatch(createSchedule(body))
      .unwrap()
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch((_error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='adminAddScheduleModal' className={cx('modalContainer')}>
      <form className={cx('modalContent')} onSubmit={handleSubmit(handleAddSchedule)}>
        <div className={cx('modalHead')}>
          {/* <img
                src={icons.commonIconCloseModal}
                className={cx('closeIcon')}
                alt={t('common_img_text_alt')}
                onClick={onClose}
              /> */}
          <div className={cx('modalTitle')}>{t('admin_manage_schedule_title_modal')}</div>
        </div>

        <div className={cx('modalBody')}>
          <div className={cx('twoCol')}>
            <Controller
              name='jobId'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  label={t('admin_manage_schedule_select_job_label')}
                  options={dataJobList}
                  placeholder={t('common_placeholder_select')}
                  value={value || EMPTY_STRING}
                  onChange={({ value }) => onChange(value)}
                  isRequired
                  errorMessage={errors.jobId?.message}
                />
              )}
            />

            {jobId && (
              <Controller
                name='phaseId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('admin_manage_schedule_select_job_phase_label')}
                    options={jobPhaseList}
                    placeholder={
                      isLoadingJobPhase ? t('common_text_loading') : t('common_placeholder_select')
                    }
                    value={value || EMPTY_STRING}
                    onChange={({ value }) => onChange(value)}
                    errorMessage={errors.phaseId?.message}
                  />
                )}
              />
            )}
          </div>

          <Controller
            name='assigneeIds'
            control={control}
            render={({ field: { value, onChange } }) => (
              <AdminAddAssigneeModal
                isOpen
                technicianList={listAssignee ?? []}
                onAdd={(assignee) => onChange([...value, assignee.id])}
                onCloseAssignee={(assignee) =>
                  onChange(value.filter((idAssignee) => idAssignee !== assignee.id))
                }
                isRequired
                errorMessage={errors.assigneeIds?.message}
              />
            )}
          />

          <div className={cx('twoCol')}>
            <Controller
              name='startDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  label={t('admin_manage_schedule_start_date_label')}
                  placeholderText={t('common_placeholder_select_date')}
                  onDateSelected={(date) => {
                    onChange(date);

                    getValues('endDate') && trigger('endDate');
                    getValues('endTime') && trigger('endTime');
                  }}
                  value={value || EMPTY_STRING}
                  isRequired
                  errorMessage={errors?.startDate?.message}
                />
              )}
            />

            <Controller
              name='endDate'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseDatePicker
                  label={t('admin_manage_schedule_end_date_label')}
                  placeholderText={t('common_placeholder_select_date')}
                  value={value || EMPTY_STRING}
                  onDateSelected={(date) => {
                    onChange(date);
                    getValues('endTime') && trigger('endTime');
                  }}
                  isRequired
                  errorMessage={errors?.endDate?.message}
                />
              )}
            />
          </div>

          <div className={cx('twoCol')}>
            <Controller
              name='startTime'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTimePicker
                  label={t('admin_manage_schedule_start_time_label')}
                  onTimeChange={(time) => {
                    onChange(time.toISOString());
                    getValues('endTime') && trigger('endTime');
                  }}
                  errorMessage={errors?.startTime?.message}
                />
              )}
            />

            <Controller
              name='endTime'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTimePicker
                  label={t('admin_manage_schedule_end_time_label')}
                  onTimeChange={(time) => onChange(time.toISOString())}
                  errorMessage={errors?.endTime?.message}
                />
              )}
            />
          </div>

          <div className={cx('tagNote')}>
            <Controller
              name='note'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  label={t('admin_manage_schedule_add_note_title')}
                  height={120}
                  value={value || EMPTY_STRING}
                  onTextareaChange={onChange}
                  errorMessage={errors?.note?.message}
                />
              )}
            />
          </div>
        </div>

        <div className={cx('modalFooter')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              onClick={handleCancel}
              typeStyle={ButtonTypeEnum.CANCEL}
            />
          </div>

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
          />
        </div>

        {isLoading && <Loading />}
      </form>
    </div>
  );
};

export default AdminAddScheduleModal;

import { TFunction } from 'i18next';
import * as yup from 'yup';

export const settingIncomeDefaultSchema = (payload: { t: TFunction }) => {
  const { t } = payload;

  return yup.object({
    quickBookIncomePaymentAccountId: yup
      .string()
      .required(t('income_default_tab_default_payment_account_required')),
  });
};

// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useCallback, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, SearchDropdown } from '~/components';
// Others
import { CommonIconPlus } from '~/assets/svgComponents';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListAccountQuickBook, updateAccountSetting } from '~/thunks/quickBook/quickBookThunk';
import { CYAN600, WHITE } from '~/utils/constants/color';
import {
  DEFAULT_NUMBER_OPTIONS_SELECT,
  typeDefaultExpensesAccount,
  typeDefaultPaymentAccount,
} from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { ISettingExpensesDefault } from '~/utils/interface/expensesDefault';
import { settingExpensesDefaultSchema } from './helper';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './SettingExpensesDefault.module.scss';

type Props = {
  expensesDefault: ISettingExpensesDefault;
  onApplySetting: () => void;
};

const cx = classNames.bind(styles);

const SettingExpensesDefault = (props: Props) => {
  //#region Destructuring Props
  const { expensesDefault, onApplySetting } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<ISettingExpensesDefault>({
    resolver: yupResolver(settingExpensesDefaultSchema({ t })),
    defaultValues: expensesDefault,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!expensesDefault.quickBookExpenseAccountId || !expensesDefault.quickBookPaymentAccountId) {
      return;
    }

    reset(expensesDefault);
  }, [expensesDefault.quickBookExpenseAccountId, expensesDefault.quickBookPaymentAccountId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSubmitSetting = (data: ISettingExpensesDefault, closeFn: Function) => {
    loading?.show();
    dispatch(updateAccountSetting(data))
      .unwrap()
      .then((_res) => {
        reset();
        closeFn();
        onApplySetting();
      })
      .catch((_err) => {})
      .finally(() => loading?.hide());
  };

  const fetchOptionsListDefaultExpensesAccount = useCallback(
    async (search: string, page: number) => {
      try {
        const listDefaultExpensesAccount = await dispatch(
          getListAccountQuickBook({
            page: page,
            limit: DEFAULT_NUMBER_OPTIONS_SELECT,
            type: typeDefaultExpensesAccount,
            ...(search ? { searchKey: search } : {}),
          })
        ).unwrap();

        return {
          items: listDefaultExpensesAccount?.data?.responses,
          hasMore:
            listDefaultExpensesAccount?.data?.pagination.page <
              listDefaultExpensesAccount?.data?.pagination?.totalPages || false,
        };
      } catch (error) {
        return {
          items: [],
          hasMore: false,
        };
      }
    },
    []
  );

  const fetchOptionsListDefaultPaymentAccount = useCallback(
    async (search: string, page: number) => {
      try {
        const listDefaultExpensesAccount = await dispatch(
          getListAccountQuickBook({
            page: page,
            limit: DEFAULT_NUMBER_OPTIONS_SELECT,
            type: typeDefaultPaymentAccount,
            ...(search ? { searchKey: search } : {}),
          })
        ).unwrap();

        return {
          items: listDefaultExpensesAccount?.data?.responses,
          hasMore:
            listDefaultExpensesAccount?.data?.pagination.page <
              listDefaultExpensesAccount?.data?.pagination?.totalPages || false,
        };
      } catch (error) {
        return {
          items: [],
          hasMore: false,
        };
      }
    },
    []
  );

  const handleClose = (closeFn: Function) => {
    reset();
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='settingPopupComponent'>
      <Popover className='relative'>
        <PopoverButton type='button' className={cx('btnGroup')}>
          <img
            src={icons.commonIconSettingDropdownProfile}
            alt={t('common_img_text_alt')}
            className={cx('btnImg')}
          />
        </PopoverButton>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '10px' }}
          className={cx('popupFilterContainer')}
        >
          {({ close }) => {
            return (
              <form onSubmit={handleSubmit((data) => handleSubmitSetting(data, close))}>
                <h3 className={cx('popTitle')}>{t('common_btn_setting')}</h3>

                <div className={cx('popContent')}>
                  <div className={cx('contentFilterWrap')}>
                    <Controller
                      name='quickBookExpenseAccountId'
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <SearchDropdown
                            fetchOptions={fetchOptionsListDefaultExpensesAccount}
                            placeholder={t('common_placeholder_select')}
                            label={t('expenses_default_tab_default_expenses_account_label')}
                            value={value}
                            width={250}
                            onChange={(item) => onChange(item?.id)}
                            renderLabel={(item) => {
                              return <>{item.name}</>;
                            }}
                            optionsWidth={250}
                            renderOption={(item, selectedValue) => {
                              return (
                                <div className={cx('optionSelect')}>
                                  <div className={cx('name')}>{item.name}</div>

                                  <CommonIconPlus
                                    width={16}
                                    height={16}
                                    strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                  />
                                </div>
                              );
                            }}
                            getOptionKey={(item) => {
                              return item.id;
                            }}
                            isRequired
                            errorMessage={errors.quickBookExpenseAccountId?.message}
                            isLoading
                          />
                        );
                      }}
                    />

                    <Controller
                      name='quickBookPaymentAccountId'
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <SearchDropdown
                            fetchOptions={fetchOptionsListDefaultPaymentAccount}
                            placeholder={t('common_placeholder_select')}
                            label={t('expenses_default_tab_default_payment_account_label')}
                            value={value}
                            width={250}
                            onChange={(item) => onChange(item?.id)}
                            renderLabel={(item) => {
                              return <>{item.name}</>;
                            }}
                            optionsWidth={250}
                            renderOption={(item, selectedValue) => {
                              return (
                                <div className={cx('optionSelect')}>
                                  <div className={cx('name')}>{item.name}</div>

                                  <CommonIconPlus
                                    width={16}
                                    height={16}
                                    strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                  />
                                </div>
                              );
                            }}
                            getOptionKey={(item) => {
                              return item.id;
                            }}
                            isRequired
                            errorMessage={errors.quickBookPaymentAccountId?.message}
                            isLoading
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className={cx('popBtnWrap')}>
                  <div className={cx('popBtnGroup')}>
                    <BaseButton
                      label={t('common_btn_cancel')}
                      type='button'
                      onClick={() => handleClose(close)}
                      typeStyle={ButtonTypeEnum.CANCEL}
                      minWidth={50}
                    />

                    <BaseButton
                      label={t('common_btn_apply')}
                      type='submit'
                      typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                      minWidth={50}
                      isDisable={!isDirty}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default SettingExpensesDefault;

// Libs
import { pdf } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, ProposalInvoicePdf } from '~/components';
import { useAppSelector } from '~/redux/hooks';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { formatAddress, getFullName } from '~/utils/helper';
import { IProposal, IProposalPdfData } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  onPdfGenerated: (pdfBlob: Blob) => void;
};

const SendInvoiceButton = (props: Props) => {
  //#region Destructuring Props
  const { onPdfGenerated } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control: proposalControl } = useFormContext<IProposal>();
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useAppSelector((state) => state.job.jobDetails);
  //#endregion Selector

  const lineItems = useWatch({ control: proposalControl, name: 'lineItems' });
  const proposalValue = useWatch({ control: proposalControl, name: 'value' });
  const proposalTaxRate = useWatch({ control: proposalControl, name: 'taxRate' });
  const proposalTerm = useWatch({ control: proposalControl, name: 'termsConditions' });
  const proposalDesc = useWatch({ control: proposalControl, name: 'description' });

  const dataPDF: IProposalPdfData = useMemo(() => {
    const clientName = getFullName({
      firstName: jobDetails?.client?.firstName,
      lastName: jobDetails?.client?.lastName,
    });

    const projectName = jobDetails?.name;
    const address = formatAddress({
      address: jobDetails?.streetAddress,
      city: jobDetails?.city,
      country: jobDetails?.country,
      state: jobDetails?.state,
      zipCode: jobDetails?.zipCode,
    });

    const taxPercent = +(proposalTaxRate ?? 0);
    const subtotal = proposalValue ?? 0;
    const tax = (+(proposalTaxRate ?? 0) / 100) * subtotal;
    const total = subtotal + tax;

    return {
      basicInfo: { clientName, projectName, address },
      lineItems: lineItems,
      price: { subtotal, tax, taxPercent, total },
      termConditions: proposalTerm ?? '',
      description: proposalDesc ?? '',
    };
  }, [jobDetails, lineItems, proposalValue, proposalTaxRate, proposalTerm]);

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const generatePdfBlob = async (): Promise<Blob | null> => {
    try {
      return await pdf(<ProposalInvoicePdf dataPdf={dataPDF} />).toBlob();
    } catch (error) {
      console.error('Generate PDF failed', error);
      return null;
    }
  };

  const handleClick = async () => {
    const pdfBlob = await generatePdfBlob();
    if (pdfBlob) {
      onPdfGenerated(pdfBlob);
    }
  };
  //#endregion Handle Function

  return (
    <BaseButton
      label={t('template_create_proposal_button_send_invoice')}
      typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
      type='button'
      width={170}
      onClick={handleClick}
    />
  );
};

export default SendInvoiceButton;

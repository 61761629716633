// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others
import {
  DEFAULT_LIMIT_FILE_SIZE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NAME_VALIDATE_TEST_EMPTY,
} from '~/utils/constants/common';
import { IFormDocument } from '~/utils/interface/document';

export const schemaFormDocumentVendor = (t: TFunction, documentId?: string) => {
  return yup
    .object()
    .shape({
      document: yup
        .mixed<File>()
        .test(
          NAME_VALIDATE_TEST_EMPTY,
          t('form_document_vendor_required_select_document'),
          (value) => {
            if (!documentId) {
              return value instanceof File && value.size > DEFAULT_NUMBER_ZERO;
            }
            return true;
          }
        )
        .test('fileSize', t('common_validate_file_size'), (value) => {
          if (!value) return true;
          return value instanceof File && value.size <= DEFAULT_LIMIT_FILE_SIZE;
        })
        .optional(),
      name: yup.string().required(t('form_document_vendor_required_document_name')),
      createdBy: yup.string().optional(),
      updatedAt: yup.string().optional(),
      businessId: yup.string().optional(),
      businessType: yup.string().optional(),
    })
    .required();
};

export const defaultValuesFormDocumentVendor: IFormDocument = {
  name: EMPTY_STRING,
  document: undefined,
  createdBy: EMPTY_STRING,
  updatedAt: EMPTY_STRING,
  businessId: EMPTY_STRING,
  businessType: EMPTY_STRING,
};

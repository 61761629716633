// Libs
import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';

// Components, Layouts, Pages
import {
  BaseFilter,
  BaseImageCircle,
  BaseMoreAction,
  BasePagination,
  BaseSelect,
  InvoiceDetailModal,
  ModalUnderDevelopment,
  Status,
  TableSelected,
  Toolbar,
} from '~/components';

// Others
import {
  IBodyRecordInvoicesPayments,
  IFilterInvoice,
  IGetListInvoiceReq,
  IInvoices,
} from '~/utils/interface/invoices';
import {
  BaseTableEnum,
  CurrencyEnum,
  FilterInvoiceByStatusEnum,
  WebSocketEventEnum,
  AccountRoleCodesEnum,
  StorageEnum,
} from '~/utils/enum';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  optionsFilterInvoiceByStatus,
} from '~/utils/constants/common';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import { formatCurrency, formattedTime, getFullName } from '~/utils/helper';
import { LoadingData, WebsocketContext } from '~/context';
import { getListInvoice, recordPaymentInvoice } from '~/thunks/invoice/invoiceThunk';
import useDebounce from '~/utils/hooks/useDebounce';
import { wssJoinInvoice, wssLeaveInvoice } from '~/utils/helpers/wss';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { invoiceActions } from '~/thunks/invoice/invoiceSlice';

// Styles, images, icons
import styles from './Invoices.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const columns = (
  t: TFunction<'translation'>,
  handleViewInvoiceDetail: (id: string) => void,
  handleViewQuickBooks: (url: string) => void,
  handleRecordPaymentInvoiceSelected: (id: string) => void
): ColumnType<IInvoices>[] => {
  return [
    {
      key: 'id',
      title: t('invoices_table_label_invoice_id'),
      dataIndex: 'id',
      render: (_, record) => {
        return <div>{record?.id || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'clientName',
      title: t('invoices_table_label_client_name'),
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {record?.client?.firstName || record?.client?.lastName ? (
            <>
              <BaseImageCircle
                firstText={record?.client?.firstName}
                secondText={record?.client?.firstName}
                url={record?.client?.thumbnailUrl || record?.client?.avatarUrl}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record?.client?.firstName,
                lastName: record?.client?.lastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'email',
      title: t('invoices_table_label_email'),
      render: (_, record) => {
        return <div>{record?.client.email || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'dueDate',
      title: t('invoices_table_label_due_date'),
      render: (_, record) => {
        return <div>{record?.dueDate ? formattedTime(record?.dueDate) : EMPTY_STRING}</div>;
      },
    },
    {
      key: 'createdAt',
      title: t('invoices_table_label_invoice_date'),
      render: (_, record) => {
        return <div>{record?.createdAt ? formattedTime(record?.createdAt) : EMPTY_STRING}</div>;
      },
    },
    {
      key: 'total',
      title: t('invoices_table_label_invoice_amount'),
      render: (_, record) => (
        <div>
          <span>
            {record?.total ? formatCurrency(CurrencyEnum.USD, record.total) : DEFAULT_CURRENCY}
          </span>
        </div>
      ),
    },
    {
      key: 'status',
      title: t('invoices_table_label_status'),
      render: (_, record) => {
        return record.status ? (
          <Status type='tag' status={record.status} />
        ) : (
          <span>{EMPTY_STRING}</span>
        );
      },
    },
    {
      key: 'action',
      title: t('invoices_table_label_action'),
      dataIndex: 'id',
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleViewInvoiceDetail(record.id),
          },
          {
            label: t('common_action_view_on_quick_books'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleViewQuickBooks(record.externalLinkQB),
          },
          {
            label: t('common_action_record_payment'),
            icon: (
              <img
                src={icons.commonIconRecordPayment}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleRecordPaymentInvoiceSelected(record.id),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const ManageInvoices = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { wss, wsData } = useContext(WebsocketContext);
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [invoicesList, setInvoicesList] = useState<IInvoices[]>([]);
  const [totalOverdue, setTotalOverdue] = useState<number>(DEFAULT_NUMBER_ZERO);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowInvoiceDetail, setIsShowInvoiceDetail] = useState<boolean>(false);
  const [invoiceSelected, setInvoiceSelected] = useState<string>(EMPTY_STRING);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  // const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  // const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  const [isShowRecordPayment, setIsShowRecordPayment] = useState<boolean>(false);
  const [invoiceIds, setIdInvoices] = useState<number[]>([]);
  const [invoiceIdSelected, setIdInvoiceSelected] = useState<number[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListInvoice(newParams, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!wss) return;
    wssJoinInvoice(wss);

    return () => {
      wssLeaveInvoice(wss);
    };
  }, [wss]);

  useEffect(() => {
    if (!wsData) return;

    switch (wsData?.type) {
      case WebSocketEventEnum.SEND_INVOICE:
        handleGetListInvoice({
          page: Number(params.page) || DEFAULT_CURRENT_PAGE,
          limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsData]);

  useEffect(() => {
    if (!invoiceIdSelected.length) return;

    handleRecordPaymentInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceIdSelected]);
  //#endregion Implement Hook

  //#region Handle Function

  const handleShowModalUnderDevelopment = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleChangePagination = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleViewInvoiceDetail = (id: string) => {
    if (!id) return;
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.invoices}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.invoices}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.invoices}/${id}`);
        break;

      default:
        break;
    }
  };

  const handleGetListInvoice = (payload: IGetListInvoiceReq, displayLoading?: boolean) => {
    displayLoading && loadingData?.show();

    dispatch(getListInvoice(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res.data;
        responses && setInvoicesList(responses);
        pagination && setPagination(pagination);

        setTotalOverdue(res.data?.overdueInvoicesCount || DEFAULT_NUMBER_ZERO);
      })
      .catch((error) => {})
      .finally(() => {
        displayLoading && loadingData?.hide();
      });
  };

  const handleCloseInvoiceDetail = () => {
    setInvoiceSelected(EMPTY_STRING);
    setIsShowInvoiceDetail(false);
  };

  const handleInvoiceSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleConnectQuickBook = (url: string) => {
    window.open(url);
  };

  const handleCLickRow = (record: IInvoices) => {
    if (!record.id) return;
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.invoices}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.invoices}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.invoices}/${record.id}`);
        break;

      default:
        break;
    }
  };

  const handleApplyFilterVendor = (valueFilter: IFilterInvoice) => {
    const { status, ...restParams } = params;

    if (
      !valueFilter ||
      Object.keys(valueFilter).length === DEFAULT_NUMBER_ZERO ||
      valueFilter.status === FilterInvoiceByStatusEnum.ALL
    ) {
      setSearchParams({
        ...restParams,
        page: DEFAULT_NUMBER_ONE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      });
      return;
    }

    setSearchParams({
      ...restParams,
      page: DEFAULT_NUMBER_ONE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.status ? { status: valueFilter?.status } : {}),
    });
  };

  const handleRowSelection = (selectedRowKeys: React.Key[], selectedRows: IInvoices[]) => {
    const hasData = selectedRowKeys.length > 0 || selectedRows.length > 0;
    const listIdInvoices = selectedRowKeys.map((key) => Number(key));

    setIsShowRecordPayment(hasData);
    setIdInvoices(listIdInvoices);
  };

  const handleRecordPaymentInvoiceSelected = (invoiceIdSelected: string) => {
    if (!invoiceIdSelected) return;

    const invoiceIdAsNumber = Number(invoiceIdSelected);
    if (!isNaN(invoiceIdAsNumber)) {
      setIdInvoiceSelected([invoiceIdAsNumber]);
    }
  };

  const handleRecordPaymentInvoice = () => {
    loadingData?.show();
    const payload: IBodyRecordInvoicesPayments = {
      invoiceIds: invoiceIdSelected.length ? invoiceIdSelected : invoiceIds,
    };

    dispatch(recordPaymentInvoice(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        dispatch(invoiceActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIdInvoiceSelected([]);
      });
  };
  //#endregion Handle Function

  return (
    <div id='invoicesPage' className={cx('container')}>
      <Toolbar
        title={t('invoices_toolbar_title', {
          total: pagination?.totalItems || DEFAULT_NUMBER_ZERO,
        })}
        // primaryBtn={{
        //   action: handleSyncToQuickBooks,
        //   label: t('invoices_sync_to_quick_books_label'),
        // }}
        childrenRight={() => (
          <div className={cx('overdueInvoice')}>
            {t('invoices_toolbar_over_due_title', {
              total: totalOverdue,
            })}
          </div>
        )}
        onSearch={handleInvoiceSearch}
        valueSearch={searchKey || EMPTY_STRING}
        {...(isShowRecordPayment && {
          actionButton: {
            action: handleRecordPaymentInvoice,
            label: t('invoices_table_toolbar_btn_record_bill_label'),
          },
        })}
      >
        <BaseFilter<IFilterInvoice>
          defaultValue={{ status: FilterInvoiceByStatusEnum.ALL }}
          onApply={handleApplyFilterVendor}
          valueFilter={{
            status: (params?.status as FilterInvoiceByStatusEnum) || FilterInvoiceByStatusEnum.ALL,
          }}
        >
          {({ valueFilter, onChange }) => {
            return (
              <div className={cx('contentFilterWrap')}>
                <div className={cx('labelFilter')}>{t('invoices_filter_by_status_label')}</div>

                <BaseSelect
                  name='status'
                  options={optionsFilterInvoiceByStatus || []}
                  height={31}
                  value={valueFilter?.status}
                  placeholder={t('common_placeholder_select')}
                  onChange={({ value }, name) => {
                    onChange({
                      name: name as keyof IFilterInvoice,
                      value: value as FilterInvoiceByStatusEnum,
                    });
                  }}
                />
              </div>
            );
          }}
        </BaseFilter>
      </Toolbar>

      <section className={cx('body')}>
        <div className={cx('statisticTable')}>
          <TableSelected
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewInvoiceDetail,
              handleConnectQuickBook,
              handleRecordPaymentInvoiceSelected
            )}
            dataSource={invoicesList || []}
            onClickRow={handleCLickRow}
            rowSelection={{
              type: 'checkbox',
              onChange: handleRowSelection,
            }}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handleChangePagination}
          />
        </div>
      </section>

      {isShowModalUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleShowModalUnderDevelopment} />
      )}

      <InvoiceDetailModal
        invoiceId={invoiceSelected}
        isOpen={isShowInvoiceDetail}
        onClose={handleCloseInvoiceDetail}
      />
    </div>
  );
};

export default ManageInvoices;

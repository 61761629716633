// Others
import { urlApiPhases } from '~/utils/constants/common';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import { IListJobPhases } from '~/utils/interface/phases';
import { IParamJobPhases } from '~/utils/interface/job';

const phasesApi = {
  getListJobPhase(params: IParamJobPhases) {
    const { jobId, ...restParams } = params;
    const url = `${urlApiPhases.getListJobPhase(jobId)}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListJobPhases[]>>>(url, {
      params: restParams,
    });
  },
};

export default phasesApi;

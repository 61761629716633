// Libs
import classNames from 'classnames/bind';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelect, FormInput } from '~/components';
// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { IFormProductPayload } from '~/utils/interface/product';
import { IBaseOption } from '~/utils/interface/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldVendorProduct.module.scss';

type Props = {
  fieldIndex: number;
  removeField: UseFieldArrayRemove;
  vendorOptions: IBaseOption[];
  isProductByVendor?: boolean;
};

const cx = classNames.bind(styles);

const FieldVendorProduct = (props: Props) => {
  //#region Destructuring Props
  const { fieldIndex, removeField, vendorOptions, isProductByVendor } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IFormProductPayload>();
  //#endregion Declare Hook

  //#region Selector

  //#endregion Selector

  //#region Declare Form Watch
  //#endregion Form Watch

  //#region Declare Memo
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldVendorProductComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className={cx('col-span-3')}>
          <Controller
            name={`vendors.${fieldIndex}.vendorId`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                label={t('admin_manage_products_add_vendor_label')}
                placeholder={t('common_placeholder_select')}
                value={value.toString() || EMPTY_STRING}
                options={vendorOptions}
                isRequired
                onChange={(optionSelected: IBaseOption) => {
                  setValue(`vendors.${fieldIndex}.name`, optionSelected.label);
                  onChange(optionSelected.value);
                }}
                errorMessage={errors.vendors?.[fieldIndex]?.vendorId?.message}
                disabled={isProductByVendor}
              />
            )}
          />
        </div>
        <Controller
          name={`vendors.${fieldIndex}.unitMeasure`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              required
              label={t('admin_manage_products_add_unit_of_measure_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              errorMessage={errors.vendors?.[fieldIndex]?.unitMeasure?.message}
            />
          )}
        />

        <Controller
          name={`vendors.${fieldIndex}.sku`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('admin_manage_products_add_sku_label')}
              value={value || EMPTY_STRING}
              required
              onChange={onChange}
              errorMessage={errors.vendors?.[fieldIndex]?.sku?.message}
            />
          )}
        />

        <Controller
          name={`vendors.${fieldIndex}.costCode`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              required
              label={t('admin_manage_products_add_cost_code_label')}
              value={value || EMPTY_STRING}
              onChange={onChange}
              errorMessage={errors.vendors?.[fieldIndex]?.costCode?.message}
            />
          )}
        />

        <Controller
          name={`vendors.${fieldIndex}.unitPrice`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              label={t('admin_manage_products_add_unit_price_label')}
              value={value ?? DEFAULT_NUMBER_ZERO}
              onChange={(e) => {
                const convertValueToNumber = +e.target.value;
                if (!isNaN(Number(e.target.value))) {
                  onChange(convertValueToNumber);
                }
              }}
              required
              errorMessage={errors.vendors?.[fieldIndex]?.unitPrice?.message}
            />
          )}
        />

        <Controller
          name={`vendors.${fieldIndex}.unitCost`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              required
              label={t('admin_manage_products_add_unit_cost_label')}
              value={value ?? DEFAULT_NUMBER_ZERO}
              onChange={(e) => {
                const convertValueToNumber = +e.target.value;
                if (!isNaN(Number(e.target.value))) {
                  onChange(convertValueToNumber);
                }
              }}
              errorMessage={errors.vendors?.[fieldIndex]?.unitCost?.message}
            />
          )}
        />
      </div>

      {!isProductByVendor && (
        <div className={cx('btnRemoveContainer')}>
          <button
            className={cx('btnRemoveField')}
            type='button'
            onClick={() => removeField(fieldIndex)}
          >
            <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
          </button>
        </div>
      )}
    </div>
  );
};

export default FieldVendorProduct;

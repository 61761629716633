// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, FormInput } from '~/components';
// Others
import { LoadingContext } from '~/context/loadingContext/LoadingContext';
import { useAppDispatch } from '~/redux/hooks';
import { EMPTY_STRING } from '~/utils/constants/common';
import {
  ButtonTypeEnum,
  InputAuthName,
  InputTypeEnum,
  InputTypeStyleEnum,
  StorageEnum,
} from '~/utils/enum';
import { ICreateNewPasswordForm, ICreateNewPasswordPayload } from '~/utils/interface/auth';
import { publicRouteAbsolute } from '~/utils/constants/route';
import { authActions } from '~/thunks/auth/authSlice';
import { createNewPassword } from '~/thunks/auth/authThunk';
// Styles, images, icons
import styles from './CreateNewPassword.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) =>
  yup
    .object()
    .shape({
      newPassword: yup
        .string()
        .required(t('common_validate_require_new_password'))
        .min(8, t('common_error_message_password_invalid')),
      passwordConfirm: yup
        .string()
        .required(t('common_validate_require_confirm_password'))
        .oneOf([yup.ref('newPassword')], t('common_validate_password_match')),
    })
    .required();

const CreateNewPassword = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  const accountId = sessionStorage.getItem(StorageEnum.ACCOUNT_ID);

  const initialValues: ICreateNewPasswordForm = useMemo(() => {
    return {
      newPassword: EMPTY_STRING,
      passwordConfirm: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<ICreateNewPasswordForm>({
    resolver: yupResolver(schema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!accountId) navigate(`${publicRouteAbsolute.forgotPassword}`);
  }, [accountId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCreatePassword = (data: ICreateNewPasswordForm) => {
    if (!accountId) return;
    loadingContext?.show();

    const payload: ICreateNewPasswordPayload = {
      newPassword: data.newPassword,
      accountId: accountId,
    };

    dispatch(createNewPassword(payload))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();

        if (!res) return;
        const { code, message, data } = res;
        dispatch(authActions.clearAccountId(data.accountId));

        navigate(publicRouteAbsolute.createPasswordSuccess);
      })
      .catch((error) => {
        loadingContext?.hide();
        //@Binh: todo handle show toast error
      });
  };
  //#endregion Handle Function

  return (
    <div id='createNewPasswordPage' className={cx('container')}>
      <div className={cx('content')}>
        <div className={cx('title')}>{t('admin_create_new_password_title')}</div>

        <div className={cx('createNewPasswordFormNote')}>
          <span className={cx('createNewPasswordTextNote')}>
            {t('admin_create_new_password_title_text_note')}
          </span>
        </div>

        <form onSubmit={handleSubmit(handleCreatePassword)}>
          <div className={cx('form')}>
            <div className={cx('formItem')}>
              <Controller
                name={InputAuthName.NEW_PASSWORD}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_new_password_label')}
                    type={InputTypeEnum.PASSWORD}
                    typeStyle={InputTypeStyleEnum.AUTH}
                    height={36}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.newPassword?.message}
                  />
                )}
              />
            </div>
            <div className={cx('formItem')}>
              <Controller
                name={InputAuthName.CONFIRM_PASSWORD}
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_confirm_new_password_label')}
                    type={InputTypeEnum.PASSWORD}
                    typeStyle={InputTypeStyleEnum.AUTH}
                    height={36}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.passwordConfirm?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('action')}>
            <BaseButton
              borderRadius={8}
              height={36}
              typeStyle={ButtonTypeEnum.AUTH}
              isBorder={true}
              label={t('common_btn_create')}
              type='submit'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewPassword;

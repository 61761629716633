import * as yup from 'yup';
import { IBodyUpdateIncomeDefault } from '~/utils/interface/incomeDefault';

export const initialValues = {
  items: [],
};

export const accountantIncomeDefault = () => {
  return yup.object().shape({
    items: yup
      .array(
        yup.object<IBodyUpdateIncomeDefault>().shape({
          clientId: yup.string().optional(),
          quickBookPaymentAccountId: yup.string().optional(),
        })
      )
      .required(),
  });
};

// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseMoreAction,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  Status,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListPurchaseOrderByJob } from '~/thunks/purchaseOrder/purchaseOrderThunk';
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  EMPTY_VALUE,
  POUND,
  defaultCurrentPage,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  CurrencyEnum,
  ManageJobsTabsEnum,
  StorageEnum,
} from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { ColumnType, IPagination, IQueryBase, MoreActionItem } from '~/utils/interface/common';
import { IPurchaseOrderByJob, IQueryListPOByJob } from '~/utils/interface/purchaseOrder';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './JobPOsTab.module.scss';

type Props = {
  jobId?: string;
};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetailAction: (id: string) => void
): ColumnType<IPurchaseOrderByJob>[] => {
  return [
    {
      key: 'orderNumber',
      title: t('common_table_purchase_orders_po_number'),
      render: (_, record) => {
        return (
          <>
            <span className={cx('poNumber')}>{POUND}</span>
            {record.orderNumber}
          </>
        );
      },
    },
    {
      key: 'total',
      title: t('common_table_purchase_orders_total_amount'),
      render: (_, record) => {
        return (
          <>{record.total ? formatCurrency(CurrencyEnum.USD, record.total) : DEFAULT_CURRENCY}</>
        );
      },
    },
    {
      key: 'status',
      title: t('common_table_purchase_orders_table_status'),
      render: (_, record) => {
        return record.status ? (
          <Status type='tag' status={record.status} />
        ) : (
          <span>{EMPTY_VALUE}</span>
        );
      },
    },
    {
      key: 'action',
      width: '10%',
      title: t('common_table_purchase_orders_table_action'),
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetailAction(record.id.toString()),
          },
        ];
        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const JobPOsTab = (props: Props) => {
  //#region Destructuring Props
  const { jobId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );

  const role = localStorage.getItem(StorageEnum.ROLE);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [purchaseOrderListByJob, setPurchaseOrderListByJob] = useState<IPurchaseOrderByJob[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!jobId) return;
    const { tab, ...rest } = params;

    const newParams = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListPOs({ ...newParams, jobId: jobId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, params]);

  useEffect(() => {
    if (!pagination) return;
    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListPOs = (payload: IQueryListPOByJob) => {
    loadingData?.show();

    dispatch(getListPurchaseOrderByJob(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses, pagination } = res.data;

        responses && setPurchaseOrderListByJob(responses);
        pagination && setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleShowModalAddPO = () => {
    if (!role) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.createPo}`, { state: jobId });
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.createPo}`, { state: jobId });
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.createPo}`, { state: jobId });
        break;
    }
  };

  const onViewDetailAction = (id: string) => {
    if (!role || !id) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.purchaseOrder}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.purchaseOrder}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.purchaseOrder}/${id}`);
        break;
    }
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSearchPO = (value: string) => {
    setSearchKey(value);
  };

  const handleCLickRow = (record: IPurchaseOrderByJob) => {
    if (!role || !record.id) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.purchaseOrder}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.purchaseOrder}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.purchaseOrder}/${record.id}`);
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='jobPOsTab' className={cx('container')}>
      <Toolbar
        primaryBtn={{
          action: handleShowModalAddPO,
          label: t('template_pos_jobs_btn_add_po'),
        }}
        rounded={false}
        onSearch={handleSearchPO}
        valueSearch={searchKey || EMPTY_STRING}
      />

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(t, onViewDetailAction)}
            dataSource={purchaseOrderListByJob.length ? purchaseOrderListByJob : []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </div>
  );
};

export default JobPOsTab;

import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULT_NUMBER_ONE } from '~/utils/constants/common';

export const scheduleSchema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      jobId: yup.string().required(t('admin_manage_schedule_select_job_required')),
      phaseId: yup.string().optional(),
      note: yup.string().optional(),
      startDate: yup
        .string()
        .required(t('admin_manage_schedule_start_date_required'))
        .test('is-future-date', t('admin_manage_schedule_start_date_valid'), function (value) {
          if (!value) return false;
          return moment(value, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day');
        }),
      endDate: yup
        .string()
        .required(t('admin_manage_schedule_end_date_required'))
        .test('is-endDate-valid', t('admin_manage_schedule_end_date_invalid'), function (value) {
          const { startDate } = this.parent;

          return !value || !startDate || new Date(value) >= new Date(startDate);
        }),
      startTime: yup.string().optional(),
      endTime: yup
        .string()
        .optional()
        .test('is-time-valid', t('admin_manage_schedule_end_time_invalid'), function (endTime) {
          const { startDate, endDate, startTime } = this.parent;

          if (!startDate || !endDate || !startTime || !endTime) return true;

          if (startDate === endDate) {
            const parsedEndTime = moment(endTime);
            const parsedStartTime = moment(startTime);

            return parsedEndTime.isAfter(parsedStartTime, 'minute');
          }

          return true;
        }),
      assigneeIds: yup
        .array()
        .of(yup.number().required())
        .min(DEFAULT_NUMBER_ONE, t('admin_manage_schedule_assignee_required'))
        .required(t('admin_manage_schedule_assignee_required')),
    })
    .required();
};

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BasePagination, BaseTable, ConfirmModal, ThreeDotOptions, Toolbar } from '~/components';
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, BaseTableEnum, CurrencyEnum, StorageEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { ColumnType, IPagination } from '~/utils/interface/common';
import { IProduct, IQueryListProductByVendor } from '~/utils/interface/product';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteProduct, getListProductsByVendor } from '~/thunks/product/productThunk';
import { productActions, selectIsRefreshProductByVendorList } from '~/thunks/product/productSlice';
import useDebounce from '~/utils/hooks/useDebounce';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
// Styles, images, icons
import styles from './ProductVendorTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleShowProductDetail: (productId: string) => void,
  handleShowEditProductModal: (productId: string) => void,
  handleShowConfirmDeleteModal: (productId: string) => void
): ColumnType<IProduct>[] => {
  return [
    {
      key: 'name',
      title: t('admin_manage_products_table_product_name'),
      dataIndex: 'name',
      render: (_, record) => (
        <span
          onClick={() => {
            handleShowProductDetail(String(record?.id));
          }}
          className={cx('tableLink')}
        >
          {record?.name || EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'unitMeasure',
      title: t('admin_manage_products_table_unit_of_measure'),
      dataIndex: 'unitMeasure',
      render: (_, record) => <span>{record?.unitMeasure || EMPTY_STRING}</span>,
    },
    {
      key: 'unitPrice',
      title: t('admin_manage_products_table_unit_price'),
      dataIndex: 'unitPrice',
      render: (_, record: IProduct) => (
        <div>
          {record.unitPrice ? (
            <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitPrice))}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'unitCost',
      title: t('admin_manage_products_table_unit_cost'),
      dataIndex: 'unitCost',
      render: (_, record: IProduct) => (
        <div>
          {record.unitCost ? (
            <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitCost))}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_products_table_action'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          onView={() => handleShowProductDetail(record?.id)}
          onEdit={() => handleShowEditProductModal(record?.id)}
          onDelete={() => handleShowConfirmDeleteModal(record.id)}
        />
      ),
      width: '10%',
    },
  ];
};

const ProductVendorTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;
  //#endregion Declare Hook

  //#region Selector
  const isRefreshProductByVendorList = useAppSelector(selectIsRefreshProductByVendorList);
  //#endregion Selector

  //#region Declare State
  const [productsListByVendor, setProductsListByVendor] = useState<IProduct[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const [productId, setProductId] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const { tab, ...rest } = params;
    const payload = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListProductsByVendor(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  // ** Re-fresh list when CRUD **
  useEffect(() => {
    if (!isRefreshProductByVendorList) return;
    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
    };

    handleGetListProductsByVendor(newParams);
    dispatch(productActions.setRefreshByVendorList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshProductByVendorList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCLickRow = (record: IProduct) => {
    if (!role || !record) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.products}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.products}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.products}/${record.id}`);
        break;
    }
  };

  const handleShowConfirmDeleteModal = (productId?: string) => {
    if (productId) {
      setProductId(productId);
    }

    setIsShowConfirmDelete(!isShowConfirmDelete);
  };

  const handleUpdateProduct = (productId: string) => {
    if (!role || !productId) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.editProduct}/${productId}`, { state: vendorId });
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.editProduct}/${productId}`, { state: vendorId });
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.editProduct}/${productId}`, {
          state: vendorId,
        });
        break;
    }
  };

  const handleShowProductDetail = (productId: string) => {
    if (!role || !productId) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.products}/${productId}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.products}/${productId}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.products}/${productId}`);
        break;
    }
  };

  const handleAddProduct = () => {
    if (!role) return;

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.createProduct}`, { state: vendorId });
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.createProduct}`, { state: vendorId });
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.createProduct}`, { state: vendorId });
        break;
    }
  };

  const handleSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleDeleteProduct = () => {
    if (!productId) return;

    loadingData?.show();
    dispatch(deleteProduct(productId))
      .unwrap()
      .then((res) => {
        dispatch(productActions.setRefreshByVendorList(true));
        setProductId(EMPTY_STRING);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleGetListProductsByVendor = (payload: IQueryListProductByVendor) => {
    if (!payload || !vendorId) return;

    loadingData?.show();
    dispatch(getListProductsByVendor({ vendorId, payload }))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setProductsListByVendor(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='productVendorTab' className={cx('container')}>
      <Toolbar
        onSearch={handleSearch}
        primaryBtn={{
          action: handleAddProduct,
          label: t('admin_manage_products_btn_add_product'),
        }}
        rounded={false}
        valueSearch={searchKey || EMPTY_STRING}
      />

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleShowProductDetail,
              handleUpdateProduct,
              handleShowConfirmDeleteModal
            )}
            dataSource={productsListByVendor || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowConfirmDelete && productId && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteProduct}
        />
      )}
    </div>
  );
};

export default ProductVendorTab;

// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
// Components, Layouts, Pages
import { BasicInformationBillTab, DocumentBillTab, NavTab, Toolbar } from '~/components';
// Others
import { DetailBillTabsEnum, SearchParamsEnum } from '~/utils/enum';
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_RECORD_TO_FETCH } from '~/utils/constants/common';
import { billDetailTab } from '~/utils/constants/tab';
// Styles, images, icons
import styles from './BillDetail.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const BillDetail = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!params?.tab) {
      setSearchParams({
        tab: DetailBillTabsEnum.BASIC_INFORMATIONAL,
      });
    }
  }, [params?.tab]);
  //#endregion Implement Hook

  //#region Handle Function
  const renderTabContent = useMemo(() => {
    const currentTab = params?.tab || DetailBillTabsEnum.BASIC_INFORMATIONAL;

    switch (currentTab) {
      case DetailBillTabsEnum.BASIC_INFORMATIONAL:
        return <BasicInformationBillTab />;

      case DetailBillTabsEnum.DOCUMENTS:
        return <DocumentBillTab />;

      default:
        return <div className={cx('noDataAvailable')}>{t('common_empty_data')}</div>;
    }
  }, [params?.tab, t]);

  const handleSearchParams = (tab: string) => {
    setSearchParams({
      tab,
      ...(tab === DetailBillTabsEnum.DOCUMENTS
        ? { page: DEFAULT_NUMBER_ONE.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() }
        : {}),
    });
  };
  //#endregion Handle Function

  return (
    <div id='billDetailPage' className={cx('container')}>
      <Toolbar title={t('template_bill_detail_toolbar_title')} />

      <div className={cx('body')}>
        <NavTab
          items={billDetailTab}
          searchParams={SearchParamsEnum.TAB}
          onClick={handleSearchParams}
        />

        <div className={cx('tabContent')}>{renderTabContent}</div>
      </div>
    </div>
  );
};

export default BillDetail;

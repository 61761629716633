// Libs
import {
  Description,
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_SELECT_HEIGHT, DEFAULT_SELECT_WIDTH } from '~/utils/constants/component';
import { IBaseOption } from '~/utils/interface/common';
import { ASTERISK_SYMBOL, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseSelect.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  options: IBaseOption[];
  name?: string;
  value?: string;
  disabled?: boolean;
  isRequired?: boolean;
  borderRadius?: number | string;
  onChange?: (value: IBaseOption, name: string) => void;
};

const cx = classNames.bind(styles);

const BaseSelect = (props: Props) => {
  //#region Destructuring Props
  const {
    width = DEFAULT_SELECT_WIDTH,
    height = DEFAULT_SELECT_HEIGHT,
    borderRadius,
    label,
    placeholder,
    errorMessage,
    options,
    name,
    value,
    disabled = false,
    onChange,
    isRequired,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<IBaseOption | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) {
      setSelectOption(null);
      return;
    }
    const optionMatchWithValue = options?.find((item) => item.value === value);

    setSelectOption(optionMatchWithValue || null);
  }, [value, options]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionChange = (value: IBaseOption) => {
    setSelectOption(value);
    onChange && onChange(value, name ?? '');
  };
  //#endregion Handle Function

  return (
    <Field
      id='baseSelectComponent'
      className={cx('container')}
      style={{ width }}
      disabled={disabled}
    >
      {label && (
        <label className={cx('label')}>
          {label} {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </label>
      )}

      <Listbox value={selectOption} onChange={handleOptionChange}>
        <ListboxButton className={cx('btnSelect')} style={{ height, borderRadius }}>
          {({ open }) => (
            <>
              {placeholder && !selectOption ? (
                <span className={cx('btnPlaceholder')}>{placeholder}</span>
              ) : (
                <span className={cx('btnText')}>
                  {selectOption?.label ? t(selectOption?.label) : EMPTY_STRING}
                </span>
              )}
              <img
                src={icons.commonIconArrowBottom}
                className={cx(open ? 'iconActive' : '')}
                alt={t('common_img_text_alt')}
              />
            </>
          )}
        </ListboxButton>

        <ListboxOptions
          className={cx('optionList')}
          transition
          anchor={{ to: 'bottom', gap: '4px' }}
        >
          {options.length > 0 ? (
            <>
              {options.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option}
                  className={({ selected, focus }) =>
                    cx(
                      'optionItem',
                      (selected || selectOption?.value === option.value) && 'optionActive'
                    )
                  }
                >
                  {t(option.label)}
                </ListboxOption>
              ))}
            </>
          ) : (
            <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
          )}
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default BaseSelect;

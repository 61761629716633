// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react/jsx-runtime';
// Components, Layouts, Pages
// Others
import { DEFAULT_CURRENCY } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IFormCreatePurchaseOrderProduct } from '~/utils/interface/purchaseOrder';
// Styles, images, icons

type Props = {
  data: IFormCreatePurchaseOrderProduct[];
};

const PurchaseOrderProductRow = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const rows = data.map((item, index) => (
    <View style={styles.row} key={index}>
      <View style={styles.product}>
        <Text>{item.productName}</Text>
      </View>

      <View style={styles.qty}>
        <Text>{item.quantity}</Text>
      </View>

      <View style={styles.price}>
        <Text>
          {item?.unitCost
            ? formatCurrency(CurrencyEnum.USD, Number(item?.unitCost))
            : DEFAULT_CURRENCY}
        </Text>
      </View>

      <View style={styles.total}>
        <Text>
          {item?.subTotal
            ? formatCurrency(CurrencyEnum.USD, Number(item?.subTotal))
            : DEFAULT_CURRENCY}
        </Text>
      </View>
    </View>
  ));
  //#endregion Handle Function

  return <Fragment>{rows}</Fragment>;
};

export default PurchaseOrderProductRow;

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#333333',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 'auto',
    width: '100%',
  },

  product: {
    flex: 0.6,
    borderRightColor: '#333333',
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  qty: {
    flex: 0.15,
    borderRightColor: '#333333',
    borderRightWidth: 1,
    paddingRight: 8,
    paddingLeft: 8,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  price: {
    flex: 0.15,
    borderRightColor: '#333333',
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    paddingLeft: 8,
    height: 24,
    alignItems: 'flex-end',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  total: {
    flex: 0.15,
    textAlign: 'right',
    paddingRight: 8,
    paddingLeft: 8,
    height: 24,
    alignItems: 'flex-end',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

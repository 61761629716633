// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  getAuthUrl,
  getListAccountQuickBook,
  getListExpensesDefault,
  getListIncomeDefault,
  getListQuickBookCustomer,
  getListQuickBookItem,
  getListQuickBookTerms,
  getListQuickBookVendors,
  updateAccountIncomeSetting,
  updateAccountSetting,
  updateExpensesDefault,
  updateIncomeDefault,
} from './quickBookThunk';

export interface QuickBookState {
  isRefreshListExpensesDefault: boolean;
  isRefreshListIncomeDefault: boolean;
}

const initialState: QuickBookState = {
  isRefreshListExpensesDefault: false,
  isRefreshListIncomeDefault: false,
};

const quickBookSlice = createSlice({
  name: 'quickBookState',
  initialState,
  reducers: {
    setRefreshListExpensesDefault(state, action) {
      state.isRefreshListExpensesDefault = action.payload;
    },
    setRefreshListIncomeDefault(state, action) {
      state.isRefreshListIncomeDefault = action.payload;
    },
  },
  extraReducers(builder) {
    // Get Auth Url
    builder
      .addCase(getAuthUrl.pending, (state) => {})
      .addCase(getAuthUrl.fulfilled, (state, action) => {})
      .addCase(getAuthUrl.rejected, (state) => {});

    // Get list quick book customers
    builder
      .addCase(getListQuickBookCustomer.pending, (state) => {})
      .addCase(getListQuickBookCustomer.fulfilled, (state, action) => {})
      .addCase(getListQuickBookCustomer.rejected, (state) => {});

    // Get list quick book items
    builder
      .addCase(getListQuickBookItem.pending, (state) => {})
      .addCase(getListQuickBookItem.fulfilled, (state, action) => {})
      .addCase(getListQuickBookItem.rejected, (state) => {});

    // Get list quick book vendors
    builder
      .addCase(getListQuickBookVendors.pending, (state) => {})
      .addCase(getListQuickBookVendors.fulfilled, (state, action) => {})
      .addCase(getListQuickBookVendors.rejected, (state) => {});

    // Get list quick book terms
    builder
      .addCase(getListQuickBookTerms.pending, (state) => {})
      .addCase(getListQuickBookTerms.fulfilled, (state, action) => {})
      .addCase(getListQuickBookTerms.rejected, (state) => {});

    // Get list quick book expenses default
    builder
      .addCase(getListExpensesDefault.pending, (state) => {})
      .addCase(getListExpensesDefault.fulfilled, (state, action) => {})
      .addCase(getListExpensesDefault.rejected, (state) => {});

    // Get list quick book account
    builder
      .addCase(getListAccountQuickBook.pending, (state) => {})
      .addCase(getListAccountQuickBook.fulfilled, (state, action) => {})
      .addCase(getListAccountQuickBook.rejected, (state) => {});

    // Update Account Setting
    builder
      .addCase(updateAccountSetting.pending, (state) => {})
      .addCase(updateAccountSetting.fulfilled, (state, action) => {})
      .addCase(updateAccountSetting.rejected, (state) => {});

    // Update Expenses Default
    builder
      .addCase(updateExpensesDefault.pending, (state) => {})
      .addCase(updateExpensesDefault.fulfilled, (state, action) => {})
      .addCase(updateExpensesDefault.rejected, (state) => {});

    // Get List Income Default
    builder
      .addCase(getListIncomeDefault.pending, (state) => {})
      .addCase(getListIncomeDefault.fulfilled, (state, action) => {})
      .addCase(getListIncomeDefault.rejected, (state) => {});

    // Update Account Income Setting
    builder
      .addCase(updateAccountIncomeSetting.pending, (state) => {})
      .addCase(updateAccountIncomeSetting.fulfilled, (state, action) => {})
      .addCase(updateAccountIncomeSetting.rejected, (state) => {});

    // Update Income Default
    builder
      .addCase(updateIncomeDefault.pending, (state) => {})
      .addCase(updateIncomeDefault.fulfilled, (state, action) => {})
      .addCase(updateIncomeDefault.rejected, (state) => {});
  },
});

export const quickBookActions = quickBookSlice.actions;

export const selectJobResp = (state: RootState) => state.quickBook;
export const selectIsRefreshListExpensesDefault = (state: RootState) =>
  state.quickBook.isRefreshListExpensesDefault;
export const selectIsRefreshListIncomeDefault = (state: RootState) =>
  state.quickBook.isRefreshListIncomeDefault;

const quickBookReducer = quickBookSlice.reducer;

export default quickBookReducer;

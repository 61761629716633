// Libs
import classNames from 'classnames/bind';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';

// Components, Layouts, Pages
import { BaseButton, FormInput } from '~/components';

// Others
import { ButtonTypeEnum, InputAuthName, InputTypeStyleEnum } from '~/utils/enum';
import { IForgotPasswordPayload } from '~/utils/interface/auth';
import { publicRouteAbsolute } from '~/utils/constants/route';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingContext } from '~/context';
import { forgotPassword } from '~/thunks/auth/authThunk';
import { authActions } from '~/thunks/auth/authSlice';
import { EMPTY_STRING } from '~/utils/constants/common';

// Styles, images, icons
import styles from './ForgotPassword.module.scss';

const cx = classNames.bind(styles);

const schema = (t: TFunction) =>
  yup
    .object()
    .shape({
      email: yup
        .string()
        .required(t('common_validate_require_email'))
        .email(t('common_validate_format_email')),
    })
    .required();

const ForgotPassword = () => {
  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const navigate = useNavigate();

  const initialValues: IForgotPasswordPayload = useMemo(() => {
    return {
      email: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordPayload>({
    resolver: yupResolver(schema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleForgotPassword = async (data: IForgotPasswordPayload) => {
    const payload: IForgotPasswordPayload = {
      email: data.email,
    };

    loadingContext?.show();

    dispatch(forgotPassword(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        const { data } = res;

        navigate(`${publicRouteAbsolute.verifyCode}`, { state: payload });
        dispatch(authActions.setAccountId(data.accountId));
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='forgotPasswordPage' className={cx('forgotPasswordContainer')}>
      <form onSubmit={handleSubmit(handleForgotPassword)}>
        <div>
          <div className={cx('forgotPasswordTitle')}>{t('auth_forgot_password_title')}</div>
          <p className={cx('forgotPasswordNote')}>{t('admin_forgot_password_note')}</p>
          <div className={cx('formForgotPassword')}>
            <Controller
              name={InputAuthName.EMAIL}
              control={control}
              render={({ field }) => (
                <FormInput
                  label={t('admin_forgot_password_email_label')}
                  typeStyle={InputTypeStyleEnum.AUTH}
                  height={36}
                  value={field.value || EMPTY_STRING}
                  onChange={field.onChange}
                  errorMessage={errors.email?.message}
                />
              )}
            />
          </div>
          <BaseButton
            borderRadius={8}
            height={36}
            typeStyle={ButtonTypeEnum.AUTH}
            isBorder={true}
            type='submit'
            label={t('admin_forgot_password_send_button')}
          />
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;

// Libs
import classNames from 'classnames/bind';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AdminAddScheduleModal,
  BaseCheckbox,
  BaseFilter,
  CircleAvatar,
  FullSchedule,
  ModalUnderDevelopment,
  MySchedule,
  SearchDropdown,
  Toolbar,
} from '~/components';
// Others
import { capitalizeFirstLetter } from '~/components/specific/scheduleModules/helper';
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { getScheduleEvents } from '~/thunks/schedule/scheduleThunk';
import {
  DEFAULT_SCHEDULE_VIEWS,
  SCHEDULE_MODE_FULL,
  SCHEDULE_MODE_MYSELF,
} from '~/utils/constants/component';
import { checkTimelineMode, getFullName } from '~/utils/helper';
import { IListDataResponse } from '~/utils/interface/common';
import {
  IFilterSchedule,
  IParamsGetSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
} from '~/utils/interface/schedule';
import { TCalendarViewMode, TScheduleMode } from '~/utils/type/schedule';
import { AccountRoleCodesEnum, CircleAvatarEnum, RolesEnum } from '~/utils/enum';
import { getListClient } from '~/thunks/client/clientThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { CYAN600, WHITE } from '~/utils/constants/color';
import { getListAccountMultipleRole } from '~/thunks/accountant/accountantThunk';
import { IJobFilterSchedule } from '~/utils/interface/job';
import { getListJob } from '~/thunks/job/jobThunk';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './Schedule.module.scss';
import { CommonIconPlus } from '~/assets/svgComponents';

type Props = {
  role: RolesEnum;
};

const cx = classNames.bind(styles);

const ManageSchedule = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  const { calendarView, scheduleMode, currentTime, refreshSchedule } = useAppSelector(
    (state: RootState) => state.scheduleState
  );
  //#endregion Selector

  //#region Declare Memo
  const timeValue = useMemo(() => {
    switch (calendarView) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM YYYY');
      default:
        return moment(currentTime).format('MMMM, YYYY');
    }
  }, [calendarView, currentTime]);

  const isTimelineMode = useMemo(
    () => checkTimelineMode(calendarView, scheduleMode),
    [calendarView, scheduleMode]
  );
  //#endregion Declare Memo

  //#region Declare State
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isShowModalUnderDev, setIsShowModalUnderDev] = useState<boolean>(false);
  const [scheduleEvents, setScheduleEvents] = useState<IScheduleEvent[]>([]);
  const [timelineEvents, setTimelineEvents] = useState<IScheduleTimelineData[]>([]);
  const [actionScheduleMode] = useState<string>(
    role === RolesEnum.ADMIN ? SCHEDULE_MODE_FULL : SCHEDULE_MODE_MYSELF
  );
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (role !== RolesEnum.ADMIN) return;

    handleChangeScheduleMode(SCHEDULE_MODE_FULL);
  }, []);

  useEffect(() => {
    const startDate = moment(currentTime).startOf(calendarView).format('YYYY-MM-DD');
    const endDate = moment(currentTime).endOf(calendarView).format('YYYY-MM-DD');
    const view = calendarView.toUpperCase();
    let type =
      role !== RolesEnum.ADMIN ? scheduleMode.toUpperCase() : actionScheduleMode.toUpperCase();
    const { clientId, jobId, projectManagerId, plumberId } = params;
    const queryParam: IParamsGetSchedule = {
      ...(scheduleMode === 'full' && (calendarView === 'week' || calendarView === 'day')
        ? { page: DEFAULT_CURRENT_PAGE, limit: DEFAULT_NUMBER_OPTIONS_SELECT }
        : {}),
      clientId,
      jobId,
      projectManagerId,
      plumberId,
      startDate,
      endDate,
      view,
      type,
    };

    handleFetchScheduleEvents(queryParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTime,
    calendarView,
    refreshSchedule,
    ...(role === RolesEnum.STAFF ? [scheduleMode] : []),
    params,
  ]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFetchScheduleEvents = (queryParam: IParamsGetSchedule) => {
    loadingData?.show();
    setScheduleEvents([]);
    setTimelineEvents([]);

    dispatch(getScheduleEvents(queryParam))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        if (isTimelineMode) {
          const { responses } = res as IListDataResponse<IScheduleTimelineData[]>;
          setTimelineEvents(responses);
          return;
        }

        const responses = res as IScheduleEvent[];
        setScheduleEvents(responses);
      })
      .catch((err) => {
        loadingData?.hide();
        // TODO: SonVuGRF-ABD - Handle error
      });
  };

  const goPreviousTime = () => {
    dispatch(scheduleActions.goPreviousTime());
  };

  const goNextTime = () => {
    dispatch(scheduleActions.goNextTime());
  };

  const goToday = () => {
    dispatch(scheduleActions.goToday());
  };

  const handleChangeCalendarView = (newValue: TCalendarViewMode): void => {
    dispatch(scheduleActions.changeCalendarViewMode(newValue));
  };

  const handleChangeScheduleMode = (newValue: TScheduleMode) => {
    dispatch(scheduleActions.changeScheduleMode(newValue));

    if (newValue === SCHEDULE_MODE_MYSELF) {
      setSearchParams({});
    }
  };

  const handleToggleModalAdd = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleAddScheduleSuccess = () => {
    dispatch(scheduleActions.setRefreshScheduleList(!refreshSchedule));
    handleToggleModalAdd();
  };

  const handleCloseUnderDev = () => {
    setIsShowModalUnderDev(false);
  };

  const handleSelectedEventFullSchedule = (event: IScheduleEvent) => {
    // HieuGRF-ABD - Handle selected event full schedule
  };

  const handleApplyFilterSchedule = (valueFilter: IFilterSchedule) => {
    const { clientId, jobId, projectManagerId, plumberId, ...restParams } = params;

    // Handle Set Param Later
    setSearchParams({
      ...restParams,
      ...(valueFilter?.clientId ? { clientId: valueFilter?.clientId } : {}),
      ...(valueFilter?.jobId ? { jobId: valueFilter?.jobId } : {}),
      ...(valueFilter?.projectManagerId ? { projectManagerId: valueFilter?.projectManagerId } : {}),
      ...(valueFilter?.plumberId ? { plumberId: valueFilter?.plumberId } : {}),
    });
  };

  const fetchOptionsClient = useCallback(async (search: string, page: number) => {
    try {
      const listClient = await dispatch(
        getListClient({
          page: page,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listClient.data.responses,
        hasMore: listClient.data.pagination.page < listClient.data.pagination.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchOptionsProjectManager = useCallback(async (search: string, page: number) => {
    try {
      const paramsProjectManager = {
        page: page,
        limit: DEFAULT_NUMBER_OPTIONS_SELECT,
        roles: [AccountRoleCodesEnum.PROJECT_MANAGER],
        ...(search ? { searchKey: search } : {}),
      };

      const listProjectManager = await dispatch(
        getListAccountMultipleRole(paramsProjectManager)
      ).unwrap();

      return {
        items: listProjectManager.data.responses,
        hasMore:
          listProjectManager.data.pagination.page < listProjectManager.data.pagination.totalPages ||
          false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchOptionsPlumber = useCallback(async (search: string, page: number) => {
    try {
      const paramsProjectManager = {
        page: page,
        limit: DEFAULT_NUMBER_OPTIONS_SELECT,
        roles: [AccountRoleCodesEnum.TECHNICIAN],
        ...(search ? { searchKey: search } : {}),
      };

      const listPlumber = await dispatch(getListAccountMultipleRole(paramsProjectManager)).unwrap();

      return {
        items: listPlumber.data.responses,
        hasMore: listPlumber.data.pagination.page < listPlumber.data.pagination.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);

  const fetchOptionsJob = useCallback(async (search: string, page: number) => {
    try {
      const listJob = await dispatch(
        getListJob({
          page: page,
          limit: DEFAULT_NUMBER_OPTIONS_SELECT,
          ...(search ? { searchKey: search } : {}),
        })
      ).unwrap();

      return {
        items: listJob.data.responses,
        hasMore: listJob.data.pagination.page < listJob.data.pagination.totalPages || false,
      };
    } catch (error) {
      return {
        items: [],
        hasMore: false,
      };
    }
  }, []);
  //#endregion Handle Function
  return (
    <div id='manageSchedule' className={cx('manageScheduleContainer')}>
      <Toolbar
        title={t('admin_manage_schedule_title')}
        primaryBtn={{
          action: handleToggleModalAdd,
          label: t('admin_manage_schedule_btn_add_title'),
        }}
      />

      <div className={cx('body')}>
        <div className={cx('toolbarGroup')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('btnControl')}>
              <button className={cx('dateControlBtn', 'btnPrev')} onClick={goPreviousTime}>
                <img
                  className={cx('dateControlIcon')}
                  src={icons.commonIconPrev}
                  alt={t('common_img_text_alt')}
                />
              </button>
              <button className={cx('dateControlBtn', 'btnNext')} onClick={goNextTime}>
                <img
                  className={cx('dateControlIcon')}
                  src={icons.commonIconNext}
                  alt={t('common_img_text_alt')}
                />
              </button>
            </div>

            <button className={cx('btnToday')} onClick={goToday}>
              {t('common_component_schedule_btn_today')}
            </button>
          </div>

          <div className={cx('timeValue')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            {scheduleMode === SCHEDULE_MODE_FULL && (
              <BaseFilter<IFilterSchedule>
                defaultValue={{}}
                onApply={handleApplyFilterSchedule}
                valueFilter={{
                  clientId: params?.clientId || EMPTY_STRING,
                  jobId: params?.jobId || EMPTY_STRING,
                  projectManagerId: params?.projectManagerId || EMPTY_STRING,
                  plumberId: params?.plumberId || EMPTY_STRING,
                }}
              >
                {({ isChecked, valueFilter, onChange, onCheckboxChange }) => {
                  return (
                    <div className={cx('filterContainer')}>
                      <div className={cx('filterGroup')}>
                        <BaseCheckbox
                          name='clientId'
                          label={t('admin_manage_schedule_filter_by_client')}
                          value={!!valueFilter?.clientId || isChecked?.clientId}
                          onChange={(checked) => {
                            onCheckboxChange('clientId', checked);
                          }}
                        />

                        {isChecked?.clientId && (
                          <div className={cx('contentFilterWrap')}>
                            <SearchDropdown
                              fetchOptions={fetchOptionsClient}
                              name='clientId'
                              height={31}
                              optionsWidth={200}
                              value={valueFilter?.clientId}
                              placeholder={t('common_placeholder_select')}
                              onChange={(item, name) => {
                                onChange({
                                  name: name as keyof IFilterSchedule,
                                  value: item?.id,
                                });
                              }}
                              renderLabel={(item, onClose) => {
                                return (
                                  <div className={cx('tagAssigneeSelected')}>
                                    <CircleAvatar
                                      width={16}
                                      height={16}
                                      fontSize={8}
                                      imageUrl={item.avatar}
                                      firstName={item.firstName}
                                      lastName={item.lastName}
                                      type={
                                        item.avatar ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT
                                      }
                                    />

                                    <span className={cx('tagFirstName')}>
                                      {getFullName({ ...item })}
                                    </span>

                                    <img
                                      src={icons.commonIconCloseModal}
                                      className={cx('iconCloseAssignee')}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        onClose();
                                      }}
                                      alt={t('common_img_text_alt')}
                                    />
                                  </div>
                                );
                              }}
                              renderOption={(item, selectedValue) => {
                                return (
                                  <div className={cx('optionSelect')}>
                                    <div className={cx('avatarFirstLastName')}>
                                      <CircleAvatar
                                        width={20}
                                        height={20}
                                        fontSize={10}
                                        firstName={item.firstName}
                                        lastName={item.lastName}
                                        imageUrl={item.avatar}
                                        type={
                                          item.avatar
                                            ? CircleAvatarEnum.IMAGE
                                            : CircleAvatarEnum.TEXT
                                        }
                                      />

                                      <div className={cx('firstLastName')}>
                                        {getFullName({ ...item })}
                                      </div>
                                    </div>

                                    <CommonIconPlus
                                      width={16}
                                      height={16}
                                      strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                    />
                                  </div>
                                );
                              }}
                              getOptionKey={(item) => {
                                return item.id;
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className={cx('filterGroup')}>
                        <BaseCheckbox
                          name='jobId'
                          label={t('admin_manage_schedule_filter_by_job')}
                          value={!!valueFilter?.jobId || isChecked?.jobId}
                          onChange={(checked) => {
                            onCheckboxChange('jobId', checked);
                          }}
                        />

                        {isChecked?.jobId && (
                          <div className={cx('contentFilterWrap')}>
                            <SearchDropdown<IJobFilterSchedule>
                              fetchOptions={fetchOptionsJob}
                              name='jobId'
                              height={31}
                              optionsWidth={200}
                              value={valueFilter?.jobId}
                              placeholder={t('common_placeholder_select')}
                              onChange={(item, name) => {
                                onChange({
                                  name: name as keyof IFilterSchedule,
                                  value: item?.id,
                                });
                              }}
                              renderLabel={(item, onClose) => {
                                return (
                                  <div className={cx('tagAssigneeSelected')}>
                                    <span className={cx('tagJobName')}>{item.jobName}</span>

                                    <img
                                      src={icons.commonIconCloseModal}
                                      className={cx('iconCloseAssignee')}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        onClose();
                                      }}
                                      alt={t('common_img_text_alt')}
                                    />
                                  </div>
                                );
                              }}
                              renderOption={(item, selectedValue) => {
                                return (
                                  <div className={cx('optionSelect')}>
                                    <div className={cx('avatarFirstLastName')}>
                                      <div className={cx('firstLastName')}>{item.jobName}</div>
                                    </div>

                                    <CommonIconPlus
                                      width={16}
                                      height={16}
                                      strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                    />
                                  </div>
                                );
                              }}
                              getOptionKey={(item) => {
                                return item.id;
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className={cx('filterGroup')}>
                        <BaseCheckbox
                          name='projectManagerId'
                          label={t('admin_manage_schedule_filter_by_project_manager')}
                          value={!!valueFilter?.projectManagerId || isChecked?.projectManagerId}
                          onChange={(checked) => {
                            onCheckboxChange('projectManagerId', checked);
                          }}
                        />

                        {isChecked?.projectManagerId && (
                          <div className={cx('contentFilterWrap')}>
                            <SearchDropdown
                              fetchOptions={fetchOptionsProjectManager}
                              name='projectManagerId'
                              height={31}
                              optionsWidth={200}
                              value={valueFilter?.projectManagerId}
                              placeholder={t('common_placeholder_select')}
                              onChange={(item, name) => {
                                onChange({
                                  name: name as keyof IFilterSchedule,
                                  value: item?.id,
                                });
                              }}
                              renderLabel={(item, onClose) => {
                                return (
                                  <div className={cx('tagAssigneeSelected')}>
                                    <CircleAvatar
                                      width={16}
                                      height={16}
                                      fontSize={8}
                                      imageUrl={item.avatarUrl}
                                      firstName={item.firstName}
                                      lastName={item.lastName}
                                      type={
                                        item.avatarUrl
                                          ? CircleAvatarEnum.IMAGE
                                          : CircleAvatarEnum.TEXT
                                      }
                                    />

                                    <span className={cx('tagFirstName')}>
                                      {getFullName({ ...item })}
                                    </span>

                                    <img
                                      src={icons.commonIconCloseModal}
                                      className={cx('iconCloseAssignee')}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        onClose();
                                      }}
                                      alt={t('common_img_text_alt')}
                                    />
                                  </div>
                                );
                              }}
                              renderOption={(item, selectedValue) => {
                                return (
                                  <div className={cx('optionSelect')}>
                                    <div className={cx('avatarFirstLastName')}>
                                      <CircleAvatar
                                        width={20}
                                        height={20}
                                        fontSize={10}
                                        firstName={item.firstName}
                                        lastName={item.lastName}
                                        imageUrl={item.avatarUrl}
                                        type={
                                          item.avatarUrl
                                            ? CircleAvatarEnum.IMAGE
                                            : CircleAvatarEnum.TEXT
                                        }
                                      />

                                      <div className={cx('firstLastName')}>
                                        {getFullName({ ...item })}
                                      </div>
                                    </div>

                                    <CommonIconPlus
                                      width={16}
                                      height={16}
                                      strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                    />
                                  </div>
                                );
                              }}
                              getOptionKey={(item) => {
                                return item.id;
                              }}
                            />
                          </div>
                        )}
                      </div>

                      <div className={cx('filterGroup')}>
                        <BaseCheckbox
                          name='plumberId'
                          label={t('admin_manage_schedule_filter_by_plumber')}
                          value={!!valueFilter?.plumberId || isChecked?.plumberId}
                          onChange={(checked) => {
                            onCheckboxChange('plumberId', checked);
                          }}
                        />

                        {isChecked?.plumberId && (
                          <div className={cx('contentFilterWrap')}>
                            <SearchDropdown
                              fetchOptions={fetchOptionsPlumber}
                              name='plumberId'
                              height={31}
                              optionsWidth={200}
                              value={valueFilter?.plumberId}
                              placeholder={t('common_placeholder_select')}
                              onChange={(item, name) => {
                                onChange({
                                  name: name as keyof IFilterSchedule,
                                  value: item?.id,
                                });
                              }}
                              renderLabel={(item, onClose) => {
                                return (
                                  <div className={cx('tagAssigneeSelected')}>
                                    <CircleAvatar
                                      width={16}
                                      height={16}
                                      fontSize={8}
                                      imageUrl={item.avatarUrl}
                                      firstName={item.firstName}
                                      lastName={item.lastName}
                                      type={
                                        item.avatarUrl
                                          ? CircleAvatarEnum.IMAGE
                                          : CircleAvatarEnum.TEXT
                                      }
                                    />

                                    <span className={cx('tagFirstName')}>
                                      {getFullName({ ...item })}
                                    </span>

                                    <img
                                      src={icons.commonIconCloseModal}
                                      className={cx('iconCloseAssignee')}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        onClose();
                                      }}
                                      alt={t('common_img_text_alt')}
                                    />
                                  </div>
                                );
                              }}
                              renderOption={(item, selectedValue) => {
                                return (
                                  <div className={cx('optionSelect')}>
                                    <div className={cx('avatarFirstLastName')}>
                                      <CircleAvatar
                                        width={20}
                                        height={20}
                                        fontSize={10}
                                        firstName={item.firstName}
                                        lastName={item.lastName}
                                        imageUrl={item.avatarUrl}
                                        type={
                                          item.avatarUrl
                                            ? CircleAvatarEnum.IMAGE
                                            : CircleAvatarEnum.TEXT
                                        }
                                      />

                                      <div className={cx('firstLastName')}>
                                        {getFullName({ ...item })}
                                      </div>
                                    </div>

                                    <CommonIconPlus
                                      width={16}
                                      height={16}
                                      strokePath={selectedValue?.id === item.id ? WHITE : CYAN600}
                                    />
                                  </div>
                                );
                              }}
                              getOptionKey={(item) => {
                                return item.id;
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }}
              </BaseFilter>
            )}

            {role !== RolesEnum.ADMIN && (
              <div className={cx('btnGroup')}>
                <button
                  className={cx('btnCalendar', scheduleMode === SCHEDULE_MODE_MYSELF && 'active')}
                  onClick={() => handleChangeScheduleMode(SCHEDULE_MODE_MYSELF)}
                >
                  {t('common_component_schedule_btn_myself_mode')}
                </button>
                <button
                  className={cx('btnCalendar', scheduleMode === SCHEDULE_MODE_FULL && 'active')}
                  onClick={() => handleChangeScheduleMode(SCHEDULE_MODE_FULL)}
                >
                  {t('common_component_schedule_btn_full_mode')}
                </button>
              </div>
            )}

            <div className={cx('btnGroup')}>
              {Object.keys(DEFAULT_SCHEDULE_VIEWS).map((view: string, index: number) => {
                return (
                  <button
                    key={index}
                    className={cx('btnCalendar', view === calendarView && 'active')}
                    onClick={() => handleChangeCalendarView(view as TCalendarViewMode)}
                  >
                    {capitalizeFirstLetter(view)}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className={cx('scheduleWrap')}>
          {scheduleMode === SCHEDULE_MODE_FULL ? (
            <FullSchedule
              view={calendarView}
              date={currentTime}
              events={isTimelineMode ? timelineEvents : scheduleEvents}
              onSelectEvent={handleSelectedEventFullSchedule}
            />
          ) : (
            <MySchedule view={calendarView} date={currentTime} events={scheduleEvents} />
          )}
        </div>
      </div>

      {isOpenModal && (
        <AdminAddScheduleModal
          isOpen={isOpenModal}
          onClose={handleToggleModalAdd}
          onSuccess={handleAddScheduleSuccess}
        />
      )}
      {isShowModalUnderDev && <ModalUnderDevelopment onClose={handleCloseUnderDev} />}
    </div>
  );
};

export default ManageSchedule;

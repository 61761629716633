// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { LabelValueField, Status } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getVendorDetails } from '~/thunks/vendors/vendorsThunk';
import { IContactFullVendor, IDetailVendorData } from '~/utils/interface/vendors';
import { DEFAULT_CURRENCY, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatAddress, formatCurrency, formatVendorType } from '~/utils/helper';
// Styles, images, icons
import styles from './BasicInformation.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const BasicInformation = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { vendorId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [vendorDetail, setVendorDetail] = useState<IDetailVendorData>();
  const [additionalContacts, setAdditionalContacts] = useState<IContactFullVendor[]>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!vendorId) return;

    handleGetVendorDetails(vendorId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetVendorDetails = (vendorId: string) => {
    loadingData?.show();

    dispatch(getVendorDetails(vendorId))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const contactData: IContactFullVendor[] = res?.data?.contacts?.map((item, index) => ({
          id: index.toString(),
          name: item?.name || EMPTY_STRING,
          email: item?.email || EMPTY_STRING,
          phoneNumber: item?.phoneNumber || EMPTY_STRING,
          title: item?.title || EMPTY_STRING,
          countryCode: item?.countryCode || EMPTY_STRING,
        }));

        setAdditionalContacts(contactData || []);
        setVendorDetail(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='basicInformationComponent' className={cx('container')}>
      <div className={cx('basicInformationWrap')}>
        <div className={cx('vendorDetailWrap')}>
          <div className={cx('vendorDetailBody')}>
            <LabelValueField
              label={t('admin_manage_vendors_detail_vendor_name_title')}
              value={vendorDetail?.name || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_contact_name_title')}
              value={vendorDetail?.contactName || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_address_title')}
              value={formatAddress({ ...vendorDetail, address: vendorDetail?.address })}
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_email_title')}
              value={vendorDetail?.email || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_other_title')}
              value={vendorDetail?.other || EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_vendor_type_title')}
              value={vendorDetail?.type ? formatVendorType(vendorDetail?.type) : EMPTY_STRING}
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_pos_title')}
              value={
                vendorDetail?.pos
                  ? formatCurrency(CurrencyEnum.USD, vendorDetail?.pos)
                  : DEFAULT_CURRENCY
              }
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_outstanding_bills_title')}
              value={
                vendorDetail?.outstandingBills
                  ? formatCurrency(CurrencyEnum.USD, vendorDetail?.outstandingBills)
                  : DEFAULT_CURRENCY
              }
            />

            <LabelValueField
              label={t('admin_manage_vendors_detail_status_title')}
              value={vendorDetail?.status ? <Status status={vendorDetail?.status} /> : EMPTY_STRING}
            />

            <LabelValueField
              label={t('template_client_detail_status_quick_book_label')}
              value={
                vendorDetail?.quickBookVendorId ? (
                  <div className={cx('greenCircle')} />
                ) : (
                  <div className={cx('grayCircle')} />
                )
              }
            />
          </div>
        </div>

        <div className={cx('additionalContactWrap')}>
          <div className={cx('titleGroup')}>
            {t('admin_manage_vendors_detail_group_additional_contacts_title')}
          </div>

          <div className={cx('additionalContactWrap')}>
            {Array.isArray(additionalContacts) &&
            additionalContacts?.length > DEFAULT_NUMBER_ZERO ? (
              <div className={cx('contact')}>
                <div className={cx('contactHeader')}>
                  <span className={cx('titleContactHeader')}>
                    {t('admin_manage_vendors_detail_form_name_title')}
                  </span>

                  <span className={cx('titleContactHeader')}>
                    {t('admin_manage_vendors_detail_form_title')}
                  </span>

                  <span className={cx('titleContactHeader')}>
                    {t('admin_manage_vendors_detail_form_email_title')}
                  </span>

                  <span className={cx('titleContactHeader')}>
                    {t('admin_manage_vendors_detail_form_phone_title')}
                  </span>
                </div>

                {additionalContacts?.map((item) => (
                  <div key={item.id} className={cx('additionalContactDetailItem')}>
                    <span className={cx('titleContact')}>
                      {item.name ? item.name : EMPTY_STRING}
                    </span>

                    <span className={cx('titleContact')}>
                      {item.title ? item.title : EMPTY_STRING}
                    </span>

                    <span className={cx('titleContact')}>
                      {item.email ? item.email : EMPTY_STRING}
                    </span>

                    <span className={cx('titleContact')}>
                      {item.phoneNumber ? item.phoneNumber : EMPTY_STRING}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <p className={cx('textNoData')}>{t('common_empty_data')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;

import { TFunction } from 'i18next';
import * as yup from 'yup';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ILineItems } from '~/utils/interface/proposal';

export const lineItemSchema = (t: TFunction) => {
  return yup.object({
    name: yup.string().required(t('template_proposal_line_items_name_required')),
    price: yup.string().required(t('template_proposal_line_items_price_required')),
    isTax: yup.boolean().optional(),
    comment: yup.string().optional().nullable(),
    linkPhase: yup.object().optional(),
    materials: yup.array().optional(),
    subcontractor: yup.object().optional(),
    labors: yup.array().optional(),
    equipments: yup.array().optional(),
    miscellanea: yup.array().optional(),
  });
};

export const proposalSchema = (t: TFunction) => {
  return yup.object({
    jobId: yup.string().optional(),
    attn: yup.string().optional().nullable(),
    po: yup.string().optional().nullable(),
    contract: yup.string().optional().nullable(),
    estimateEnd: yup.string().optional().nullable(),
    paymentTerm: yup.string().optional().nullable(),
    description: yup.string().optional().nullable(),
    termsConditions: yup.string().optional().nullable(),
    taxRate: yup.string().optional().nullable(),
    estimateStart: yup.string().required(t('template_proposal_estimate_start_date_required')),
    lineItems: yup.array(lineItemSchema(t)).required(t('template_proposal_line_items_required')),
    quickBookTermId: yup.string().optional().nullable(),
  });
};

export const defaultDataLineItem: ILineItems = {
  name: EMPTY_STRING,
  price: EMPTY_STRING,
  isTax: false,
  subcontractor: {},
  equipments: [],
  labors: [],
  materials: [],
  miscellanea: [],
  isShowBreakDown: false,
  linkPhases: [],
};

// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseCheckbox,
  BaseFilter,
  BaseMoreAction,
  // FormProductModal,
  BasePagination,
  BaseSelect,
  BaseSelectSearchLocal,
  BaseTable,
  ConfirmModal,
  ModalUnderDevelopment,
  ThreeDotOptions,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { productActions, selectIsRefreshProductList } from '~/thunks/product/productSlice';
import {
  deleteProduct,
  getListProducts,
  sendToQuickBocksProduct,
} from '~/thunks/product/productThunk';
import { getListVendors } from '~/thunks/vendors/vendorsThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  PRODUCT_CATEGORY_OPTIONS,
} from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  FilterProductByCategoryEnum,
  FilterVendorByStatusEnum,
  StorageEnum,
} from '~/utils/enum';
import { formatNumber } from '~/utils/helper';
import useDebounce from '~/utils/hooks/useDebounce';
import { ColumnType, IAddAssignee, IPagination, MoreActionItem } from '~/utils/interface/common';
import { IFiltersProduct, IProduct, IQueryListProduct } from '~/utils/interface/product';
import { IQueryListVendor, IVendor } from '~/utils/interface/vendors';
// Styles, images, icons
import styles from './Products.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  handleShowProductDetail: (productId: string) => void,
  handleShowEditProductModal: (productDetail: IProduct) => void,
  handleShowConfirmDeleteModal: (id: string) => void,
  handleSendToQBProduct: (id: string) => void
): ColumnType<IProduct>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={() => handleSelectAll()}
    //     />
    //   ),
    //   render(_, record: IProduct) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'name',
      title: t('admin_manage_products_table_product_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
      width: '10%',
    },
    {
      key: 'description',
      title: t('admin_manage_products_table_description'),
      dataIndex: 'description',
      render: (_, record) => <span>{record?.description || EMPTY_STRING}</span>,
    },
    {
      key: 'extendedDescription',
      title: t('admin_manage_products_table_extended_description'),
      dataIndex: 'extendedDescription',
      render: (_, record) => <span>{record?.extendedDescription || EMPTY_STRING}</span>,
    },
    // {
    //   key: 'unitMeasure',
    //   title: t('admin_manage_products_table_unit_of_measure'),
    //   dataIndex: 'unitMeasure',
    //   render: (_, record) => <span>{record?.unitMeasure || EMPTY_STRING}</span>,
    // },
    // {
    //   key: 'unitPrice',
    //   title: t('admin_manage_products_table_unit_price'),
    //   dataIndex: 'unitPrice',
    //   render: (_, record: IProduct) => (
    //     <div>
    //       {record.unitPrice ? (
    //         <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitPrice))}</span>
    //       ) : (
    //         <span>{EMPTY_STRING}</span>
    //       )}
    //     </div>
    //   ),
    // },
    // {
    //   key: 'unitCost',
    //   title: t('admin_manage_products_table_unit_cost'),
    //   dataIndex: 'unitCost',
    //   render: (_, record: IProduct) => (
    //     <div>
    //       {record.unitCost ? (
    //         <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitCost))}</span>
    //       ) : (
    //         <span>{EMPTY_STRING}</span>
    //       )}
    //     </div>
    //   ),
    // },
    {
      key: 'quickBookItemId',
      title: (
        <span className={cx('titleQuickBook')}>{t('admin_manage_products_table_quick_book')}</span>
      ),
      dataIndex: 'quickBookItemId',
      render: (_, record) => (
        <div className={cx('quickBookWrap')}>
          {record?.quickBookItemId ? (
            <div className={cx('greenCircle')} />
          ) : (
            <div className={cx('grayCircle')} />
          )}
        </div>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_products_table_action'),
      dataIndex: 'id',
      render: (_, record) => {
        const isQBLinked = record?.quickBookItemId;

        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleShowProductDetail(record.id),
          },
          {
            label: t('common_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleShowEditProductModal(record),
          },
          ...(!isQBLinked
            ? [
                {
                  label: t('common_action_send_quick_books'),
                  icon: (
                    <img
                      src={icons.commonIconSend}
                      alt={t('common_img_text_alt')}
                      width={16}
                      height={16}
                    />
                  ),
                  onClick: () => handleSendToQBProduct(record?.id),
                },
              ]
            : []),
          {
            label: t('common_btn_delete'),
            icon: (
              <img
                src={icons.commonIconTrash}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => handleShowConfirmDeleteModal(record.id),
          },
        ];
        return <BaseMoreAction actions={actions} />;
      },
      width: '10%',
    },
  ];
};

const ManageProducts = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const currentRole = localStorage.getItem(StorageEnum.ROLE);
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  const isRefreshProductsList: boolean = useSelector(selectIsRefreshProductList);
  //#endregion Selector

  //#region Declare State
  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isShowFormProduct, setIsShowFormProduct] = useState<boolean>(false);
  const [productId, setProductId] = useState<string>(EMPTY_STRING);
  const [productDetail, setProductDetail] = useState<IProduct | null>(null);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);

  const [listVendor, setListVendor] = useState<IVendor[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const payload: IQueryListVendor = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      status: FilterVendorByStatusEnum.ACTIVE,
    };

    handleGetListVendors(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(params?.category ? { category: params?.category } : {}),
      ...(params?.vendorId ? { vendorId: params?.vendorId } : {}),
    };

    handleGetListProducts(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!isRefreshProductsList) return;

    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
      ...(params?.category ? { category: params?.category } : {}),
      ...(params?.vendorId ? { vendorId: params?.vendorId } : {}),
    };

    handleGetListProducts(newParams);
    dispatch(productActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshProductsList]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (productsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = productsList.every((product: IProduct) => product.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = productsList
      .filter((employee: IProduct) => employee.isSelected)
      .map((employee: IProduct) => employee.id);

    //@Todo: Perform interactions with the employee id list
  }, [productsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListVendors = (payload: IQueryListVendor) => {
    dispatch(getListVendors(payload))
      .unwrap()
      .then((res) => {
        if (!res || !res?.data) return;

        const { responses } = res?.data;

        setListVendor(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListProducts = (payload: IQueryListProduct) => {
    if (!payload) return;

    loadingData?.show();

    dispatch(getListProducts(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setProductsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDeleteProduct = () => {
    if (!productId) return;

    loadingData?.show();
    dispatch(deleteProduct(productId))
      .unwrap()
      .then((res) => {
        handleActionProductSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleAddProduct = () => {
    const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;

    const routes = {
      [AccountRoleCodesEnum.ADMIN]: adminRouteAbsolute.createProduct,
      [AccountRoleCodesEnum.STAFF]: staffRouteAbsolute.createProduct,
      [AccountRoleCodesEnum.PROJECT_MANAGER]: projectManageRouteAbsolute.createProduct,
    };

    if (role && routes[role as keyof typeof routes]) {
      navigate(routes[role as keyof typeof routes]);
    }
  };

  const handleShowConfirmDeleteModal = (id?: string) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);

    if (id) {
      setProductId(id);
    }
  };

  const handleUpdateProduct = (productDetail: IProduct) => {
    const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;
    const routes = {
      [AccountRoleCodesEnum.ADMIN]: `${adminRouteAbsolute.editProduct}/${productDetail?.id}`,
      [AccountRoleCodesEnum.STAFF]: `${staffRouteAbsolute.editProduct}/${productDetail?.id}`,
      [AccountRoleCodesEnum.PROJECT_MANAGER]: `${projectManageRouteAbsolute.editProduct}/${productDetail?.id}`,
    };

    if (role && routes[role as keyof typeof routes]) {
      navigate(routes[role as keyof typeof routes]);
    }
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleActionProductSuccess = () => {
    dispatch(productActions.setRefreshList(true));
    setIsShowFormProduct(false);
    setProductDetail(null);
  };

  const handleSelectItem = (id: string) => {
    setProductsList((prevList: IProduct[]) =>
      prevList.map((employee: IProduct) =>
        employee.id === id ? { ...employee, isSelected: !employee.isSelected } : employee
      )
    );
  };

  const handleSelectAll = () => {
    setProductsList((prevList) =>
      prevList.map((employee: IProduct) => ({
        ...employee,
        isSelected: !isSelectAll,
      }))
    );
  };

  const handleCLickRow = (record: IProduct) => {
    if (!record.id) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.products}/${record.id}`);
      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.products}/${record.id}`);
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.products}/${record.id}`);
    }
  };

  const handleSearchProduct = (value: string) => {
    setSearchKey(value);
  };

  const handleShowProductDetail = (productId: string) => {
    if (!productId) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(`${adminRouteAbsolute.products}/${productId}`);
      case AccountRoleCodesEnum.STAFF:
        return navigate(`${staffRouteAbsolute.products}/${productId}`);
      case AccountRoleCodesEnum.PROJECT_MANAGER:
        return navigate(`${projectManageRouteAbsolute.products}/${productId}`);
    }
  };

  const handleApplyFilterVendor = (valueFilter: IFiltersProduct) => {
    const { category, vendorId, ...restParams } = params;

    setSearchParams({
      ...restParams,
      page: DEFAULT_CURRENT_PAGE.toString(),
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
      ...(valueFilter?.category ? { category: valueFilter?.category } : {}),
      ...(valueFilter?.vendorId ? { vendorId: valueFilter?.vendorId } : {}),
    });
  };

  const handleSendToQBProduct = (productId: string) => {
    if (!productId) return;

    loadingData?.show();
    dispatch(sendToQuickBocksProduct(productId))
      .unwrap()
      .then((res) => {
        dispatch(productActions.setRefreshList(true));
      })
      .catch((_error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='manageProductsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_products_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleAddProduct,
          label: t('admin_manage_products_btn_add_product'),
        }}
        onSearch={handleSearchProduct}
        valueSearch={searchKey || EMPTY_STRING}
      >
        <BaseFilter<IFiltersProduct>
          defaultValue={{}}
          onApply={handleApplyFilterVendor}
          valueFilter={{
            category: params?.category as FilterProductByCategoryEnum,
            vendorId: params?.vendorId,
          }}
        >
          {({ valueFilter, onCheckboxChange, isChecked, onChange }) => {
            return (
              <div className={cx('filterContainer')}>
                <div className={cx('filterGroup')}>
                  <BaseCheckbox
                    name='category'
                    label={t('admin_manage_products_filter_category_label')}
                    value={!!valueFilter?.category}
                    onChange={(checked) => {
                      onCheckboxChange('category', checked);
                    }}
                  />

                  {isChecked?.category && (
                    <div className={cx('contentFilterWrap')}>
                      <BaseSelect
                        name='category'
                        options={PRODUCT_CATEGORY_OPTIONS || []}
                        height={31}
                        value={valueFilter?.category}
                        placeholder={t('common_placeholder_select')}
                        onChange={({ value }, name) => {
                          onChange({
                            name: name as keyof IFiltersProduct,
                            value: value as FilterProductByCategoryEnum,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className={cx('filterGroup')}>
                  <BaseCheckbox
                    name='vendorId'
                    label={t('admin_manage_products_filter_vendor_label')}
                    value={!!valueFilter?.vendorId}
                    onChange={(checked) => {
                      onCheckboxChange('vendorId', checked);
                    }}
                  />

                  {isChecked?.vendorId && (
                    <div className={cx('contentFilterWrap')}>
                      <BaseSelectSearchLocal
                        isOpen
                        width={'100%'}
                        widthOptions={350}
                        height={31}
                        technicianList={
                          listVendor?.map((vendor) => ({
                            id: vendor?.id,
                            firstName: vendor?.name,
                            lastName: EMPTY_STRING,
                            isSelected: false,
                          })) || []
                        }
                        value={valueFilter?.vendorId}
                        onAdd={(assignee: IAddAssignee) => {
                          onChange({
                            name: 'vendorId',
                            value: String(assignee?.id),
                          });
                        }}
                        onCloseAssignee={(assignee: IAddAssignee) => {
                          onChange({
                            name: 'vendorId',
                            value: String(assignee?.id),
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        </BaseFilter>
      </Toolbar>

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleShowProductDetail,
              handleUpdateProduct,
              handleShowConfirmDeleteModal,
              handleSendToQBProduct
            )}
            dataSource={productsList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowConfirmDelete && productId && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteProduct}
        />
      )}

      {isShowUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />
      )}
    </div>
  );
};

export default ManageProducts;

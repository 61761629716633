// Others
import { CONTENT_TYPE_FORM_DATA, urlApiInvoice } from '~/utils/constants/common';
import { BaseResponse, IListDataResponseInvoice } from '~/utils/interface/common';
import {
  IBodyRecordInvoicesPayments,
  IDataCreateResponseInvoice,
  IGetListInvoiceReq,
  IInvoiceDetail,
  IInvoices,
} from '~/utils/interface/invoices';
import axiosClient from './axiosClient';

const invoiceApi = {
  getListInvoice(params: IGetListInvoiceReq) {
    const url = `${urlApiInvoice.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponseInvoice<IInvoices[]>>>(url, {
      params,
    });
  },

  createInvoice(formData: FormData) {
    const url = `${urlApiInvoice.createInvoice}`;
    return axiosClient.post<BaseResponse<IDataCreateResponseInvoice>>(url, formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  getDetailInvoice(invoiceId: string) {
    const url = `${urlApiInvoice.getDetail(invoiceId)}`;
    return axiosClient.get<BaseResponse<IInvoiceDetail>>(url);
  },

  recordPaymentBill(body: IBodyRecordInvoicesPayments) {
    const url = `${urlApiInvoice.recordPaymentInvoice}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default invoiceApi;

// Libs
import { Document, Page, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
import PurchaseOrderLogo from './components/logo/PurchaseOrderLogo';
import PurchaseOrderTitle from './components/title/PurchaseOrderTitle';
import PurchaseOrderContact from './components/contact/PurchaseOrderContact';
import PurchaseOrderBillTo from './components/billTo/PurchaseOrderBillTo';
import PurchaseOrderShipTo from './components/shipTo/PurchaseOrderShipTo';
import PurchaseOrderProduct from './components/product/PurchaseOrderProduct';
// Others
import { IPurchaseOrderData } from '~/utils/interface/purchaseOrder';
// Styles, images, icons
import { styles } from './style';

type Props = {
  dataPdf?: IPurchaseOrderData;
};

const PurchaseOrderPdf = (props: Props) => {
  //#region Destructuring Props
  const { dataPdf } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.mainHeader}>
          <PurchaseOrderLogo />

          <PurchaseOrderTitle />
        </View>

        <PurchaseOrderContact dataPdf={dataPdf!} />

        <View style={styles.mainBill}>
          <PurchaseOrderBillTo data={dataPdf!} />

          <PurchaseOrderShipTo />
        </View>

        <PurchaseOrderProduct data={dataPdf!} />
      </Page>
    </Document>
  );
};

export default PurchaseOrderPdf;

// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getListJobPhases } from '~/thunks/phases/phasesThunk';

export interface PhasesState {}

const initialState: PhasesState = {};

const phasesSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list product
    builder
      .addCase(getListJobPhases.pending, (state) => {})
      .addCase(getListJobPhases.fulfilled, (state) => {})
      .addCase(getListJobPhases.rejected, (state) => {});
  },
});

// Action
export const phasesActions = phasesSlice.actions;

// Reducer
const phasesReducer = phasesSlice.reducer;
export default phasesReducer;

// Libs
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseCheckbox,
  CommonConfirmModal,
  ConfirmModal,
  EstTemplateNameModal,
  FormPhaseContainer,
} from '~/components';
// Others
import { estimateSchema } from '~/components/specific/formEstimateContainer/helper';
import { LoadingData } from '~/context';
import { createEstimate, updateEstimate } from '~/thunks/estimate/estimateThunk';
import {
  DEFAULT_NUMBER_ZERO,
  DEFAULT_PHASE_CONTENT,
  DIV_ELEMENT,
  EMPTY_STRING,
  FORM_ESTIMATE_MODE_CREATE,
  FORM_ESTIMATE_MODE_EDIT,
} from '~/utils/constants/common';
import { AddEstimateEnum, ButtonTypeEnum, CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import {
  IEstimateContent,
  IEstimateDetail,
  IUpdateEstimatePayload,
} from '~/utils/interface/estimate';
import { TFormModalMode } from '~/utils/type/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FormEstimateContainer.module.scss';

type ConditionalData<T> = T extends 'edit' ? IEstimateDetail & IEstimateContent : null;

type Props<T> = {
  isTemplatePage?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  mode: T;
  data?: ConditionalData<T>;
};

const cx = classNames.bind(styles);

const FormEstimateContainer = <T extends TFormModalMode>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    onCancel,
    onSave,
    isTemplatePage = false,
    mode = FORM_ESTIMATE_MODE_CREATE,
    data = null,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const { jobId } = useParams();

  const defaultData: IEstimateContent = useMemo(() => {
    return { isTemplate: isTemplatePage, phases: [] };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IEstimateContent>({
    resolver: yupResolver(estimateSchema(t)),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'phases' });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Form Watch
  const phaseName = useWatch({ control, name: 'phases' }).map((phase) => phase.name);
  const phases = useWatch({ name: `phases`, control });
  const materialTotal = useWatch({ name: `budget.material`, control });
  const laborTotal = useWatch({ name: `budget.labor`, control });
  const subcontractorTotal = useWatch({ name: `budget.subcontractor`, control });
  const equipmentTotal = useWatch({ name: `budget.equipment`, control });
  const miscellaneaTotal = useWatch({ name: `budget.miscellanea`, control });
  const totalEstimate = useWatch({ name: `total`, control });
  //#endregion Form Watch

  //#region Declare State
  const [indexItemDelete, setIndexItemDelete] = useState<number>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowAddNameTemplate, setIsShowAddNameTemplate] = useState<boolean>(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);
  //#endregion Declare State

  //#region Declare useMemo
  const isDisableSave: boolean = useMemo(() => {
    if (mode === FORM_ESTIMATE_MODE_CREATE) return false;

    return !isDirty;
  }, [isDirty, mode]);
  //#endregion Declare useMemo

  //#region Implement Hook
  useEffect(() => {
    // Case create new
    if (mode === FORM_ESTIMATE_MODE_CREATE && !data) {
      reset(defaultData);
      return;
    }

    // Case create with selected a template
    if (mode === FORM_ESTIMATE_MODE_CREATE && data) {
      const dataForm: IEstimateContent = {
        isTemplate: false,
        phases: data.phases,
      };
      reset(dataForm);
      return;
    }

    if (mode === FORM_ESTIMATE_MODE_EDIT && data) {
      const dataForm: IEstimateContent = {
        isTemplate: data.isTemplate,
        budget: data.budget,
        total: data.total,
        phases: data.phases,
      };
      if (isTemplatePage) {
        dataForm.name = data.name;
      }
      reset(dataForm);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, data]);

  useEffect(() => {
    const materialsTotals = watch('phases')?.reduce((totals, phase) => {
      return (totals +=
        phase?.materials?.reduce(
          (sum, item) => sum + (item?.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    const laborsTotals = watch('phases')?.reduce((totals, phase) => {
      return (totals +=
        phase?.labors?.reduce(
          (sum, item) => sum + (item?.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    const subContractorsTotals = watch('phases')?.reduce((totals, phase) => {
      if (phase.subcontractor) {
        const unassignedTotal =
          phase.subcontractor?.unassignedWorks?.reduce(
            (sum, unassignedItem) => sum + Number(unassignedItem?.value || DEFAULT_NUMBER_ZERO),
            DEFAULT_NUMBER_ZERO
          ) || DEFAULT_NUMBER_ZERO;

        const assignedTotal =
          phase.subcontractor?.assignedSubcontractors?.reduce(
            (sum, assignedItem) => sum + Number(assignedItem?.value || DEFAULT_NUMBER_ZERO),
            DEFAULT_NUMBER_ZERO
          ) || DEFAULT_NUMBER_ZERO;

        return totals + unassignedTotal + assignedTotal;
      }
      return totals;
    }, DEFAULT_NUMBER_ZERO);

    const equipmentsTotals = watch('phases')?.reduce((totals, phase) => {
      return (totals +=
        phase?.equipments?.reduce(
          (sum, item) => sum + (item?.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    const miscellaneaTotals = watch('phases')?.reduce((totals, phase) => {
      return (totals +=
        phase?.miscellanea?.reduce(
          (sum, item) => sum + (item?.subTotal || DEFAULT_NUMBER_ZERO),
          DEFAULT_NUMBER_ZERO
        ) || DEFAULT_NUMBER_ZERO);
    }, DEFAULT_NUMBER_ZERO);

    const totalBudgets =
      materialsTotals + laborsTotals + equipmentsTotals + miscellaneaTotals + subContractorsTotals;

    setValue(`budget.material`, materialsTotals);
    setValue(`budget.labor`, laborsTotals);
    setValue(`budget.subcontractor`, subContractorsTotals);
    setValue(`budget.equipment`, equipmentsTotals);
    setValue(`budget.miscellanea`, miscellaneaTotals);
    setValue(`total`, totalBudgets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phases]);

  const handleCancelFormEstimate = () => {
    onCancel && onCancel();
  };

  const handleSubmitDataEst = (dataForm: IEstimateContent) => {
    if (!dataForm.phases || dataForm.phases.length === DEFAULT_NUMBER_ZERO) {
      setIsShowModalConfirm(true);
      return;
    }

    // Case add name for save Template
    if (dataForm.isTemplate) {
      setIsShowAddNameTemplate(true);
      return;
    }

    handleSaveEst(dataForm);
  };

  const handleSaveEst = (payloadData: IEstimateContent) => {
    if (mode === FORM_ESTIMATE_MODE_CREATE) {
      const payloadFormAdd: IEstimateContent = {
        ...payloadData,
        ...(jobId ? { jobId: +jobId } : {}),
      };
      handleAdd(payloadFormAdd);
      return;
    }

    if (mode === FORM_ESTIMATE_MODE_EDIT && data && data?.id) {
      const payloadFormUpdate: IUpdateEstimatePayload = { estimateId: data.id, body: payloadData };
      handleUpdate(payloadFormUpdate);
      return;
    }
  };

  const handleAdd = (payload: IEstimateContent) => {
    const formattedPayload = formatBody(payload);
    if (!formattedPayload) return;

    loadingData?.show();
    dispatch(createEstimate(formattedPayload))
      .unwrap()
      .then(() => {
        onSave && onSave();
      })
      .catch(() => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdate = (payload: IUpdateEstimatePayload) => {
    const formattedPayload: IUpdateEstimatePayload = {
      ...payload,
      body: formatBody(payload.body),
    };
    if (!formattedPayload) return;

    loadingData?.show();
    dispatch(updateEstimate(formattedPayload))
      .unwrap()
      .then(() => {
        onSave && onSave();
      })
      .catch(() => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const formatBody = (payload: IEstimateContent): IEstimateContent => {
    if (!payload.phases || !Array.isArray(payload.phases)) return payload;

    const formattedPhases = payload.phases.map((phase) => ({
      ...phase,
      materials: phase.materials?.map(({ productIdSelected, ...rest }) => rest) || [],
      equipments: phase.equipments?.map(({ productIdSelected, ...rest }) => rest) || [],
      miscellanea: phase.miscellanea?.map(({ productIdSelected, ...rest }) => rest) || [],
    }));

    return {
      ...payload,
      phases: formattedPhases,
    };
  };

  const toggleRemovePhase = (indexRemove: number) => {
    setIndexItemDelete(indexRemove);
    setIsShowConfirmDelete(true);
  };

  const handleCancelConfirmDeleteModal = () => {
    setIsShowConfirmDelete(false);
  };

  const handleRemovePhase = () => {
    remove(indexItemDelete);
    setIsShowConfirmDelete(false);
    reset({ phases: getValues().phases }, { keepDirty: true });
  };

  const handleCloseModalConfirm = () => {
    setIsShowModalConfirm(false);
  };

  const handleCloseModalAddTemplateName = () => {
    setIsShowAddNameTemplate(false);
  };
  //#endregion Handle Function

  return (
    <>
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          getValues,
          formState: { errors },
        } as UseFormReturn<IEstimateContent>)}
      >
        <form
          id='formEstimateComponent'
          className={cx('container')}
          onSubmit={handleSubmit(handleSubmitDataEst)}
        >
          <div className={cx('budgetHeader')}>
            <div className={cx('header')}>
              <p className={cx('title')}>
                {t('template_add_estimate_number_phase', { number: watch('phases').length })}
              </p>

              <div className={cx('btnGroup')}>
                {(mode === FORM_ESTIMATE_MODE_CREATE || isTemplatePage) && (
                  <BaseButton
                    label={t('common_btn_cancel')}
                    width={117}
                    typeStyle={ButtonTypeEnum.CANCEL}
                    type='button'
                    onClick={handleCancelFormEstimate}
                  />
                )}

                <BaseButton
                  label={t('common_btn_save')}
                  width={117}
                  typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                  type='submit'
                  isDisable={isDisableSave} // Todo: SonVuGRF-ABD Need update logic
                />
              </div>
            </div>

            <section className={cx('budgetContainer')}>
              <p className={cx('budgetTitle')}>{t('template_add_estimate_budget_section_title')}</p>

              <div className={cx('budgetNumbersGroup')}>
                <div className={cx('budgetNumberItem')}>
                  <p className={cx('budgetNumberLabel')}>
                    {t('template_add_estimate_budget_section_total')}
                  </p>

                  <p className={cx('budgetNumberValue')}>
                    {formatCurrency(CurrencyEnum.USD, Number(totalEstimate) || 0)}
                  </p>
                </div>

                <div className={cx('budgetNumberItem')}>
                  <p className={cx('budgetNumberLabel')}>
                    {t('template_add_estimate_budget_section_materials')}
                  </p>

                  <p className={cx('budgetNumberValue')}>
                    {formatCurrency(CurrencyEnum.USD, Number(materialTotal) || 0)}
                  </p>
                </div>

                <div className={cx('budgetNumberItem')}>
                  <p className={cx('budgetNumberLabel')}>
                    {t('template_add_estimate_budget_section_labor')}
                  </p>

                  <p className={cx('budgetNumberValue')}>
                    {formatCurrency(CurrencyEnum.USD, Number(laborTotal) || 0)}
                  </p>
                </div>

                <div className={cx('budgetNumberItem')}>
                  <p className={cx('budgetNumberLabel')}>
                    {t('template_add_estimate_budget_section_subcontractors')}
                  </p>

                  <p className={cx('budgetNumberValue')}>
                    {formatCurrency(CurrencyEnum.USD, Number(subcontractorTotal) || 0)}
                  </p>
                </div>

                <div className={cx('budgetNumberItem')}>
                  <p className={cx('budgetNumberLabel')}>
                    {t('template_add_estimate_budget_section_equipment')}
                  </p>

                  <p className={cx('budgetNumberValue')}>
                    {formatCurrency(CurrencyEnum.USD, Number(equipmentTotal) || 0)}
                  </p>
                </div>

                <div className={cx('budgetNumberItem')}>
                  <p className={cx('budgetNumberLabel')}>
                    {t('template_add_estimate_budget_section_miscellanea')}
                  </p>

                  <p className={cx('budgetNumberValue')}>
                    {formatCurrency(CurrencyEnum.USD, Number(miscellaneaTotal) || 0)}
                  </p>
                </div>
              </div>
            </section>
          </div>

          <div className={cx('formContent')}>
            <CommonConfirmModal
              isOpen={isShowModalConfirm}
              onClose={handleCloseModalConfirm}
              title={t('template_add_estimate_label_create_phase_content')}
            />
            {fields.map((field, index) => (
              <Disclosure
                key={field.id}
                as={DIV_ELEMENT}
                className={cx('accordionContainer')}
                defaultOpen={!field?.name && index === fields.length - 1}
              >
                <div className={cx('accordionBtnContainer')}>
                  <DisclosureButton className={cx('group', 'accordionBtnWrap')}>
                    <img
                      src={icons.commonIconArrowBottom}
                      className={cx('accordionIconArrow')}
                      alt={t('common_img_text_alt')}
                    />

                    <span className={cx('accordionPhaseName')}>
                      {phaseName[index] ? phaseName[index] : EMPTY_STRING}
                    </span>
                  </DisclosureButton>

                  <div className={cx('accordionActionWrap')}>
                    <button
                      className={cx('buttonActionContact')}
                      type='button'
                      onClick={() => toggleRemovePhase(index)}
                    >
                      <img src={icons.adminIconDeleteContact} alt={t('common_img_text_alt')} />
                    </button>
                  </div>
                </div>

                <DisclosurePanel transition className={cx('accordionPanel')}>
                  <FormPhaseContainer
                    phaseIndex={index}
                    isShowSetContractItemTemplate={!isTemplatePage}
                  />
                </DisclosurePanel>
              </Disclosure>
            ))}

            <button
              type='button'
              className={cx('btnAddItem', 'group')}
              onClick={() => {
                append(DEFAULT_PHASE_CONTENT);
              }}
            >
              <img
                src={icons.commonIconAddField}
                alt={t('common_img_text_alt')}
                className={cx('iconAddItem')}
              />
              <span className={cx('btnTextItem')}>{t('template_add_estimate_btn_add_phase')}</span>
            </button>

            {!isTemplatePage && (
              <div className={cx('saveTemplate')}>
                <Controller
                  name={AddEstimateEnum.SAVE_TEMPLATE}
                  control={control}
                  render={({ field: { value, onChange, name } }) => (
                    <BaseCheckbox
                      name={name}
                      value={value}
                      label={t('template_add_estimate_label_save_template')}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </form>

        {isShowConfirmDelete && (
          <ConfirmModal
            title={t('common_modal_confirm_delete_title')}
            titleAction={t('common_btn_delete')}
            onCancel={handleCancelConfirmDeleteModal}
            onAction={handleRemovePhase}
          />
        )}

        <EstTemplateNameModal
          isOpen={isShowAddNameTemplate}
          onClose={handleCloseModalAddTemplateName}
          onSave={handleSaveEst}
        />
      </FormProvider>
    </>
  );
};

export default FormEstimateContainer;

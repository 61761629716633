// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// Components, Layouts, Pages
import {
  BaseTable,
  BasePagination,
  Toolbar,
  BaseSelect,
  BaseButton,
  SettingExpensesDefault,
} from '~/components';
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  typeDefaultExpensesAccount,
  typeDefaultPaymentAccount,
} from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { ColumnType, IBaseOption, IPagination, IQueryBase } from '~/utils/interface/common';
import useDebounce from '~/utils/hooks/useDebounce';
import { BaseTableEnum, ButtonTypeEnum } from '~/utils/enum';
import {
  IBodyUpdateExpensesDefault,
  IExpensesDefault,
  ISettingExpensesDefault,
} from '~/utils/interface/expensesDefault';
import { accountantExpensesDefault, initialValues } from './helper';
import {
  getListAccountQuickBook,
  getListExpensesDefault,
  updateExpensesDefault,
} from '~/thunks/quickBook/quickBookThunk';
import { IParamsListPaymentExpenses } from '~/utils/interface/quickBook';
import { quickBookActions } from '~/thunks/quickBook/quickBookSlice';
import { RootState } from '~/redux/store';
// Styles, images, icons
import styles from './AccountantExpensesDefaultTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const AccountantExpensesDefaultTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<{ items: IBodyUpdateExpensesDefault[] }>({
    resolver: yupResolver(accountantExpensesDefault()),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListExpensesDefault } = useAppSelector((state: RootState) => state.quickBook);
  //#endregion Selector

  //#region Declare State
  const [expensesDefault, setExpensesDefault] = useState<ISettingExpensesDefault>({
    quickBookExpenseAccountId: EMPTY_STRING,
    quickBookPaymentAccountId: EMPTY_STRING,
  });
  const [expensesDefaultList, setExpensesDefaultList] = useState<IExpensesDefault[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);

  const [listDefaultExpensesAccount, setListDefaultExpensesAccount] = useState<IBaseOption[]>([]);
  const [listDefaultPaymentAccount, setListDefaultPaymentAccount] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!expensesDefaultList || expensesDefaultList.length === 0) {
      return;
    }

    const items = expensesDefaultList.map((expense) => {
      return {
        vendorId: expense.id,
        quickBookExpenseAccountId: expense.quickBookExpenseAccountId || EMPTY_STRING,
        quickBookPaymentAccountId: expense.quickBookPaymentAccountId || EMPTY_STRING,
      };
    });

    reset({
      items: items,
    });
  }, [expensesDefaultList]);

  useEffect(() => {
    const paramListDefaultExpensesAccount: IParamsListPaymentExpenses = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      type: typeDefaultExpensesAccount,
    };

    const paramListDefaultPaymentAccount: IParamsListPaymentExpenses = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      type: typeDefaultPaymentAccount,
    };

    handleGetListDefaultExpensesAccount(paramListDefaultExpensesAccount);
    handleGetListDefaultPaymentAccount(paramListDefaultPaymentAccount);
  }, []);

  useEffect(() => {
    const { tab, ...rest } = params;
    const payload = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListExpensesDefault(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshListExpensesDefault) return;

    const { tab, ...rest } = params;
    const payload = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListExpensesDefault(payload);
    dispatch(quickBookActions.setRefreshListExpensesDefault(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListExpensesDefault]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListExpensesDefault = (params: IQueryBase) => {
    loading?.show();

    dispatch(getListExpensesDefault(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const {
          pagination,
          responses,
          quickBookDefaultExpenseAccountId,
          quickBookDefaultPaymentAccountId,
        } = res.data;
        const expensesDefaultSetting: ISettingExpensesDefault = {
          quickBookExpenseAccountId: quickBookDefaultExpenseAccountId,
          quickBookPaymentAccountId: quickBookDefaultPaymentAccountId,
        };

        setExpensesDefault(expensesDefaultSetting);
        setExpensesDefaultList(responses);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleGetListDefaultExpensesAccount = (params: IQueryBase) => {
    dispatch(getListAccountQuickBook(params))
      .unwrap()
      .then((response) => {
        if (!response?.data) return;
        const { responses } = response.data;
        const newListDefaultExpensesAccount: IBaseOption[] = responses.map((response) => {
          return {
            label: response.name || EMPTY_STRING,
            value: response.id,
          };
        });
        setListDefaultExpensesAccount(newListDefaultExpensesAccount);
      })
      .catch((_err) => {})
      .finally(() => {});
  };

  const handleGetListDefaultPaymentAccount = (params: IQueryBase) => {
    dispatch(getListAccountQuickBook(params))
      .unwrap()
      .then((response) => {
        if (!response?.data) return;
        const { responses } = response.data;
        const newListDefaultPaymentAccount: IBaseOption[] = responses.map((response) => {
          return {
            label: response.name || EMPTY_STRING,
            value: response.id,
          };
        });
        setListDefaultPaymentAccount(newListDefaultPaymentAccount);
      })
      .catch((_err) => {})
      .finally(() => {});
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSearch = (value: string) => {
    setSearchKey(value);
  };

  const columns = useMemo((): ColumnType<IExpensesDefault>[] => {
    return [
      {
        key: 'vendor',
        title: t('expenses_default_tab_vendor_label'),
        render(value, record, index) {
          return <>{record?.name || EMPTY_STRING}</>;
        },
      },
      {
        key: 'defaultExpensesAccount',
        title: t('expenses_default_tab_default_expenses_account_label'),
        render(value, record, index) {
          return (
            <Controller
              name={`items.${index}.quickBookExpenseAccountId`}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <BaseSelect
                    placeholder={t('common_placeholder_select')}
                    options={listDefaultExpensesAccount || []}
                    value={value || EMPTY_STRING}
                    onChange={({ value }) => onChange(value)}
                  />
                );
              }}
            />
          );
        },
      },
      {
        key: 'defaultPaymentAccount',
        title: t('expenses_default_tab_default_payment_account_label'),
        render(value, record, index) {
          return (
            <Controller
              name={`items.${index}.quickBookPaymentAccountId`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  options={listDefaultPaymentAccount || []}
                  placeholder={t('common_placeholder_select')}
                  value={value}
                  onChange={({ value }) => onChange(value)}
                />
              )}
            />
          );
        },
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, control, expensesDefaultList, listDefaultExpensesAccount, listDefaultPaymentAccount]);

  const handleSubmitForm = (data: { items: IBodyUpdateExpensesDefault[] }) => {
    if (!data.items || data.items.length === DEFAULT_NUMBER_ZERO) return;

    loading?.show();
    dispatch(updateExpensesDefault(data.items))
      .unwrap()
      .then((res) => {})
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleApplySettingSuccess = () => {
    dispatch(quickBookActions.setRefreshListExpensesDefault(true));
  };
  //#endregion Handle Function

  return (
    <form id='accountantExpensesDefaultTab' className={cx('container')}>
      <Toolbar onSearch={handleSearch} rounded={false} valueSearch={searchKey || EMPTY_STRING}>
        <SettingExpensesDefault
          expensesDefault={expensesDefault}
          onApplySetting={handleApplySettingSuccess}
        />

        <BaseButton
          label={t('common_btn_save')}
          type='button'
          typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
          isDisable={!isDirty}
          onClick={handleSubmit(handleSubmitForm)}
        />
      </Toolbar>

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns}
            dataSource={expensesDefaultList || []}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </form>
  );
};

export default AccountantExpensesDefaultTab;

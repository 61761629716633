// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
// Others
import { getFileNameFromUrl } from '~/utils/helper';
import {
  ASTERISK_SYMBOL,
  DEFAULT_UPLOAD_ACCEPT_IMAGE_AND_PDF,
  EMPTY_STRING,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './UploadFile.module.scss';
import { icons } from '~/assets';

type Props = {
  name: string;
  width?: number | string;
  height?: number | string;
  maxSize?: number;
  resetFile?: boolean;
  errorMessage?: string;
  fileUrl?: string;
  isRequired?: boolean;
  accept?: string;
  onFileChange: (name: string, file: File | null) => void;
};

const cx = classNames.bind(styles);

const UploadFile = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    width,
    height,
    maxSize,
    resetFile,
    errorMessage,
    fileUrl,
    isRequired,
    accept = DEFAULT_UPLOAD_ACCEPT_IMAGE_AND_PDF,
    onFileChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileName, setFileName] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (resetFile) {
      resetInputFile();
    }

    if (fileUrl) {
      const fileNameConverted = getFileNameFromUrl(fileUrl);
      fileNameConverted && setFileName(fileNameConverted);
    }
  }, [resetFile, fileUrl]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    const { name } = event.target;

    if (!file || !name) return;

    if (file && maxSize && file.size > maxSize) {
      return;
    }

    setFileName(file.name);
    onFileChange(name, file);
  };

  const resetInputFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = EMPTY_STRING;
      setFileName(EMPTY_STRING);
      onFileChange(name, null);
    }
  };
  //#endregion Handle Function

  return (
    <div id='uploadFileComponent' className={cx('container')}>
      <div className={cx('boxInput')} style={{ width, height }}>
        <p className={cx('label')}>
          <span>
            {fileName ? (
              `${t('common_selected_document_label')}: `
            ) : (
              <>
                {`${t('common_select_document_label')} `}
                {isRequired && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
              </>
            )}

            {fileName ? fileName : EMPTY_STRING}
          </span>
        </p>

        <label htmlFor={name} className={cx('fileUpload')}>
          <img
            src={icons.commonIconUploadFile}
            className={cx('iconUpload')}
            alt={t('common_img_text_alt')}
          />

          <input
            ref={fileInputRef}
            name={name}
            type='file'
            id={name}
            accept={accept}
            className={cx('fileInput')}
            onChange={handleFileChange}
          />
        </label>
      </div>

      {errorMessage && <p className={cx('errorMessage')}>{errorMessage}</p>}
    </div>
  );
};

export default UploadFile;

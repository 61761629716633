// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// Components, Layouts, Pages
import {
  BaseTable,
  BasePagination,
  Toolbar,
  BaseSelect,
  BaseButton,
  SettingIncomeDefault,
} from '~/components';
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  typeDefaultPaymentAccount,
} from '~/utils/constants/common';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { LoadingData } from '~/context';
import { ColumnType, IBaseOption, IPagination, IQueryBase } from '~/utils/interface/common';
import useDebounce from '~/utils/hooks/useDebounce';
import { BaseTableEnum, ButtonTypeEnum } from '~/utils/enum';
import { accountantIncomeDefault, initialValues } from './helper';
import {
  getListAccountQuickBook,
  getListIncomeDefault,
  updateIncomeDefault,
} from '~/thunks/quickBook/quickBookThunk';
import { IParamsListPaymentIncome } from '~/utils/interface/quickBook';
import {
  IBodyUpdateIncomeDefault,
  IIncomeDefault,
  ISettingIncomeDefault,
} from '~/utils/interface/incomeDefault';
import { getFullName } from '~/utils/helper';
import { RootState } from '~/redux/store';
import { quickBookActions } from '~/thunks/quickBook/quickBookSlice';
// Styles, images, icons
import styles from './AccountantIncomeDefaultTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const AccountantIncomeDefaultTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  const dispatch = useAppDispatch();
  const loading = useContext(LoadingData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<{ items: IBodyUpdateIncomeDefault[] }>({
    resolver: yupResolver(accountantIncomeDefault()),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshListIncomeDefault } = useAppSelector((state: RootState) => state.quickBook);
  //#endregion Selector

  //#region Declare State
  const [incomeDefaultList, setIncomeDefaultList] = useState<IIncomeDefault[]>([]);
  const [incomeSettingDefault, setIncomeSettingDefault] = useState<ISettingIncomeDefault>({
    quickBookIncomePaymentAccountId: EMPTY_STRING,
  });
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);

  const [listDefaultPaymentAccount, setListDefaultPaymentAccount] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!incomeDefaultList || incomeDefaultList.length === 0) {
      return;
    }

    const items = incomeDefaultList.map((expense) => {
      return {
        clientId: expense.id,
        quickBookPaymentAccountId: expense.quickBookPaymentAccountId || EMPTY_STRING,
      };
    });

    reset({
      items: items,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeDefaultList]);

  useEffect(() => {
    const paramListDefaultPaymentAccount: IParamsListPaymentIncome = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      type: typeDefaultPaymentAccount,
    };

    handleGetListDefaultPaymentAccount(paramListDefaultPaymentAccount);
  }, []);

  useEffect(() => {
    const { tab, ...rest } = params;
    const payload = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListIncomeDefault(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshListIncomeDefault) return;

    const { tab, ...rest } = params;
    const payload = {
      ...rest,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetListIncomeDefault(payload);
    dispatch(quickBookActions.setRefreshListIncomeDefault(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshListIncomeDefault]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListIncomeDefault = (params: IQueryBase) => {
    loading?.show();

    dispatch(getListIncomeDefault(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { pagination, responses, quickBookDefaultPaymentAccountId } = res.data;
        const incomeSettingDefault: ISettingIncomeDefault = {
          quickBookIncomePaymentAccountId: quickBookDefaultPaymentAccountId || EMPTY_STRING,
        };

        setIncomeDefaultList(responses);
        setIncomeSettingDefault(incomeSettingDefault);
        setPagination(pagination);
      })
      .catch((err) => {})
      .finally(() => loading?.hide());
  };

  const handleGetListDefaultPaymentAccount = (params: IQueryBase) => {
    dispatch(getListAccountQuickBook(params))
      .unwrap()
      .then((response) => {
        if (!response?.data) return;
        const { responses } = response.data;
        const newListDefaultPaymentAccount: IBaseOption[] = responses.map((response) => {
          return {
            label: response.name || EMPTY_STRING,
            value: response.id,
          };
        });
        setListDefaultPaymentAccount(newListDefaultPaymentAccount);
      })
      .catch((_err) => {})
      .finally(() => {});
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSearch = (value: string) => {
    setSearchKey(value);
  };

  const columns = useMemo((): ColumnType<IIncomeDefault>[] => {
    return [
      {
        key: 'client',
        title: t('income_default_tab_client_label'),
        render(value, record, index) {
          return (
            <>
              {getFullName({
                firstName: record?.firstName,
                lastName: record?.lastName,
              }) || EMPTY_STRING}
            </>
          );
        },
        width: '50%',
      },

      {
        key: 'defaultPaymentAccount',
        title: t('income_default_tab_default_payment_account_label'),
        width: '50%',
        render(value, record, index) {
          return (
            <Controller
              name={`items.${index}.quickBookPaymentAccountId`}
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseSelect
                  options={listDefaultPaymentAccount || []}
                  placeholder={t('common_placeholder_select')}
                  value={value}
                  onChange={({ value }) => onChange(value)}
                />
              )}
            />
          );
        },
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, control, incomeDefaultList, listDefaultPaymentAccount]);

  const handleSubmitForm = (data: { items: IBodyUpdateIncomeDefault[] }) => {
    if (!data.items || data.items.length === DEFAULT_NUMBER_ZERO) return;
    loading?.show();

    dispatch(updateIncomeDefault(data.items))
      .unwrap()
      .then((res) => {})
      .catch((error) => {})
      .finally(() => loading?.hide());
  };

  const handleApplySettingSuccess = () => {
    dispatch(quickBookActions.setRefreshListIncomeDefault(true));
  };
  //#endregion Handle Function

  return (
    <form id='accountantIncomeDefaultTab' className={cx('container')}>
      <Toolbar onSearch={handleSearch} rounded={false} valueSearch={searchKey || EMPTY_STRING}>
        <SettingIncomeDefault
          incomeSettingDefault={incomeSettingDefault}
          onApplySetting={handleApplySettingSuccess}
        />

        <BaseButton
          label={t('common_btn_save')}
          type='button'
          typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
          isDisable={!isDirty}
          onClick={handleSubmit(handleSubmitForm)}
        />
      </Toolbar>

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns}
            dataSource={incomeDefaultList || []}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </form>
  );
};

export default AccountantIncomeDefaultTab;

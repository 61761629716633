// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseMoreAction,
  BasePagination,
  BaseTable,
  ConfirmModal,
  ModalUnderDevelopment,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteEstimate, getEstimateList } from '~/thunks/estimate/estimateThunk';
import { RootState } from '~/redux/store';
import { estimateActions } from '~/thunks/estimate/estimateSlice';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import { IEstimate, IEstimateTableParams } from '~/utils/interface/estimate';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, BaseTableEnum, StorageEnum } from '~/utils/enum';
import {
  adminRouteAbsolute,
  projectManageRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { formatNumber } from '~/utils/helper';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import styles from './Estimate.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  isSelectAll: boolean,
  onSelectAll: () => void,
  onSelectItem: (id: string) => void,
  handleShowDetailEstimate: (id: string) => void,
  handleShowConfirmDeleteModal: (id: string) => void
): ColumnType<IEstimate>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={() => onSelectAll()}
    //     />
    //   ),
    //   render(_, record) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => onSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'name',
      title: t('estimate_table_label_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'action',
      title: t('admin_manage_products_table_action'),
      dataIndex: 'id',
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view_and_edit'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleShowDetailEstimate(record.id),
          },
          {
            label: t('common_btn_delete'),
            icon: (
              <img
                src={icons.commonIconTrash}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            hasOtherColor: true,
            onClick: () => handleShowConfirmDeleteModal(record.id),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
      width: '10%',
    },
  ];
};

const Estimate = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isRefreshEstimateList } = useAppSelector((state: RootState) => state.estimate);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  const textSearchParams = useMemo<string>(
    () => String(params?.searchKey || EMPTY_STRING),
    [params?.searchKey]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [estimateList, setEstimateList] = useState<IEstimate[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [estimateId, setEstimateId] = useState<string>(EMPTY_STRING);

  const [isShowDevelopment, setIsShowDevelopment] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(textSearchParams || EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey.trim(), DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const newParams = {
      ...params,
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    handleGetEstimateList({ ...newParams, isTemplate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!textSearchParams) setSearchKey(EMPTY_STRING);
  }, [textSearchParams]);

  useEffect(() => {
    if (!isRefreshEstimateList) return;

    const newParams = {
      page: Number(params.page) || DEFAULT_CURRENT_PAGE,
      limit: Number(params.limit) || DEFAULT_NUMBER_RECORD_TO_FETCH,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : {}),
      isTemplate: true,
    };

    handleGetEstimateList(newParams);
    dispatch(estimateActions.setRefreshList(false));
  }, [isRefreshEstimateList]);

  useEffect(() => {
    if (estimateList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = estimateList.every((item: IEstimate) => item.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = estimateList
      .filter((item: IEstimate) => item.isSelected)
      .map((item: IEstimate) => item.id);

    //@Todo: Perform interactions with the estimate id list
    console.log('Selected Estimate IDs:', selectedIds);
  }, [estimateList]);

  useEffect(() => {
    if (!pagination) return;

    if (debouncedSearchKey) {
      setSearchParams({
        ...params,
        page: DEFAULT_CURRENT_PAGE.toString(),
        limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
        searchKey: debouncedSearchKey,
      });
    } else {
      const { searchKey, ...rest } = params;
      setSearchParams(rest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetEstimateList = (data: IEstimateTableParams) => {
    loadingData?.show();

    dispatch(getEstimateList(data))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { pagination, responses } = res.data;

        setEstimateList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAddNewEstimate = () => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.createNewEstimate}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.createNewEstimate}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.createNewEstimate}`);
        break;
    }
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowDevelopment(!isShowDevelopment);
  };

  const handleActionViewEditEstimate = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.detailEstimate}/${id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.detailEstimate}/${id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.detailEstimate}/${id}`);
        break;
    }
  };

  const handleShowConfirmDeleteModal = (id?: string) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);

    if (id) {
      setEstimateId(id);
    }
  };

  const handleDeleteEstimate = () => {
    if (!estimateId) return;

    loadingData?.show();
    dispatch(deleteEstimate(estimateId))
      .unwrap()
      .then((res) => {
        handleActionEstimateSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleActionEstimateSuccess = () => {
    dispatch(estimateActions.setRefreshList(true));
  };

  const handlePaginationChange = (page: number) => {
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSelectAll = () => {
    setEstimateList((prevList) =>
      prevList.map((item: IEstimate) => ({
        ...item,
        isSelected: !isSelectAll,
      }))
    );
  };

  const handleSelectItem = (id: string) => {
    setEstimateList((prevList: IEstimate[]) =>
      prevList.map((item: IEstimate) =>
        item.id === id ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleCLickRow = (record: IEstimate) => {
    const role = localStorage.getItem(StorageEnum.ROLE);

    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        navigate(`${adminRouteAbsolute.detailEstimate}/${record.id}`);
        break;

      case AccountRoleCodesEnum.STAFF:
        navigate(`${staffRouteAbsolute.detailEstimate}/${record.id}`);
        break;

      case AccountRoleCodesEnum.PROJECT_MANAGER:
        navigate(`${projectManageRouteAbsolute.detailEstimate}/${record.id}`);
        break;
    }
  };

  const handleSearchEstimate = (value: string) => {
    setSearchKey(value);
  };
  //#endregion Handle Function

  return (
    <div id='estimatePage' className={cx('container')}>
      <Toolbar
        title={t('estimate_title', {
          total: formatNumber(estimateList?.length ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{ action: handleAddNewEstimate, label: t('estimate_add_new_estimate') }}
        onSearch={handleSearchEstimate}
        valueSearch={searchKey || EMPTY_STRING}
      />

      <div className={cx('body')}>
        <div className={cx('table')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleActionViewEditEstimate,
              handleShowConfirmDeleteModal
            )}
            dataSource={estimateList || []}
            onClickRow={handleCLickRow}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowConfirmDelete && estimateId && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteEstimate}
        />
      )}

      {isShowDevelopment && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />}
    </div>
  );
};

export default Estimate;
